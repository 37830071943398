









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LogoMarca extends Vue {

  @Prop({default: false }) public inverse!: string;
}
