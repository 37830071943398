



























import Step from '@/components/steps/StepProgress.vue';
import { VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
    components: {
        Step
    }
})
export default class JobStep extends Vue {
    
    @Prop({ default: "" })
    public status!: string;
    
    @Prop({ default: false })
    public canAnswer!: boolean;

    @Prop({ default: false })
    public requireFullProfile!: boolean;

    @Prop({ default: false })
    public hiddenCurriculumDone!: boolean;
    
    @Prop({ default: null })
    public vaga!: VagaEntity|null;
    
    public progress: any|null = null;
    public progressContent: any[] = [];
    public current: number = 0;
    public ALLSTATUSORDERS: string[] = [
        'EMREGISTRO',
        'EMREPESCAGEM', 
        'EXAMESPENDENTES',
        'EXAMESEMANALISE',
        'EXAMESREPROVADO',
        'VIDEOSPENDENTES', 
        'VIDEOSEMPROCESSAMENTO',
        'EMANALISE',
        'APROVADO',
        'REPROVADO'
    ];

    @Watch('status')
    public updateFromStatus() {
        this.statusToSteps()
    }

    @Watch('vaga')
    public updateFromVaga() {
        this.statusToSteps()
    }
    
    get hasHOrP()
    {
        return ((this.vaga!.PerguntasCorte && this.vaga!.PerguntasCorte!.length>0) || (this.vaga!.vagaHabilidades && this.vaga!.vagaHabilidades!.length>0) || (this.vaga?.questionaries && this.vaga?.questionaries?.length > 0)) ;
    }

    public mounted() {
        
        this.statusToSteps();
    }

    public getNextMessage() {
        if(([1,4].indexOf(this.ALLSTATUSORDERS.indexOf(this.status)) >= 0)) {
            return this.$t("Thank you for your interest in the vacancy, at the moment your profile did not pass the pre-filter. Don't be discouraged and stay tuned, the recruiter can still give you the chance to record the interview").toString();
        }
        return this.$t("Next Step").toString();
    }

    public statusToSteps() {
        this.progressContent = [
            {
                current: 0,
                message: this.$t("To apply for this vacancy you need to complete your JOBECAM profile").toString(),
                title: this.$t("Complete my profile").toString(),
                icon: 'fa fa-address-card',
                btnText: this.$t('Let`s Go').toString(),
                // em registro é uma regra diferente
                completed: !this.requireFullProfile || this.vaga?.isCandidatado ,
                showWhen: (((this.ALLSTATUSORDERS.indexOf(this.status) > 0)) || !this.requireFullProfile) && !this.vaga?.mostrarCurriculoOculto,
                action: () => {
                    if(!this.vaga?.isCandidatado) {
                        this.$emit('goToProfile')
                    } else {
                        this.$emit('apply')
                    }
                        
                }
            },
            {
                current: 1,
                message: this.$t("To apply for this vacancy you need to create your hidden curriculum").toString(),
                title: this.$t("Anonymous Curriculum").toString(),
                icon: 'fa fa-address-card',
                btnText: this.$t('Let`s Go').toString(),
                // em registro é uma regra diferente
                completed: this.hiddenCurriculumDone || this.vaga?.isCandidatado,
                showWhen: ((this.ALLSTATUSORDERS.indexOf(this.status) > 0)) || (this.vaga?.mostrarCurriculoOculto),
                action: () => {
                    if(this.hiddenCurriculumDone) {
                        this.$emit('applyChecked')
                    } else {
                        this.$emit('apply')
                    }
                }
            },
            {
                current: 2,
                message:  this.$t("Now you need to perform a test proposed by the recruiter").toString(),
                title: this.$t("Online Test").toString(),
                icon: 'fa fa-tasks',
                btnText: this.$t('Let`s Go').toString(),
                completed: (this.ALLSTATUSORDERS.indexOf(this.status) > 2),
                showWhen: this.status == 'EXAMESPENDENTES' || (this.hasHOrP),
                action: () => {
                    if(!this.vaga?.isCandidatado) {
                        this.$emit('applyChecked')
                    } else {
                        this.$emit('goToExams')
                    }
                }
            },
            {
                current: 3,
                message:  this.$t("The recruiting team has prepared some questions for you. You must answer these questions by recording a video. Check your connection, take a deep breath and").toString(),
                title: this.$t("Record Interview").toString(),
                icon: 'fa fa-video',
                btnText: this.$t('Let`s Go').toString(),
                completed: (this.ALLSTATUSORDERS.indexOf(this.status) > 5 && !(this.status == 'EMANALISE' && this.canAnswer)),
                showWhen: (this.ALLSTATUSORDERS.indexOf(this.status) == 5 || (this.canAnswer) || !(this.hasHOrP) ) ,
                action: () => {
                    this.$emit('goToInterview')
                }
            },
            {
                current: 4,
                message:  this.$t("You have completed all the necessary steps. Please wait for the recruitment team's analysis").toString(),
                title: this.$t("Completed steps").toString(),
                icon: 'far fa-check-circle',
                btnText: 'Ok',
                completed: (this.ALLSTATUSORDERS.indexOf(this.status) > 6 && !this.canAnswer),
                showWhen: (this.ALLSTATUSORDERS.indexOf(this.status) > 5 ) ,
                action: () => {
                    this.$bvModal.hide('modal-job-step')
                    return;
                }
            },
            {
                current: 5,
                message: this.getNextMessage(),
                title: this.$t("Under Review").toString(),
                icon: 'far fa-question-circle',
                completed: false,
                btnText: this.$t('Back').toString(),
                showWhen: (this.ALLSTATUSORDERS.indexOf(this.status) < 5) ,
                action: () => {
                    this.$bvModal.hide('modal-job-step')
                }
            }
        ];
        this.progress = {
            currentSteps: 0,
            countSteps: 0,
            steps: [],
            icons: []
        }
        const array = this.progressContent;
        let previousCompleted: boolean = false;
        let hasCompleted: boolean = false;
        let firstStep: number|null = null;
        for (let index = 0; index < array.length; index++) {
            const element: any = array[index];
            if(element.showWhen) {
                
                if(firstStep == null) {
                    firstStep = element.current;
                }

                this.progress.steps.push(element.title)
                this.progress.icons.push(element.icon)
                this.progress.countSteps++

                if(previousCompleted == true) {
                    this.current = element.current;
                    previousCompleted = false
                }

                if(element.completed) {
                    this.progress.currentSteps++;
                    this.current = element.current;
                    if(element.current == 4) {
                        previousCompleted = false
                    }
                    previousCompleted = true;
                    hasCompleted = true;
                }

                if( index == (array.length-1) && !hasCompleted ) {
                    this.current = firstStep||0
                }
            }
        }
    }

}
