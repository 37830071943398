
























import CardBox from '@/components/CardBox.vue';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  components: {
    'card-box': CardBox,
  },
})
export default class CardWarning extends Vue {}
