




import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class RootPublicArea extends Vue {

  updated() {
    // /* console.log('update root') */
  }

  mounted() {
    // console.info('App this router:', this.$router)
    // console.info('App currentRoute:', this.$router.currentRoute)
  }
}
