
export interface UiState {
  showVagasFilter: boolean
};

const state: UiState = {
  showVagasFilter: false
};

export default state;


/*
export class UiState {
  public showVagasFilter: false = false;
};

const state: UiState = new UiState();
*/