import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import BootstrapVue from 'bootstrap-vue';
import { i18n } from './plugins/i18n';
import ShareSession from '@/plugins/ShareSession';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import VueScrollTo from 'vue-scrollto';
import VuePromiseBtn from 'vue-promise-btn';
import VueClipboard from 'vue-clipboard2';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueAnalytics from "vue-analytics";
import Vuelidate from 'vuelidate';
import ConfirmPlugin from './plugins/ConfirmPlugin';
import AlertPlugin from './plugins/AlertPlugin';
import ToastPlugin from './plugins/ToastPlugin';
import MomentPlugin from './plugins/MomentPlugin';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueTour from 'vue-tour';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app"
import "@firebase/firestore";
import "@firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCwpezZ0RBp9iJh0XKRiwEKDz2Ba7cBFh4",
  authDomain: "jobecam-candidate-prd.firebaseapp.com",
  databaseURL: "https://jobecam-candidate-prd.firebaseio.com",
  projectId: "jobecam-candidate-prd",
  storageBucket: "jobecam-candidate-prd.appspot.com",
  messagingSenderId: "585421530089",
  appId: "1:585421530089:web:16a6a32b33e150688aa178",
  measurementId: "G-VYD318WVEZ"
};

initializeApp(firebaseConfig);

if(process.env.NODE_ENV == 'production') {
  // Sentry.init({
  //   Vue,
  //   dsn: "https://520b84a23ef1479bb30822e463352359@o300135.ingest.sentry.io/1723356",
  //   integrations: [
  //     new BrowserTracing({
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //       tracePropagationTargets: ["localhost", "jobecam.com", /^\//],
  //     }),
  //   ],
  //   // Set tracesSampleRate to 1.0 to capture 100%
  //   // of transactions for performance monitoring.
  //   // We recommend adjusting this value in production
  //   tracesSampleRate: 1.0,
  // });
  // alias
  Vue.prototype.$analytics = getAnalytics();
  
  Vue.config.productionTip = false
}

import '@/mixins/global';
import '@hokify/vuejs-datepicker/dist/vuejs-datepicker.css';
import 'vue-promise-btn/dist/vue-promise-btn.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-tour/dist/vue-tour.css';

const c = new ShareSession();

Vue.use(vueAwesomeCountdown, 'countdown');
Vue.use(BootstrapVue);
Vue.config.productionTip = false;
// Custom Plugins
Vue.use(ConfirmPlugin);
Vue.use(AlertPlugin);
Vue.use(ToastPlugin);
Vue.use(MomentPlugin);

Vue.use(VueTour);

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

Vue.use(VueScrollTo, {
  container: '#page-content',
  duration: 0,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VuePromiseBtn);

Vue.use(VueAnalytics, {
  id: 'UA-93012005-1',
  router
});
Vue.use(Vuelidate);

// ----- components
import Raters from 'vue-rate-it';

Vue.component('star-rating', Raters.StarRating);
Vue.component('heart-rating', Raters.HeartRating);
Vue.component('fa-rating', Raters.FaRating);
Vue.component('image-rating', Raters.ImageRating);
Vue.component(VueQrcode.name, VueQrcode);

import SelectInput from '@/components/form/SelectInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import TextareaInput from '@/components/form/TextareaInput.vue';
import Tag from '@/components/tags/Tag.vue';
import CardSeparator from '@/components/tags/CardSeparator.vue';
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import TextLabel from '@/components/form/TextLabel.vue';
import TextLabelValue from '@/components/form/TextLabelValue.vue';
import TextLabelHtml from '@/components/form/TextLabelHtml.vue';
import ButtonIconRounded from '@/components/button-icon/ButtonIconRounded.vue';
import Icon from '@/components/icon/Icon.vue';

Vue.component('select-input', SelectInput);
Vue.component('text-input', TextInput);
Vue.component('textarea-input', TextareaInput);
Vue.component('info-tag', Tag);
Vue.component('card-separator', CardSeparator);
Vue.component('checkbox-input', CheckboxInput);
Vue.component('text-label', TextLabel);
Vue.component('text-label-value', TextLabelValue);
Vue.component('text-label-html', TextLabelHtml);
Vue.component('btn-icon-round', ButtonIconRounded);
Vue.component('icon', Icon);
// -----

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
