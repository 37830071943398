














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Section from "@/components/section/Section.vue";
import HabilidadeCandidatoService from "@/core/Services/Candidato/HabilidadeCandidato";
import { HabilidadeCandidatoEntity } from "@/core/models/Entities";
import CircleRate from "@/components/rate/CircleRate.vue";

@Component<CardPdfSkills>({
  components: {
    "section-card": Section,
    CircleRate
  }
})
export default class CardPdfSkills extends Vue {
  public habilidadeSRV!: HabilidadeCandidatoService;
  public habilidades: HabilidadeCandidatoEntity[] = [];
  public items: any[] = [];
  public fields: any[] = [];
  public num: number = 4

  constructor() {
    super();
    this.habilidadeSRV = new HabilidadeCandidatoService();
  }

  public created() {
    this.fields = [
      { key: "tecnicas" },
      { key: "nivel", label: '' },
    ];
  }

  public loadData(data:any) {
    this.habilidades = data;
    this.items = [];
    this.habilidades.forEach((el: any) => {
      this.items.push({
        tecnicas: el.habilidade.name,
        nivel: el.nivel,
      });
    });
  }
}
