






























































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FormacaoService from "@/core/Services/CandidatoFormacao";
import { CandidatoEntity, FormacaoEntity } from "@/core/models/Entities";
import Section from '@/components/section/Section.vue';
import DateInput from '@/components/form/DateInput.vue';
import Alert from '@/components/alerts/Alert.vue';
import Confirm from '../alerts/Confirm.vue';
import VueScrollTo from 'vue-scrollto';



@Component<DadosAcademicosEdit>({
  components: {
    "section-card": Section,
    DateInput
  }
})
export default class DadosAcademicosEdit extends Vue {
  @Prop({default: []})
  public formacoes!: FormacaoEntity[];

  @Prop({default: () => {new CandidatoEntity()}})
  public value!: CandidatoEntity;
  
  @Watch('formacoes')
  public handleChangeTipo(v: any){
    this.loadData()
  }
  public formSRV!: FormacaoService;
  public showLocalidadeText: boolean = true;
  public items: any[] = [];
  public fields: any[] = [];
  public disabled: boolean = false;
  public buttonLabel: any;
  public dataEntrada: Date|null = null;
  public dataSaida: Date|null = null;
  public editEnable: boolean = false;
  public statusArr: any[] = [];
  public currentPromise: any|null = null;
  public currentDeletePromise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public alertConfirm: Confirm;
  public options!: any;
  public typeList: any[] =[];

  constructor() {
    super();
    this.alertConfirm = new Confirm();
    this.formSRV = new FormacaoService();
    this.options = {
      container: "#page-content",
      easing: "ease-in",
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }
  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }
  
  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

  public created() {
    this.buttonLabel = ""+this.$t('Save');
    
  }

  public mounted(){
    this.fields = [
      { key: "nome", label: this.$t('Name') },
      { key: "atividade", label: this.$t('Field of Study')},
      { key: "dataEntrada", label: this.$t('Start Date') },
      { key: "dataSaida", label: this.$t('End Date') },
      { key: "localidade", label: this.$t('Location') },
      { key: "opcoes", label: this.$t('Options') }
    ];
    this.typeList = [
      {text: this.$t('Select'), value: ''},
      {text: this.$t('Technical'), value: this.$t('Technical')},
      {text: this.$t('Graduate'), value: this.$t('Graduate')},
      {text: this.$t('Postgraduate'), value: this.$t('Postgraduate')},
      {text: this.$t('Master degree'), value: this.$t('Master degree')},
      {text: this.$t('Doctorate degree'), value: this.$t('Doctorate degree')},
      {text: this.$t('Post Doctor'), value: this.$t('Post Doctor')},
    ]
    this.statusArr.push("")
    this.statusArr.push({text: this.$t("Studying").toString(), value: this.$t("Studying").toString()})
    this.statusArr.push({text: this.$t("Completed").toString(), value: this.$t("Completed").toString()})
    this.statusArr.push({text: this.$t("Incompleted").toString(), value: this.$t("Incompleted").toString()})
    this.loadData()
  }

  public reset() {
    this.formSRV.entidade = new FormacaoEntity()
    this.formSRV.entidade.tipo = "";
    this.buttonLabel = ""+this.$t('Save');
    this.dataEntrada = null;
    this.dataSaida = null;
    this.editEnable = false;
  }

  public save(evt: any) {
    if (this.formSRV.entidade.atual_ == 1) {
      this.formSRV.entidade.dataSaida = ''
    }
    evt.preventDefault();
    if(!this.disabled)
      this.currentPromise = this.formSRV.save().then((ret: boolean) => {
        if(ret) {
          let hasObject: boolean = false;
          const obj = {
                  nome: this.formSRV.entidade.nome,
                  atividade: this.formSRV.entidade.atividade,
                  dataEntrada: this.formSRV.entidade.dataEntrada,
                  dataSaida: this.formSRV.entidade.dataSaida,
                  localidade: this.formSRV.entidade.localidade,
                  opcoes: this.formSRV.entidade.id,
                  promise: null,
                };
          this.items.forEach((el: any,index: number) => {
            if(el.opcoes==this.formSRV.entidade.id){
              hasObject = true
              el = obj
            }
          })
          if (!hasObject) {
            this.items.push(obj);
            this.formacoes.push(this.formSRV.entidade)
          }

          if((this.$refs.tAcademic as any))
            (this.$refs.tAcademic as any).refresh();

          this.value.perfil.temFormacao = true;
          this.showAlert();
          this.reset();
          this.$emit('saved',true);
        }
      })
  }

  public saveField() {
    this.$emit('saved',true);
  }
 
  public editEXP(id: any) {
    this.buttonLabel = this.$t('Edit').toString();
    this.editEnable = true;
    this.getEntidadeFromList(id);
    VueScrollTo.scrollTo("#dped", 500, this.options);
    
  }

  public getEntidadeFromList(id?: any) {
    if (!id) {
      return null;
    }
    this.formacoes.forEach(el => {
      if( el.id === id ){
        this.formSRV.entidade = el;
        const arrE: any[] = el.dataEntrada!.split('-');
        if(!isNaN(parseInt(arrE[0])) && !isNaN(parseInt(arrE[1])) ) {
          this.dataEntrada = new Date(parseInt(arrE[1]),parseInt(arrE[0])-1,1)
        }
        if (el.dataSaida) {
          const arrS: any[] = el.dataSaida!.split('-');
          if(!isNaN(parseInt(arrS[0])) && !isNaN(parseInt(arrS[1])) ) {
            this.dataSaida = new Date(parseInt(arrS[1]),parseInt(arrS[0])-1,1)
          }
        }
        return true;
      }
    })
  }
  
  public handleDateEntradaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.formSRV.entidade.dataEntrada = str.substring(str.length-7,str.length);
  }

  public handleDateSaidaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.formSRV.entidade.dataSaida = str.substring(str.length-7,str.length);
  }

  public removeEXP(id: any) {
     let tIndex: number = -1;
    let tElement: any = null;
     
    this.items.forEach((element: any,index: number) => {
      if(element.opcoes === id){
        tIndex = index;
        tElement = element;
        return true;
      }
    })

    if (tIndex > -1 && tElement != null) {
      this.alertConfirm.addTitle(this.$t('Attention').toString())
      this.alertConfirm.addMsg(this.$t('Do you want to remove this academic experience?') + ' ' + tElement.atividade)
      this.alertConfirm.callBox().then((dec: boolean) => {    
        if(dec) {
          this.items[tIndex].promise = this.formSRV.delete({id}).then(() => {
            this.items.splice(tIndex,1);
            this.formacoes.splice(tIndex, 1);
            
            if((this.$refs.tAcademic as any))
              (this.$refs.tAcademic as any).refresh();
          });
        }
      });
    }
  }

  public loadData(value?: any) {
    if (value) {
      this.formacoes = value;
    }

    this.items = [];
    this.formacoes.forEach((el: any) => {
      this.items.push({
        nome: el.nome,
        atividade: el.atividade,
        dataEntrada: el.dataEntrada,
        dataSaida: el.dataSaida,
        localidade: el.localidade,
        opcoes: el.id
      });
    });
  }
}
