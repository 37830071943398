









import { Vue, Component, Prop } from 'vue-property-decorator';


@Component
export default class Modal extends Vue {
  @Prop({default: ''})
  public id!: string;

  @Prop({default: ''})
  public title!: string;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: false})
  public size!: boolean;

  @Prop({default: false})
  public hideFooter!: boolean;
  
  @Prop({default: false})
  public hideHeader!: boolean;
}
