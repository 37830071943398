
































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import ExperienciaService from '@/core/Services/CandidatoExperiencia';
import { ExperienciaEntity } from '@/core/models/Entities';
import Section from '@/components/section/Section.vue';

@Component<CardPdfProfessionalInfo>({
  components: {
    "section-card": Section
  }
})
export default class CardPdfProfessionalInfo extends Vue {
  public experiencias: ExperienciaEntity[] = [];
  public items: any[] = [];
  public fields: any[] = [];
  public expSRV!: ExperienciaService;

  constructor() {
    super();
    this.expSRV = new ExperienciaService();
  }

  // public created() {
  //   this.fields = [
  //     { key: "nome" },
  //     { key: "atividade" },
  //     { key: "dataEntrada" },
  //     { key: "dataSaida" },
  //     { key: "localidade" },
  //   ];
  // }

  public mounted(){
    this.fields = [
      { key: "nome", label: this.$t('Name') },
      { key: "atividade", label: this.$t('Position')},
      { key: "dataEntrada", label: this.$t('Start Date') },
      { key: "dataSaida", label: this.$t('End Date') },
      { key: "localidade", label: this.$t('Location') },
    ];
  }  

  public loadData(value?: any) {
    this.experiencias = value;
    this.experiencias.forEach(el => {
      this.items.push({
        nome: el.nome,
        atividade: el.atividade,
        dataEntrada: el.dataEntrada,
        dataSaida: el.dataSaida,
        localidade: el.localidade,
        opcoes: el.id,
        descricaoAtividade: el.descricaoAtividade,
        _showDetails: true
      });
    });
  }

}
