
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import PublicRepository from '@/core/Services/PublicRepository';


export default class LinkedIn extends PublicRepository {
    constructor() {
        super(new ApiRouter({
            // fetch: { url: '/v1/linked-in/0', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/social-login', method: 'GET', headers: {} }
        }));
    }

}


