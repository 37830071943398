








































// List Company evaluations
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import CandidateExam from '@/core/Services/Exams/CandidateExam';
import FormQuestion from './FormQuestion.vue';
import { QuestionEntity, AnswerEntity, QuestionaryCandidatoEntity } from '@/core/models/Entities';
import { EQuestionType } from '@/core/Enums/EQuestionType';
import ImageModal from '@/components/modal/ImageModal.vue';

@Component({
  components: {
    FormQuestion,
    ImageModal
  }
})
export default class FormExam extends Vue {
  public service: CandidateExam;
  public currentImgURL: string = '';
  public firstTierOpen: boolean = false;
  public loaded:boolean = false;
  public promise: Promise<any>|null = null;

  @Prop()
  public questionaryCandidatoId!: number;

  @Prop({ default: false })
  public timeLeftEnded!: boolean;

  @Watch('timeLeftEnded')
  public saveWhenTimeLeftEnds(timeLeftEnded) {
    if (timeLeftEnded === false) return;

    this.save();
  }

  constructor() {
    super();
    this.service = new CandidateExam();
  }
  
  public async mounted() {
    // this.service = new Exam();
    this.loaded = false;
    this.service = new CandidateExam();
    const qid: number = this.questionaryCandidatoId;
    if(qid && !isNaN(qid)) {
      this.service.questionary =  await this.service.fetch(qid)
      this.service.entidade.id = this.questionaryCandidatoId;

      const entityFromLocalStorage = this.service.loadEntityFromLocalStorage();
      if (entityFromLocalStorage) {
        this.service.entidade.answers = entityFromLocalStorage.answers;
      }

      this.loaded = true;

      if (this.timeLeftEnded) {
        this.save();
      }
    }
  }

  public openImageModal(url: string) {
    this.currentImgURL = url;
    this.$bvModal.show('call-img-question')
  }

  public save() {
    this.service.deleteEntityFromLocalStorage();

    this.promise = this.service.save().then((data: any) => {
      this.$emit('saved', this.questionaryCandidatoId, data.gg)
    })
  }

  public onQuestionInput() {
    this.service.saveEntityInLocalStorage();
  }
}
