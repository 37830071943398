
































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import RespostaEntrevista from '@/core/Services/Calendar/RespostaEntrevista';
import Section from "@/components/section/Section.vue";
import Datepicker from '@hokify/vuejs-datepicker';

@Component<RespostaAgenda>({
  components: {
    "section-card": Section,
    Datepicker
  }
})
export default class RespostaAgenda extends Vue {
  public service: RespostaEntrevista;
  public token: string = '';
  public resp: string = '';
  public obs: string = '';
  public dataReagendamento: Date|null = null;
  public hora: string = '';
  public data: string = '';
  public scopeID: string;
  public promise: Promise<any>|null = null;
  public sended: boolean = false;
  public sending: boolean = false;

  constructor() {
    super();
    this.service = new RespostaEntrevista();
    this.scopeID = (new Date()).getTime() + '';
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }
  public mounted() {
    this.cleanSession();
    this.resp = this.$route.query.resp + "";
    this.submit(this.resp=='1');
  }

  public submit(flag: boolean) {
    this.token = this.$route.query.token + "";
    let str1 = '';
    let str = '';
    if (this.dataReagendamento) {
      str = (this.dataReagendamento.getFullYear() +'-'+ (this.dataReagendamento.getMonth()+1).toString().padStart(2,'0'));
      str1 = str+'-'+(this.dataReagendamento.getDay()+'').padStart(2,'0');
      this.data = str1;
    }
    if(this.token && this.resp && flag) {
      this.cleanSession();
      this.sending = true;
      this.promise = this.service.create({
        token: this.token,
        resp: this.resp,
        obs: this.obs,
        data: (this.dataReagendamento) ? this.$dateUTCDB(this.dataReagendamento!, this.hora) : '',
        hora: this.hora
      }).then((_: any) => { 
        this.resp = '1';        
      }).catch((x: any) => {
        this.resp = '1'
      }).finally(() => {
        this.sending = false;
        this.sended = true;
      })
    }
  }
  

}
