
















































import { CandidaturaEntity, VagaEntity } from '@/core/models/Entities';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component<CardJobEngagedList>({

})
export default class CardJobEngagedList extends Vue {
  public candidaturas: CandidaturaEntity[] = [];
  public candidaturaService: CandidaturaService;
  public fields: any[] = [];
  public items: any[] = [];
  public vaga: VagaEntity;
  public limit: number = 6;

  constructor() {
    super();
    // this.candidaturas = []
    this.candidaturaService = new CandidaturaService();
    this.vaga = new VagaEntity;
  }

  public created() {
    this.applications()

    this.fields = [
      { key: "nivel" },
      { key: "vaga" },
      { key: "empresa" },
      { key: "dataCandidatura" },
      { key: "mensagem" },
      { key: "detalhes" }
    ];
  }

  public loadData() {
    this.items = [];
    this.candidaturas.forEach((el: any) => {
      this.items.push({
        nivel: 'nivel',
        vaga: el.vaga.posicao,
        empresa: el.vaga.empresa.nomeEmpresa,
        dataCandidatura: this.$options.filters!.dateOnly(el.dataCadastro),
        mensagem: '',
        detalhes: ''
      });
    });
    
  }

  public applications() {
    this.candidaturaService.fetchAll({limit: this.limit})
      .then((data: any) => {
        this.candidaturas = data.candidaturas;
        this.loadData()
      })
      // tslint:disable-next-line:no-console
      .catch(console.error);
  }
}
