import PlatformUtil from "@/core/recording/PlatformUtil";
import Avatar from "../canvas/Avatar";
import BasicScene from "../canvas/BasicScene";
import TasksVision from "./TasksVision";

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};

export default class VisionManager {
  public container: HTMLDivElement | null = null;
  public videoElement: HTMLVideoElement | null = null;
  public opened: Boolean = false;
  public avatar: Avatar | null = null;
  public basicScene: BasicScene | null = null;
  private tasksVisions: TasksVision = new TasksVision();
  private lastVideoTime: number = -1;
  private avatarLoaded: boolean = false;
  public callbacks: Function[] = [];
  // public modelURL: string = location.protocol + "//" + location.host + "/static/models/jb_head.glb";//"/static/models/facecap_1.glb";
  public modelURL: string = location.protocol + "//" + location.host + "/static/models/avatar.glb";

  constructor(container: HTMLDivElement, videoElement: HTMLVideoElement, modelURL?: string) {
    this.container = container;
    this.videoElement = videoElement;

    if (modelURL)
      this.modelURL = modelURL

    window['vision_manager'] = this;
  }

  public getContext(): WebGL2RenderingContext | WebGLRenderingContext | null {
    if (this.basicScene)
      return this.basicScene.getContext();

    return null;
  }

  public async start() {
    await this.tasksVisions.loadVisionTasks()
    this.initThreeJS();
    this.loadObjModel();
    this.opened = this.tasksVisions.opened;
    this.animate();
    await sleep(20);
  }

  private initThreeJS() {
    const container = this.container;
    if (!container) return;

    this.basicScene = new BasicScene({ container });
  }

  private loadObjModel() {
    if (!this.basicScene || this.avatar) return;

    this.avatar = new Avatar(this.modelURL, this.basicScene, true)
  }

  private getFaces = () => {
    if (this.tasksVisions.results) {
      this.avatar?.applyFaceLandmarksToModel(this.tasksVisions.results);
    }
  }

  public closeAll() {
    this.opened = false;
    this.tasksVisions.close()
    this.basicScene?.dispose();
  }

  public platform() {
    return PlatformUtil.getInstance();
  }


  private async animate() {
    if (!this.opened) return;

    if (this.tasksVisions.faceLandmarker) {

      if (!this.avatarLoaded && this.avatar?.gltf?.scene) {
        this.basicScene?.scene?.add(this.avatar?.gltf?.scene)
        this.avatarLoaded = true;
      }

      let nowInMs = Date.now();
      if (this.videoElement && this.lastVideoTime !== this.videoElement?.currentTime) {
        this.lastVideoTime = this.videoElement.currentTime || 0;
        try {
          this.tasksVisions.detectForVideo(this.videoElement);
          this.getFaces();
        } catch (e) {
          console.error(e)
        }
      }

    } else {
      console.log('no facemarker', this.tasksVisions.faceLandmarker)
    }
    try {
      this.basicScene?.render();
      if (this.platform().isMobileDevice()) {
        requestAnimationFrame(() => { this.animate() })
      } else {
        setTimeout(() => { this.animate() }, 40)
      }
      //this.controls?.update();
    } catch (error) {
    }
  }

}