


















import Vue from "vue";
import Component from "vue-class-component";
import CardBox from "@/components/CardBox.vue";
import NavBar from "@/views/layouts/NavBar.vue";
import LinkedinRegister from "@/components/social/LinkedinRegister.vue";
import { baseEndPoint, clientId } from "@/core/endpoint";
import { Action, Getter, State } from "vuex-class";
const namespace: string = "profile";

let PIDINTERVAL: any = 0;

@Component<RegisterCompletedSocial>({
  components: {
    "card-box": CardBox,
    "nav-bar": NavBar
  }
})
export default class RegisterCompletedSocial extends Vue {
  public myPopUp: Window | null = null;
  public uri: string =
    window.document!.location!.protocol +
    "//" +
    window.document!.location!.host;

  public toPlatform() {
    
    const vagaURL: string = (this.$route.query.vagaURL??"")+"";
    const cCode: string = (this.$route.query.ccode??"")+"";
    if(cCode && vagaURL) {
      this.$router.push({ name: 'ProcessStartSimple', params: {ccode: cCode, urlcode: vagaURL }, query: { a: "1" }});
      return;
    }
    if(vagaURL) {
      this.$router.push({ name: 'DetalheVagaCandidato', params: {id: vagaURL }});
      return;
    }
    let rurl: string|null = sessionStorage.getItem("redirectTo");
    if (rurl) {
        this.$router.push({ path: rurl, query: {lang: this.$i18n.locale }});
    } else {
        this.$router.push({ path: '/', query: {lang: this.$i18n.locale }});
    }
  }

  public toggleBodyClass(addRemoveClass: any, className:any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  public mounted() {
    setTimeout(() => { 
      this.toPlatform()
    },2000);
    this.toggleBodyClass('addClass', 'register')
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'register')
  }
}
