

















import Vue from "vue";
import Component from "vue-class-component";
import Section from "@/components/section/Section.vue";
import ButtonCollapse from "@/components/button-icon/ButtonCollapse.vue";
import FaqService from "@/core/Services/Basic/Faq";
import { FaqEntity } from "@/core/models/Entities";
import { Watch } from 'vue-property-decorator';

@Component<HelpCandidato>({
  components: {
    "section-card": Section,
    ButtonCollapse
  }
})
export default class HelpCandidato extends Vue {
    public FaqSRV!: FaqService;
    public Questions: any;
    public Faqs: FaqEntity[] = [];
  
  @Watch('$i18n')
  async getFaqs() {
    this.Questions = await this.FaqSRV.fetchAll({lang:this.$i18n.locale.substring(0,2)});

    this.Questions.faqs.forEach((item: any) => {
      if(item.lang.substring(0,2) == this.$i18n.locale.substring(0,2)){
        this.Faqs.push(item)
      }
    });  
  }

  constructor() {
    super();
    this.FaqSRV = new FaqService();    
    this.Faqs = [];
  }    

  async created(){
    this.getFaqs();
  }

  
}
