// tslint:disable
import ApiRouter from "@/core/apirouting/EndpointRouterInterface";
import { CB } from "@/core/util";
import ClientAuthentication from "../ClientAuthentication";
import Repository from "../Repository";


export default class AccountCofirmation extends Repository {

    constructor(i18n?: any) {
      super(new ApiRouter({
          create: { url: "/v1/confirmation", method: "POST", headers: {} },
        }));    
  }
  
  public create(data?: any) {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        return this.call('create', cdta, 'application/json').then(resolve).catch(reject);
      }).catch((resp) => {
          reject(resp);
      });
    });
  }
}
