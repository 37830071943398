// tslint:disable
import { GetterTree } from "vuex";
import { RootState } from "../types";
import { UiState } from "./state";

export const getters: GetterTree<UiState, RootState> = {
  showVagasFilter(state) {
    return state.showVagasFilter;
  },
};
