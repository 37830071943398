






















import { Vue, Component } from 'vue-property-decorator';

import PublicHeader from '@/components/layouts/PublicHeader.vue';

@Component({
  components: {
    PublicHeader,
  },
})
export default class IphoneError extends Vue {
  public goToHomepage() {
    this.$router.push(`/?lang=${this.$i18n.locale}`);
  }
}
