import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class PublicVagaToken extends Repository {

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/public-vaga-token', method: 'POST', headers: {} },
            fetch: { url: '/v1/public-vaga-token/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/public-vaga-token', method: 'GET', headers: {} }, // tem parametro de Busca 's'
        }));
    }

}

