import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { CB, MyIp } from '@/core/util';
import ClientAuthentication from '@/core/Services/ClientAuthentication';


export default class RespostaEntrevista extends Repository {
  constructor() {
    super(
      new ApiRouter({
        create: { url: '/v1/resposta-entrevista', method: 'POST', headers: {} },
        fetch: { url: '/v1/resposta-entrevista/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/resposta-entrevista', method: 'GET', headers: {} }, // tem parametro de Busca 's'
        update: { url: '/v1/resposta-entrevista/{id}', method: 'PUT', headers: {} }
      }));
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }

  public create(data: any, ctype?: string): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      (new ClientAuthentication()).auth().then((rtoken: any) => {
        const dta: any = {
          rtoken,
          ...data
        };
        const cdta = CB.genC(dta);
        this.cleanSession();
        super.create(cdta, ctype).then(resolve).catch(reject);
      }).catch((resp) => {
        reject(resp);
      });
    });
  }
}

