






















































































// tslint:disable
// require("./static/css/layouts/page-center.css")
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import NavBar from "@/views/layouts/NavBar.vue";
import FacebookRegister from "@/components/social/FacebookRegister.vue";
import LinkedinRegister from "@/components/social/LinkedinRegister.vue";
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import PublicVagaToken from "@/core/Services/Vaga/PublicVagaToken";
import { FacebookUser, LinkedInUser } from '@/core/models/Entities';

const namespace: string = "profile";

@Component({
  components: {
    "nav-bar": NavBar,
    "linkedin-button": LinkedinRegister
  }
})
export default class LoginBR extends Vue {
  public user_name: string = "";
  public user_email: string = "";
  public user_cpf: string = "";
  public user_phone: string = "";

  public myPopUp: Window | null = null;

  public serverMessage?: string | null = null;
  public showDismissibleAlert: boolean = false;
  public loginPromise: Promise<any>|null = null;
  public alertVariant: string = "danger";
  public service: PublicVagaToken;

  public gestaoCandidatoSRV: GestaoCandidato = new GestaoCandidato();

  @Action("userRequest", { namespace }) public userRequest: any;
  @Action("authRequest", { namespace }) public authRequest: any;
  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfile", { namespace }) public getProfile: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  public async created() {
  }

  constructor() {
    super();
    this.service = new PublicVagaToken();
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
    sessionStorage.removeItem('facebook_user');
  }

  public async registerUser(accessToken?: string) {
    if (accessToken) {
      // this.candidato =  new Candidato();
      sessionStorage.setItem("access_token", accessToken);
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", "candidate");
      await this.userRequest()
        .then(() => {
          /* console.log("ok") */
        })
        .catch(() => {
          this.removeItensFromStorage();
        });
    } else {
      this.removeItensFromStorage();
    }
  }

  public login() {
    const token: string = this.$route.params.tkn;
    const { user_name, user_email, user_cpf, user_phone } = this;

    if (!user_name || !user_email || !user_cpf || !user_phone ) {
      return;
    }

    this.service.create({
      cpf: user_cpf,
      email: user_email,
      token: token,
      telefone: user_phone,
      primeironome: user_name
    }).then(async (resp: any) => {
        if(resp.access_token) {
          await this.registerUser(resp.access_token);
          this.$router.push({
            name: 'FastInterview',
            params: {
              tkn: token,
              cid: resp.cid
            }
           })
        }
    })
  }

  public toggleBodyClass(addRemoveClass: any, className: any) {
    const el = document.body;

    if (addRemoveClass === "addClass") {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }

  public mounted() {
    this.toggleBodyClass("addClass", "login");
  }

  public destroyed() {
    this.toggleBodyClass("removeClass", "login");
  }
}
