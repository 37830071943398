
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { VagaEntity } from '@/core/models/Entities';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';


export default class Vaga extends Repository {
    public entidade: VagaEntity;

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/vaga', method: 'POST', headers: {} },
                fetch: { url: '/v1/vaga/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/vaga', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/vaga/{id}', method: 'PUT', headers: {} }}));
        this.entidade = new VagaEntity();
    }

    public loadVaga(id: number): void {
        super.fetch(id).then((data: any) => {
            this.entidade = data;
        }).catch((err: any) => {
            // tslint:disable-next-line:no-console
            /* console.log('Couldn\'t load vaga.', err) */
        });
    }

    public publicFetchWithData(data: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            (new ClientAuthentication()).auth().then((rtoken: any) => {
                const d = {
                    rtoken,
                    ...data
                };
                this.call('fetch', (!d ? {} : d), 'application/json').then(resolve).catch(reject);
            }).catch((resp) => {
                reject(resp);
            });
        });
    }

    public fetchWithData(data: any): Promise<any> {
        return this.call('fetch', (!data ? {} : data), 'application/json');
    }

    public fetchAll(data?: any): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token') === null) {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const d = {
                        rtoken,
                        ...data
                    }
                    super.fetchAll(d).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            } else {
                super.fetchAll(data).then(resolve).catch(reject);
            }
        });
    }

    public save(): Promise<any> {
       if (!!this.entidade.id) {
            return super.update(this.entidade);
       }
       return super.create(this.entidade);
    }
}


