

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import { Prop } from 'vue-property-decorator';
import DadosPessoaisView from '@/components/perfil/DadosPessoaisView.vue';
import ProfilePdfHeader from '@/components/pdf/ProfilePdfHeader.vue';
import ProfilePdfFooter from '@/components/pdf/ProfilePdfFooter.vue';
import CardPdfProfessionalInfo from '@/components/cards/CardPdfProfessionalInfo.vue';
import CardPdfAcademicInfo from '@/components/cards/CardPdfAcademicInfo.vue';
import CardPdfSkills from '@/components/cards/CardPdfSkills.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';

const namespace: string = 'profile';

@Component({
  components: {
    DadosPessoaisView,
    ProfilePdfHeader,
    ProfilePdfFooter,
    CardPdfProfessionalInfo,
    CardPdfAcademicInfo,
    CardPdfSkills
  }
})
export default class ProfilePdf extends Vue {
  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  // @Prop({ default: false }) 
  // public showButtons!: boolean;

  public show: boolean = false;

  public async created() {
    if (!this.candidato) {
      await this.loadCandidatoInfo();
      (this.$refs.pro as CardPdfProfessionalInfo).loadData(this.candidato!.perfil!.experiencias);
      (this.$refs.aca as CardPdfAcademicInfo).loadData(this.candidato!.perfil!.formacoes);
      (this.$refs.habe as CardPdfSkills).loadData(this.candidato!.perfil!.habilidades);
    }
  }
}
