import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class MessageJob extends Repository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/messages-job', method: 'POST', headers: {} },
            fetch: { url: '/v1/messages-job/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/messages-job', method: 'GET', headers: {} },
            update: { url: '/v1/messages-job/{id}', method: 'PUT', headers: {} }
        }));
    }
}
