




























































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import SpeakingBox from './SpeakingBox.vue'

@Component<Stream>({
  components: {
    ButtonIcon,
    SpeakingBox
  }
})
export default class Stream extends Vue {
  public nickname: string = "";
  public showForm: boolean = false;
  public mutedSound: boolean = false;

  @Prop({default: null})
  public value!: UserModel;

  @Prop({default: false})
  public blind!: boolean;

  @Prop({default: false})
  public local!: boolean;

  @Prop({default: 8})
  public maxParticipants!: number;
  
  @Prop({default: 8})
  public numberOfHiddenParticipants!: number;
  
  @Prop({default: 0})
  public index!: number;

  get hideVideo() {
    return  (this.index > (this.maxParticipants -2)) && this.numberOfHiddenParticipants > 1
  }

  get userNick() {
    return this.value?.getNickname();
  }

  get userNick2() {
    return this.value?.getNickname().substring(0,2);
  }

  get speaking() {
    return this.value?.speaking;
  }

  get isCandidate() {
    return (this.value?.userType == 'candidate' || this.value?.appFrom == 1);
  }

  get blinded() {
    return this.value?.blinded && this.value?.userType == 'candidate' && this.blind
  }

  get randomImg() {
    const x: string = ""+this.value.random;
    return 'blindoffset-img'+ x.padStart(2,'00');
  }

  get isLocal() {
    return this.value?.isLocal();
  }

  get videoActive() {
    return this.value?.isVideoActive();
  }

  get shareActive() {
    return this.value?.isScreenShareActive();
  }
  
  get audioActive() {
    return this.value?.isAudioActive();
  }
  get videoId() {
    return 'video-' + (this.streamId || 'local' );
  }

  get streamId() {
    return (this.value?.getStreamManager()) ? this.value?.getStreamManager().stream.streamId : null;
  }

  @Watch('value.streamManager.stream.streamId')
  public setVideo() {
    console.log('setVideo')
    if(!this.value.getStreamManager()?.stream?.streamId) return;
    this.value.getStreamManager().addVideoElement(this.$refs.videoRef);
    if((this.$refs?.videoRef as any)?.paused)
      (this.$refs?.videoRef as any)?.play()
  }

  get iconMute() {
    return this.mutedSound ? "fa fa-volume-up" : "fa fa-volume-mute";
  }

  public playPID: any = 0;
  public mounted() {
    this.nickname = this.value.getNickname();
    if (this.value && this.value.streamManager && !!this.$refs.videoRef) {
        this.value.getStreamManager().addVideoElement(this.$refs.videoRef);
    }
      
    this.playPID = setTimeout(() => {
      try {
        this.value.getStreamManager().addVideoElement(this.$refs.videoRef);
        if((this.$refs?.videoRef as any)?.paused)
          (this.$refs?.videoRef as any)?.play()
      } catch(e) {
      }
    },4000)
  }

  public beforeDestroy() {
    clearTimeout(this.playPID)
  }

  public toggleSound() {
    this.mutedSound = !this.mutedSound;
  }

  public handleChange() {
    if(this.blinded)
      return;

    this.$emit('handleNickname', this.nickname);
    this.showForm = false;
  }

  public toggleNicknameForm() {
    if(this.blinded)
      return;

    if (this.value.isLocal()) {
        this.nickname = this.value.getNickname();
        this.showForm = true;
    }
  }
}
