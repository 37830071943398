






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import CardAcademicInfo from "@/components/cards/CardAcademicInfo.vue";

@Component({
  components: {
    CardAcademicInfo
  }
})
export default class DadosAcademicosView extends Vue {
  @Prop({ default: null })
  candidato!: CandidatoEntity;
}
