



















































































import GoogleMap from '@/components/maps/GoogleMap.vue';
import { EmpresaEntity } from '@/core/models/Entities';
import SeguirEmpresaService from "@/core/Services/Basic/SeguirEmpresa";
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

const namespace: string = "profile";

@Component<CardCompany>({
  components: {
    GoogleMap
  }
})
export default class CardCompany extends Vue {
  public currentDate = new Date()
  // public empresa!:any
  public followService: SeguirEmpresaService;

  @Prop({default: () => { return new EmpresaEntity()}})
  public empresa!: EmpresaEntity;

  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  constructor(){
    super();
    this.followService = new SeguirEmpresaService();
  }

  public empresaPage(empresa: any) {
    let nUrl: string = empresa!.nomeUrl+"";
    
      this.$router.push({name: 'CompanyProfile', params: { urlcode: nUrl }, query: { lang: this.$i18n.locale }});
      // location.reload();
      return;
  }

  public follow(){
    /* console.log('follow') */
    this.followService.create({
      id: this.empresa.id,
      a: 1
    }).then((data: any) => {
      /* console.log(data) */
      this.empresa.seguindo = true;
      // this.$message({  type: 'info', message: ""+this.$t('Action Done') });
    })
    .catch((err: any) => {
      /* console.log("erro") */
    })
  }

  public unfollow(){
    /* console.log('unfollow') */
    this.followService.create({
      id: this.empresa.id,
      a: 0
    }).then((data: any) => {
      this.empresa.seguindo = false;
      // this.$message({  type: 'info', message: ""+this.$t('Action Done') });
    })
    .catch((err: any) => {
      /* console.log("erro") */
    })
  }

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1]||url.split("youtu.be/")[1];
    if(parsedStr) {
      let resultId = parsedStr.split("&")[0];
      return resultId
    }
    return '';
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }
}

