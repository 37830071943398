










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonIcon extends Vue {

  @Prop({default: 'info'}) public iconName!: string;
  @Prop({default: false }) public inverse!: string;

  public clickEvent(event: any) {
    this.$emit('click', event)
  }

}
