






import { Component, Prop, Vue } from 'vue-property-decorator';
import Section from '@/components/section/Section.vue';
import { PaginaInstitucionalEntity } from '@/core/models/Entities';


@Component<CompanyPage>({
  components: {
    "section-card": Section
  },
})
export default class CompanyPage extends Vue {
  @Prop({default: () => { return new PaginaInstitucionalEntity() }})
  public page!: PaginaInstitucionalEntity;

}
