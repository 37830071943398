import _Vue from 'vue';
import Confirm from '@/components/alerts/Confirm.vue';


export class ConfirmPluginOptions {
  // add stuff
}
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function ConfirmPlugin(Vue: typeof _Vue, options?: ConfirmPluginOptions): void {
    // do stuff with options
    Vue.prototype.$confirm = new Confirm();
}