import { FaceLandmarker, FaceLandmarkerResult, FilesetResolver, ImageSegmenter } from "@mediapipe/tasks-vision";
declare interface WasmFileset {
  /** The path to the Wasm loader script. */
  wasmLoaderPath: string;
  /** The path to the Wasm binary. */
  wasmBinaryPath: string;
  /** The optional path to the asset loader script. */
  assetLoaderPath?: string;
}
export default class TasksVision {
  private vision: WasmFileset | null = null;
  public faceLandmarker: FaceLandmarker | null = null;
  public imageSegmenter: ImageSegmenter | null = null;
  public results: FaceLandmarkerResult | undefined | null = null;
  public opened: Boolean = false;
  private lastVideoTime: number = -1;

  async loadFileSet() {
    this.vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm"
    );
  }
  
  async loadVisionTasks() {
    try {
      await this.loadFileSet()
      if(!this.vision) return;
      this.faceLandmarker = await FaceLandmarker.createFromModelPath(this.vision,
        "https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/latest/face_landmarker.task"
        );
        await this.faceLandmarker.setOptions({
          baseOptions: {
            delegate: "GPU"
          },
          runningMode: "VIDEO",
          numFaces: 1,
        outputFaceBlendshapes: true,
        outputFacialTransformationMatrixes: true
      });
      this.opened = true;
    } catch (error) {
      console.error(error)
      this.opened = false;
    }
  }
  
  async loadImageSegmenter() {
    try {
      await this.loadFileSet()
      if(!this.vision) return;
      this.imageSegmenter = await ImageSegmenter.createFromModelPath(this.vision,
        "https://storage.googleapis.com/mediapipe-models/image_segmenter/deeplab_v3/float32/1/deeplab_v3.tflite"
      );
      await this.imageSegmenter.setOptions({
        baseOptions: {
          delegate: "GPU"
        },
        runningMode: "VIDEO",
        outputCategoryMask: true,
        outputConfidenceMasks: false
      });
      this.opened = true;
    } catch (error) {
      console.error(error)
      this.opened = false;
    }
  }

  public detectForVideo(videoElement: HTMLVideoElement | null):FaceLandmarkerResult|null {
    if (!this.faceLandmarker) return null;
 
    let nowInMs = Date.now();
    if (videoElement && this.lastVideoTime !== videoElement?.currentTime) {
      this.lastVideoTime = videoElement.currentTime || 0;
      try {
        this.results = this.faceLandmarker.detectForVideo(videoElement, nowInMs);
        return this.results;
      } catch (e) {
        console.error(e)
      }
    }
    return null;
  }

  public close() {
    this.faceLandmarker?.close()
  }
}