










import Vue from 'vue';
import Component from 'vue-class-component';
import CardChangePassword from '@/components/cards/CardChangePassword.vue'
import CardNotification from '@/components/cards/CardNotification.vue'
import CardMyInfo from '@/components/cards/CardMyInfo.vue'
import CardLanguage from '@/components/cards/CardLanguage.vue'
import CardRemoveAccount from '@/components/cards/CardRemoveAccount.vue'
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';
import { Getter, Action } from 'vuex-class';

const namespace: string = 'profile';

@Component<AccountSettings>({
  components: {
    "change-password": CardChangePassword,
    "notification": CardNotification,
    "my-info": CardMyInfo,
    "language": CardLanguage,
    "remove-account": CardRemoveAccount
  }
})
export default class AccountSettings extends Vue {
  public candidatoSRV = new CandidatoService;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  public async created() {
    if (!this.candidato) {
      this.loadCandidatoInfo();
    }
  }

  public saveField(field: {key: string, value: any}) {
    this.candidatoSRV.saveField(field.key, field.value, this.candidato!.id);
  }

  public saveLanguage() {
    this.candidatoSRV.saveProfileField('idiomaId', this.candidato!.perfil!.idiomaId, this.candidato!.id);
  }
}
