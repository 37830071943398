
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { FormacaoEntity } from '../models/Entities';


export default class CandidatoFormacao extends Repository {
  public entidade: FormacaoEntity;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/formacao', method: 'POST', headers: {} },
      delete: { url: '/v1/formacao/{id}', method: 'DELETE', headers: {} },
      fetch: { url: '/v1/formacao/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/formacao', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/formacao/{id}', method: 'PUT', headers: {} }
    }));
    this.entidade = new FormacaoEntity();
  }


  public async save(): Promise<any> {
    if (!this.entidade.id) {
      return super.create(this.entidade)
        .then((data: any) => {
          this.entidade = data;
          return true;
        })
        .catch((err: any) => {
          return false;
        });
    } else if (this.entidade.id! > 0) {
      return super.update(this.entidade)
        .then((data: any) => {
          this.entidade = data;
          return true;
        })
        .catch((err: any) => {
          return false;
        });
    }
  }
}
