





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import { FormacaoEntity } from "@/core/models/Entities";

@Component
export default class CardPdfAcademicInfo extends Vue {
  @Prop()
  public formacoes!: FormacaoEntity[];
  
  public items: any[] = [];
  public fields: any[] = [];

  constructor() {
    super();
  }

  public mounted(){
    this.fields = [
      { key: "atividade", label: this.$t('Field of Study')},
      { key: "status", label: this.$t('Status') },
      { key: "tipo", label: this.$t('Type') }
    ];
    // this.loadData()
  }  

  public loadData() {
    this.items = [];
    this.formacoes.forEach((el: any) => {
      this.items.push({
        atividade: el.atividade,
        status: el.status||(el.atual_ == 1 ? this.$t("Studying") : this.$t("Completed")),
        tipo: el.tipo
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
        text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }

}
