









import Vue from 'vue';
import Component from 'vue-class-component';
import HelpCandidato from '@/components/help/HelpCandidato.vue';
import TermsPolicy from '@/components/terms-policy/TermsPolicy.vue';

@Component<HelpPage>({
  components: {
    HelpCandidato,
    TermsPolicy
  }
})
export default class HelpPage extends Vue {
  
  
}
