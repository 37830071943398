






























import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class AcademyView extends Vue {

  public academyVideoList:any []| null = null;
  
  public mounted() {
    this.academyVideoList = [
      {
        title: "Plural Jobecam - Day 1 - Unconscious Bias, Religious Intolerance, D&I profession and more",
        video: 'https://youtu.be/JYAX8MMQmG4'
      },
      {
        title: "Plural Jobecam - Day 2 - Hiring trans people, inclusive leadership, women's issues and more",
        video: 'https://www.youtube.com/watch?v=rWnLVMtFRpU'
      },
      {
        title: 'FHiring funnel: steps and how to use them to your advantage with Carol Manciola',
        video: 'https://youtu.be/z_wdbyePbaE'
      },
      {
        title: 'Online Interview Tips - With Yochabell Cammila',
        video: 'https://youtu.be/EEOS6DreaOI'
      },
      {
        title: 'Interview Tip - Car Method',
        video: 'https://youtu.be/t_RFMeqyryA'
      },
      {
        title: 'Tips for sales professionals! - With João Olivério',
        video: 'https://youtu.be/Im2MdKDv6aU'
      },
      {
        title: 'Internal Interview - Tips for Success! - With Naomi Wernli',
        video: 'https://youtu.be/1-ExFh76KBE'
      },
      {
        title: 'How to make a pdf curriculum',
        video: 'https://youtu.be/JnTDmtVim-0'
      },
      {
        title: 'Recorded interview - How to prepare',
        video: 'https://youtu.be/N6MCVuijMcM'
      },
      {
        title: 'How to make a resume video',
        video: 'https://youtu.be/XUGNKGC_q5U'
      },
    ]
  }

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }

}
