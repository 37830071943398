






















import Vue from "vue";
import Component from "vue-class-component";
import Section from '@/components/section/Section.vue';

@Component({
  components: {
    "section-card": Section
  }
})
export default class CandidaturaSucesso extends Vue {
public url:string = '';

public indeed_get_conv_url(label, redirect, script) {
    var w = window;
    var proto = w.location.protocol.toLowerCase();
    if ('http:' != proto && 'https:' != proto) {
    proto = 'http:';
    }
    var url = proto + '//conv.indeed.com' +
    '/pagead/conv/' + escape(w.indeed_conversion_id) +
    '/?rand=' + (new Date()).getTime();
    if (w.indeed_conversion_value) {
    url = url + '&value=' + escape(w.indeed_conversion_value);
    }
    if (label) {
    url = url + '&channel=' + escape(label);
    }
    if (w.indeed_conversion_count > 1) {
    url = url + '&count=' + w.indeed_conversion_count;
    }
    if (w.indeed_conversion_ia) {
    url = url + "&ia=1";
    }
    if (w.indeed_conversion_applied) {
    url = url + "&applyComplete=" + w.indeed_conversion_applied;
    }
    if (w.indeed_conversion_hired) {
    url = url + "&hired=" + w.indeed_conversion_hired;
    }
    if (w.indeed_conversion_candidate_id) {
    url = url + "&candidateId=" + w.indeed_conversion_candidate_id;
    }
    if (w.indeed_conversion_step) {
    url = url + "&step=" + w.indeed_conversion_step;
    }
    url = url + '&script=' + (script ? script : 0);
    if (redirect) {
    url = url + "&url=" + escape(redirect);
    }
    return url;
}



public indeed_handle_conversion() {
  var w = window;
  if (w.indeed_conversion_count) {
  w.indeed_conversion_count = w.indeed_conversion_count + 1;
  } else {
  w.indeed_conversion_count = 1;
  }
  if (w.indeed_conversion_id) {
  this.url = this.indeed_get_conv_url(w.indeed_conversion_label, "", 1);
  // document.write('<ifr' + 'ame name="indconv"' +
  // ' width="1" height="1" src="' + url + '"' +
  // ' frameborder="0" marginwidth="0" marginheight="0"' +
  // ' vspace="0" hspace="0" allowtransparency="true"' +
  // ' scrolling="no">');
  // document.write('<i' + 'mg height="1" ' +
  // 'width="1" border="0" ' +
  // 'src="' + url + '&ifr' + 'ame=0" />');
  // document.write('</ifr' + 'ame>');
  }
  w.indeed_conversion_id = null;
  w.indeed_conversion_value = null;
  w.indeed_conversion_label = null;
  w.indeed_conversion_ia = null;
}


  public mounted() {
    window.indeed_conversion_id = '2791652193719856';
    window.indeed_conversion_label = '';
    this.indeed_handle_conversion();
  }

  public gotoInterview() {
    if (this.$router.currentRoute.query.cid)
    {
      this.$router.push({
        name: 'Entrevista',
        params: { id: this.$router.currentRoute.query.cid!.toString() },
        query: { lang: this.$i18n.locale }
      }).catch(() => {});
    } else {
      this.$router.push({
        path: '/vagas/candidaturas',
        query: { lang: this.$i18n.locale }
      });

    }
  }
}
