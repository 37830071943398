




















import CardBox from '@/components/CardBox.vue';
import { CandidaturaEntity } from '@/core/models/Entities';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import Vue from 'vue';
import Component from 'vue-class-component';



@Component({
  components: {
    'card-box': CardBox,
  },
})
export default class CardProfileViewedByCompany extends Vue {
  public candidaturas: CandidaturaEntity[];
  public candidaturaService: CandidaturaService;

  constructor() {
      super();
      this.candidaturas = [];
      this.candidaturaService = new CandidaturaService();

  }

  public created() {
      this.candidaturaService.fetchAll()
      .then((data: any) => {
        data.candidaturas.forEach((el:any) => {
          if(el.status != 'VIDEOSPENDENTES')
            this.candidaturas.push(el);
        });
      })
      // tslint:disable-next-line:no-console
      .catch(console.error);
  }
}
