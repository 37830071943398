

































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';
import { CandidatoEntity } from '@/core/models/Entities';
import Logo from '@/components/logo/Logo.vue';


@Component({
  components: {
    Logo
  }
})
export default class TopNavBar extends Vue {

  public show: boolean = false;
    
  @Prop({default: () => { return [] }})
  public subscribers!: UserModel[];

  @Prop({default: null})
  public canApprove!: boolean;

  @Prop({default: false })
  public blindEnabled!: boolean;

  @Prop({default: false })
  public enableCustomBack!: boolean;

  @Prop({default: '' })
  public currentRAToken!: string;

  public selectedCandidato: CandidatoEntity|null = null;
  
  public recordingPromise: Promise<any>|null = null;

  @Prop({default: () => {return new UserModel()}})
  public value!: UserModel;

  @Prop({default: false })
  public showNotification!: boolean;

  @Prop({default: false })
  private isAuthenticatedUser!: boolean;

  @Prop({default: [] })
  public audioDevices!: any[];

  @Prop({default: [] })
  public videoDevices!: any[];

  @Prop({default: "" })
  private sessionId!: string;

  @Prop({default: false })
  public fullscreen!: boolean;

  @Prop({default: false })
  public sharing!: boolean;

  @Prop({default: false })
  private recordingEnabled!: boolean;

  @Prop({default: false })
  public permitRecord!: boolean;
  
  get subscribersList() {
    return this.subscribers;
  }

  get isProductionEnv() {
     return (process.env.NODE_ENV == 'production');
  }

  get isAuthenticated() {
    return this.isAuthenticatedUser;
  }

  get isSharing() {
    return this.sharing;
  }

  get micIcon() {
    return this.value.isAudioActive() ? 'fa fa-microphone' : 'fa fa-microphone-slash';
  }

  get videoIcon() {
    return this.value.isVideoActive() ? 'fa fa-video' : 'fa fa-video-slash';
  }
  
  get fullScreenIcon() {
    return this.fullscreen ? 'fa fa-compress' : 'fa fa-expand';
  }

  get screenIcon() {
    return this.value.isScreenShareActive() ? 'fa fa-window-close' : 'fa fa-window-restore';
  }

  
  public recName: string = '';
  public showPopover: boolean = false;
  public showPopoveRec: boolean = false;
  public showPopoverConfig: boolean = false;
  public pitch: number = 1;
  public rate: number = 1;
  public tempo: number = 1;

  private currentAudioDeviceId: string = "";
  private currentVideoDeviceId: string = "";


  public micStatusChanged(event: any) {
    this.$emit('micStatusChanged', event);
  }

  public camStatusChanged(event: any) {
    this.$emit('camStatusChanged', event);
  }

  public screenShare(event): any {
   this.$emit('screenShare', event);
  }

  public leaveSession(event: any) {
    this.$emit('leaveSession', event);
  }

  public toggleFullscreen(event: any) {
    this.$emit('toggleFullscreen', event);
  }

  public toggleBackgroundSidebar(event: any) {
    this.$emit('toggleBackgroundSidebar', event);
  }

  public stopScreenShare(event) {
    this.$emit('stopScreenShare', event);
  }

  public showManual1Visible(event) {
    this.$emit('showManual1Visible', event);
  }

  public toggleChat(event: any) {
    const property: string = this.showNotification ? 'block' : 'none';
    this.$emit('toggleChat', property)
  }

  public audioFilter(v: any) {
    this.$emit('audioFilter', v)
  }

  public audioCustomFilter(v: any) {
    this.$emit('audioCustomFilter', this.pitch,this.rate, this.tempo);
    this.showPopover = false;
  }
  
  public removeFilter(v: any) {
    this.$emit('removeFilter', v)
  }

  public videoFilter(v: any) {
    this.$emit('videoFilter', v)
  }

  public doCallStartRecordingWithName() {
    this.$emit('startRecording', this.recName, this.recordingPromise)
    this.showPopoveRec = false;
  }

  public startRecording(v: any) {
    this.showPopoveRec = true;
  }

  public stopRecording(v: any) {
    this.$emit('stopRecording', v, this.recordingPromise)
  }

  onClose() {
    this.showPopover = false
    this.showPopoveRec = false
    this.showPopoverConfig = false
  }
  onOk() {
  }
  onShow() {
    // This is called just before the popover is shown
    // Reset our popover form variables
  }
  onShown() {
    // Called just after the popover has been shown
    // Transfer focus to the first input
  }
  onHidden() {
    // Called just after the popover has finished hiding
    // Bring focus back to the button
  }
 
  public onCopy(object: any) {
    this.successAlert(this.$t('Link successfully copied!').toString() + ' ' +object.text)
    this.$bvModal.hide('modalCopyLink')
  }

  public onError() {
    this.$toast.variant = 'danger';
    this.$toast.addMsg(this.$t('It was not possible to copy the link to your desktop'))
    this.$toast.addTitle(this.$t('Oops').toString());
    this.$toast.open();
    console.log("not copied");
  }

  public successAlert(msg: string) {
    this.$toast.variant = 'success';
    this.$toast.addMsg(msg)
    this.$toast.addTitle(' - ');
    this.$toast.open();
  }

  public doCallToggleDevices() {
    this.$emit('toggleDevices',this.currentVideoDeviceId,this.currentAudioDeviceId)
  }
  
  public showSubscriber(u: UserModel) {
    let msg: any = this.$t('Are you sure you want to reveal the person?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('showSubscriber',u)
      }
    });
  }

  public showEvalModal(u: UserModel) {
    this.$emit('showEvalSidebar', u)
  }
  
  public muteSubscriber(u: UserModel) {
    let msg: any = this.$t('Are you sure about that?')
    this.$confirm.open({okTitle: this.$t('Yes').toString(), cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
      if (v) {
        this.$emit('muteSubscriber',u)
      }
    });
  }

  public async changeSubscriberAudoFilter(u: UserModel, n: number) {
    
    const value: boolean = await this.$bvModal.msgBoxConfirm(this.$t('Are you sure about that?').toString())
    if(value && u.blinded)
      this.$emit('changeSubscriberAudoFilter',u, n)
  }

  public IsRecruiter() {
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  public evaluated() {
    
    this.$toast.addMsg(this.$t('Evaluation sent successfully'));
    this.$toast.variant = 'success';
    this.$toast.open();
    this.selectedCandidato = null;
  }
  
}
