


























































import Vue from 'vue';
import Component from 'vue-class-component';
import SearchInput from '@/components/search/Search.vue';
import CheckboxInput from '@/components/form/CheckboxInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import MessageJob from "@/core/Services/Basic/MessageJob";
import { MessageJobEntity, EmpresaEntity, CandidatoEntity } from '@/core/models/Entities';

@Component<ListaMensagens>({
  components: {
    'search-input': SearchInput,
    CheckboxInput,
    TextInput
  }
})
export default class ListaMensagens extends Vue {
  public messageJobService: MessageJob = new MessageJob;
  public mobileIsShowMessages = false;
  public rootMessagesCache: MessageJobEntity[]|null = null;
  public rootMessages: MessageJobEntity[]|null = null;
  public containedIds: number[]= [];
  public currRootMessage: MessageJobEntity|null = null;
  public page: number = 1;
  public infiniteId: number = (new Date().getTime());
  public total: number =0;

  public searchRecebidas = '';
  public searchEnvidas = '';
  public sendMessage = '';

  public mounted() {
    this.rootMessages = this.rootMessagesCache = [];
    this.containedIds = [];
    this.loadMessages(null);
  }

  public loadMessages($state: any|null) {
    const dataSearch: any = {
      search: this.searchRecebidas,
      page: this.page
    }
    this.messageJobService.fetchAll(dataSearch).then(data => {
      if(data) {
        this.total = data.size;
        data.mensagens.forEach((element: MessageJobEntity) => {
          if(this.containedIds!.indexOf(element.id!) == -1){
            this.containedIds!.push(element.id!)
            this.rootMessages!.push(element)
          }
        });
        this.rootMessagesCache = this.rootMessages;
        if($state){
          $state.loaded();
        }
        if(!data!.mensagens!.length && $state){
          $state.complete();
        }
      } else if($state) {
        $state.complete();
      }
    });
  }

  public newSearch(): Promise<any> {
    const dataSearch: any = {
      search: this.searchRecebidas,
      page: 1,
      limit:100,
    }
    return this.messageJobService.fetchAll(dataSearch).then(data => {
      if(data) {
        data.mensagens.forEach((element: MessageJobEntity) => {
          if(this.containedIds!.indexOf(element.id!) == -1){
            this.containedIds!.push(element.id!)
            this.rootMessagesCache!.push(element)
          }
        });
      }
    });
  }

  public infiniteHandler($state: any)
  {
    this.page++
    this.loadMessages($state);
  }

  public setMessagesList() {
    this.mobileIsShowMessages = false;
  }


  public setCurrRootMessage(message: MessageJobEntity) {
    this.mobileIsShowMessages = true;
    this.currRootMessage = message;
    this.$emit('select', message)
  }
  
  public async doSearch(value: string, request: boolean = true) {
    if (this.searchRecebidas && this.searchRecebidas != '') {
      const listFilter = (<Array<MessageJobEntity>>this.rootMessagesCache).filter((msg: MessageJobEntity) => (msg.assunto+msg.candidato!.primeironome+msg.candidato!.sobrenome).toLowerCase().indexOf(this.searchRecebidas.trim().toLowerCase()) > -1);

      if (listFilter) {
        this.rootMessages = listFilter;
      }
      if(request) {
        await this.newSearch()
        this.doSearch('',false)
      }
    } else {
      this.rootMessages = this.rootMessagesCache;
    }
  }

  public showDate(data:any) {
    return this.$showDateFromObject(data)
  }
}
