












































import { Vue, Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Section from '@/components/section/Section.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';

const namespace: string = 'profile';

@Component({
  components: {
    "section-card": Section
  }
})
export default class CardNotification extends Vue {
  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('setCandidatoData', { namespace })
  public setCandidatoData: any;

  private set notifAcompanhamento(value: number) {
    this.setCandidatoData({ notifAcompanhamento: value });
  }
  private get notifAcompanhamento() {
    return this.candidato ? this.candidato.notifAcompanhamento : 0;
  }

  private set notifIndicacao(value: number) {
    this.setCandidatoData({ notifIndicacao: value });
  }
  private get notifIndicacao() {
    return this.candidato ? this.candidato.notifIndicacao : 0;
  }

  private set notifNovidades(value: number) {
    this.setCandidatoData({ notifNovidades: value });
  }
  private get notifNovidades() {
    return this.candidato ? this.candidato.notifNovidades : 0;
  }

  public saveField(key: string, value: any){
    this.$emit('saveField', {key, value});
  }
}
