import Cookies from 'js-cookie'

export const insertIntoCookies = (
  key: string,
  value: unknown,
  encrypt?: boolean
) => {
  let valueInString = encodeURIComponent(JSON.stringify(value))

  if (encrypt) {
    valueInString = btoa(valueInString)
  }

  Cookies.set(key, valueInString)
}

export const getValueIntoCookies = (key: string, isEncrypted?: boolean) => {
  try {
    if (Cookies.get(key)) {
      if (isEncrypted) {
        return JSON.parse(atob(decodeURIComponent(Cookies.get(key) as string)))
      }

      return JSON.parse(decodeURIComponent(Cookies.get(key) as string))
    }
    return null;
  } catch (e) {
    return null;
  }
}

export const removeValueIntoCookies = (key: string) => {
  if (Cookies.get(key)) {
    Cookies.remove(key)
  }
}


export default class ShareSession {
  constructor() {
    // transfers sessionStorage from one tab to another
    const sessionStorageTransfer = (event: any) => {
      if (!event) { event = window.event; } // ie suq
      if (!event.newValue) { return; }          // do nothing if no value to work with
      if (event.key === 'JB__T_CDL_EVT') {
        // another tab asked for the sessionStorage -> send it
        localStorage.setItem('JB_TALENT_SESSION_SHARE', JSON.stringify(sessionStorage));
      } else if (event.key === 'JB_TALENT_SESSION_SHARE' && !sessionStorage.getItem('access_token')) {
        // another tab sent data <- get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          if (key) {
            sessionStorage.setItem(key, data[key]);
          }
        }
      }
      setTimeout(() => {
        // the other tab should now have it, so we're done with it.
        localStorage.removeItem('JB_TALENT_SESSION_SHARE'); // <- could do short timeout as well.
      }, 500);
    };

    try {
      const cdata: any = getValueIntoCookies(`logincandidateData`)
      if (cdata && cdata.access_token) {
        sessionStorage.setItem("token_type", 'Bearer');
        sessionStorage.setItem("access_token", cdata.access_token);
        sessionStorage.setItem("profile_type", "candidate");
      }
    } catch (e) {
      //do nothing
    }

    // listen for changes to localStorage
    if (window.addEventListener) {
      window.addEventListener('storage', sessionStorageTransfer, false);
    } else {
      (window as any).attachEvent('onstorage', sessionStorageTransfer);
    }

    if (!sessionStorage.length) {
      localStorage.setItem('JB__T_CDL_EVT', 'foobar');
      localStorage.removeItem('JB__T_CDL_EVT');
    }
  }

  public forceCheck() {
    /* console.log('force Check') */
    localStorage.setItem('JB__T_CDL_EVT', 'foobar');
    localStorage.removeItem('JB__T_CDL_EVT');
  }
}

