import _Vue from 'vue';
import Alert from '@/components/alerts/Alert.vue';


export class AlertPluginOptions {
  // add stuff
}
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function AlertPlugin(Vue: typeof _Vue, options?: AlertPluginOptions): void {
    // do stuff with options
    Vue.prototype.$alert = new Alert();
}