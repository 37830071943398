




import Vue from 'vue';
import Component from 'vue-class-component';
import {i18n} from '@/plugins/i18n'

@Component
export default class Confirm extends Vue {
  public msg: any[] = [];
  public okvariant: string = 'primary';
  public cancelvariant: string = 'danger';
  public title: any = ' ';
  public header: string = '';
  public i18n: any;
  public id: string = '';
  public contentMSG: string = '';
  public contentTitle: any[] = [];
  public oktitle: string = '';
  public currentModal: any|null = null;
  public cancelTitle: string = '';


  constructor() {
    super();
    this.id = (new Date()).getTime()+'';
    this.i18n = i18n;
  }

  public created() {
    this.i18n = i18n;
  }

  public callBox() : Promise<any>
  {
      let modal = this.$bvModal.msgBoxConfirm(this.msg, {
        title: this.title,
        size: 'md',
        buttonSize: 'md',
        okVariant:  this.okvariant,
        okTitle: 'OK',
        cancelTitle: ''+this.i18n.t('Cancel'),
        cancelVariant: this.cancelvariant,
        footerClass: 'p-2',
        headerClass: this.header,
        hideHeaderClose: true,
        centered: true
      });
      this.msg = [];
      return modal;
  }
  // Modal Atenção
  public callBoxDanger() : Promise<any> 
  {
      this.okvariant = 'danger';
      this.cancelvariant = 'secondary',
      // Modify modal header with bootstrap class
      this.header = 'p-2 border-bottom border-danger text-danger'
      return this.callBox()
  }

  public callBoxSuccess() : Promise<any> 
  {
      this.okvariant = 'success';
      // Modify modal header with bootstrap class
      this.header = 'p-2 border-bottom border-success text-success'
      return this.callBox()
  }

  public addTitle(contentTitle: string){
      this.title = contentTitle;
  }

  public addMsg(msg: any){
      if(!this.msg) {
          this.msg = [];
      }

      this.msg.push(this.$createElement('div',msg+""));
  }

  
  public callBoxImageDoubleButton(type: string) {
    let modal = this.$bvModal.msgBoxConfirm(this.msg, {
      id: this.id,
      title: this.title,
      size: 'md',
      buttonSize: 'md',
      okVariant: 'success text-uppercase col-md-5 float-right',
      okTitle: this.oktitle,
      cancelVariant: 'danger text-uppercase col-md-5 float-left',
      cancelTitle: this.cancelTitle,
      headerClass: type + 'Header',
      bodyClass: type + 'ConfirmBody text-center',
      hideHeaderClose: true,
      footerClass: type + 'ConfirmFooter border-top-0 d-block',
      contentClass: type + 'ConfirmContent',
      centered: true
    });
    this.msg = [];
    return modal;
  }

  public open(props: { okTitle: string, cancelTitle: string, msg: string}, type:string){
    this.addMsg(props.msg);
    this.oktitle = props.okTitle || '';
    this.cancelTitle = props.cancelTitle || '';
    return this.callBoxImageDoubleButton(type)
  }


}
