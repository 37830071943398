























import CardPdfSkills from '@/components/cards/CardPdfSkills.vue';
import DadosAcademicosView from '@/components/perfil/DadosAcademicosView.vue';
import DadosPessoaisView from '@/components/perfil/DadosPessoaisView.vue';
import DadosProfissionaisView from '@/components/perfil/DadosProfissionaisView.vue';
import CardIdiomas from '@/components/perfil/card-group-view/CardIdiomas.vue';
import ProfileHeaderNavigation from '@/components/perfil/header-navigation/ProfileHeaderNavigation.vue';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

import { CandidatoEntity } from '@/core/models/Entities';

const namespace: string = 'profile';

@Component({
  components: {
    ProfileHeaderNavigation,
    DadosPessoaisView,
    DadosProfissionaisView,
    DadosAcademicosView,
    CardPdfSkills,
    CardIdiomas
  }
})
export default class PerfilView extends Vue {
  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  public candidatoModel: CandidatoEntity|null = null;

  public async mounted() {
    if(!this.candidato)
      await this.fetchCandidatoInfo();

    if ((this as any)._isDestroyed) {
      // Trocou de página e componente foi destruído
      return;
    }

    this.$set(this,'candidatoModel', JSON.parse(JSON.stringify(this.candidato)));

    this.updateCandidato();

    setTimeout(async () => {
      await this.fetchCandidatoInfo();
    }, 60000);
    this.$nextTick(() => {
      (this.$refs.hnav as ProfileHeaderNavigation).goToTop();
      (this.$refs.habe as CardPdfSkills).loadData(this.candidato!.perfil!.habilidades);
    })
  }

  public updateCandidato() {
    if(this.$route.query.updateVC == "1") {
      setTimeout(async () => {
        await this.fetchCandidatoInfo();
      },3000)
    }
  }
}
