










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Logo extends Vue {

  @Prop({default: false }) public inverse!: string;
  @Prop({default: false }) public small!: boolean;
}
