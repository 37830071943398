



































import { CandidatoEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ProfilePdfHeader extends Vue {
  @Prop({ default: null })
  candidato!: CandidatoEntity;

  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;

  get fullNome() {
    return this.candidato ? `${this.candidato.primeironome} ${this.candidato.sobrenome}` : ''; 
  }

  get nomeUrl() {
    return Boolean(this.candidato!.nomeUrl)
      ? this.uri+'/c/'+this.candidato!.nomeUrl
      : null;
  }
}
