import { render, staticRenderFns } from "./CardProfessionalInfo.vue?vue&type=template&id=073fd653&scoped=true"
import script from "./CardProfessionalInfo.vue?vue&type=script&lang=ts"
export * from "./CardProfessionalInfo.vue?vue&type=script&lang=ts"
import style0 from "./CardProfessionalInfo.vue?vue&type=style&index=0&id=073fd653&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073fd653",
  null
  
)

export default component.exports