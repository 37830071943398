
import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { ExperienciaEntity } from '../models/Entities';


export default class Experiencia extends Repository {
  public entidade: ExperienciaEntity;

  constructor() {
    super(
      new ApiRouter({
        create: { url: '/v1/candidato-experiencia', method: 'POST', headers: {} },
        delete: { url: '/v1/candidato-experiencia/{id}', method: 'DELETE', headers: {} },
        fetch: { url: '/v1/candidato-experiencia/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/candidato-experiencia', method: 'GET', headers: {} },
        update: { url: '/v1/candidato-experiencia/{id}', method: 'PUT', headers: {} },
      }));
    this.entidade = new ExperienciaEntity();
  }

  public async save(): Promise<any> {
    if (!this.entidade.id) {
      return super.create(this.entidade)
      .then((data: any) => {
        this.entidade = data;
        return true;
      })
      .catch((err: any) => {
        return false;
      });
    } else if (this.entidade.id > 0 ) {
      return super.update(this.entidade)
      .then((data: any) => {
        this.entidade = data;
        return true;
      })
      .catch((err: any) => {
        return false;
      });
    }
  }
}

