

















































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import IdiomaService from "@/core/Services/Basic/Idioma";
import PaisService from "@/core/Services/Pais";
import EstadoService from "@/core/Services/Estado";
import CidadeService from "@/core/Services/Cidade";
import { PerfilCandidatoEntity } from '@/core/models/Entities';


@Component
export default class DadosPessoaisEdit extends Vue {

  @Prop({default: null})
  public value!: PerfilCandidatoEntity;

  public idiomaSRV!: IdiomaService;
  public paisSRV!: PaisService;
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public selected: any|null = null;
  public showLocalidadeText: boolean = true
  public allGood: boolean = false;

  constructor() {
    super();
    this.paisSRV = new PaisService();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
    this.estadoSRV.loadOptions();
    this.paisSRV.loadOptions();
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(
      this.value.estadoId
    );
    if (value) {
      this.value.cidadeId = this.cidadeSRV.cidadeByName(
        value
      );
    }
  }

  public mounted() {
   this.loadLocation();
  }

  public loadLocation() {
    this.checkCountry();
    this.estadoSRV.loadOptions(0);
    this.cidadeSRV.loadOptions(this.value.estadoId);
  }

  public checkCountry() {
    if (this.value.pais === "BR") {
      this.value.localidade = "";
      this.showLocalidadeText = false;
    } else {
      this.value.estadoId = null;
      this.value.cidadeId = null;
      this.showLocalidadeText = true;
    }
  }

  public searchByCEP() {
    this.cidadeSRV
      .searchByCEP(this.value.cep)
      .then(dta => {
        this.value.rua = dta.logradouro;
        this.value.bairro = dta.bairro;
        this.value.pais = "BR";
        this.value.estadoId = this.estadoSRV.estadoByName(
          dta.uf
        );
        this.chainToCity(dta.localidade);
        this.checkCountry()
      }).catch(() => {});
  }

  get invalidFeedback() {
    return ''
  }

  get validFeedback(){
      return '';
  }


}
