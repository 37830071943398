import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=468d1c8e"
import script from "./Chat.vue?vue&type=script&lang=ts"
export * from "./Chat.vue?vue&type=script&lang=ts"
import style0 from "@/assets/css/live-interview/chat.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports