












import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Section from '@/components/section/Section.vue';
import IdiomaService from '@/core/Services/Basic/Idioma';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity, IdiomaEntity } from '@/core/models/Entities';

const namespace: string = 'profile';

@Component<CardLanguage>({
  components: {
    'section-card': Section
  }
})

export default class CardLanguage extends Vue {
  public idiomaService = new IdiomaService();
  public candidatoService = new CandidatoService();

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('setCandidatoData', { namespace })
  public setCandidatoData: any;

  private set idiomaId(idiomaId: number|undefined) {
    let data: any = { idiomaId };
    
    if (idiomaId) {
      data.idioma = new IdiomaEntity();
      data.idioma.id = idiomaId;
      const idiomaItem: any = this.idiomaService.optionList.find((idioma: {value: number, text: string, locale: string}) => {
        return idioma.value === idiomaId;
      });

        data.idioma.legenda = idiomaItem.text;
        this.$router.replace({path:  this.$router.currentRoute.path, query: { lang: idiomaItem.locale } })
    }

    this.setCandidatoData({ perfil: data });

    this.saveLanguage();
  }
  private get idiomaId(): number|undefined {
    return this.candidato ? this.candidato.perfil!.idioma!.id! : undefined;
  }

  public created() {
    this.idiomaService.loadOptions();
  }

  public saveLanguage() {
    this.$emit('saveLanguage');
  }
}

