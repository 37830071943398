















import { Component, Prop, Vue } from "vue-property-decorator";


@Component
export default class Section extends Vue {
  @Prop({ default: "" }) public id!: string;
  @Prop({ default: "" }) public iconName!: string;
  @Prop({ default: "" }) public title!: string;
}
