
































































































































import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import InviteFriendsEmail from '@/components/invite-dialogs/InviteFriendsEmail.vue';
import SearchInput from '@/components/search/Search.vue';
import { CandidatoEntity } from '@/core/models/Entities';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from 'vuex-class';

const namespace: string = 'profile';

@Component({
  components: {
    SearchInput,
    ButtonIcon,
    InviteFriendsEmail,
  },
})
export default class SubHeader extends Vue {
  public candidatoSRV = new CandidatoService;
  public title!: string;
  private showModalInvite = false;
  private toggleClickChat: boolean = false;
  private langs = [
    {
      id: '10',
      locale: 'pt-BR',
      name: 'Português'
    },
    {
      id: '3',
      locale: 'es',
      name: 'Español'
    },
    {
      id: '2',
      locale: 'en-US',
      name: 'English'
    },
    {
      id: '5',
      locale: 'fr',
      name: 'Français'
    }
  ];

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter("isEntrevistaPage", { namespace })
  private isEntrevistaPage!: boolean;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('setCandidatoData', { namespace })
  public setCandidatoData!: (data: {}) => void;


  @Action('showVagasFilter', { namespace: 'ui' })
  public showVagasFilter!: () => void;

  @Action('toggleVagasFilter', { namespace: 'ui' })
  public toggleVagasFilter!: () => void;

  private get show(): boolean {
    return !this.isEntrevistaPage;
  }

  get isNotHoliday() {
    return (
          (((new Date()).getUTCMonth()+1) == 12 && (new Date()).getFullYear() == 2020) ||
          (((new Date()).getUTCMonth()+1) == 1 && (new Date()).getFullYear() == 2021 && (new Date()).getUTCDate() < 4)
        ) == false;
  }

  public logout() {
    this.$router.replace({ path: '/logout', query: {lang: this.$i18n.locale }});
  }

  public goHelp() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/1`;
    window.open(fc, '_blank');
    //this.$router.replace({ path: '/ajuda', query: {lang: this.$i18n.locale }});
  }

  public toggleChat() {
    
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/cpp/create-ticket/`;
    window.open(fc, '_blank');

    // if(this.toggleClickChat && window.jivo_destroy) {
    //   /* console.log('destroy') */
    //   this.toggleClickChat = false;
    //   window.jivo_destroy();
    // } else {
    //   /* console.log('open') */
    //   this.openChat()
    // }
  }

  public openChat() {
    this.toggleClickChat = true;
    if(!window.jivo_init) {
      const widget_id = 'VmLYh3b5rh';
      let s = document.createElement('script'); 
      s.type = 'text/javascript'; s.async = true;
      s.src = '//code3.jivosite.com/script/widget/'+widget_id; 
      document.body.append(s);  
      this.timeToOpenChat();
    } else {
      window.jivo_init();
      this.timeToOpenChat();
    }
  }

  public timeToOpenChat() {
    setTimeout(() => {  
        try { 
          if(window.jivo_api)
            window.jivo_api.open();
        } catch (e) {
        }
      },1500)
  }

  public config() {
    this.$router.replace({ path: '/settings', query: { lang: this.$i18n.locale }});
  }

  public goToVagasWithFilter() {
    if (this.isInRootVagas()) {
      this.toggleVagasFilter();
      return;
    }
    this.showVagasFilter();
    this.$router.push({ name: 'Vagas', query: { lang: this.$i18n.locale }});
  }

  private isInRootVagas() {
    return !!this.$route.matched.find((route) => {
      return route.name === 'RootVagas';
    });
  }

  public created() {
    this.title = this.$router.currentRoute.meta?.title

    if (!this.candidato) {
      this.loadCandidatoInfo();
    }
  }

  public setLocale(lang: any) {
    if (this.$i18n.locale === lang.locale) {
      return;
    }
    this.$i18n.locale = lang.locale;
    this.saveLanguage(lang);
    this.$router.replace({path:  this.$router.currentRoute.path, query: { lang: lang.locale } })
  }

  public saveLanguage(lang) {
    // this.candidato!.perfil!.idioma!.id = lang.id;
    this.setCandidatoData({
      perfil: {
        idioma: {
          id: lang.id
        }
      }
    });
    this.candidatoSRV.saveProfileField('idiomaId', lang.id, this.candidato!.id);
  }

  public toggleSidebar() {
    this.$emit('on-sidebar');
  }
}
