















































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import Section from "@/components/section/Section.vue";

@Component<CardAcademicInfo>({
  components: {
    "section-card": Section
  }
})
export default class CardAcademicInfo extends Vue {
  @Prop({ default: null })
  public candidato!: CandidatoEntity;

  public fields: any[] = [];

  // public created() {
  //   this.fields = [
  //     { key: "nome" },
  //     { key: "atividade" },
  //     { key: "dataEntrada" },
  //     { key: "dataSaida" },
  //     { key: "localidade" },
  //     { key: "informacoes", class: 'text-center' }
  //   ];
  // }

  public mounted(){
    this.fields = [
      { key: "nome", label: this.$t('Name') },
      { key: "atividade", label: this.$t('Activity')},
      { key: "dataEntrada", label: this.$t('Start Date') },
      { key: "dataSaida", label: this.$t('End Date') },
      { key: "localidade", label: this.$t('Location') },
      { key: "informacoes", label: this.$t('Informations'), class: 'text-center' }
    ];
  }  
}
