


























































import { CandidaturaEntity, VagaEntity } from '@/core/models/Entities';
import VagasFavoritasService from '@/core/Services/Basic/VagasFavoritas';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import Alert from '../alerts/Alert.vue';
const namespace: string = 'profile';

@Component
export default class CardJob extends Vue {
  public currentDate = new Date();
  public salvarVagasSRV!: VagasFavoritasService
  public alert!: Alert;

  constructor() {
    super();
    this.salvarVagasSRV = new VagasFavoritasService();
    this.alert = new Alert();
  }

  @Prop({default: () => { return new VagaEntity()}})
  public vaga!: VagaEntity;

  @Prop({default: () => { return new CandidaturaEntity()}})
  public candidatura!: CandidaturaEntity;

  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  get vagaPage(){
    if (this.$route.name == 'CompanyPublicProfile') {
      return {name: 'VagaPublico', params: { urlcode: this.vaga.nomeUrl+"" }, query: { lang: this.$i18n.locale }};
    } else {
      return {name: 'DetalheVagaCandidato', params: { id: this.vaga.nomeUrl+"" }, query: { lang: this.$i18n.locale }};
    }
  }

  public entrevistaPage() {
    this.$router.push({name: 'Entrevista', params: { id: this.candidatura.id+"" }, query: { lang: this.$i18n.locale }}).catch(() => {});
  }
 
 public examPage() {
    this.$router.push({name: 'Exams', params: { cid: this.candidatura.id+"" }, query: { lang: this.$i18n.locale }});
  }

  get canMakeInterview(): boolean {
    return this.vaga.isCandidatado! && (this.candidatura.status == 'VIDEOSPENDENTES' || (this.candidatura.canAnswer == true && this.candidatura.status == 'EMANALISE'));
  }

  get canMakeExam(): boolean {
    return this.vaga.isCandidatado! && ( this.candidatura.status == 'EXAMESPENDENTES' );
  }

  get companyPage() {
    let nUrl: string = this.vaga!.empresa!.nomeUrl+"";
    if(!this.isAuthenticated) {
      return {name: 'CompanyPublicProfile', params: { urlcode: nUrl  }, query: { lang: this.$i18n.locale }};
    }

    return {name: 'CompanyProfile', params: { urlcode: nUrl }, query: { lang: this.$i18n.locale }};
  }

  get showSeemoreCompany() {
    return (this.vaga.confidencial !== 'SIM' && this.$route.name != 'CompanyProfile' && this.$route.name != 'CompanyPublicProfile')
  }

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }

  get nivel(): string {
    return this.vaga.nivel ? this.vaga.nivel.substring(0, 2) : '';
  }

  public addVagaSalva() {
    this.salvarVagasSRV.create({vagaId: this.vaga.id})
    .then((data: any) => {
        this.alert.addMsgCentralized(this.$t('Job saved successfully').toString());
        this.alert.callBoxCheckWithTitle(this.$t('OK').toString());

        this.vaga.isSaved = true;
    })
  }

  public vagaNaoSalva() {
    /* console.log(this.vaga.id) */
    this.salvarVagasSRV.delete({id: this.vaga.id})
    .then((data: any) => {
        this.alert.addMsgCentralized(this.$t('Job removed from favorites').toString());
        this.alert.callBoxCheckWithTitle(this.$t('OK').toString());

        this.vaga.isSaved = false;
    })
  }

}
