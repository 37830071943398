








































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import PerfilService from "@/core/Services/Candidato/PerfilCandidato";
import { CandidatoEntity } from '@/core/models/Entities';
import DadosProfissionaisSimple from '@/components/perfil/DadosProfissionaisSimple.vue';
import DadosAcademicosSimple from '@/components/perfil/DadosAcademicosSimple.vue';
import PaisService from "@/core/Services/Pais";
import EstadoService from "@/core/Services/Estado";
import CidadeService from "@/core/Services/Cidade";
const namespace: string = 'profile';

@Component({
  components: {
    DadosProfissionaisSimple,
    DadosAcademicosSimple
  }
})
export default class ModalCurriculoOculto extends Vue {
  public candidatoSRV!: CandidatoService;
  public perfilSRV!: PerfilService;
  public paisSRV!: PaisService;
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public promise: Promise<any>|null = null;
  
  public professionalStatus: boolean = false;
  public academicStatus: boolean = false;
  public showLocalidadeText: boolean = false;

  // public candidato!: CandidatoEntity;
  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Prop({default: ''})
  public id!: string;

  @Prop({default: ''})
  public title!: string;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: false})
  public size!: boolean;

  @Prop({default: null})
  public nextButtomText!: string;

  @Prop({default: null})
  public titleText!: string;

  @Prop({default: false})
  public resumed!: boolean;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.perfilSRV = new PerfilService();
    this.paisSRV = new PaisService();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
  }

  get profile() {
    if(this.candidatoSRV && this.candidatoSRV.entidade && this.candidatoSRV.entidade.perfil)
      return this.candidatoSRV.entidade.perfil;
    return null;
  }

  get formacoes() {
    if(this.profile) 
      return this.profile.formacoes
    
    return [];
  }
  

  get experiencias() {
    if(this.profile) 
      return this.profile.experiencias
    
    return [];
  }

  public async mounted() {
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    }
    this.$set(this.candidatoSRV, 'entidade', Object.assign({},this.candidato));
    this.$set(this.candidatoSRV.entidade, 'perfil', Object.assign({},this.candidato?.perfil));
    this.estadoSRV.loadOptions();
    await this.paisSRV.loadOptions();
    this.loadLocation();
    const profile: any = this.candidatoSRV?.entidade?.perfil;
    if(profile?.formacoes?.length||0 > 0 || profile?.temFormacao === false) {
      this.academicStatus = true
    }
    if(profile?.experiencias?.length||0 > 0 || profile?.temExperiencia === false) {
      this.professionalStatus = true
    }
  }

  public async openCurriculumPage() {
    await this.save(false)
    let routeData = this.$router.resolve({name: 'HiddenTalentInfoPage', query: {lang: this.$i18n.locale}});
    window.open(routeData.href, '_blank');
    this.$bvModal.show('modal-job-step')
  }

  public loadLocation() {
    this.checkCountry();
    this.estadoSRV.loadOptions(0);
    this.cidadeSRV.loadOptions(this.candidatoSRV.entidade?.perfil?.estadoId);
  }

  public checkCountry() {
    if (this.candidatoSRV.entidade?.perfil?.pais === "BR") {
      this.candidatoSRV.entidade.perfil.localidade = "";
      this.showLocalidadeText = false;
    } else {
      this.candidatoSRV.entidade.perfil.estadoId = null;
      this.candidatoSRV.entidade.perfil.cidadeId = null;
      this.showLocalidadeText = true;
    }
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(
      this.candidatoSRV.entidade.perfil!.estadoId
    );
    if (value) {
      this.candidatoSRV.entidade.perfil!.cidadeId = this.cidadeSRV.cidadeByName(
        value
      );
    }
  }

  public async loadCInfo() {
    await this.fetchCandidatoInfo();
    this.$set(this.candidatoSRV, 'entidade', Object.assign({},this.candidato));
    this.$set(this.candidatoSRV.entidade, 'perfil', Object.assign({},this.candidato?.perfil));
  }

  public handleSaveProfissional(status: boolean) {
    this.professionalStatus = status;
    //this.loadCInfo();
  }
  public handleSaveAcademic(status: boolean) {
    this.academicStatus = status;
    //this.loadCInfo();
  }
  public save(emit: boolean = true) {
    if(this.professionalStatus && this.academicStatus) {
      console.log('gg')
      this.$bvModal.hide('modalCurriculoOculto');
      this.loadCInfo();
      if(emit)
        this.$emit('saved', true);       
    } else {

    }
  }

  public saveProfile(key: string) {
    const data: any = {};
    data['id'] = this.candidatoSRV.entidade.perfil.id;
    data[key] = this.candidatoSRV.entidade.perfil[key];

    this.perfilSRV.update(data).catch((e) => {
        this.$alert.addMsg(this.$t('There was an error collecting this candidates data').toString());
        this.$alert.addTitle(this.$t('Oops').toString());
        this.$alert.callBoxError();
      })
  }

  public async justSave(){
    const profile: any = this.candidatoSRV?.entidade?.perfil;
    
    if((profile?.formacoes?.length||0) > 0 || profile?.temFormacao === false) {
      this.academicStatus =true;
    }

    if((profile?.experiencias?.length||0) > 0 || profile?.temExperiencia === false) {
      this.professionalStatus =true;
    }

    await this.candidatoSRV.save(false)

  }
}
