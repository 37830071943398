
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class MatchCandidato extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/match-candidato', method: 'POST', headers: {} },
                fetch: { url: '/v1/match-candidato/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/match-candidato', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/match-candidato/{id}', method: 'PUT', headers: {} }}));
    }

}


