


































































































































































































































































































































































































































































import DateInput from '@/components/form/DateInput.vue';
import EtniaSelect from '@/components/perfil/EtniaSelect.vue';
import PcdDetails from '@/components/perfil/PcdDetails.vue';
import Section from "@/components/section/Section.vue";
import { CandidatoEntity } from '@/core/models/Entities';
import { pcdObject } from '@/core/models/pcd';
import AreaAtuacaoService from "@/core/Services/AreaAtuacao";
import CandidatoService from "@/core/Services/Candidato/Candidato";
import NivelEscolaridadeService from "@/core/Services/NivelEscolaridade";
import Datepicker from '@hokify/vuejs-datepicker';
import moment from 'moment';
import MultiSelect from 'vue-multiselect';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { en, ptBR } from 'vuejs-datepicker/dist/locale';
import FormEndereco from './form-group/FormEndereco.vue';


@Component<DadosPessoaisEdit>({
  components: {
    "section-card": Section,
    DateInput,
    Datepicker,
    MultiSelect,
    PcdDetails,
    EtniaSelect,
    FormEndereco
  }
})
export default class DadosPessoaisEdit extends Vue {

  @Prop({default: null})
  public value!: CandidatoEntity;

  public candidatoSRV!: CandidatoService;
  public atuacaoSRV!: AreaAtuacaoService;
  public escolaridadeSRV!: NivelEscolaridadeService;
  public genders: any[] = [];
  public pcdList: any[] = [];
  public pcdArrayDescritivo: any[] = [];
  public selected: any|null = null;
  public showLocalidadeText: boolean = true;
  public state: boolean = true;
  public showCurriculumChange: boolean = false;
  public dataNascimento: Date|null = null;
  public idiomPromisse: any| null = null;
  public allGood: boolean = false;
  public scopeID: string;
  public pcdDetails: any = {};
  public pcdOBJ = pcdObject;
  public fromVaga: boolean = false;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.atuacaoSRV = new AreaAtuacaoService();
    this.escolaridadeSRV = new NivelEscolaridadeService();
    this.atuacaoSRV.loadOptions();
    this.escolaridadeSRV.loadOptions();
    this.scopeID = (new Date()).getTime() + '';
  }

  public handleClickPCD(){
    this.removePCD()
    this.pcdArrayDescritivo.forEach((el) => {
      if(!this.pcdDetails[el.key])
        this.pcdDetails[el.key] = { "tipo": null, "nivel": null, "key": el.key };
    })
  }

  public customLabel({text,value}) {
    return ""+this.$t(text)
  }

  get haveCV() {
    return !!(this.value.perfil.videoapresentacaoMP4);
  }

  public selectedType(key:string, value:string) {
    this.pcdDetails[key].tipo = value;
  }

  public selectedNivel(key:string, value:string) {
    this.pcdDetails[key].nivel = value;
  }

  public selectedEtnia(value) {
    if(['PRETO','PARDO'].indexOf(value) > -1) {
      this.value.perfil.grupoMovBlack = true;
    } else if( value == 'INDÍGENA') {
      this.value.perfil.grupoIndiginas = true;
    } else {
      this.value.perfil.grupoMovBlack = false;
      this.value.perfil.grupoIndiginas = false;

    }
  }

  public removePCD() {
    let aux = {};
    this.pcdArrayDescritivo.forEach((el) => {
      aux[el.key] = this.pcdDetails[el.key];
    })
    this.pcdDetails = aux;
  }

  public handleDataNascChange(){

    const value: Date|null = this.dataNascimento;

    if (!(value instanceof Date))
      return null;

    /* console.log(value) */
    const month: string =  (value.getMonth()+1).toString().padStart(2,"00");
    const year: string =  (value.getFullYear()).toString();
    const day: string =  (value.getUTCDate()).toString().padStart(2,"00");
    
    this.value.perfil.dataNasc = `${day}/${month}/${year}`;
    this.value.perfil.dataNascimento = `${year}-${month}-${day}`;

    const now: Date = new Date();

    now.setFullYear(now.getFullYear()-49);

    if ( value < now ) {
      this.value.perfil.grupo3Idade = true;
    } else {
      this.value.perfil.grupo3Idade = false;
    }

  }

  public parseTypedDate(value:Date) {

    let momentDate = moment(value, "DD/MM/YYYY");
    this.value.perfil.dataNascimento = value;
    this.value.perfil.dataNasc = value.toString();

    return momentDate.toDate();

  }

  public mounted() {
    
    this.genders = [
      { text: ""+this.$t("Select"), value: null },
      { text: ""+this.$t("Female"), value: "FEMININO" },
      { text: ""+this.$t("Male"), value: "MASCULINO" },
      { text: ""+this.$t("Trans Man"), value: "HOMEM TRANS" },
      { text: ""+this.$t("Trans Woman"), value: "MULHER TRANS" },
      { text: ""+this.$t("Non-binary"), value: "NAO BINARIO" },
      { text: ""+this.$t("Others"), value: "OUTROS" }
    ];


    this.pcdList = [
      {text: "Auditiva", value: "Auditiva", key: "auditiva" },
      {text: "Fala", value: "Fala", key: "fala"},
      {text: "Física", value: "Física", key: "fisica" },
      {text: "Intelectual/Mental", value: "Intelectual/Mental", key: "mental"},
      {text: "Visual", value: "Visual", key: "visual"}
    ]

    this.fromVaga = !!this.$route.query.vaid;
    this.loadData();
   
  }

  public loadData() {
      if(this.value && this.value.perfil) {
        if(this.value.perfil.dataNasc) {
          const arr: any[] = this.value.perfil!.dataNasc!.split('/')
          if(arr && arr.length>0) {
            if(!isNaN(parseInt(arr[2])) && !isNaN(arr[1]-1) && !isNaN(parseInt(arr[0])))
              this.dataNascimento = new Date(arr[2],arr[1]-1,arr[0])
          }
        }
        try {
          const data:any = JSON.parse(this.value.perfil.pcdDescricao)
          if(data && Array.isArray(data.array))
            this.pcdArrayDescritivo = data.array;
          if(data && data.details)
            this.pcdDetails = data.details;
        } catch (e) {
        }
      }
  }


  get invalidFeedback() {
    return ''
  }

  public handleCurriculumChange(event: any) {
    // /* console.log(event.target.files) */
    const file:any|null = event.target.files[0];
    if (file) {
      this.candidatoSRV.entidade = this.value;
      this.candidatoSRV.saveCurriculum(file).then((d: any) => {
        this.$toast.variant = 'info';
        this.$toast.addMsg(this.$t("Curriculum").toString())
        this.$toast.addTitle(this.$t("Saved successfully").toString());
        this.$toast.open();
        this.$emit('curriculumSaved',d.perfil!.curriculum)
        event.target.value = '';
        this.showCurriculumChange = false;
      })
      .catch((d: any) => {
        this.value.perfil!.curriculum = "";
        event.target.value = '';
      })
    }
  }

  
  public handleAvatarChange(event: any) {
    const file:any|null = event.target.files[0];
    if(file) {
      this.candidatoSRV.entidade = this.value;
      this.candidatoSRV.saveAvatar(file)
      .then((data: any) => {
        this.$toast.variant = 'info';
        this.$toast.addMsg(this.$t("Avatar").toString())
        this.$toast.addTitle(this.$t("Saved successfully").toString());
        this.$toast.open();
        this.$emit('avatarSaved',data.fotoAvatar);
        (document.querySelector('#candAvatar') as HTMLImageElement).src = data.fotoAvatar;
        event.target.value = '';
      }).catch((d: any) => {
        event.target.value = '';
      })
    }
  }

  get validFeedback(){
      return '';
  }

  public handleSubmit() {
    this.allGood = false;
    (this.$refs.submitButton as any).click();
  }

  public submitForm(evt: any) {
    evt.preventDefault();
    this.allGood = true;
    const data: any = {
      array: this.pcdArrayDescritivo,
      details: this.pcdDetails
    };
    this.value.perfil.pcdDescricao = JSON.stringify(data);
  }

  get getDateLang(){
    if (this.$i18n.locale == 'pt-BR')
        return ptBR;
    if (this.$i18n.locale == 'es')
        return en;
    return en
  }

  public limparPCDList(){
    if(!this.value.perfil.pcd){
      this.pcdArrayDescritivo = [];
      this.value.perfil.grupoPCD = false;
      this.removePCD();
    } else {
      this.value.perfil.grupoPCD = true;
    }
  }

  public handleGenderChange() {
    if(this.value.sexo == "FEMININO") {
      this.value.perfil.grupoEmpoderaFeminino = true;
    } else if(this.value.sexo != "MASCULINO") {
      this.value.perfil.grupoLgbt = true;
    } else { 
      this.value.perfil.grupoEmpoderaFeminino = false;
      this.value.perfil.grupoLgbt = false;
    }
  }

}
