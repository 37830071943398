






















import { Component, Prop, Vue } from "vue-property-decorator";

interface Progress {
  currentSteps: number;
  countSteps: number;
  steps: string[];
  icons: string[]
}

@Component({
  components: {}
})
export default class StepProgress extends Vue {
  @Prop({ default: 1 }) progress!: Progress;

  get progressO() {
    return this.progress
  }
}
