import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import PublicRepository from '@/core/Services/PublicRepository';


export default class PaginaEmpresa extends PublicRepository {
  
  constructor() {
    super(new ApiRouter({
      fetchAll: { url: '/v1/pagina-empresa', method: 'GET', headers: {} }
    }));
  }

}