


































































import { Vue, Component, Prop } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import Logo from '@/components/logo/Logo.vue';

@Component<Toolbar>({
  components: {
    ButtonIcon,
    Logo
  }
})
export default class Toolbar extends Vue {
  @Prop({default: () => {return new UserModel()}})
  private value!: UserModel;

  @Prop({default: false })
  private showNotification!: boolean;

  @Prop({default: false })
  private isAuthenticatedUser!: boolean;

  @Prop({default: [] })
  private audioDevices!: any[];

  @Prop({default: [] })
  private videoDevices!: any[];

  @Prop({default: [] })
  private closedCaptionBuffer!: string[];

  @Prop({default: "" })
  private sessionId!: string;

  @Prop({default: false })
  private fullscreen!: boolean;

  @Prop({default: false })
  private enableCC!: boolean;

  @Prop({default: false })
  private sharing!: boolean;
  
  @Prop({default: false })
  private recordingEnabled!: boolean;
  
  @Prop({default: false })
  private blindEnabled!: boolean;
  
  public URL: string = location.protocol + "//" + location.host;
  public showPopover: boolean = false;
  public pitch: number = 1;
  public rate: number = 1;
  public tempo: number = 1;

  private currentAudioDeviceId: string = "";
  private currentVideoDeviceId: string = "";

  public micStatusChanged(event: any) {
    this.$emit('micStatusChanged', event);
  }

  public camStatusChanged(event: any) {
    this.$emit('camStatusChanged', event);
  }

  public screenShare(event): any {
   this.$emit('screenShare', event);
  }

  public leaveSession(event: any) {
    this.$emit('leaveSession', event);
  }

  public toggleFullscreen(event: any) {
    this.$emit('toggleFullscreen', event);
  }

  public toggleSpeechRecognition(event: any) {
    this.$emit('toggleSpeechRecognition', event);
  }

  public stopScreenShare(event) {
    this.$emit('stopScreenShare', event);
  }

  public toggleChat(event: any) {
    const property: string = this.showNotification ? 'block' : 'none';
    this.$emit('toggleChat', property)
  }

  public doCallToggleDevices() {
    this.$emit('toggleDevices',this.currentVideoDeviceId,this.currentAudioDeviceId)
  }


  get isAuthenticated() {
    return this.isAuthenticatedUser;
  }

  get isSharing() {
    return this.sharing;
  }

  get micIcon() {
    return this.value.isAudioActive() ? 'fa fa-microphone' : 'fa fa-microphone-slash';
  }

  get videoIcon() {
    return this.value.isVideoActive() ? 'fa fa-video' : 'fa fa-video-slash';
  }
  
  get fullScreenIcon() {
    return this.fullscreen ? 'fa fa-compress' : 'fa fa-expand';
  }

  get screenIcon() {
    return this.value.isScreenShareActive() ? 'fa fa-window-close' : 'fa fa-window-restore';
  }

}
