









import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class ButtonIconRounded extends Vue {
  @Prop({ default: "black" }) public color!: string;
  @Prop({ default: "black" }) public border!: string;
  @Prop({ default: "transparent" }) public back!: string;
  @Prop({ default: "white" }) public backHover!: string;
  @Prop({ default: "white" }) public fontSize!: string;
  public clickEvent(event: any) {
    this.$emit('click', event)
  }
}
