import { Vue, Component, Watch } from 'vue-property-decorator';

@Component
export default class VagasSearchMixin extends Vue {
  public service: any;
  public list: any[]|null = null;
  public limit!: number;
  public companyId!: number;
  public companyUrl!: string;
  public mini!: boolean;
  public search!: string;
  public loaded: boolean = false;
  public page: number = 1;
  public loading: boolean = true;
  public totalCount: number = 0;
  public keywords: string = "";
  public showFilter: boolean = false;
  private hasMore = true;
  public posicao!: any;
  public salario!: number;
  public cargo!: number;
  public nomeEmpresa!: any;
  public ufid!: number;
  public cidid!: number;
  public min!: number;
  public max!: number;
  public estado!: any;
  public cidade!: any;
  public notCandidated: boolean = true;
  public responseKey: string = '';
  public pais: string = '';

  private get currLimit() {
    return this.limit ? this.limit : 8;
  }

  public clearSearchVar() {
    this.search = '';
  }

  public mounted() {
    this.search = this.$route.query.s ? this.$route.query.s as string : '';

    this.filterResults();
  }

  @Watch('$route.query.s')
  globalSearchChanged(value: string) {
    this.search = value;
    this.handleFilterVagas({});
  }

  public handleFilterVagas(filter:any) {
    this.list = [];
    this.page = 1;
    this.posicao = filter.posicao
    this.cargo = filter.nivel
    this.min = filter.faixaMin
    this.max = filter.faixaMax
    this.nomeEmpresa = filter.nomeEmpresa
    this.ufid = filter.ufid
    this.cidid = filter.cidid
    this.cidade = filter.s
    this.estado = filter.s
    this.pais = filter.pais

    this.filterResults();
  }

  public filterResults(page?: number) {
    this.loading = true;
    this.page = page ? page : this.page;
    let u = this.companyUrl ? this.companyUrl : "";
    let i = this.companyId ? this.companyId : "";
    let l = this.currLimit;
    let s = this.search || this.estado || this.cidade ? this.search || this.estado || this.cidade : this.keywords;
    let posicao = this.posicao ? this.posicao : this.keywords;
    let nomeEmpresa = this.nomeEmpresa ? this.nomeEmpresa : this.keywords;
    let cidid = this.cidid ? this.cidid : '';
    let ufid = this.ufid ? this.ufid : '';
    let cargo = this.cargo ? this.cargo : '';
    let salarioMin = this.min ? this.min : '';
    let salarioMax = this.max ? this.max :'';
    const pais = this.pais ?? '';

    this.service
      .fetchAll({
        page: this.page,
        limit: l,
        s: s,
        eid: i,
        curl: u,
        cidid: cidid,
        ufid: ufid,
        posicao: posicao,
        nomeEmpresa: nomeEmpresa,
        nivel: cargo,
        faixaMin: salarioMin,
        faixaMax: salarioMax,
        notCandidated: this.notCandidated,
        pais,
      })
      .then((data: any) => {
        if (data) {
          if (!this.list) {
            this.list = [];
          }
          const itens = data[this.responseKey];

          itens.forEach((vaga: any) => {
            this.list!.push(vaga);
          });
          this.totalCount = parseInt(data["size"]) - 10;

          /* console.log('itens.length') */
          /* console.log(itens.length, this.currLimit) */

          if (itens.length < this.currLimit) {
            this.hasMore = false;
          }
        }
        this.loading = false;
      })
      .catch((err: any) => {
        /* console.log(err) */
        this.loading = false;
      });
  }

  public showMore() {
    this.filterResults(++this.page);
  }
}
