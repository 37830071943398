






























































































// import Vue from "vue";
// import Component from "vue-class-component";
import EstadoService from "@/core/Services/Estado";
import CidadeService from "@/core/Services/Cidade";
import PaisService from "@/core/Services/Pais";
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';

@Component
export default class VagasFilter extends Vue {
  public paisSRV!: PaisService;
  public pais: string = '';
  public posicao:any|null = null;
  public nomeEmpresa:any|null = null;
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public faixa:number|null = null;
  public faixaMinMaxList!: any[];
  public min!: any;
  public max!: any;
  public cidade: string|null = '';
  public estado: string = '';
  public cidadeText: string = '';
  public estadoText: string = '';
  public nivel: string = '';
  public showLocalidadeText: boolean = true;
  //public sel : string = this.$t("Select").toString();
  
  @Prop({default: null})
  public currentPromise!: Promise<any>|null;

  public faixaList: any[] = [];

  public nivelList: any[] = [];


  constructor() {
    super();
    this.paisSRV = new PaisService();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();

    // this.faixaList =  [
    //   {text:"sel", value: null},
    //   {text:"R$0 - R$1000", value: 0},
    //   {text:"R$1001 - R$2000", value: 1},
    //   {text:"R$2001 - R$3000", value: 2},
    //   {text:"R$3001 - R$5000", value: 3},
    //   {text:"R$5001 - R$10000", value: 4},
    // ]
    // this.nivelList = [
    //   {text:"Selecione", value: ''},
    //   {text:"Estágio", value: "estagio"},
    //   {text:"Júnior", value: "junior"},
    //   {text:"Pleno", value: "pleno"},
    //   {text:"Senior", value: "senior"},
    //   {text:"Consultor", value: "consultor"},
    //   {text:"Especialista", value: "especialista"},
    // ];
    this.faixaMinMaxList = [{min: 0, max: 1000},
                            {min: 1001, max: 2000},
                            {min: 2001, max: 3000},
                            {min: 3001, max: 5000},
                            {min: 5001, max: 10000}
                            ]
  }


  public mounted(){
    // let test = this.$t("Select");
    // /* console.log(test) */

    this.faixaList =  [
      {text:this.$t("Select"), value: null},
      {text:this.$t("R$0 - R$1000"), value: 0},
      {text:this.$t("R$1001 - R$2000"), value: 1},
      {text:this.$t("R$2001 - R$3000"), value: 2},
      {text:this.$t("R$3001 - R$5000"), value: 3},
      {text:this.$t("R$5001 - R$10000"), value: 4},
    ];
    this.nivelList = [
      {text:this.$t("Select"), value: ''},
      {text:this.$t("Estágio"), value: "estagio"},
      {text:this.$t("Júnior"), value: "junior"},
      {text:this.$t("Pleno"), value: "pleno"},
      {text:this.$t("Senior"), value: "senior"},
      {text:this.$t("Consultor"), value: "consultor"},
      {text:this.$t("Especialista"), value: "especialista"},
    ];    
  }


  public handleFaixaChange() {
    const faixaIndex: number = this.faixa === null ? -1 : this.faixa;

    if (faixaIndex < 0) {
      this.min = undefined;
      this.max = undefined;

      return;
    }

    this.min = this.faixaMinMaxList[faixaIndex].min
    this.max = this.faixaMinMaxList[faixaIndex].max
  }

  public chainToCity(value?: any) {
    this.cidadeSRV.loadOptions(this.estado);
  }

  public loadData(value?: any) {
    this.paisSRV.loadOptions(this.$t('Select'));
    this.estadoSRV.loadOptions(0,this.$t('Select'));
    // this.cidadeSRV.loadOptions(this.estadoSRV);
  }

  public checkCountry() {
    if (this.pais === "BR") {
      this.showLocalidadeText = false;
    } else {
      this.showLocalidadeText = true;
    }

    this.cidadeText = '';
    this.estadoText = '';

    this.cidade = '';
    this.estado = '';
  }

  public handleFiltrar() {
    this.$emit('filterVagas', {
      posicao: this.posicao,
      nomeEmpresa: this.nomeEmpresa,
      faixaMin: this.min,
      faixaMax: this.max,
      ufid: this.estado,
      cidid: this.cidade,
      nivel: this.nivel,
      pais: this.pais,
      s: [this.estadoText, this.cidadeText].join(' ')
    })
  }
}
