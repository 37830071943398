// tslint:disable
import Vue from "vue";
import { MutationTree } from "vuex";
import { ProfileState } from "./types";
import { CandidatoEntity } from '@/core/models/Entities';

export const mutations: MutationTree<ProfileState> = {
  authRequest(state:any):any  {
    state.status = "loading";
    state.error = false;
  },
  authSuccess(state:any, resp:any):any {
    state.status = "success";
    state.user = resp;
    state.token = sessionStorage.getItem("access_token");
    state.error = false;
  },
  authError(state:any):any {
    state.status = "error";
    state.error = true;
    state.token = null;
  },
  authLogout(state:any):any {
    state.user = null;
    state.error = false;
    state.token = null;
  },
  userRequest (state:any):any {
    state.status = "loading";
  },
  userSucess(state:any, resp:any):any {
    state.status = "success";
    state.token = sessionStorage.getItem("access_token");
    Vue.set(state, "user", resp);
    Vue.set(state, "profile", resp);
  },
  userError(state:ProfileState):any {
    state.status = "error";
  },
  setCandidatoInfo(state: ProfileState, candidato: CandidatoEntity): void {
    state.candidato_info = candidato;
  },

  // Seta os valores passados em 'data' recursivamente no candidato_info
  setCandidatoData(state: ProfileState, data: object): void {
    const setData: any = (obj: object, data: object) => {
      Object.keys(data).forEach((key: string) => {
        const value = (<any> data)[key];

        if (typeof value === 'object') {
          setData((<any> obj)[key], value);
        }
        else {
          (<any> obj)[key] = value;
        }
      });
    }

    Object.keys(data).forEach((key: string) => {
      setData(state.candidato_info, data);
    });
  }
};