






















































































// tslint:disable
// require('./static/css/layouts/page-center.css')
import Vue from "vue";
import { State, Action, Getter } from 'vuex-class';
import Component from 'vue-class-component';
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import NavBar from "@/views/layouts/NavBar.vue";
import Alert from '@/components/alerts/Alert.vue';
import { PasswordStrengh } from '@/core/util';
const namespace: string = 'profile';


@Component<ResetPassword>({
  components: {
    "nav-bar": NavBar,
  }
})
export default class ResetPassword extends Vue {
  senha = '';
  confirmaSenha = '';
  token: any = ''
  public typePassword: string = "password";
  public showDismissibleAlert: boolean = false;
  public serverMessage?: string|null = null;
  public passwordStrengh: PasswordStrengh|null = null;

  public gestaoCandidatoService!: GestaoCandidato
  public currentPromise: any|null = null;

  public alert!: Alert;

  constructor() {
    super();
    this.gestaoCandidatoService = new GestaoCandidato();
    this.passwordStrengh = new PasswordStrengh();
    this.alert = new Alert();
  }


  public toggleBodyClass(addRemoveClass: any, className: any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }
  public mounted() {
    this.token = this.$route.query.tkn;
   this.toggleBodyClass('addClass', 'login');
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'login')
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }
  public async reset() {
    this.cleanSession();
    this.senha = this.passwordStrengh!.password
    if(this.senha !== this.confirmaSenha){
      this.showDismissibleAlert = true;
      this.serverMessage =  ""+this.$t('New password must match confirmation')
      return false;
    }

    if(!this.passwordStrengh!.validatePassword()){
      this.serverMessage =  this.$t('Your password has not passed the security requirements').toString();
      return false;
    }

    this.showDismissibleAlert = false;
    
    let data: any = {
      id: 0, reset: true, pass: this.senha,
      passConfirm: this.confirmaSenha,
      token: this.token,
      lang: this.$i18n.locale
    };
    this.currentPromise = this.gestaoCandidatoService.update(data).then((data: any) => {
        this.alert.addMsg(this.$t('Your password has been changed successfully').toString());
        this.alert.addTitle(this.$t('Attention').toString());
        this.alert.callBoxSuccess();
        this.$router.push({path: '/login',query: {lang: this.$i18n.locale} })
    }).catch((resp: any) => {
        this.showDismissibleAlert = true;
        try {
          this.serverMessage += this.$t('There was a problem').toString()
          this.serverMessage += resp.detail;
        } catch(e) {
          /* console.log(e) */
        }
    })

    return false;
  }

}
