





































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Getter, Action } from "vuex-class";
import { CandidatoEntity } from '@/core/models/Entities';
import PcdDetails from '@/components/perfil/PcdDetails.vue';
import MultiSelect from 'vue-multiselect'
import {pcdObject} from '@/core/models/pcd';
import EtniaSelect from '@/components/perfil/EtniaSelect.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';


@Component({
  components: {
    MultiSelect,
    PcdDetails,
    EtniaSelect
  }
})
export default class FormDiversidade extends Vue {
  public genders: any[] = [];
  public pcdList: any[] = [];
  public pcdArrayDescritivo: any[] = [];
  public pcdDetails: any = {};
  public pcdOBJ = pcdObject;
  public state: boolean = true;
  public service: CandidatoService = new CandidatoService();
  public promise: Promise<any>|null = null;

  constructor() {
    super();
  }
  
  @Getter("candidatoInfo", { namespace: 'profile' })
  public candidato?: CandidatoEntity;

  @Action("fetchCandidatoInfo", { namespace: 'profile' })
  public fetchCandidatoInfo: any;

  public handleClickPCD(){
    this.removePCD()
    this.pcdArrayDescritivo.forEach((el) => {
      if(!this.pcdDetails[el.key])
        this.pcdDetails[el.key] = { "tipo": null, "nivel": null, "key": el.key };
    })
  }  

  
  
  public selectedEtnia(value) {
    if(['PRETO','PARDO'].indexOf(value) > -1) {
      this.service.entidade.perfil.grupoMovBlack = true;
    } else if( value == 'INDÍGENA') {
      this.service.entidade.perfil.grupoIndiginas = true;
    } else {
      this.service.entidade.perfil.grupoMovBlack = false;
      this.service.entidade.perfil.grupoIndiginas = false;

    }
  }

  public handleGenderChange() {
    if(this.service.entidade.sexo) {
      if(this.service.entidade.sexo == "FEMININO") {
        this.service.entidade.perfil.grupoEmpoderaFeminino = true;
      } else if(this.service.entidade.sexo != "MASCULINO") {
        this.service.entidade.perfil.grupoLgbt = true;
      } else { 
        this.service.entidade.perfil.grupoEmpoderaFeminino = false;
        this.service.entidade.perfil.grupoLgbt = false;
      }
    }
  }

  public notempty(value: any) {
    return value !=null && value !=''
  }

  public removePCD() {
    let aux = {};
    this.pcdArrayDescritivo.forEach((el) => {
      aux[el.key] = this.pcdDetails[el.key];
    })
    this.pcdDetails = aux;
  }

  public customLabel({text,value}) {
    return ""+this.$t(text)
  }

  public selectedType(key:string, value:string) {
    this.pcdDetails[key].tipo = value;
  }

  public selectedNivel(key:string, value:string) {
    this.pcdDetails[key].nivel = value;
  }
  
  public async loadData() {
    
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    } 

    this.$set(this.service,'entidade', JSON.parse(JSON.stringify(this.candidato)));

    if(this.service.entidade && this.service.entidade.perfil) {
      try {
        const data:any = JSON.parse(this.service.entidade.perfil.pcdDescricao)
        if(data && Array.isArray(data.array))
          this.pcdArrayDescritivo = data.array;
          console.log(data.array);
        if(data && data.details)
          this.pcdDetails = data.details;
          console.log(data.details);
      } catch (e) {
      }
    }
  }

  public mounted() {
    this.genders = [
      { text: ""+this.$t("Select"), value: null },
      { text: ""+this.$t("Female"), value: "FEMININO" },
      { text: ""+this.$t("Male"), value: "MASCULINO" },
      { text: ""+this.$t("Trans Man"), value: "HOMEM TRANS" },
      { text: ""+this.$t("Trans Woman"), value: "MULHER TRANS" },
      { text: ""+this.$t("Non-binary"), value: "NAO BINARIO" },
      { text: ""+this.$t("Others"), value: "OUTROS" }
    ];

    this.pcdList = [
      {text: "Auditiva", value: "Auditiva", key: "auditiva" },
      {text: "Fala", value: "Fala", key: "fala"},
      {text: "Física", value: "Física", key: "fisica" },
      {text: "Intelectual/Mental", value: "Intelectual/Mental", key: "mental"},
      {text: "Visual", value: "Visual", key: "visual"}
    ]

    this.loadData();   
  }

  public saveDiversidade() {
    if(this.service.entidade.perfil.grupoMovBlack==null){
      this.service.entidade.perfil.grupoMovBlack=false;
    }
    const data1: any = {
      array: this.pcdArrayDescritivo,
      details: this.pcdDetails
    };
    this.service.entidade.perfil.pcdDescricao = JSON.stringify(data1);
    this.promise = this.service.save().then(async(data: any) => {
        this.$toast.addMsg(this.$t('Profile successfully saved'));
        this.$toast.addTitle(this.$t('Success').toString()); 
        this.$toast.variant = 'success';
        this.$toast.open();
        this.fetchCandidatoInfo();
        this.$emit('saved')
    }).catch(() => {
        this.$toast.addMsg(this.$t('Incomplete Registration'));
        this.$toast.addTitle(this.$t('Oops').toString()); 
        this.$toast.variant = 'warning';
        this.$toast.open();
        this.$emit('error')
    })
  }

  get invalidFeedback() {
    return ''
  }

  
  get validFeedback(){
      return '';
  }

  public setPCD(){
    this.service.entidade.perfil.pcd = this.service.entidade.perfil.grupoPCD;
  }

}
