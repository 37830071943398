





import { i18n } from "@/plugins/i18n";
import { BvModalEvent } from 'bootstrap-vue';
import Vue, { VNode } from 'vue';
import Component from 'vue-class-component';

@Component
export default class Alert extends Vue {
  public msg: any[] = [];
  public okvariant: string = '';
  public title: any = ' ';
  public header: string = '';
  public id: string = '';
  public contentMSG: string = '';
  public contentTitle: any[] = [];
  public oktitle: string = '';
  public currentModal: any|null = null;
  public cancelTitle: string = '';

  constructor() {
    super();
    this.loadImage('https://jobecam-assets.s3-us-west-1.amazonaws.com/aviso_tempo.png').catch(() => {});
    this.loadImage('https://jobecam-assets.s3-us-west-1.amazonaws.com/aviso_video_pronto.png').catch(() => {});
    this.loadImage('https://jobecam-assets.s3-us-west-1.amazonaws.com/warn.png').catch(() => {});
    this.id = (new Date()).getTime() + "";
  }

  public loadImage(url: any) {
    return new Promise(function(resolve, reject) {
      var img = new Image()
      img.onload = () => {
        resolve(url)
      }
      img.onerror = () => {
        reject(url)
      }
      img.src = url
    })
}

  public callBox() : Promise<any>
  {
    this.currentModal = this.$bvModal.msgBoxOk(this.msg, {
      id: this.id,
      title: this.title,
      size: 'md',
      buttonSize: 'md',
      okVariant: this.okvariant,
      headerClass: this.header,
      footerClass: 'p-2 border-top-0',
      contentClass: 'call-box',
      centered: true
    });
    this.msg = [];
    this.title = '';
    this.oktitle = '';
    return this.currentModal;
  }

  public callBoxWithImage(type: string) {
    this.currentModal = this.$bvModal.msgBoxOk(this.msg, {
      id: this.id,
      title: this.title,
      size: 'md',
      buttonSize: 'md',
      okVariant: 'alert-image__button btn-blue '+ type + 'Button',
      okTitle: this.oktitle,
      headerClass: type + 'Header',
      bodyClass: type + 'Body',
      hideHeaderClose: true,
      footerClass: type + 'Footer border-top-0',
      contentClass: type + 'Content',
      // centered: true
    });
    this.msg = [];
    this.title = '';
    this.oktitle = '';
    return this.currentModal;
  }

  public callBoxImageDoubleButton(type: string) {
    let modal = this.$bvModal.msgBoxConfirm(this.msg, {
      id: this.id,
      title: this.title,
      size: 'md',
      buttonSize: 'md',
      okVariant: 'double__button btn-blue  text-uppercase col-md-12',
      okTitle: this.oktitle,
      cancelVariant: 'double2__button btn text-uppercase col-md-12',
      cancelTitle: this.cancelTitle,
      headerClass: type + 'Header',
      bodyClass: type + 'Body',
      hideHeaderClose: true,
      footerClass: type + 'Footer border-top-0 d-block',
      contentClass: type + 'Content',
      centered: true
    });
    this.msg = [];
    this.title = '';
    this.oktitle = '';
    return modal;
  }

  public myfun(){
    /* console.log('clicou') */
  }

  // Modal Atenção
  public callBoxDanger() : Promise<any>
  {
    this.okvariant = 'danger';
    // Modify modal header with bootstrap class
    this.header = 'p-2 border-bottom border-danger text-danger'
    return this.callBox()
  }

  public callBoxSuccess() : Promise<any>
  {
    this.okvariant = 'success';
    // Modify modal header with bootstrap class
    this.header = 'p-2 border-bottom border-success text-success'
    return this.callBox()
  }

  public addMsg(msg: any){
    if(!this.msg) {
        this.msg = [];
    }
    this.msg.push(this.$createElement('div',msg+""));
  }

  public addMsgCentralized(msg: any){
    if(!this.msg) {
        this.msg = [];
    }
    this.msg.push(this.$createElement('div', {
      attrs: {
        class: 'text-center'
      }
    }, <string> msg));
  }

  public addMsgStrong(msg: string){
    if(!this.msg) {
        this.msg = [];
    }
    this.msg.push(this.$createElement('strong',msg));
  }

  public addUlMsg(typeClass: string, msgs: string[], elements?: VNode[]) {
    if(!this.msg) {
        this.msg = [];
    }
    this.msg.push(this.$createElement('ul', msgs.map(msg => {
      return this.$createElement('li', {
      class: 'text-left ' + typeClass
    }, msg);
    })));

    this.msg.push(elements);
  }

  public addElementsMsg(elements: VNode[]) {
    if(!this.msg) {
        this.msg = [];
    }
    this.msg.push(elements);
  }

  public addContentTitle(contentTitle: any){
    if(!this.contentTitle) {
        this.contentTitle = [];
    }
    this.contentTitle.push(this.$createElement('div',contentTitle+""));
  }

  public addTitle(contentTitle: string){
    this.title = contentTitle;
  }

  public pleaseTryLater(title: string): Promise<any> {
    this.addMsg(i18n.t('Please try again later').toString());
    this.title = i18n.t(title);
    return this.callBoxDanger();
  }


  public callBoxTime(){
    this.oktitle = i18n.t("OK, I got it!").toString();
    return this.callBoxWithImage('time')
  }

  public callBoxCheckWithTitle(title: string){
    this.oktitle = title;
    return this.callBoxWithImage('check');
  }

  public callBoxCheckWithTitleAndDoubleButtons(props: any){
    this.oktitle = props.b1Title || '';
    this.cancelTitle = props.b2Title || '';
    return this.callBoxImageDoubleButton('check').then(result => {
      // debugger;
      if(result === true) {
        props.b1CallBack()
      }
      if(result === false) {
        props.b2CallBack()
      }
    })
  }

  public callBoxCheckWithTitleAndDoubleButtonsTyped(props: any, type:string){
    this.oktitle = props.b1Title || '';
    this.cancelTitle = props.b2Title || '';
    return this.callBoxImageDoubleButton(type).then(result => {
      // debugger;
      if(result === true) {
        props.b1CallBack()
      }
      if(result === false) {
        props.b2CallBack()
      }
    })
  }

  public callBoxCheck(buttonTitle:string){
    this.oktitle = i18n.t(buttonTitle).toString()
    return this.callBoxWithImage('check')
  }

  public callBoxWarn(){
    this.oktitle = 'OK'
    return this.callBoxWithImage('warn')
  }

  public callBoxError(){
    this.oktitle = 'OK'
    return this.callBoxWithImage('error')
  }

  public on(event: string, callback: (bvEvent: BvModalEvent, modalId: string) => void): void {
    this.$root.$on('bv::modal::' + event, callback);
  }

  public hide() {
    this.$bvModal.hide(this.id)
  }

}
