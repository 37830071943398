





































import Vue from "vue";
import Component from "vue-class-component";
import CardBox from "@/components/CardBox.vue";
import NavBar from "@/views/layouts/NavBar.vue";
import LinkedinRegister from "@/components/social/LinkedinRegister.vue";
import { baseEndPoint, clientId } from "@/core/endpoint";
import { Action, Getter, State } from "vuex-class";
const namespace: string = "profile";

let PIDINTERVAL: any = 0;

@Component<RegisterCompleted>({
  components: {
    "card-box": CardBox,
    "nav-bar": NavBar
  }
})
export default class RegisterCompleted extends Vue {
  public myPopUp: Window | null = null;
  public email: String | null = '';
  public uri: string =
    window.document!.location!.protocol +
    "//" +
    window.document!.location!.host;

  public toLogin() {
    let rurl: string|null = sessionStorage.getItem("redirectTo");
    if (rurl) {
        this.$router.push({ path: '/login', query: {lang: this.$i18n.locale, redirectUrl: rurl }});
    } else {
        this.$router.push({ path: '/login', query: {lang: this.$i18n.locale }});
    }
  }

  public toggleBodyClass(addRemoveClass: any, className:any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }
  public created(){
    console.log(this.$route.params.email);
    if (this.$route.params.email) {
      console.log(this.$route.params.email);
      this.email = '('+this.$route.params.email+')';
    }    
  }

  public mounted() {
    this.toggleBodyClass('addClass', 'register')
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'register')
  }
}
