export default function BrowserSupport() {

  this.browserName = function() {
    var agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1: return "MS Edge";
      case agent.indexOf("edg/") > -1: return "Edge ( chromium based)";
      case agent.indexOf("opr") > -1 && !!window.opr: return "Opera";
      case agent.indexOf("chrome") > -1 && !!window.chrome: return "Chrome";
      case agent.indexOf("trident") > -1: return "MS IE";
      case agent.indexOf("firefox") > -1: return "Mozilla Firefox";
      case agent.indexOf("safari") > -1: return "Safari";
      default: return "other";
    }
  }

  this.browserDetection = function(i18n) {
    var isOpera,isEdgeChromium,isEdge,isOpera,isChrome,isFirefox,isIE,isSafari,isOther;

    var agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf("edge") > -1: 
        isEdge = true; break;
      case agent.indexOf("edg/") > -1: 
        isEdgeChromium = true; break;
      case agent.indexOf("opr") > -1 && !!window.opr:
        isOpera = true; break;
      case agent.indexOf("chrome") > -1 && !!window.chrome: 
        isChrome = true; break;
      case agent.indexOf("trident") > -1:
        isIE = true; break;
      case agent.indexOf("firefox") > -1:
        isFirefox = true; break;
      case agent.indexOf("safari") > -1: 
        isSafari = true; break;
      default: 
        isOther = true;
  }

    if (isIE || isEdge) {
      /* console.log('ischrome') */
      var msg = i18n.t("This browser does not support all the functionality of the Jobecam platform. We recommend using Firefox, Chrome or Safari as a browser for a better experience.")
      var x = document.createElement('div')
      x.innerHTML = 
        '<div id="alertaBROWSER" class="sticky-top position-fixed alert alert-warning alert-dismissible" role="alert" style=" left: 0;right: 0; z-index: 1090;">' +
          "<center>" + msg + "</center>" +
          '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span>' +
          '</button>' +
          '</div>'
      document.body.append(x)

      document.querySelector('#alertaBROWSER button').onclick = function () {
        document.getElementById('alertaBROWSER').style.display = 'none';
      }
      
    }
    if (isOther) {
      /* console.log('ischrome') */
      var msg = i18n.t("This browser does not support all the functionality of the Jobecam platform. We recommend using Chrome for Android devices and Safari for iPhone devices as a browser for a better experience.")
      var x = document.createElement('div')
      x.innerHTML = 
        '<div id="alertaBROWSER" class="sticky-top position-fixed alert alert-warning alert-dismissible" role="alert" style=" left: 0;right: 0; z-index: 1090;">' +
          "<center>" + msg + "</center>" +
          '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
          '<span aria-hidden="true">&times;</span>' +
          '</button>' +
          '</div>'
      document.body.append(x)

      document.querySelector('#alertaBROWSER button').onclick = function () {
        document.getElementById('alertaBROWSER').style.display = 'none';
      }
      
    }
  }

  


}
