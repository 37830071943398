







import { Vue, Component, Prop } from 'vue-property-decorator';
import GoogleMapsApiLoader from 'google-maps-api-loader';
import config from '@/core/maps/config';

@Component
export default class GoogleMap extends Vue {
  google?: typeof google;
  map?: google.maps.Map;

  @Prop({default: 0})
  public longitude!: number;

  @Prop({default: 0})
  public latitude!: number;

  @Prop({default: ''})
  public localidade!: string;

  get localeWithPlus() {
    return this.localidade ? 'https://maps.googleapis.com/maps/api/staticmap?center='+this.localidade.replace(/ /g,'+')+'&zoom=14&size=1280x300&maptype=roadmap&key='+config.apiKey : null;
  }

  get mapsLink() {
    return this.localidade ? 'https://maps.google.com/?q='+this.localidade.replace(/ /g,'+') : null;
  }

  async mounted() {
    // const googleMapApi = await GoogleMapsApiLoader({
    //   apiKey: config.apiKey
    // });

    // this.google = googleMapApi;
    // this.initializeMap();
  }

  // initializeMap(): void {
  //   /* console.log( this.latitude,' lo',  this.longitude) */
  //   const google = this.google!;

  //   this.map = new google.maps.Map(
  //     <Element> this.$refs.googleMap,
  //     {
  //       center: {
  //         lat: this.latitude,
  //         lng: this.longitude
  //       },
  //       zoom: 12
  //     }
  //   );
    /*
    const address = 'Santos - SP, Brasil';

    new google.maps.Geocoder().geocode({ 'address': address }, (results, status) => {
      if (status != google.maps.GeocoderStatus.OK) {
        alert("Geocode was not successful for the following reason: " + status);
        return;
      }
      if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
        alert("No results found");
        return;
      }

      const location = results[0].geometry.location;

      this.map!.setCenter(location);

      const infowindow = new google.maps.InfoWindow({
        content: '<b>' + address + '</b>'
      });

      const marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: address
      });

      google.maps.event.addListener(marker, 'click', () => {
        infowindow.open(this.map, marker);
      });
    });
    */

}
