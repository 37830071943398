import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class SeguirCandidato extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/seguir-empresa', method: 'POST', headers: {} },
                fetch: { url: '/v1/seguir-empresa/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/seguir-empresa', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/seguir-empresa/{id}', method: 'PUT', headers: {} }}));
    }
}
