
import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import ClientAuthentication from './ClientAuthentication';
import { CB } from '../util';


export default class PublicRepository extends Repository {
    constructor(ep: ApiRouter) {
        super(ep);
    }

    public call(key: string, data: any, ctype: string, blockCatch?: boolean): Promise<any> {
        return new Promise((resolve: any, reject: any) => {
            if (sessionStorage.getItem('access_token')) {
                super.call(key, data, ctype, blockCatch).then(resolve).catch(reject);
            } else {
                (new ClientAuthentication()).auth().then((rtoken: any) => {
                    const dta: any = {
                        rtoken,
                        ...data
                    };
                    const cdta = CB.genC(dta);
                    super.call(key, cdta, ctype, blockCatch).then(resolve).catch(reject);
                }).catch((resp) => {
                    reject(resp);
                });
            }
        });
    }
}
