import { EApplication } from '@/core/Enums/EApplication';
import { Endereco } from '@/core/models/Entities';
import moment from 'moment';
import Vue from 'vue';

Vue.mixin({
  data: function () {
    return {
      company: {},
      messagePID: 0,
      isCompanyApp: process.env.VUE_APP_APPLICATION == EApplication.Company.toString(),
      isCandidateApp: process.env.VUE_APP_APPLICATION == EApplication.Candidate.toString(),
      isDev: (process.env.NODE_ENV != 'production'),
      tinyMCEini: {
        height: 700,
        menubar:false,
        toolbar: 'alignleft aligncenter alignright alignjustify | outdent indent',
        toolbar_sticky: true,
      }
    }
  },
  filters: {
    dateFormat(timeStamp: undefined | {date: string, timezone_type: number, timezone: string}) {
      return (timeStamp && timeStamp.date) ? moment.utc(timeStamp.date + '').local().format('DD/MM/YYYY HH:mm') : ' - ';
    },
    dateOnly(timeStamp: {date: string, timezone_type: number, timezone: string} | undefined) {
      return (timeStamp && timeStamp.date) ? moment.utc(timeStamp.date + '').local().format('DD/MM/YYYY') : '';
    },
    formatEndereco(endereco: undefined | Endereco): string {
      let enderecoStr = '';

      if (endereco) {
        enderecoStr += endereco.rua ? endereco.rua : '';
        enderecoStr += endereco.endereco ? endereco.endereco : '';
        enderecoStr += endereco.numero ? ' , ' : '';
        enderecoStr += endereco.numero
          ? endereco.numero
          : '';
        enderecoStr += endereco.complemento ? ' - ' : '';
        enderecoStr += endereco.complemento
          ? endereco.complemento
          : '';

        enderecoStr += endereco.bairro ? ' | ' : '';

        enderecoStr += endereco.bairro
          ? endereco.bairro
          : '';
        enderecoStr += endereco.nomeCidade ? ' - ' : '';
        enderecoStr += endereco.nomeCidade
          ? endereco.nomeCidade
          : '';
        enderecoStr += endereco.nomeEstado ? ' - ' : '';
        enderecoStr += endereco.nomeEstado
          ? endereco.nomeEstado
          : '';

        enderecoStr += endereco.cep ? ' | ' : '';

        enderecoStr += endereco.cep ? endereco.cep : '';
        enderecoStr += endereco.pais ? ' - ' : '';
        enderecoStr += endereco.pais ? endereco.pais : '';
      }

      return enderecoStr;
    },
  },
  methods: {
    scroolBottom(id) {
      const element: HTMLElement | null = document.getElementById(id);
      if (element)
        element.scrollTop = element.scrollHeight - element.clientHeight;
    },
    notempty(value: any) {
      return value !=null && value !=''
    },
    fakeLoading() {
      // wololo
      const _this: any = this;
      _this.loadingEvent = true;
      setTimeout(() => {
        if (_this)
         _this.loadingEvent = false;
      },5000)
    },
    globalToastError(msg: string) {
      // wololo
      const _this: any = this;

      _this.$toast.variant = 'danger';
      _this.$toast.addMsg(this.$t(msg))
      _this.$toast.addTitle(this.$t('Oops').toString());
      _this.$toast.open();
    },
    countdownTime(time: number) {
      return Math.round(time / 1000).toString();
    },
    initUW() {
      window['UserWay']?.widgetOpen()
    },
    initHT() {
      try{
        if(window.handTalk) {
          window.handTalk.destroy();
          window.handTalk = null;
          return;
        }
      } catch (e) {
       console.log('ht not destroyed, maybe it was not initialized') 
      }
      const h: string[] = ["MAYA","HUGO"];
      const rndInt = Math.floor(Math.random() * 2);
      if(window.HT){
        window.handTalk = new window.HT({
          token: "8bee7199afae0759d70be1cffcd68778",
          avatar: h[rndInt],
          parentElement: document.body
        });
        return;
      }
    },
    destroyHT() {
      if(window.handTalk){
        window.handTalk.destroy()
      }
    },
    isPWA() {
      return window && window.matchMedia("(display-mode: standalone)").matches;
    },
    async sleepFor(ms: any) {
      return new Promise((resolve: any) => setTimeout(resolve, ms));
    }
  }
});
