
























































import { Component, Vue } from 'vue-property-decorator';
import RootHeaderNavigation from '@/components/root-header-navigation/RootHeaderNavigation.vue';
import VueScrollTo from 'vue-scrollto';

@Component({
  components: {
    RootHeaderNavigation
  }
})
export default class ProfileHeaderNavigation extends Vue {
  private activeElementId: string = '';

  public mounted() {
    this.goToTop()
  }

  public goToTop() {
    this.scrollToElement('dpe');
  }

  public isActive(targetId: string) {
    return this.activeElementId === targetId;
  }

  public scrollToElement(targetId: string) {
    this.activeElementId = targetId;
    let containerWrapper: string = '#page-content';
    // if (/Mobi|Android/i.test(navigator.userAgent) || window.innerWidth < window.innerHeight) {
    if (window.innerWidth < 768) {
        containerWrapper = 'body'
    }
    VueScrollTo.scrollTo('#' + targetId, 0, {
      container: containerWrapper,
      offset: -40
    });
  }
}
