



































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { CandidatoEntity } from '@/core/models/Entities';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import DadosPessoaisView from './partials/DadosPessoaisView.vue';
import CardPdfProfessionalInfo from './partials/CardPdfProfessionalInfo.vue';
import CardPdfAcademicInfo from './partials/CardPdfAcademicInfo.vue';
import CardPdfSkills from './partials/CardPdfSkills.vue';
const namespace: string = 'profile';

@Component<HiddenTalentInfoPage>({
  components: {
    DadosPessoaisView,
    CardPdfProfessionalInfo,
    CardPdfSkills,
    CardPdfAcademicInfo
  }
})
export default class HiddenTalentInfoPage extends Vue {

  public service: CandidatoService = new CandidatoService();

  // public candidato!: CandidatoEntity;
  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  get experiencias() {
    return this.candidato?.perfil?.experiencias;
  }

  get formacoes() {
    return this.candidato?.perfil?.formacoes;
  }

  get habilidades() {
    return this.candidato?.perfil?.habilidades;
  }

  public async mounted() {
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    }  
    
    setTimeout(() => {
      const colors: string[] = ["#006FF3","#FF9F05", "#CE3CCA", "#00AF69", "#FF9357", "#ED74E2"]
      const x: HTMLDivElement|null = document.querySelector('.sidebar-colored')
      document.body.style.overflow = 'auto';
      const height: number = (window.innerHeight|| document.documentElement.clientHeight||document.body.clientHeight);
      if(x != null) {
        
        if(x.offsetHeight < height)
          x.style.height =  height+'px'

        x.style.backgroundColor = colors[(Math.floor((Math.random() * 6)))];
      }
      
    },100)
  }
}
