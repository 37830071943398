export default class AlertHeadline
{
  
  private alertElement: HTMLDivElement|null = null;

  constructor(msg, variant_ = 'warning') {

     this.setText(msg,variant_);

      setTimeout(() => {
        console.log('adding');
        (document.querySelector('#alertHeadlineJB button') as HTMLButtonElement).onclick = () => {
          console.log('hidding')
          if (this.alertElement)
            this.alertElement.style.display = 'none';
        }
      },3000);
            
    }

    public setText(msg:string, variant_ = 'warning') {

      const variant:string = variant_;
      let z = document.querySelector('#alertHeadlineJBTEXT');
      if (!z) {
        this.alertElement = document.createElement('div')
        this.alertElement.id = "alertHeadlineParentJB"
        this.alertElement.innerHTML = 
          `<div id="alertHeadlineJB" class="sticky-top position-fixed alert alert-${variant} alert-dismissible" role="alert" style=" left: 0;right: 0; z-index: 1090;">
            <div id="alertHeadlineJBTEXT" class="text-center">${msg}</div>
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
            </div>`;
        document.body.append(this.alertElement);
      } else {
        this.alertElement = (document.querySelector('#alertHeadlineParentJB') as HTMLDivElement);
        this.alterVariantClasses('alert-'+variant);
        z.innerHTML = msg;
        this.alertElement.style.display = 'block';
        document.getElementById('alertHeadlineJB')!.style.display = 'block';
      }
    }

    public setHidTimeout(n: number) {
      if (n >0)
        setTimeout(() => {
          if (this.alertElement)
            this.alertElement.style.display = 'none';
        },n)
    }

    public alterVariantClasses(variant: string) {
      if (this.alertElement) {
        this.alertElement.children[0].classList.remove('alert-warning')
        this.alertElement.children[0].classList.remove('alert-info')
        this.alertElement.children[0].classList.remove('alert-default')
        this.alertElement.children[0].classList.remove('alert-success')
        this.alertElement.children[0].classList.remove('alert-primary')
        this.alertElement.children[0].classList.remove('alert-secondary')
        this.alertElement.children[0].classList.remove('alert-danger')
        this.alertElement.children[0].classList.add(variant);
      }
      
    }
}

  
