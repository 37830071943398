

















import { Component, Prop, Vue } from "vue-property-decorator";
import AvatarUpload from '@/components/form/avatar-upload/AvatarUpload.vue';

@Component
export default class TextInputAppend extends Vue {
  @Prop({ default: null }) public value!: string;
  @Prop({ default: false }) public required!: Boolean;
  @Prop({ default: false }) public disabled!: Boolean;
  @Prop({ default: "" }) public placeholder!: string;
  @Prop({ default: "" }) public label!: string;
  @Prop({ default: "text" }) public type!: string;
  @Prop({ default: "fas fa-link" }) public icon!: string;

  private iconClass = `text-input-append__append-icon ${this.icon}`

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
  
  public handleChange(v: any) {
    this.$emit('change', v);
  }
}
