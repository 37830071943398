














import { Vue, Component, Prop } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';
import Section from "@/components/section/Section.vue";
import FormIdiomas from '../form-group/FormIdiomas.vue';

@Component<CardVideoCurriculum>({
  components: {
    "section-card": Section,
    FormIdiomas
  }
})
export default class CardVideoCurriculum extends Vue {

  @Prop({default: null})
  public value!: CandidatoEntity;
  
}
