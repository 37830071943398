























































































































































// tslint:disable
// require("./static/css/layouts/page-center.css")
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import Candidato from "@/core/Services/Candidato/Candidato";
import Logo from '@/components/logo/LogoMarca.vue';
import { baseEndPoint, clientId } from "@/core/endpoint";
import { CandidatoEntity, FacebookUser, LinkedInUser } from '@/core/models/Entities';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { ScriptAsyncLoad, PasswordStrengh } from '@/core/util';
import Alert from '@/components/alerts/Alert.vue';
import VueTelInput from 'vue-tel-input';
const namespace: string = "profile";

import loadFacebookSdk from '@/core/loaders/loadFacebookSdk';

Vue.use(VueTelInput);

@Component<ThirdPartyRegistry>({
  components: {
    'logo-jobcam': Logo,
  },
})
export default class ThirdPartyRegistry extends Vue {

  @Action("userRequest", { namespace }) public  userRequest: any;

  public contentTitle: string = "Now there is little left!";
  public contentText: string = "Now you just need to create a password and provide a phone for the recruiter to contact you.";
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
  public candidatoService: CandidatoService = new CandidatoService();
  public candidato: CandidatoEntity = new CandidatoEntity();
  public username: string = ""
  public password: string = ""
  public passConfirm: string = "";
  public emailConfirm: string = "";
  public load: boolean = false;
  public accept: number = 0;
  public accept_news: string = 'N';
  public passwordStrengh: PasswordStrengh = new PasswordStrengh();
  public box: any;
  public alert: Alert = new Alert();
  public typePassword: string = "password";
  public currentPromise: any|null = null;
  public myPopUp: Window|null = null;
  public telefone: string = "";
  public firebaseConfig: any = {};
  public authCode: string = '';
  private alertBemVindo: Alert = new Alert();
  private linkedinToken = '';

  public closeWindow() {
    window.close()
  }

  public created() {
    this.toggleBodyClass("addClass", "login");
    this.whereToSend();

    this.handleFacebookRegister();
    this.handleLinkedInRegister();
  }

  get isLinkedIn() {
    return !!sessionStorage.getItem('linkedin_user');
  }

  get isFacebook() {
    return !!sessionStorage.getItem('facebook_user');
  }

  private async handleLinkedInRegister() {
    const linkedinUserStr = sessionStorage.getItem('linkedin_user');
    

    if (linkedinUserStr) {
      this.setCandidatoDataFromLinkedIn(JSON.parse(linkedinUserStr));
    }
  }
  private async handleFacebookRegister() {
    const facebookUserStr = sessionStorage.getItem('facebook_user');

    if (facebookUserStr) {
      this.setCandidatoDataFromFB(JSON.parse(facebookUserStr));
    }

    if ('facebook' === this.$route.query.authType) {
      const FB = await loadFacebookSdk();
      const accessToken = this.$route.query.accessToken;

      FB.api('/me', {
        access_token: accessToken,
        fields: 'first_name,last_name,email'
      }, (user: FacebookUser) => {
        this.setCandidatoDataFromFB(user);
      });
    }
  }


  public loggedInFacebookRegistered(accessToken: string) {
    this.registerUser(accessToken);
    this.whereToSend();
  }

  public setCandidatoDataFromFB(fbUser: FacebookUser) {
    this.candidato.primeironome = fbUser.first_name;
    this.candidato.sobrenome = fbUser.last_name;
    this.candidato.email = fbUser.email;
  }

  public setCandidatoDataFromLinkedIn(liUser: LinkedInUser) {
    this.candidato.primeironome = liUser.firstName;
    this.candidato.sobrenome = liUser.lastName;
    this.candidato.email = liUser.emailAddress;
  }


  public haveaccount() {
    const path = '/login'
        this.$router.push({path: path ,query: {
          vagaURL: ((this.$route.query.vagaURL) ? this.$route.query.vagaURL||"" : ""),
          organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : ""),
          lang: this.$i18n.locale} })
  }

    public mounted(){
      this.toggleBodyClass("addClass", "login");
    }

    public whereToSend(){
        let rurl: any = this.$route.query.redirectUrl;
        if(rurl){
            sessionStorage.setItem("redirectTo", rurl);
        }
        if(sessionStorage.getItem("access_token")) {
          const path = rurl ? rurl : '/';
          this.$router.push({path: path,query: {lang: this.$i18n.locale} })
        }
    }

    public removeItensFromStorage(): void {
        sessionStorage.removeItem("token_type");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("profile_type");
        sessionStorage.removeItem("profile");
    }

    public async registerUser(at: string) {
        if(at) {
        // this.candidato =  new Candidato();
        sessionStorage.setItem("access_token", at+'');
        sessionStorage.setItem("token_type", "Bearer");
        sessionStorage.setItem("profile_type", "candidate");
        await this.userRequest().then(() => {
            /* console.log("ok") */
        }).catch(() => {
            this.removeItensFromStorage();
        })
        }else {
          this.removeItensFromStorage();
        }
    }

    public verifySignUpCodeWithCallBack(call: any) {
        let code = this.authCode.replace(/\s|-/g, '');
        let me = this
        window.confirmationResult.confirm(code).then(function (result:any) {
            call();
        }).catch(function (error:any) {
            /* console.log(error) */
            me.alert.addMsg(me.$t('Invalid code. Please check your code and enter again, or click in resend code.'));
            me.alert.title = me.$t('Attention')
            me.alert.callBoxError()
        });
    }

    public async loginWithLinkedIn(value: string|LinkedInUser) {
      if (this.linkedinToken) {
        return;
      }

      if (typeof(value) === 'string') {
        this.linkedinToken = value;
        await this.registerUser(this.linkedinToken);
      }
      else {
        const user = value;
        this.setCandidatoDataFromLinkedIn(user);
      }
    }

    public linkedInPopupClosed() {
      this.whereToSend();
    }


    get passwordIsnotEmpty(){
        return this.passwordStrengh!.password;
    }

    public isValidForm(midia: boolean) {
        let valid: boolean = true;

        if (!midia) {
            if( (this.candidato.primeironome == "")||(this.candidato.primeironome == null) ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Name'));

            }
            if( (this.candidato.sobrenome == "")||(this.candidato.sobrenome == null) ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Last name'));
            }

            if( (this.candidato.email == "")||(this.candidato.email == null) ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Email'));
            } else if ( this.candidato.email.match(/[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/) == null ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Invalid email format'));
            }

            if( (this.candidato.perfil!.telefone == "")||(this.candidato.perfil!.telefone == null) ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Telephone'));
            }

            this.password = this.passwordStrengh!.password;
            if( (this.password == "")||(this.password == null) ){
                valid = false;
                this.alert.addMsg('- '+this.$t('Password'));
            }

            if(this.password != this.passConfirm){
                valid = false;
                this.alert.addMsg('- '+this.$t('Passwords entered in the fields (New Password and Confirm New Password) must be the same.'));
            }

            if(!this.passwordStrengh!.validatePassword()){
                valid = false;
                this.alert.addMsg('- '+this.$t('Your password has not passed the security requirements'));
            }
        }
        if(this.accept==0){
            valid = false;
            this.alert.addMsg('- '+this.$t('Before proceeding you must accept the Terms of Use and Privacy Policy'));
        }

        if(!valid){
            this.box = ''
            this.alert.title = this.$t('Please inform')
            this.alert.callBoxError()
            .then(value => {
                this.box = value
            })
            .catch(err => {
                /* console.log(err) */
            })
        }
        return valid;
    }

    public submitFormCandidato(){
        let valid: boolean = this.isValidForm(false);

        if (!valid && this.authCode === "" || this.authCode === null) {
            return
        }

        let data = {
            primeironome: this.candidato.primeironome,
            sobrenome: this.candidato.sobrenome,
            email: this.candidato.email,
            senha: this.password,
            lingua: this.$i18n.locale ? this.$i18n.locale : 'en',
            idioma: this.$i18n.locale ? this.$i18n.locale : 'en',
            notification: 1,
            newsletter: this.accept_news,
            vagaURL: this.$route.query.vagaURL,
            organica: this.$route.query.organica,
            status: "CONFIRMADO",
            perfilcandidato: {
                telefone: this.candidato.perfil!.telefone
            }
        }
        this.load = true;
        /* console.log("ok") */
        // $.blockUI();
        this.currentPromise = this.candidatoService.create(data).then((data: any) => {
            // $.unblockUI();
            /* console.log(data) */
            if(data){
                /* console.log(data) */
                this.load = false;
                this.box = '';
                if(data.confirmation)
                  this.registerUser(data.confirmation)

                let rurl: string|null = sessionStorage.getItem("redirectTo");
                const query: any =  { 
                      lang: this.$i18n.locale,                      
                      vagaURL: (this.$route.query.vagaURL||""),
                      organica: (this.$route.query.organica||""),
                      ccode: this.$route.query.ccode
                    }
                if (rurl) {
                    query["redirectUrl"] = rurl
                } 
                
                this.$router.push({ path: '/completed-social', query });
                
                sessionStorage.removeItem('facebook_user');
                sessionStorage.removeItem('linkedin_user');
            }
        }).catch((err: any) => {
            // $.unblockUI();
            this.load = false;
            /* console.log(err) */
        });


    }

    public beforeCreate(){
        new ScriptAsyncLoad('/static/js/validate/localization/messages_pt_BR.js');
    }

    public verifySignUpCode() {
        let code = this.authCode.replace(/\s|-/g, '');
        let me = this
        window.confirmationResult.confirm(code).then(function (result:any) {
            me.submitFormCandidato();
        }).catch(function (error:any) {
            /* console.log(error) */
            me.alert.addMsg(me.$t('Invalid code. Please check your code and enter again, or click in resend code.'));
            me.alert.title = me.$t('Attention')
            me.alert.callBoxError()
        });
    }
  public toggleBodyClass(addRemoveClass: any, className: any) {
    const el = document.body;

    if (addRemoveClass === "addClass") {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }
  
  public destroyed() {
    this.toggleBodyClass("removeClass", "login");
  }

}
