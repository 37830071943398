

























import { Vue, Component, Prop } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';
import Section from "@/components/section/Section.vue";

@Component<CardVideoCurriculum>({
  components: {
    "section-card": Section
  }
})
export default class CardVideoCurriculum extends Vue {

  @Prop({default: null})
  public value!: CandidatoEntity;
  
  get haveCV() {
    return !!(this.value.perfil.videoapresentacaoMP4);
  }
  
  public goToVideoCV() {
        
    this.$alert.title = this.$t("Attention");
    this.$alert.addMsg(this.$t("You will be redirected to another page").toString());

    this.$alert.callBoxCheckWithTitle(this.$t("Record video curriculum").toString()).then((c) => {
      if(c) {
        this.$emit('curriculumSaved',true);
        let query: any = {lang: this.$i18n.locale };
        if (this.$route.query.vaid) {
          query["vaid"] = this.$route.query.vaid;
        } 
        this.$router.push({ path: '/video-curriculum', query }).catch(() => {});
      }
    })
  }
}
