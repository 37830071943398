












import Vue from 'vue';
import Component from 'vue-class-component';
import PublicHeader from '@/components/layouts/PublicHeader.vue';
import SidebarEmpty from '@/views/layouts/SidebarEmpty.vue';
import Content from '@/components/layouts/Content.vue';

@Component<RootPublicAreaNoSidebar>({
  components: {
    'app-header': PublicHeader,
    'app-content': Content,
  },
})
export default class RootPublicAreaNoSidebar extends Vue {
  private sidebarIsOpen: boolean = false;

  public toggleSidebar() {
    this.sidebarIsOpen = !this.sidebarIsOpen;
  }
}
