

























import { Component, Prop, Vue } from "vue-property-decorator";
import ButtonIcon from "@/components/button-icon/ButtonIcon.vue";

@Component<Avatar>({
  components: {
    "button-icon": ButtonIcon
  }
})
export default class Avatar extends Vue {
  @Prop({
    default:
      "https://jobecam-assets.s3-us-west-1.amazonaws.com/empty_avatar.png"
  })
  public src!: string;
  @Prop({ default: "" }) public alt!: string;
  @Prop({ default: "white" }) public border!: string;
  @Prop({ default: "lg" }) public size!: string;
  @Prop({ default: "" }) public nomeUrl!: string;
  @Prop({ default: false }) public edit!: boolean;

  constructor() {
    super();
  }

  public onCopy() {
    this.$alert.addTitle(this.$t("Success").toString());
    this.$alert.addMsg(
      this.$t(
        "Link successfully copied. Share your profile with recruiters."
      ).toString()
    );
    this.$alert.callBoxCheckWithTitle("OK");
  }

  public onError() {
    /* console.log("not copied") */
  }

  get isDefault() {
    return this.edit && ((this.src == undefined) || (this.src != undefined && this.src == "https://jobecam-assets.s3-us-west-1.amazonaws.com/empty_avatar.png"))
  }

  private get fotoSrc() {
    return (
      this.src ||
      "https://jobecam-assets.s3-us-west-1.amazonaws.com/empty_avatar.png"
    );
  }
}
