











































































// List Company evaluations
import Candidatura from '@/core/Services/Candidato/Candidatura';
import CandidateExam from '@/core/Services/Exams/CandidateExam';
import { CandidaturaEntity, DateEntity, QuestionaryJobEntity } from '@/core/models/Entities';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import FormExam from './partials/FormExam.vue';

import Alert from "@/components/alerts/Alert.vue";

@Component({
  components: {
    FormExam
  }
})
export default class Exams extends Vue {
  public tempoTotal:number|null = null;
  public startedAt:DateEntity|null = null;
  public jobExams: QuestionaryJobEntity[] = [];
  public candidatura: CandidaturaEntity|null = null;
  public qids: number[] = [];
  public service: CandidateExam;
  public serviceC: Candidatura;
  public errorMessage: string = '';
  public validStart: boolean = false;
  public currentQIndex:number = 0;
  public answeredIds: number[] = []
  public finished:boolean = false;
  public loading:boolean = false;
  public ggList: any[] = [];
  public promise: Promise<any>|null = null;
  public started:boolean = false;
  public loaded: boolean = false;
  public timer: any|null = null;
  public duration: any|null = null;
  public countDownMinutes: any = 0;
  public countDownHours: any = 0;
  public countDownSeconds: any = 0;
  public timeDiff: number = 0;
  public timeLeftEnded = false;

  private alert = new Alert();
  private alertedTimeLeftIsNearToEnd = false;
   
  constructor() {
    super();
    this.service = new CandidateExam();
    this.serviceC = new Candidatura();
  }

  public async mounted() {
    this.loaded = false;
    // pegar candidatura primeiro e retornar info do tempo total de provas    
    const cid: number = parseInt(this.$route.params.cid);
    if(cid && !isNaN(cid)) {
      this.candidatura = await this.serviceC.fetch(cid);
      if(this.candidatura && this.candidatura.status == 'EXAMESPENDENTES') {
        this.promise = this.service.fetchAll({vid: this.candidatura.vaga.id}).then((data: any) => {
          if(data && data.qJobs && data.qJobs.length) {
            this.jobExams = data.qJobs;
            this.tempoTotal = data.qJobs[0].tempoMaximo;
            this.startedAt = data.startedAt;
            this.validStart = true;
            if(data.answereds && data.answereds.length) {
              this.answeredIds = data.answereds;
            }
            if(data.finished != undefined){
              this.finished = data.finished;
            }
          }
          this.loaded = true;
        }).catch((err:any) => {
          this.errorMessage = 'Oops! No test available to start at this time';
          this.loaded = true;
        })
      } else {
          this.finished = true;
          this.loaded = true;
      }
    } else {
      this.errorMessage = 'This page does not exists';
      this.loaded = true;
    }
  }

  public saved(qidFromSave?: number, gg?: boolean) {
    if(qidFromSave) {
      this.answeredIds.push(qidFromSave);
      this.nextQuestion(qidFromSave);
    }
    this.ggList.push(gg);
  }

  public startTimer() {
    if(this.startedAt &&  this.tempoTotal) {
      this.timeDiff = this.$diffInMinutes(this.startedAt,this.tempoTotal);

      if(this.timeDiff > 0) {
        this.duration = moment.duration(this.timeDiff,'minutes')
        this.timer = setInterval(() => {
          this.duration = moment.duration(this.duration.asSeconds() - 1, 'seconds');
          this.countDownHours = this.duration.hours();
          this.countDownMinutes = this.duration.minutes();
          this.countDownSeconds = this.duration.seconds();

          this.countDownSeconds -= 1;
          if (this.countDownMinutes < 0 && this.countDownHours <= 0) return clearInterval(this.timer);

          if (this.countDownSeconds == 0 && this.countDownMinutes == 0 && this.countDownHours != 0) {
            this.countDownHours -= 1;
            this.countDownMinutes = 59;
          } 
          
          if (this.countDownSeconds < 0 && this.countDownMinutes != 0) {
            this.countDownMinutes -= 1;
            this.countDownSeconds = 59;
          } 

          if (this.countDownMinutes < 0) {
            this.countDownMinutes = 0;
            this.countDownSeconds = 0;
          } 

          const examTimeEnded = this.countDownMinutes <= 0 && this.countDownSeconds <= 0 && this.countDownHours <= 0;

          if (examTimeEnded) {
            if (this.timeLeftEnded === false) {
              this.timeLeftEnded = true;
              this.alertExamEnded();
            }

            clearInterval(this.timer);
          }

          if (this.startedAt === null || this.tempoTotal === null) return;
          
          const timeLeftInMinutes = this.$diffInMinutes(this.startedAt, this.tempoTotal);
          const timeLeftInSeconds = moment.duration(timeLeftInMinutes, 'minutes').asSeconds();

          const totalTimeInMinutes = moment.duration(this.tempoTotal, 'minutes').asMinutes();
          const totalTimeInSeconds = moment.duration(this.tempoTotal, 'minutes').asSeconds();

          const itsTimeToAlertTalent = totalTimeInMinutes <= 3
            ? timeLeftInSeconds <= totalTimeInSeconds / 3
            : timeLeftInMinutes <= 2;

          if (itsTimeToAlertTalent && !this.alertedTimeLeftIsNearToEnd) this.alertTimeLeftIsNearToEnd();
        }, 1000);
      } else {
        this.timeLeftEnded = true;
        this.alertExamEnded();
      }
    }
  }

  private alertExamEnded() {
    this.alert.addTitle(this.$t('Time is over').toString());
    this.alert.addMsg(this.$t('The time for the exam has ended, good luck.'));
    this.alert.callBoxWarn();
  }

  private alertTimeLeftIsNearToEnd() {
    this.$toast.addTitle(this.$t('Attention').toString());
    this.$toast.addMsg(this.$t('Stay tuned, time is almost up.'));
    this.$toast.variant = 'warning';
    this.$toast.open();

    this.alertedTimeLeftIsNearToEnd = true;
  }

  public nextQuestion(qidFromSave: number) {
    const ci: number = this.qids.findIndex((_: number) => { return _ == qidFromSave})
    const lastIndex: number = this.qids.length-1;
      this.currentQIndex = ci+1;
    if(this.currentQIndex > lastIndex) {
      this.finished = true;
      this.loading = true;
      this.checkCandidatura();
    }
  }

  get isExameEmAnalise() {
    return this.candidatura && this.candidatura.status == 'EXAMESEMANALISE';
  }

  get isExameReprovado() {
    return this.candidatura && this.candidatura.status == 'EXAMESREPROVADO';
  }

  public async checkCandidatura() {
    const cid: number = parseInt(this.$route.params.cid);
    if(cid && !isNaN(cid)) {
      this.candidatura = await this.serviceC.fetch(cid)
      if(this.candidatura && this.candidatura.status == 'VIDEOSPENDENTES') {

        this.$alert.title = this.$t("Congratulations");
        this.$alert.addMsg(this.$t("You have passed the test phase.").toString());
        this.$alert.addMsg(this.$t("Get ready for your interview.").toString());
        this.$alert.callBoxCheck('Let`s Go');
        this.goToInterview();      
      }
    }
    this.loading = false;
  }

  public goToInterview() {
    this.$router.push({
      name: 'Entrevista',
      params: { id: this.candidatura!.id!.toString() },
      query: { lang: this.$i18n.locale }
    }).catch(() => {});
  }

   public startExam() {
    if(!this.startedAt) {
      let msg:any = this.$t('In this step you will have ({total}) test(s). Kindly note that the time limit is {tempoMaximo} minutes.',{total: this.jobExams.length, tempoMaximo: this.tempoTotal});
      this.$confirm.open({
        okTitle: this.$t('Yes').toString(), 
        cancelTitle: this.$t('No').toString(), msg },'check').then((v:boolean) => {  
        if(v) {
          this.doStart()
        }
      });
    } else {
      this.doStart();
    }
  }

  public doStart() {
    if(this.validStart && this.candidatura) {
      this.promise = this.service.create({
        vagaId: this.candidatura.vaga.id
      }).then((_: any) => {
          if(_ && _.i && _.i.length) {
            this.qids = _.i;
          }
          if(_.startedAt) {
            this.startedAt = _.startedAt
          }
          if(this.answeredIds.length > 0) {
            this.nextQuestion(this.answeredIds[this.answeredIds.length-1])
          }
          this.started = true;
          this.startTimer();
      }).catch((err:any) => {
        this.errorMessage = 'Oops! No test available to start at this time';
      })
    }
  }

}
