











import { Prop, Component, Vue } from 'vue-property-decorator';

@Component
export default class CardVideoInstructions extends Vue {

    @Prop({default: ""}) public text!: string;
    @Prop({default: ""}) public iconName!: string;
    @Prop({default: ""}) public iconSize!: string;
    @Prop({default: ""}) public iconColor!: string;
}
