



















import { Component, Prop, Vue } from 'vue-property-decorator';

import vueAwesomeCountdown from 'vue-awesome-countdown';

Vue.use(vueAwesomeCountdown, 'countdown');

@Component
export default class InterviewBoxQuestion extends Vue {
  @Prop()
  private content!: string;

  @Prop()
  private time!: number;

  @Prop()
  private active!: boolean;

  @Prop({default: 1})
  private total!: number;
  
  @Prop({default: 0})
  private currentNumber!: number;

  @Prop()
  private showPergunta!: boolean;

  @Prop({ default: 0 })
  private videoProgressTime!: number;

  private get tempo(): string {
    const tempo = this.time.toString();
    return (1 == tempo.length ? '0' + tempo : tempo) + ':00';
  }

  private get totalLeftTime(): number {
    return this.time * 60 * 1000;
  }

  private currentLeftTime(usedTime: number): string {
    return this.formatTime(this.totalLeftTime - usedTime);
  }

  public formatTime(time: number){
    time = (time < 0) ? 0 : time;
    const seconds = time/1000; // divided by int return int
    const valor_time_mod = (seconds < -1) ? seconds * -1 : seconds;
    const m = (Math.round(seconds - (seconds % 60)) / 60).toString();
    const s = (Math.round(valor_time_mod % 60)).toString();
    return (m.length == 1 ? "0" + m : m) + ":" + (s.length == 1 ? "0" + s : s);
  }
}
