import { render, staticRenderFns } from "./Assessment.vue?vue&type=template&id=5e435de2&scoped=true"
import script from "./Assessment.vue?vue&type=script&lang=ts"
export * from "./Assessment.vue?vue&type=script&lang=ts"
import style0 from "./Assessment.vue?vue&type=style&index=0&id=5e435de2&prod&lang=sass&scoped=true"
import style1 from "vue-step-indicator/dist/vue-step-indicator.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e435de2",
  null
  
)

export default component.exports