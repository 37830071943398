import { render, staticRenderFns } from "./CardVideoInstructions.vue?vue&type=template&id=ca9f0fcc&scoped=true"
import script from "./CardVideoInstructions.vue?vue&type=script&lang=ts"
export * from "./CardVideoInstructions.vue?vue&type=script&lang=ts"
import style0 from "./CardVideoInstructions.vue?vue&type=style&index=0&id=ca9f0fcc&prod&lang=sass&scoped=true"
import style1 from "./CardVideoInstructions.vue?vue&type=style&index=1&id=ca9f0fcc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca9f0fcc",
  null
  
)

export default component.exports