












































import { CandidatoEntity, ExperienciaEntity } from "@/core/models/Entities";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class CardPdfProfessionalInfo extends Vue {

  @Prop()
  public experiencias!: ExperienciaEntity[];

  @Prop()
  public value!: CandidatoEntity;
   
  public items: any[] = [];
  public fields: any[] = [];

  constructor() {
    super();
  }

  get temExperiencia() {
    return this.value?.perfil?.temExperiencia === true;
  }

  public mounted(){
    this.fields = [
      { key: "atividade", label: this.$t('Position')},
      { key: "periodo", label: this.$t('Period') },
      { key: "segmento", label: this.$t('Segment') }
    ];
    // this.loadData()
  }  

  public loadData() {
    this.items = [];
    this.experiencias.forEach(el => {
      this.items.push({
        atividade: el.atividade,
        descricaoAtividade: el.descricaoAtividade,
        periodo: this.periodText(el.total),
        segmento: el.segmento||" - "
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
        text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }

}
