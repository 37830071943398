




























import CardBox from '@/components/CardBox.vue';
import { CandidatoEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

const namespace: string = 'profile';

@Component({
  components: {
    'card-box': CardBox
  },
})
export default class CardTip extends Vue {
  public tipsList: any[] = [];
  public counterShow: number = 0;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  constructor() {
    super();
  }

  public async created() {
    if(!this.candidato) {
      await this.loadCandidatoInfo();
    }
    this.tipsList = [
      { header: "Match", content: "Get closer to companies! Take our Pocket Cultural Match.", hasButton: true, action: this.goToAssessment },
      { header: "Tip", content: "Your Jobecam profile can be used as a digital curriculum. Always keep it updated." },
      { header: "Tip", content: "Your presentation video or video curriculum will make you be seen beyond paper. Stand out!" },
      { header: "Tip", content: "In your presentation, say your name, education, experience and skills and invite recruiters to know more about you." },
      { header: "Tip", content: "Share your jobecam profile link with recruiters and show that you're already in the age of innovation" },
      { header: "Tip", content: "Use your Jobecam profile to generate your digital curriculum. It's easy, add your information and download for free your cv in pdf." },
    ];
    if(this.candidato?.lastQuestionaryDate){
      let d = new Date();
      let d1 = new Date();
      d.setTime(this.candidato?.lastQuestionaryDate*1000)
      d.setDate(d.getDate() + 90);
      if(d >= d1) {
        this.tipsList.splice(0,1);
      }
    }
  }

  public previous() {
    this.counterShow--;

    if(this.counterShow < 0)
      this.counterShow = (this.tipsList.length-1)
  }

  public goToAssessment() {
    this.$router.push({path: '/assessment', query: {lang: this.$i18n.locale }});
    return;
  }

  public next() {
    this.counterShow++;

    if(this.counterShow >= (this.tipsList.length-1))
      this.counterShow = 0
  }

}
