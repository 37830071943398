












import { Component, Prop, Vue } from "vue-property-decorator";
import Avatar from '@/components/avatar/Avatar.vue';

@Component<AvatarUpload>({
  components: {
    'avatar': Avatar
  },
})
export default class AvatarUpload extends Vue {
  @Prop({ default: null }) public fotoAvatar!: string;
  @Prop({ default: "" }) public altAvatar!: string;
  @Prop({ default: () => {} }) public onfile!: (file: any) => any;

  private handleChange(ev: any) {
    this.$emit('change', ev.target.files[0])
  }
}
