































import Vue from "vue";
import Component from "vue-class-component";
import CardJob from "@/components/cards/CardJob.vue";
import { CandidaturaEntity } from "@/core/models/Entities";
import CandidaturaService from "@/core/Services/Candidato/Candidatura";
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
import VagasSearchMixin from './VagasSearchMixin';

@Component({
  components: {
    CardJob,
    JobsHeaderNavigation
  },
  mixins: [VagasSearchMixin]
})
export default class Candidaturas extends Vue {
  public service: CandidaturaService = new CandidaturaService();
  public list: CandidaturaEntity[]|null = null;
  public notCandidated: boolean = false;// Mesmo que candidated = true
  public responseKey = 'candidaturas';

}
