
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { QuestionaryEntity, QuestionaryCandidatoEntity, QCAnswerData } from '@/core/models/Entities';


export default class CandidateExam extends Repository {

  public questionary: QuestionaryEntity;
  public entidade: QuestionaryCandidatoEntity;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/candidate-exam', method: 'POST', headers: {} },
      fetch: { url: '/v1/candidate-exam/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/candidate-exam', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/candidate-exam/{id}', method: 'PUT', headers: {} }
    }));
    this.questionary = new QuestionaryEntity();
    this.entidade = new QuestionaryCandidatoEntity();
  }

  public save(): Promise<any> {
    if (this.entidade.id && this.entidade.id > 0) {
      return super.update(this.entidade).then((data: QuestionaryCandidatoEntity) => {
        //this.entidade = data;
        return data;
      });
    } else {
      return super.create(this.entidade).then((data: QuestionaryCandidatoEntity) => {
        //this.entidade = data;
        return data;
      });
    }
  }

  public saveEntityInLocalStorage() {
    if (!this.entidade.id) return;

    window.localStorage.setItem(`questionaryTalent${this.entidade.id}`, JSON.stringify(this.entidade));
  }

  public loadEntityFromLocalStorage() {
    const stringifiedValue = window.localStorage.getItem(`questionaryTalent${this.entidade.id}`);

    if (!stringifiedValue) return undefined;

    const entity = JSON.parse(stringifiedValue) as QuestionaryCandidatoEntity;
    return entity;
  }

  public getAnswersFromQuestion(questionId: number) {
    const entityAnswers = Object.values(this.entidade.answers ?? {}) as QCAnswerData[];
    const answers = entityAnswers.filter((answer) => {
      return answer.questionId === questionId;
    });

    return answers;
  }

  public deleteEntityFromLocalStorage() {
    window.localStorage.removeItem(`questionaryTalent${this.entidade.id}`)
  }
}


