





















import { Vue, Component, Prop } from 'vue-property-decorator';
import InterviewQuestionContent from '@/components/interview/InterviewQuestionContent.vue';
import { VagaPerguntaEntity } from '@/core/models/Entities';

import vueAwesomeCountdown from 'vue-awesome-countdown';

Vue.use(vueAwesomeCountdown, 'countdown');

@Component({
  components: {
    InterviewQuestionContent
  }
})
export default class InterviewQuestion extends Vue {
  @Prop()
  private pergunta!: VagaPerguntaEntity;

  @Prop()
  public active!: boolean;

  @Prop()
  public showPergunta!: boolean;

  @Prop({default: 1})
  public total!: number;
  
  @Prop({default: 0})
  public currentNumber!: number;

  @Prop({ default: 0 })
  public videoProgressTime!: number;

  get tempo(): string {
    const tempo = this.pergunta.tempo.toString();
    return (1 == tempo.length ? '0' + tempo : tempo) + ':00';
  }

  get totalLeftTime(): number {
    return this.pergunta.tempo * 60 * 1000 - 1000;
  }

  currentLeftTime(usedTime: number): string {
    return this.formatTime(this.totalLeftTime - usedTime);
  }

  public formatTime(time: number){
    const seconds = time/1000; // divided by int return int
    const valor_time_mod = (seconds < -1) ? seconds * -1 : seconds;
    const m = (Math.round(seconds - (seconds % 60)) / 60).toString();
    const s = (Math.round(valor_time_mod % 60)).toString();
    return (m.length == 1 ? "0" + m : m) + ":" + (s.length == 1 ? "0" + s : s);
  }
}
