import { render, staticRenderFns } from "./CompaniesFilter.vue?vue&type=template&id=28ea3bec&scoped=true"
import script from "./CompaniesFilter.vue?vue&type=script&lang=ts"
export * from "./CompaniesFilter.vue?vue&type=script&lang=ts"
import style0 from "./CompaniesFilter.vue?vue&type=style&index=0&id=28ea3bec&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ea3bec",
  null
  
)

export default component.exports