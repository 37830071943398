import { i18n } from '@/plugins/i18n';
import PlatformUtil from './PlatformUtil';

const platform = PlatformUtil.getInstance();

const sleep = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default class Speech {

  recognition = null;
  interimTranscript = [];
  finalTranscript = [];
  recognizing = false;
  stopped = false;
  ignore_onend = false;
  start_timestamp = 0;
  button_event = null;
  callbacks = {
    speechstart: [],
    speechend: [],
    start: [],
    error: [],
    end: [],
    soundend: [],
    audioend: [],
    soundstart: [],
    audiostart: [],
    result: [],
    resultMatch: [],
    resultNoMatch: [],
    errorNetwork: [],
    errorPermissionBlocked: [],
    errorPermissionDenied: [],
  }

  constructor() {
    if (platform.isChromium() && !platform.isMobileDevice()) {
      if (('SpeechRecognition' in window)) {
        this.recognition = new SpeechRecognition();
      } else if (('webkitSpeechRecognition' in window)) {
        this.recognition = new webkitSpeechRecognition();
      } else {
        this.recognition = null;
      }
      this.loadCallbacks()
      window['JBSpeech'] = this;
    }
  }

  async stop() {
    if (!this.recognition)
      return;

    this.stopped = true;
    try {
      await sleep(100);
    } catch (error) {
      
    }
    if (this.recognizing) {
      this.recognition.stop();
      return;
    }
  }

  start(lang) {
    if (!this.recognition)
      return;

    if (this.recognizing) {
      this.recognition.stop();
    }
    this.stopped = false;
    this.finalTranscript = []
    this.recognition.lang = (lang || this.getLocale());
    console.log("Initialize SpeechRecognition in ", this.recognition.lang)
    this.recognition.start();
    this.ignore_onend = false;
    this.start_timestamp = (new Date()).getTime();
  }

  getLocale() {
    if (i18n.locale.indexOf("pt") == 0) return "pt-BR";
    if (i18n.locale.indexOf("en") == 0) return "en-US";
    if (i18n.locale.indexOf("es") == 0) return "es-ES";
    return "en-US";
  }

  loadCallbacks() {
    if (!this.recognition)
      return;

    this.recognition.continuous = true;
    this.recognition.interimResults = false;
    
    if (platform.isMobileDevice()) {
      this.recognition.continuous = false;
      this.button_event = document.createElement('button')
      this.button_event.id = "speech_recon_action_button"
      this.button_event.classList.add('d-none')
      this.button_event.onclick = (e1) => {
        console.log('starting through button', e1)
        this.recognition.start()
        this.start_timestamp = e1.timeStamp
      };
      document.body.append(this.button_event)
    }

    this.recognition.onstart = (event) => {
      this.invokeCallbacks("start",event)
      this.recognizing = true;
    };

    this.recognition.onerror = (event) => {
      this.invokeCallbacks("error",event)
      if (event.error == 'no-speech') {
        this.ignore_onend = true;
      }
      if (event.error == 'audio-capture') {
        this.ignore_onend = true;
      }
      if (event.error == 'not-allowed') {
        if (event.timeStamp - this.start_timestamp < 100) {
          // blocked
          this.invokeCallbacks("errorPermissionBlocked",event)
        } else {
          // access denied
          this.invokeCallbacks("errorPermissionDenied",event)
        }
        this.ignore_onend = true;
      }
      if (event.error == 'aborted') {
        this.stop();
      }
    }

    this.recognition.onend = (event) => {
      this.invokeCallbacks("end",event)

      if (!this.stopped && this.recognition) {
        console.log("//==RecognitionEnded -- stop was not called ==//")
        if (!platform.isMobileDevice()) {
          this.recognition.start()
        } else {
          //this.button_event.click()
        }
      }

      this.recognizing = false;
      if (this.ignore_onend) {
        return;
      }
      if (!this.finalTranscript) {
        return;
      }
    }

    this.recognition.onresult = (event) => {
      if (!this.recognition)
        return;
      
      this.invokeCallbacks("result",event)
      this.interimTranscript = [];
      if (typeof (event.results) == 'undefined') {
        this.recognition.onend = null;
        this.recognition.stop();
        return;
      }
      for (var i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          if (process.env.NODE_ENV != 'production') console.log("final", event.results[i][0])
          this.finalTranscript.push(event.results[i][0]);
          this.invokeCallbacks("resultMatch",event.results[i][0]?.transcript)
        }
      }
    }
    
    this.recognition.onaudiostart = (event) => {
      this.invokeCallbacks("audiostart",event)
    }

    this.recognition.onaudioend = (event) => {
      this.invokeCallbacks("audioend",event)
    }
   
    this.recognition.onspeechstart = (event) => {
      this.invokeCallbacks("speechstart",event)
    }

    this.recognition.onspeechend = (event) => {
      this.invokeCallbacks("speechend",event)
    }
    
    this.recognition.onsoundstart = (event) => {
      this.invokeCallbacks("soundstart",event)
    }
    
    this.recognition.onsoundend = (event) => {
      this.invokeCallbacks("soundend",event)
    }

    this.recognition.onnomatch = (event) => {
      this.invokeCallbacks("resultNoMatch",event)
    }
  }
  // This method receives an array of callbacks to iterate over, and invokes each of them
  invokeCallbacks (t, ...args) {
    if(process.env.NODE_ENV !== "production") console.log("invoking", t, args)
    this.callbacks[t].forEach((cbObj) => {
      cbObj.callback.apply(cbObj.context, args);
    });
  }
  /**
   * @author https://github.com/TalAter/annyang/blob/master/src/annyang.js
   * Add a callback function to be called in case one of the following events happens:
   *
   * * `start` - Fired as soon as the browser's Speech Recognition engine starts listening.
   *
   * * `soundstart` - Fired as soon as any sound (possibly speech) has been detected.
   *
   *     This will fire once per Speech Recognition starting. See https://is.gd/annyang_sound_start.
   *
   * * `error` - Fired when the browser's Speech Recognition engine returns an error, this generic error callback will be followed by more accurate error callbacks (both will fire if both are defined).
   *
   *     The Callback function will be called with the error event as the first argument.
   *
   * * `errorNetwork` - Fired when Speech Recognition fails because of a network error.
   *
   *     The Callback function will be called with the error event as the first argument.
   *
   * * `errorPermissionBlocked` - Fired when the browser blocks the permission request to use Speech Recognition.
   *
   *     The Callback function will be called with the error event as the first argument.
   *
   * * `errorPermissionDenied` - Fired when the user blocks the permission request to use Speech Recognition.
   *
   *     The Callback function will be called with the error event as the first argument.
   *
   * * `end` - Fired when the browser's Speech Recognition engine stops.
   *
   * * `result` - Fired as soon as some speech was identified. This generic callback will be followed by either the `resultMatch` or `resultNoMatch` callbacks.
   *
   *     The Callback functions for this event will be called with an array of possible phrases the user said as the first argument.
   *
   * * `resultMatch` - Fired when annyang was able to match between what the user said and a registered command.
   *
   *     The Callback functions for this event will be called with three arguments in the following order:
   *
   *     * The phrase the user said that matched a command.
   *     * The command that was matched.
   *     * An array of possible alternative phrases the user might have said.
   *
   * * `resultNoMatch` - Fired when what the user said didn't match any of the registered commands.
   *
   *     Callback functions for this event will be called with an array of possible phrases the user might have said as the first argument.
   *
   * #### Examples:
   * ````javascript
   * annyang.addCallback('error', function() {
   *   $('.myErrorText').text('There was an error!');
   * });
   *
   * annyang.addCallback('resultMatch', function(userSaid, commandText, phrases) {
   *   console.log(userSaid); // sample output: 'hello'
   *   console.log(commandText); // sample output: 'hello (there)'
   *   console.log(phrases); // sample output: ['hello', 'halo', 'yellow', 'polo', 'hello kitty']
   * });
   *
   * // pass local context to a global function called notConnected
   * annyang.addCallback('errorNetwork', notConnected, this);
   * ````
   * @param {String} type - Name of event that will trigger this callback
   * @param {Function} callback - The function to call when event is triggered
   * @param {Object} [context] - Optional context for the callback function
   * @method addCallback
   */
  addCallback(type, callback, context) {
    var cb = callback;
    if (typeof cb === 'function' && this.callbacks[type] !== undefined) {
      this.callbacks[type].push({ callback: cb, context: context || this });
    }
  }

  /**
   * @author https://github.com/TalAter/annyang/blob/master/src/annyang.js
   * Remove callbacks from events.
   *
   * - Pass an event name and a callback command to remove that callback command from that event type.
   * - Pass just an event name to remove all callback commands from that event type.
   * - Pass undefined as event name and a callback command to remove that callback command from all event types.
   * - Pass no params to remove all callback commands from all event types.
   *
   * #### Examples:
   * ````javascript
   * annyang.addCallback('start', myFunction1);
   * annyang.addCallback('start', myFunction2);
   * annyang.addCallback('end', myFunction1);
   * annyang.addCallback('end', myFunction2);
   *
   * // Remove all callbacks from all events:
   * annyang.removeCallback();
   *
   * // Remove all callbacks attached to end event:
   * annyang.removeCallback('end');
   *
   * // Remove myFunction2 from being called on start:
   * annyang.removeCallback('start', myFunction2);
   *
   * // Remove myFunction1 from being called on all events:
   * annyang.removeCallback(undefined, myFunction1);
   * ````
   *
   * @param type Name of event type to remove callback from
   * @param callback The callback function to remove
   * @returns undefined
   * @method removeCallback
   */
  removeCallback(t, callback) {
    const compareWithCallbackParameter = (cb) => {
      return cb.callback !== callback;
    };
    // Go over each callback type in callbacks store object
    for (let callbackType in this.callbacks) {
      if (this.callbacks.hasOwnProperty(callbackType)) {
        // if this is the type user asked to delete, or he asked to delete all, go ahead.
        if (t === undefined || t === callbackType) {
          // If user asked to delete all callbacks in this type or all types
          if (callback === undefined) {
            this.callbacks[callbackType] = [];
          } else {
            // Remove all matching callbacks
            this.callbacks[callbackType] = this.callbacks[callbackType].filter(compareWithCallbackParameter);
          }
        }
      }
    }
  }

}

