import { EVideoSupplier } from '../Enums/EVideoSupplier';

// tslint:disable
export class CandidatoEntity {
  id?:number;
  guid: string | undefined;
  idMD5: string|undefined;
  primeironome: string = "";
  sobrenome: string = "";
  email: string = "";
  nomeUrl: string|undefined;
  userFacebook: string|undefined;
  userLinkedin: string|undefined;
  newsletter: string|undefined;
  sexo: string|undefined;
  idRacaCor: string|undefined;
  lingua: string|undefined;
  dataCadastro: string|undefined;
  ipcadastro: string|undefined;
  dataAlteracao: string|undefined;
  ipAlteracao: string|undefined;
  fotoAvatar: string|undefined;
  backgroundAvatar: string|undefined;
  cpf: string|undefined;
  perfil: PerfilCandidatoEntity;
  perfilcandidato: PerfilCandidatoEntity|undefined;
  completionMissing?: any;
  notification?: any;
  notif_acompanhamento?: number;
  notif_indicacao?: number;
  notifi_novidades?: number;
  lastQuestionaryDate?: number;
  notifAcompanhamento: number = 0;
  notifIndicacao: number = 0;
  notifNovidades: number = 0;
  completionPercentage: number = 0;
  grupoMovBlack: any;
  doneTutorial: boolean = false;

  constructor() {
    this.perfil = new PerfilCandidatoEntity();
  }

}
export class PerfilCandidatoEntity {
  id?:number;
  apresentacao: string|undefined;
  profissao: string|undefined;
  localidade: string|undefined;
  curriculum: string|undefined;
  videoapresentacao: string|undefined;
  idioma: IdiomaEntity|undefined;
  plano: string|undefined;
  areaAtuacao: AreaAtuacaoEntity|undefined;
  escolaridade: NivelEscolaridadeEntity|undefined;
  dataNasc: string|undefined;
  cep: string|undefined;
  rua: string|undefined;
  numero: string|undefined;
  complemento: string|undefined;
  bairro: string|undefined;
  cidade: string|undefined;
  estado: string|undefined;
  pais: string|undefined;
  telefone: string|undefined;
  cadastroConcluido: string|undefined;
  profissao2: string|undefined;
  dataCancela: string|undefined;
  dataAdesao: string|undefined;
  dataFinal: string|undefined;
  experiencias: Array<ExperienciaEntity>|undefined;
  formacoes: Array<FormacaoEntity>|undefined;
  idiomas: Array<IdiomasCandidatoEntity>|undefined;
  cidadeId: any|null;
  estadoId: any|null;
  mostrarvideo: string = 'Y';
  areaatuacaoId: number|undefined;
  escolaridadeId: number|undefined;
  nomeEstado: string|undefined;
  nomeCidade: string|undefined;
  thumbVideo: string|undefined;
  videoapresentacaoMP4: string|undefined;
  recordObject?: string;
  recordId?: string;
  habilidades: HabilidadeCandidatoEntity[] = [];
  linkFacebook: string|undefined = '';
  linkLinkedin: string|undefined = '';
  linkInstagram: string|undefined = '';
  idiomaId?: number;
  dataNascimento?: Date|string|null = null;
  pcd: boolean = false;
  pcdDescricao: string = '';
  cpf: string = '';
  idRacaCor: string = '';
  grupoLgbt: any = null;
  grupoIndiginas: any = null;
  grupoRefugiados: any = null;
  grupoMovBlack: any = null;
  grupoPCD: any = null;
  grupoEmpoderaFeminino: any = null;
  grupo3Idade: any = null;
  temExperiencia: boolean|null = null;
  temFormacao: boolean|null = null;
  aceiteDadosSensiveis: boolean|null = null;
    
  constructor() {
    this.idioma = new IdiomaEntity();
    this.areaAtuacao = new AreaAtuacaoEntity();
    this.escolaridade = new NivelEscolaridadeEntity();
    this.idiomas = [];
    this.formacoes = [];
    this.experiencias = [];
  }

}
export class IdiomasCandidatoEntity {
  id?:number;
  nivel:string|undefined;
  idiomaId:any|null;
  idioma: IdiomaEntity|undefined;
}
export class IdiomaEntity {
  id?:number;
  idioma: string|undefined;
  legenda: any|undefined;
}
export class PlanoCandidatoEntity {
  id?:number;
  plano: string|undefined;
  preco: string|undefined;
  periodo: string|undefined;
  tempoVideocv: string|undefined;
  compartilhaVideocv: string|undefined;
  quantidadeCandidaturas: string|undefined;
  destaquePerfil: string|undefined;
  quantidadeMensagens: string|undefined;
  popular: string|undefined;
  free: string|undefined;
  mostrar: any|undefined;
}
export class AreaAtuacaoEntity {
  id?:number;
  descricao: any|undefined;
}
export class NivelEscolaridadeEntity {
  id?:number;
  descricao: string|undefined;
  ordenar: any|undefined;
}
export class PaisEntity {
  id?:number;
  nome: string|undefined;
  sigla: string|undefined;
  idioma: any|undefined;
}
export class EstadoEntity {
  id?:number;
  ufNome: string|undefined;
  ufAbrev: string|undefined;
  pais: any|undefined;
}
export class CidadeEntity {
  id?:number;
  cidNome: string|undefined;
  cidCod: string|undefined;
  uf: any|undefined;
  ufId?: number|undefined;
  ufAbrev?: string|undefined;
  completoNome?: string|undefined;
}
export class FormacaoEntity {
  id?:number;
  nome: string|undefined;
  dataEntrada: string|undefined;
  dataSaida: string|undefined;
  atividade: string|undefined;
  localidade: string|undefined;
  tipo: string|undefined;
  descricaoAtividade: string|undefined;
  atual: string|undefined;
  perfilCandidato: string|undefined;
  mesInicio: string|undefined;
  anoInicio: string|undefined;
  mesFim: string|undefined;
  anoFim: string|undefined;
  status: string|undefined;
  total: any|undefined;
  atual_:number = 1;
  
  constructor() {
    this.tipo = "";
    this.status = ""
  }
}
export class ExperienciaEntity {
  id?:number;
  nome: string|undefined;
  dataEntrada: string|undefined;
  dataSaida: string|undefined;
  atividade: string|undefined;
  segmento: string|undefined;
  localidade: string|undefined;
  descricaoAtividade: string|undefined;
  atual: string|undefined;
  perfilCandidato: string|undefined;
  mesInicio: string|undefined;
  anoInicio: string|undefined;
  periodo: string|undefined;
  mesFim: string|undefined;
  anoFim: string|undefined;
  total: any|undefined;
  atual_:number = 1;
  
  constructor() {  
    this.segmento = "";
    this.periodo = "";
  }
}
export class VagaEntity {
  id?:number;
  guid: string | undefined;
  nomeUrl: string|undefined;
  posicao: string|undefined;
  salario: string|undefined;
  descricao: string|undefined;
  descricaoSemHtml: string|undefined;
  nivel: string|undefined;
  dataPublicacao: string|undefined;
  dataCadastro: string|undefined;
  dataAtualiza: string|undefined;
  dataEncerra: string|undefined;
  status: string|undefined;
  localidade: string|undefined;
  requisitos: string|undefined;
  competencias: string|undefined;
  confidencial: string|undefined;
  restrita: string|undefined;
  transcricao: number|undefined;
  periodoTrabalho: string = "";
  category: string = "";
  contractType: string = "";
  perguntas: Array<VagaPerguntaEntity>;
  candidaturas?: Array<any>|undefined;
  empresa?: EmpresaEntity|undefined;
  historicoCredito?: any|undefined;
  cidade?: CidadeEntity|undefined;
  cidadeId?: number|undefined;
  isCandidatado?: boolean;
  isPendente?: boolean;
  blind: number;
  mostrarCurriculoOculto: boolean;
  PerguntasCorte: VagaPerguntaCorteEntity[] = [];
  isSaved: boolean = false;
  isAllowed: boolean = true;
  videoSupplier: EVideoSupplier|number|null = EVideoSupplier.Openvidu;
  vagaHabilidades: VagasHabilidadesEntity[] = [];
  hideMap: boolean = false;
  applyButtonText: string|null = null;
  salaryMax: number|null = null;
  salaryMin: number|null = null;
  salaryNumber: number|null = null;
  hideSalary: boolean = false;
  beneficios: string[]|null = null;
  nomeEmpresa: string|null = null;
  requireVideoCurriculum: boolean = false;
  requireProfile: boolean = false;
  questionaries!: any[]|undefined;
  daysToRecord: number = 0;

  constructor() {
    this.perguntas = [];
    this.salario = "0";
    this.blind = 0;
    this.mostrarCurriculoOculto = false;
  }
}
export class VagaPerguntaEntity {
  id: number = 0;
  numeroPergunta!: number;
  pergunta: string|undefined;
  tempo: number = 1;
  datacadastro: any|undefined;
  idioma: string|undefined;
  answered: boolean|undefined;
}

export class TagTranscricaoEntity {
  id!: number;
  tag!: string;
  vaga?: VagaEntity;
  idvaga?: number;
  tags!: string;
}

export class VagaVideoRespostaEntity {
  pergunta?:VagaPerguntaEntity;
  candidato?:CandidatoEntity;
  candidatura?:CandidaturaEntity;
  video!:string;
  status!:string;
  pontostranscricao?:number;
  recordObject?: string;
  recordId?: string;
}

export class RecrutadorEntity {
  id?:number;
  guid: string | undefined;
  nome: string|undefined;
  email: string|undefined;
  fotoAvatar: string|undefined;
  nomeUrl: string|undefined;
  cargo: string|undefined;
  status: string|undefined;
  sexo: any|undefined;
  permissaoAdm: boolean|undefined;
}

export class EmpresaEntity {
  id?:number;
  guid: string | undefined;
  primeironome: string|undefined;
  sobrenome: string|undefined;
  email: string|undefined;
  nomeUrl: string|undefined;
  fotoAvatar: string|undefined;
  backgroundAvatar: string|undefined;
  status: string|undefined;
  sexo: string|undefined;
  lingua: string|undefined;
  relativePath: string|undefined;
  statusCadastro: string|undefined;
  statusPagamento: string|undefined;
  perfil: PerfilEmpresaEntity|undefined;
  nomeEmpresa?: string;
  logoEmpresa?: string;
  background?: string;
  cnpj?: string;
  // TODO: Implement Entity Match in Here
  matchCandidate?: any;
  seguindo: boolean = false;
  configuracoes: any|undefined;

  constructor() {
   this.perfil = new PerfilEmpresaEntity();
  }
}
export class PerfilEmpresaEntity {
  id?:number;
  nomeEmpresa: string|undefined;
  razaosocial: string|undefined;
  endereco: string|undefined;
  numero: string|undefined;
  telefones: string|undefined;
  nomeCidade: string|undefined;
  bairro: string|undefined;
  uf: string|undefined;
  pais: string|undefined;
  logoempresa: string|undefined;
  descricao: string|undefined;
  descricaoSemHtml: string|undefined;
  especializacao: string|undefined;
  setor: string|undefined;
  tamanho: string|undefined;
  fundada: string|undefined;
  tipo: string|undefined;
  site: string|undefined;
  complemento: string|undefined;
  cep: string|undefined;
  cidade?: any|null;
  cidadeId?: any|null;
  ufId?: any|null;
  cnpj?: string|null;
  videoApresentacao?: string|null;
}
export class CandidaturaEntity {
  id?:number;
  vaga: VagaEntity;
  candidato: CandidatoEntity;
  status: string|undefined;
  canAnswer: boolean|undefined;
  comentarioRecrutador: string|undefined;
  ratingRecrutador: number|undefined;
  comentarioGestor: string|undefined;
  ratingGestor: number|undefined;
  organica: string|undefined;
  dataCadastro: any;
  dataAtualiza: string|undefined;
  idvaga: string|undefined;
  idcandidato: any|undefined;
  videosRespostas?: Array<VagaVideoRespostaEntity>;
  score?:number;
  avgRating?: number;
  urlCallback?: string;

  constructor() {
    this.candidato = new CandidatoEntity();
    this.vaga = new VagaEntity();
  }

  get classeStatus() {
    switch(this.status){
      case 'VIDEOSPENDENTES':
        return 'bg-warning';
      case 'EMANALISE':
        return 'bg-info';
      case 'APROVADO':
        return 'bg-info';
      case 'REPROVADO':
        return 'bg-danger';
      case 'VIDEOSEMPROCESSAMENTO':
        return 'bg-secondary';
    }
    return 'bg-info';
  }

}
export class PlanoCreditoEntity {
  id: number|undefined;
  nome: string|undefined;
  credito_qtd: string|undefined;
  credito_adicional: string|undefined;
  tipo: string|undefined;
  preco?: number;
  recomendado?: number;
}
export class PagamentoCreditoEntity {
  id: number|undefined;
  valor: number|undefined;
  dataPagamento: string|undefined;
  dataCriacao: string|undefined;
  status: string|undefined;
  correlationid?:number;
  payerid?:number;
  token: string|undefined;
  transactionid?:number;
  expressCheckout: string|undefined;
  expressCheckoutDetails: string|undefined;
  recurringPaymentsProfile: string|undefined;
  candidato: CandidatoEntity|undefined;
  empresa: EmpresaEntity|undefined;
  planoCredito: PlanoCreditoEntity|undefined;
  moduloPagamentoId: number|undefined;
}


export class QuestionaryJobEntity {
  public id: number = 0;
  public vagaId: number|null = null;
  public name: string = '';
  public questionaryId: number|null = null;
  public empresaId: number|null = null;
  public recrutadorId: number|null = null;
  public notaCorte: number = 0;
  public tempoMaximo: number = 0;
  public createdAt: DateEntity|null = null;
}

export class FaqEntity {
  id: number  = 0;
  titulo: string = '';
  descricao: string = '';
  lang: string = '';
  tipo: number = 0;
}

export class QuestionaryEntity {
  id: number  = 0;
  name: string = '';
  isAssessment: boolean = false;
  dateCreated: string|DateEntity = '';
  isOcai: boolean = false;
  isEssay: boolean = false;
  questions: QuestionEntity[];
  status: number = 1;

  constructor() {
    this.questions = [];
  }
}
export class QuestionEntity {
  id: number = 0 ;
  questionaryId: number = 0 ;
  questionTypeId: number = 0;
  answers: AnswerEntity[];
  description: string = '';
  points: number = 0 ;
  dateCreated: string|DateEntity = '';
  dimensionId: number = 0;
  status: number = 1;
  images?: { id: number, url: string}[] = [];

  constructor() {
    this.answers = [new AnswerEntity()];
  }
}

export class AnswerEntity {
  id: number = 0;
  description: string = '';
  dateCreated: string|DateEntity = '';
  correct: boolean = false;
  points: number = 0;
  rate: number | null = null;
  status: number = 1;
  images?: { id: number, url: string}[] = [];
}

export class QuestionaryCandidatoEntity {
  public id: number = 0;
  public answered: boolean = false;
  public dateStart: string | DateEntity = '';
  public dateEnd: string | DateEntity = '';
  public candidato: CandidatoEntity | null = null;
  public questionary: QuestionaryEntity | null = null;
  public questionaryAnswerCandidato: QuestionaryAnswerCandidatoEntity[] = [];
  public questionaryId: number | null = null;
  public candidatoId: number | null = null;
  // dictionary of answers where the key is answerId
  public answers: any | null = null;

  constructor() {
    this.answers = {};
  }
}

export class QuestionaryEmpresaEntity {
  id: number|undefined;
  answered: boolean|undefined;
  dateStart: any|undefined;
  dateEnd: any|undefined;
  empresa: EmpresaEntity|undefined;
  questionary: QuestionaryEntity|undefined;
  questionaryAnswerEmpresa: QuestionaryAnswerEmpresaEntity[]|undefined;
  questionaryId: number|undefined;
  empresaId: number|undefined;
  answers: any|undefined;
  seguindo?: boolean;

  constructor() {
    this.answers = { };
  }
}

export class QCAnswerData {
  answerId: number = 0;
  rate: number|null = null;
  questionId:number = 0;
  description: string|null = null;
  
  constructor(answerId:number,rate:number){
    this.answerId = answerId;
    this.rate = rate;
  }
}

export class QuestionaryAnswerCandidatoEntity {
  id: number|undefined;
  rate: number;
  description: string|undefined;
  questionaryCandidato: QuestionaryCandidatoEntity|undefined;
  answer: AnswerEntity|undefined;
  questionaryCandidatoId: number|undefined;
  answerId: number|undefined;

  constructor(){
    this.rate = 0
  }
}

export class QuestionaryAnswerEmpresaEntity {
  id: number|undefined;
  rate: number;
  description: string|undefined;
  questionaryEmpresa: QuestionaryEmpresaEntity|undefined;
  answer: AnswerEntity|undefined;
  questionaryEmpresaId: number|undefined;
  answerId: number|undefined;

  constructor(){
    this.rate = 0
  }
}


// Payments

export class AssinaturaEntity {
  id: number|undefined;
  token: string|undefined;
  priceCents: number|undefined;
  currency: string|undefined;
  active: number|undefined;
  suspended: number|undefined;
  trial: number|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  planoCredito: PlanoCreditoEntity|undefined|null;
  createdAt: any|undefined; // date
  updatedAt: any|undefined|null;
  expiresAt: any|undefined|null;
  constructor(){
    this.planoCredito = new PlanoCreditoEntity()
  }
}

export class MetodoPagamentoEntity {
  id: number|undefined;
  token: string|undefined|null;
  description: string|undefined|null;
  itemType: string|undefined|null;
  holderName: string|undefined|null;
  displayNumber: string|undefined|null;
  brand: string|undefined|null;
  validationMonth: number|undefined|null;
  validationYear: number|undefined|null;
  tipo: number|undefined|null;
  default: number|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  createdAt: any|undefined|null;
}

export class FaturaEntity {
  id: number|undefined;
  token: string|undefined|null;
  currency: string|undefined|null;
  paidTaxesCents: number|undefined|null;
  totalPaidCents: number|undefined|null;
  totalCents: number|undefined|null;
  status: string|undefined|null;
  secureId: string|undefined|null;
  tipo: number|undefined|null;
  invoiceData: string|undefined|null;
  candidato: CandidatoEntity|undefined|null;
  empresa: EmpresaEntity|undefined|null;
  assinaturaPagamento: AssinaturaEntity|undefined|null;
  createdAt: any|undefined|null;
  paidAt: any|undefined|null;
  dataVencimento: any|undefined|null;
  constructor(){
    this.paidAt = {};
    this.empresa = new EmpresaEntity()
    this.dataVencimento = {};
    this.assinaturaPagamento = new AssinaturaEntity()
  }
}

export interface Endereco {
  rua?: string;
  endereco?: string;
  numero?: number;
  complemento?: string;
  bairro?: string;
  nomeCidade?: string;
  nomeEstado?: string;
  cep?: string;
  pais?: string;
}

export class CompaniesFilterEntity {
  nome: string = '';
  setor!: any;
  pais!: any;
  estado!: any;
  cidade!: any;
  cidid!: any;
  ufid!: any;
  localidade!: any;
}
export class HabilidadeEntity {
  id: number|undefined;
  name: string|undefined|null;
  cod: string|undefined|null;
  idioma: IdiomaEntity|undefined|null;
}

export class HabilidadeCandidatoEntity {
  id: number|undefined;
  candidatoId: number|undefined|null;
  habilidadeId: number|undefined|null;
  nivel: number|undefined|null;
  habilidade?: HabilidadeEntity;
}
export class VagasHabilidadesEntity {
  id: number|undefined;
  vagaId: number|undefined|null;
  habilidadeId: number|undefined|null;
  nivel: number = 0;
  habilidade?: HabilidadeEntity;
  applyOnProfile: boolean|null = false
}

export class MessageJobEntity {
  id?: number;
  status: string | undefined;
  assunto: string | undefined;
  mensagem: string | undefined;
  remetente: string | undefined;
  candidatoId: number | undefined;
  empresaId: number | undefined;
  paiId: number | undefined;
  empresa: EmpresaEntity | undefined;
  candidato: CandidatoEntity | undefined;
}

export class VagaPerguntaCorteEntity {
  id: number = 0;
  vaga: VagaEntity|null = null;
  pergunta: string = "";
  eliminatoria: string = "1";
}

export class CandidatoCorteEntity {
  pergunta: number|null;
  resposta: number|null;

  constructor(p: number|null = null, r: number|null = null) {
    this.pergunta = p;
    this.resposta = r;
  }
}
export class RoomEntity {
  id: number = 0;
  label: string = "";
  key: string = "";
  status: string = "";
  purpose: number = 0;
  supplier: number = 0;
  createdAt: string = "";
  createdAtDT!: DateEntity;
  blind: boolean = false;
  owner!: any;
  recordings!: any[];
}
export class RoomParticipantEntity {
  id: number = 0;
  key: string = "";
  coonectionId: string = "";
  approved: boolean = false;
  candidatoId: number|null = null;
  empresaId: number|null = null;
  recrutadorId: number|null = null;
  blind: boolean = false;
  owner: boolean = false;
  recordings!: any[];
}
export interface FacebookUser {
  first_name: string;
  last_name: string;
  email: string;
}

export interface LinkedInUser {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export class InvitedFriend {
  public id?: string = undefined;
  public nome = '';
  public email = '';
}

export class DateEntity {
  date?: string;
  timezone_type?: number;
  timezone?: string;
}


export class PaginaInstitucionalEntity {
  id: number = 0;
  menu: string|null = null;
  titulo: string|null = null;
  corpo: string|null = null;
  habilitado: boolean = true;
  empresa: string|null = null;
  empresaId: number|null = null ;  
}
