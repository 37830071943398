




import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        label: String
    }
})
export default class TextLabel extends Vue {}
