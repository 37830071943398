import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';


export default class BasicScene {
  public scene: THREE.Scene | null = null;
  public width: number = 640;
  public height: number = 480;
  public camera: THREE.PerspectiveCamera | null = null;
  public renderer: THREE.WebGLRenderer | null = null;
  public controls: OrbitControls | null = null;
  public container: HTMLDivElement | null = null;
  public lastTime: number = 0;

  constructor(props) {
    const canvas: HTMLCanvasElement | null = <HTMLCanvasElement>document.getElementById('output_canvas');
    console.log(canvas)
    if (canvas) {
      this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
      this.container = <HTMLDivElement>canvas.parentElement;
    } else {

      this.renderer = new THREE.WebGLRenderer();
      this.container = props.container;

      if(this.container?.children.length) {
        while (this.container.firstChild) {
          this.container.removeChild(this.container.firstChild);
        }
      }

      console.log('appending', this.renderer.domElement)
      this.container?.appendChild(this.renderer.domElement);
    }


    // Set up threejs
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(
      75,
      this.width / this.height,
      0.1,
      1000
    );

    // const environment = new RoomEnvironment();
    // const pmremGenerator = new THREE.PMREMGenerator(this.renderer);
    // this.scene.environment = pmremGenerator.fromScene(environment, 0.04).texture;
    this.camera.position.z = 5;
    
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = 1;
    
    // Create the texture loader
    const loader = new THREE.TextureLoader();
    const bg_image: string = location.protocol + "//" + location.host + "/static/img/back.jpg";
    const texture_custom: string = location.protocol + "//" + location.host + "/static/textures/sunset.exr";

    // Load the texture
    loader.load(bg_image, (texture) => {
        // Once the texture is loaded, assign it to scene.background
        if(this.scene)
          this.scene.background = texture;
    });
    
    const loader2 = new EXRLoader();

// Load the texture
    loader2.load(texture_custom, (texture) => {
      // Once the texture is loaded, assign it to scene.background
      if(this.scene) {
        
        texture.mapping = THREE.EquirectangularReflectionMapping;
        texture.rotation = 80;
        
        this.scene.environment = texture; //pmremGenerator.fromEquirectangular(texture).texture;
        //this.scene.background = texture;
      }
        
    });

    const ambientLight = new THREE.AmbientLight( 0xffffff, 0.4 );
    this.scene.add( ambientLight );
    
    const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 );
    this.scene.add( directionalLight );

    const pointLight = new THREE.PointLight( 0xffffff, 0.1, 500 );
    this.camera.add( pointLight );
    this.scene.add( this.camera );
    this.scene.rotation.set(0,1.6,0);



    this.container?.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  public getContext(): WebGL2RenderingContext | WebGLRenderingContext | null {
    if (this.renderer)
      return this.renderer.getContext();

    return null;
  }

  public dispose() {
    this.scene?.traverse((object: any) => {
      if (!object.isMesh) return;

      object.geometry.dispose();

      if (object.material.isMaterial) {
        this.cleanMaterial(object.material);
      } else {
        for (const material of object.material) this.cleanMaterial(material);
      }
    });
    if (this.container) {
      this.container.removeChild(this.container.firstChild!);
      const canvas = document.createElement('canvas')
      canvas.id = "output_canvas";
      canvas.width = 800;
      canvas.height = 600;
      this.container.prepend(canvas);
    }

    //this.renderer?.forceContextLoss();
  }
  public cleanMaterial(material: any) {
    material.dispose();

    // Dispose textures
    for (const key of Object.keys(material)) {
      const value = material[key];
      if (value && typeof value === 'object' && 'minFilter' in value) {
        value.dispose();
      }
    }
  }

  public resize() {
    console.log('resize')
    if (!(this.camera && this.renderer && this.scene && this.container)) return;

  
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.width, this.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  }

  public render() {
    if (!(this.renderer && this.scene && this.camera)) return;

    this.renderer.render(this.scene, this.camera);
  }
}
