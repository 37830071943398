



















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FormDiversidade from '@/components/form/diversity/FormDiversidade.vue';


@Component({
  components: {
    FormDiversidade
  }
})
export default class SidebarDiversidade extends Vue {

  @Prop({default: null})
  public id!: string;
  
  @Prop({default: false})
  public value!: boolean;
  
  public saved() {
    this.$emit('done')
  }

  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }

  /**
   * done
   */
  public done() {
    //do somenthing when done successfuly
    this.value = false;
    this.hidden();
    this.$emit('saved')
  }

  /**
   * error
   */
  public error() {
    //do somenthing when presents error
    this.value = false;
    this.hidden();
    this.$emit('error')
  }
}
