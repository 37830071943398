































import Vue from "vue";
import Component from "vue-class-component";
import CompaniesHeaderNavigation from '@/components/perfil/header-navigation/CompaniesHeaderNavigation.vue';
import CompaniesFilter from "@/components/filter/CompaniesFilter.vue";
import CardCompany from '@/components/cards/CardCompany.vue';
import EmpresaService from "@/core/Services/Empresa/Empresa";
import { Watch } from 'vue-property-decorator';

@Component<Companies>({
  components: {
    CompaniesFilter,
    CompaniesHeaderNavigation,
    CardCompany,
  },
})
export default class Companies extends Vue {
  public empresaService: EmpresaService;
  public empresasList: any[];
  public limit!: number;
  public companyId!: number;
  public currentPage: number = 1;
  public companyUrl!: string;
  public search: string = '';
  public loaded: boolean = false;
  public page: number = 0;
  public loading: boolean = true;
  public totalCount: number = 0;
  public setor!: any;
  public pais!: any;
  public estado!: any;
  public cidade!: any;
  public ufid!: number;
  public cidid!: number;
  public localidade!: string;
  public showFilter: boolean = false;
  private hasMore = true;

  constructor() {
    super();
    this.empresaService = new EmpresaService();
    this.empresasList = []
  }

  @Watch('$route.query.s')
  globalSearchChanged(value: string) {
    this.handleFilter({nome: value});
  }

  public showMore()
  {
    this.filterResults(++this.currentPage);
  }

  public handleFilter(filter:any) {
    this.page = 1;
    this.setor = filter.setor
    this.search = filter.nome
    this.cidade = filter.cidade
    this.estado = filter.estado
    this.pais = filter.pais
    this.cidid = filter.cidid
    this.ufid = filter.ufid
    this.empresasList = []
    this.filterResults()

  }

  public filterResults(page?: number) {
    this.loading = true;
    this.page = page ? page : this.page;
    let u = this.companyUrl ? this.companyUrl : '';
    let i = this.companyId ? this.companyId : '';
    let l = this.limit ? this.limit : 8;
    let s = this.search;
    let setor = this.setor ? this.setor : '';
    let pais = this.pais ? this.pais : '';
    let uf = this.estado ? this.estado : '';
    let ufid = this.ufid ? this.ufid : '';
    let cidid = this.cidid ? this.cidid : '';
    let cidade = this.cidade ? this.cidade : '';
    this.empresaService.fetchAll({
      page: this.page,
      limit: l,
      s: s,
      eid: i,
      curl: u,
      setor: setor,
      pais: pais,
      estado: uf,
      cidade: cidade,
      cidid: cidid,
      ufid: ufid
    })
    .then((data: any) => {
      if(data){
        this.empresasList.push(...data["empresas"]);
        this.totalCount = parseInt(data["size"]) - 10;
      }
      this.loading = false;
      if(!data["empresas"].length){
          this.hasMore = false;
      }
    }).catch((err: any) => {
      /* console.log(err) */
      this.loading = false;
    })
  }

  public mounted(){
    this.filterResults();
    (this.$refs.filter as CompaniesFilter).loadData();
    /* console.log((this.$refs.filter as CompaniesFilter).filterObject) */
  }

  public handleNavigation(pos: string) {
    /* console.log(pos) */
  }

  public handleShowFilter() {
    this.showFilter = !this.showFilter
  }
}
