import Repository from '@/core/Services/Repository';
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';


export default class NivelEscolaridade extends Repository {
    public optionList?: any[];

    constructor() {
        super(
            new ApiRouter({
                create: { url: '/v1/nivelescolaridade', method: 'POST', headers: {} },
                fetch: { url: '/v1/nivelescolaridade/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/nivelescolaridade', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/nivelescolaridade/{id}', method: 'PUT', headers: {} },
            }));
        this.optionList = [];
    }

    public async loadOptions() {
        //const data: any = await this.fetchAll(); 
        const niveisEscolaridade: any[] =  Object.keys(this.getI18n().messages.en.niveisEscolaridade)
        if (niveisEscolaridade.length > 0) {
            this.optionList = [
                {
                    text: '',
                    value: ''
                }
            ];
            niveisEscolaridade.forEach((id: any) => {
                this.optionList!.push({
                    text: this.getI18n().t('niveisEscolaridade.' + id),
                    value: id,
                });
            });
        }
    }
}
