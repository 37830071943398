


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AssessmentDescription extends Vue {
  public more: boolean = false;
}
