








import Vue from 'vue';
import Component from 'vue-class-component';
import { Getter } from 'vuex-class';

@Component
export default class Content extends Vue {
  @Getter("isEntrevistaPage", { namespace: 'profile' }) private isEntrevistaPage!: boolean;

  private get containerCustomClass() {
    return "custom-class_"+(this.$route.name||"")
  }
  private get containerClasses() {
    return [
      'content',
      // {
      //   'no-header-height': this.isEntrevistaPage
      // }
    ]
  }
}
