










import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ScriptAsyncLoad } from "@/core/util";
import PortugueseTerms from '@/views/terms/PortugueseTerms.vue';
import SpanishTerms from '@/views/terms/SpanishTerms.vue';
import EnglishTerms from '@/views/terms/EnglishTerms.vue';
const namespace: string = "profile";

@Component<TermsResponsability>({
	components: {
    'portuguese': PortugueseTerms,
    'spanish': SpanishTerms,
    'english': EnglishTerms
	}
})
export default class TermsResponsability extends Vue {

}
