















import VolumeBar from '@/components/audio/VolumeBar.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    VolumeBar
  }
})
export default class VolumeTracker extends Vue {
  @Prop({default: -100})
  private volume!: number;
  private maxParsedVolume: number = 100;

  @Prop({default: 11})
  private qtBars!: number;

  private get parsedVolume(): number {
    return 100 - -this.volume!;
  }

  private get barsPerColor(): number {
    return Math.floor(this.qtBars / this.colors.length);
  }

  private get colors(): string[] {
    return [
      '#006FF3',
      '#17A2B8',
      '#00AF69',
    ];
  }

  private get bars(): any[] {
    const bars: any[] = [];
    const colors = this.colors;
    let i = 0, colorIndex;

    const volumeInfo = {
      avaibleVolume: this.parsedVolume,
      maxVolumePerBar: this.maxParsedVolume / this.qtBars
    };

    for (colorIndex = 0; colorIndex < colors.length; colorIndex++) {
      for (i = (colorIndex) * this.barsPerColor; i < (colorIndex + 1) * this.barsPerColor; i++) {
        bars.push({
          color: colors[colorIndex],
          width: this.getBarWidth(volumeInfo)
        });
      }
    }

    // Adicionar barras que sobraram, se houver alguma
    for (i=i; i < this.qtBars; i++) {
      bars.push({
        color: colors[colorIndex-1],
        width: this.getBarWidth(volumeInfo)
      });
    }

    return bars.reverse();
  }

  private getBarWidth(volumeInfo: {avaibleVolume: number, maxVolumePerBar: number}): number {
    let volumeForBar = 0;

    if  (volumeInfo.avaibleVolume >= volumeInfo.maxVolumePerBar) {
      volumeForBar = volumeInfo.maxVolumePerBar;
      volumeInfo.avaibleVolume -= volumeInfo.maxVolumePerBar;
    }
    else {
      volumeForBar = volumeInfo.avaibleVolume;
      volumeInfo.avaibleVolume = 0;
    }

    return 100 * (volumeForBar / volumeInfo.maxVolumePerBar);
  }
}
