import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import PublicRepository from '@/core/Services/PublicRepository';


export default class Idioma extends PublicRepository {
    public id!: any;
    public nivel!: any;
    public optionList!: any[];
    public optionFullList!: any[];
    public optionNivelList!: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/idioma', method: 'POST', headers: {} },
                fetch: { url: '/v1/idioma/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/idioma', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/idioma/{id}', method: 'PUT', headers: {} }}));
        this.optionList = [];
        this.optionFullList = [];
    }

    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.idiomas) {
            this.optionList = [];
            data.idiomas.forEach((item: any) => {
                if (['en', 'es', 'pt-BR'].indexOf(item.idioma) > -1) {
                    this.optionList!.push({
                        text: item.legenda,
                        value: item.id,
                        locale: item.idioma
                    });
                }
                this.optionFullList!.push({
                    text: item.legenda,
                    value: item.id,
                });
            });
            this.optionNivelList = [
                { text: this.getI18n().t('Select'), value: null },
                { text: this.getI18n().t('Basic'), value: 'BASICO' },
                { text: this.getI18n().t('Intermediate'), value: 'INTERMEDIARIO' },
                { text: this.getI18n().t('Advanced'), value: 'AVANCADO' },
                { text: this.getI18n().t('Fluent'), value: 'FLUENTE' },
                { text: this.getI18n().t('Native'), value: 'NATIVO' },
            ];
        }
    }
}


