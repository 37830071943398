















import Vue from "vue";
import Component from "vue-class-component";
import VagasFilter from "@/components/filter/VagasFilter.vue";
import CardJob from "@/components/cards/CardJob.vue";
import { VagaEntity } from "@/core/models/Entities";
import VagaService from "@/core/Services/Vaga/Vaga";
import { baseEndPoint, clientId } from "@/core/endpoint";
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
import VagasSearchMixin from './vagas/VagasSearchMixin';
import { Getter } from 'vuex-class';

@Component({
  components: {
    "vagas-filter": VagasFilter,
    "vagas-job": CardJob,
    JobsHeaderNavigation
  },
  props: {
    limit: Number,
    search: String,
    companyId: Number,
    companyUrl: String,
    mini: Boolean
  },
})
export default class RootVagas extends Vue {
  public vagaService: VagaService = new VagaService();
  public vagasList: VagaEntity[] = [];
  public limit!: number;
  public companyId!: number;
  public companyUrl!: string;
  public mini!: boolean;
  public search!: string;
  public loaded: boolean = false;
  public page: number = 0;
  public currentPage: number = 1;
  public loading: boolean = true;
  public totalCount: number = 0;
  public keywords: string = "";
  public promiseFilter: Promise<any> | null = null;

  @Getter('showVagasFilter', {namespace: 'ui'})
  private showVagasFilter!: boolean;

  public handleFilter(filter:any) {

    this.promiseFilter = new Promise (async (resolve) => {
      const vagasComp = this.$refs.vagas as VagasSearchMixin;

      vagasComp.clearSearchVar();
      vagasComp.handleFilterVagas(filter);
      resolve(true);
    })
  }

  public vagaPage(vaga: any) {
    this.$router.push({
      name: "DetalheVagaCandidato",
      params: { urlcode: vaga.nomeUrl },
      query: { lang: this.$i18n.locale }
    });
  }

  public mounted() {
    (this.$refs.filter as VagasFilter).loadData();
  }

}
