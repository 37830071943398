import _Vue from 'vue';
import Toast from '@/components/alerts/Toast.vue';


export class ToastPluginOptions {
  // add stuff
}
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function ToastPlugin(Vue: typeof _Vue, options?: ToastPluginOptions): void {
    // do stuff with options
    Vue.prototype.$toast = new Toast();
}