
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import { CB } from '@/core/util';


export default class Facebook extends Repository {
    constructor() {
        super(new ApiRouter({
            // fetch: { url: '/v1/facebook/0', method: 'GET', headers: {} },
            // fetchAll: { url: '/v1/facebook', method: 'GET', headers: {} },
            create: { url: '/v1/social-login', method: 'POST', headers: {} },
        }));
    }

}


