



































import Vue from "vue";
import Component from "vue-class-component";
import CompaniesHeaderNavigation from '@/components/perfil/header-navigation/CompaniesHeaderNavigation.vue';
import CompaniesFilter from "@/components/filter/CompaniesFilter.vue";
import CardCompany from '@/components/cards/CardCompany.vue';
import EmpresaService from "@/core/Services/Empresa/Empresa";
import SeguirEmpresaService from "@/core/Services/Basic/SeguirEmpresa";
import { Watch } from 'vue-property-decorator';

@Component<Companies>({
  components: {
    CompaniesFilter,
    CompaniesHeaderNavigation,
    CardCompany,
  },
})
export default class Companies extends Vue {
  public empresaService: EmpresaService;
  public seguirEmpresaSRV: SeguirEmpresaService;
  public empresasList: any[];
  public limit!: number;
  public companyId!: number;
  public currentPage: number = 1;
  public companyUrl!: string;
  public search!: string;
  public loaded: boolean = false;
  public page: number = 0;
  public loading: boolean = true;
  public totalCount: number = 0;
  public keywords: string = "";
  private hasMore = true;

  constructor() {
    super();
    this.empresaService = new EmpresaService();
    this.seguirEmpresaSRV = new SeguirEmpresaService();
    this.empresasList = [];
  }

  public created() {
    this.filterResults();
  }

  @Watch('$route.query.s')
  globalSearchChanged(value: string) {
    this.search = value;
    this.handleFilter();
  }

  public showMore()
  {
    this.filterResults(++this.currentPage);
  }

  public handleFilter() {
    this.empresasList = [];
    this.filterResults();
  }

  public filterResults(page?: number) {
    this.loading = true;
    this.page = page ? page : this.page;
    let u = this.companyUrl ? this.companyUrl : '';
    let i = this.companyId ? this.companyId : '';
    let l = this.limit ? this.limit : 8;
    let s = this.search ? this.search : this.keywords;
    this.seguirEmpresaSRV.fetchAll({
      page: this.page,
      limit: l,
      s: s,
      eid: i,
      curl: u
    })
    .then((data: any) => {
      if(data) {
        this.empresasList.push(...data["seguindo"]);
        this.totalCount = parseInt(data["size"]);
      }
      this.loading = false;
      if(!data["seguindo"].length) {
          this.hasMore = false;
      }
    }).catch((err: any) => {
      /* console.log(err) */
      this.loading = false;
    })
  }
}
