

















import { Component, Prop, Vue } from 'vue-property-decorator';


@Component
export default class TextareaInput extends Vue {
    
    @Prop()
    public value!: any;
    
    @Prop({default: false})
    public required!: boolean;
    
    @Prop({default: false})
    public disabled!: boolean;
    
    @Prop({default: null})
    public state!: boolean|null;
    
    @Prop({default: ''})
    public placeholder!: string;

    @Prop({default: ''})
    public label!: string;

    public scopeID: string;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '_' + Math.ceil((Math.random()*100));
    }

    public handleInput(v: any) {
        this.$emit('input', v);
    }
}
