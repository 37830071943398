




















// tslint:disable
// require("./static/css/layouts/page-center.css")
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import Candidato from "@/core/Services/Candidato/Candidato";
import { baseEndPoint, clientId, siteLandingEndPoint } from "@/core/endpoint";
const namespace: string = "profile";


@Component
export default class ThirdPartyLoginSuccess extends Vue {

  @Action("userRequest", { namespace }) public  userRequest: any;

  public contentText: string = "";
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
  public candidato!: Candidato;
  public showButton: boolean = false;
  public landingURL: string = siteLandingEndPoint;

  public closeWindow() {
    window.close()
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }

  public created() {
    if (this.hasLinkedInData) {
      this.contentText = this.$t("Access released – Welcome to Jobecam") as string;
      setTimeout( () => { this.closeWindow() },1000)
    } else {
      this.removeItensFromStorage();
      this.contentText = this.$t("There was a problem") as string;
      this.showButton = true;
    }

    window.addEventListener('message', this.receiver, false);
  }

  public receiver(event) {
   if (event.origin == this.landingURL) {
    /* console.log(event.origin) */
    if(this.rawUserData) {
      event.source.postMessage('__$='+this.rawUserData, event.origin);
    } else {
      event.source.postMessage('__;='+this.accessToken, event.origin);
    }
   }
  }

  private get accessToken() {
    return this.$route.query.accessToken;
  }
  private get rawUserData() {
    return this.$route.query.p;
  }

  private get hasLinkedInData(): boolean {
    return (!!this.accessToken && typeof(this.accessToken) === 'string') || (!!this.rawUserData && typeof(this.rawUserData) === 'string');
  }

}
