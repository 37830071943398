













import Vue from 'vue';
import Component from 'vue-class-component';
import { LinkedInUser } from '@/core/models/Entities';
import { baseEndPoint, clientId } from "@/core/endpoint";
import LNService from "@/core/Services/ThirdParty/LinkedIn";

@Component
export default class LinkedinRegister extends Vue {
  private lnService: LNService = new LNService();

  public uri: string =
    window.document!.location!.protocol +
    "//" +
    window.document!.location!.host;

  private get popUpUrl() {
    let apiUrl = baseEndPoint + this.lnService.endPointAPI.fetchAll.url;
    let vagaURL = this.$route.query.vagaURL;
    let organica = this.$route.query.organica;
    return apiUrl +
      "?authurl=" +
      this.uri +
      "/3party&clientId=" +
      clientId +
      "&vagaURL=" +
      vagaURL +
      "&organica=" +
      organica +
    "&tp=ln";
  }

  public linkedinRegisterIn() {
    const popUpWindow = window.open(
      this.popUpUrl,
      "Linkedin login - Oauth Jobecam",
      "width=795, height=590, top=100, left=699, scrollbars=no, status=no, toolbar=no, location=no, menubar=no, resizable=no, fullscreen=no"
    );
    const intervalId = setInterval(async () => {
      if (this.popupIsClosed(popUpWindow)) {
        clearInterval(intervalId);
        this.$emit('popupClosed');
      }

      let url = '';

      try {
        url = popUpWindow ? popUpWindow!.location.href : '';
      }
      catch (e) {
      }

      if (!url) {
        if (this.popupIsClosed(popUpWindow)) {
          clearInterval(intervalId);
        }
        return;
      }

      const token = this.getParameterByName('accessToken', url);
      const userData = this.getParameterByName('p', url);

      if (!token && !userData) {
        return;
      }

      if (token) {
        // await this.registerUser(token);
        this.$emit('linkedinRegisterIn', token);
      }
      else {
        const user: LinkedInUser = JSON.parse(atob(decodeURIComponent(userData!)));

        this.$emit('linkedinRegisterIn', user);
      }
    }, 10);
  }

  private popupIsClosed(popUpWindow: Window|null) {
    return !popUpWindow || popUpWindow.closed;
  }

  private getParameterByName(param: string, url?: string) {
    if (!url)
      url = window.location.href;

    param = param.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + param + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);

    if (!results)
      return null;

    if (!results[2])
      return "";

    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  public mounted() {
    if ((this.uri.indexOf('talentos.jobecam.com') > -1) && process.env.NODE_ENV == 'production') {
      location.href = "https://candidato.jobecam.com" + this.$router.currentRoute.fullPath;
    }
  }
}
