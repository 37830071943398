


































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';

@Component
export default class DadosPessoaisView extends Vue {
  @Prop({ default: null })
  candidato!: CandidatoEntity;

  // showButtons is for to show or to hide video on the card
  @Prop({ default: true })
  public displayVideo!: boolean;

  @Prop({ default: false })
  public isPublic!: boolean;

  @Prop({ default: false })
  public ispdf!: boolean;

  public pcdArrayDescritivo: any[] = [];
  public pcdDetails: any = {};

  get nomeCompleto() {
    return (this.candidato?.primeironome||'')+' '+(this.candidato?.sobrenome||'')
  }

  get localidade() {
    let loc = `${this.candidato?.perfil?.localidade}`;
    if(this.candidato?.perfil?.nomeCidade)
      loc = `${this.candidato?.perfil?.nomeCidade}/${this.candidato?.perfil?.nomeEstado}`;

    return loc;
  }

  public randomImg() {
    const n: number = (Math.floor((Math.random() * 28))+1);
    const ext: string = (n >= 1 && n <= 9) ? 'jpg' : 'png';
    return `https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/blind/poster${n}.${ext}`
  }

  get endereco(): string {
    return this.$options.filters!.formatEndereco(this.candidato.perfil);
  }

}
