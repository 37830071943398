



























































import { Vue, Component, Prop } from 'vue-property-decorator';
import ButtonIcon from "@/components/ButtonIconRounded.vue"
import UserModel from '@/core/recording/UserModel';

@Component<Chat>({
  components: {
    ButtonIcon
  }
})
export default class Chat extends Vue {
  public chatInputValue: string|null = ''
  public options: any;
  public wasUpdated: boolean = false;
  
  @Prop({default: () => {new UserModel() }})
  private user!: UserModel;

  @Prop({default: []})
  private messageList!: any[];


  get messages() {
    return this.messageList;
  }
  
  public messageReceived() {
    this.$emit('messageReceived')
  }

  public scrollToBottom() {
    setTimeout(() => {
      try {
        const x: HTMLDivElement = (this.$refs.chatScroll as HTMLDivElement);
        x.scrollTop = x.scrollHeight;
      } catch (err) {}
    }, 20);
  }

  public IsRecruiter(){
    return sessionStorage.getItem("profile_type") == 'recruiter'
  }

  get primeiroNome() {
    return "nameof-1";
  }

  public closeChat() {
    this.$emit('close')
  }

  public sendMessage() {
    if (this.user && this.chatInputValue?.trim()) {
      let message = this.chatInputValue.replace(/ +(?= )/g, '');
      if (message !== '' && message !== ' ') {
        const data = { 
          message: message, 
          nickname: this.user.nickname, 
          streamId: this.user.getStreamManager().stream.streamId 
        };
        this.user.getStreamManager().stream.session.signal({
            data: JSON.stringify(data),
            type: 'chat',
        });
        this.scrollToBottom();
      }
    } else {
      /* console.log('user or message not defined') */
    }
    // this.setState({ message: '' });
    this.chatInputValue = null
  }

  public handlePressKey(event) {
    if (event.key === 'Enter') {
        this.sendMessage();
    }
  }
}
