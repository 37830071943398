

















































































import Vue from "vue";
import Component from "vue-class-component";
import PaisService from "@/core/Services/Pais";
import EstadoService from "@/core/Services/Estado";
import CidadeService from "@/core/Services/Cidade";
import { CompaniesFilterEntity } from '@/core/models/Entities';
import SetorService from '@/core/Services/Basic/SetorIndustria';

@Component
export default class CompaniesFilter extends Vue {
  public paisSRV!: PaisService;
  public setorSRV!: SetorService;
  public pais: string = '';
  public estadoSRV!: EstadoService;
  public cidadeSRV!: CidadeService;
  public showLocalidadeText: boolean = true;
  public state: boolean = true;
  public estadoId: any = '';
  public cidadeId: any = '';
  public localidade: string = '';
  public filterObject: CompaniesFilterEntity

  constructor() {
    super();
    this.paisSRV = new PaisService();
    this.setorSRV = new SetorService();
    this.estadoSRV = new EstadoService();
    this.cidadeSRV = new CidadeService();
    this.filterObject = new CompaniesFilterEntity()
  }

  public async chainToCity(value?: any) {
    await this.cidadeSRV.loadOptions(
      this.filterObject.ufid
    );
    this.filterObject.cidade = '';
    this.filterObject.estado = '';
    /* if (value) {
      this.filterObject.cidade = this.cidadeSRV.cidadeByName(
        value
      );
    } */
  }

  public loadData(value?: any) {
    /* console.log('aui no load do filtro') */
    this.paisSRV.loadOptions();
    this.setorSRV.loadOptions();
    this.loadLocation();

  }

  public loadLocation() {
    this.checkCountry();
    this.estadoSRV.loadOptions(0);
    this.cidadeSRV.loadOptions(this.filterObject.ufid);
  }

  public checkCountry() {
    if (this.filterObject.pais === "BR") {
      this.showLocalidadeText = false;
    } else {
      this.showLocalidadeText = true;
    }

    this.filterObject.estado = '';
    this.filterObject.cidade = '';

    this.filterObject.cidid = undefined;
    this.filterObject.ufid = undefined;
  }

  public filter() {
    this.$emit('filterCompanies', this.filterObject)
  }

  get invalidFeedback() {
    return ''
  }

  get validFeedback(){
    return '';
  }
}
