








import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import FormDiversidade from '@/components/form/diversity/FormDiversidade.vue';



@Component({
  components: {
    FormDiversidade,
  }
})
export default class ModalDiversidade extends Vue {

  @Prop({default: null})
  public id!: string;
  
  @Prop({default: false})
  public show!: boolean;
  
  public saved() {
    this.$emit('done')
  }

  public hidden(){
    this.$bvModal.hide(this.id);
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }

  /**
   * done
   */
  public done() {
    //do somenthing when done successfuly
    this.hidden();
    this.$emit('saved')
  }

  /**
   * error
   */
  public error() {
    //do somenthing when presents error
    this.hidden();
    this.$emit('error')
  }

}
