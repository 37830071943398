

































import { Component, Prop, Vue } from 'vue-property-decorator';
import Datepicker from 'vuejs-datepicker';
import { en, ptBR } from 'vuejs-datepicker/dist/locale';

@Component<DateInput>({
    components: {
        Datepicker
    },
    props: {
        value: Date,
        required: Boolean,
        disabled: Boolean,
        placeholder: String,
        label: String,
        type: String,
        dateFormat: {type: String, default: 'dd/MM/yyyy'},
        minimumView: String,
        maximumView: String,
        initialView: String,
    },
})
export default class DateInput extends Vue {
    public scopeID: string;
  
    @Prop()
    public value!: any;
    
    @Prop({default: false})
    public required!: boolean;
    
    @Prop({default: false})
    public disabled!: boolean;
    
    @Prop({default: ''})
    public placeholder!: string;
    
    @Prop({default: ''})
    public label!: string;

    
    @Prop({default: 'text'})
    public type!: string;
    
    @Prop({default: 255})
    public maxlength!: number;
    
    @Prop({default: ''})
    public name!: string;
    
    @Prop()
    public dateFormat!: string;
    
    @Prop()
    public minimumView!: string;
    
    @Prop()
    public maximumView!: string;
    
    @Prop()
    public initialView!: string;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }
    
    public handleInput(v: any) {
        this.$emit('input', v);
        this.$emit('change', v);
    }

    public handleBlur(v: any) {
        this.$emit('input', v);
        this.$emit('blur', v);
    }

    public handleFocus(v: any) {
        (this.$refs.datepicker as any).showCalendar();
    }


    public mounted() {
        const ele: any = document.querySelector('#input-group-' + this.scopeID + ' input')!
        ele.classList.add('form-control');
        // ele.removeAttribute('readOnly')
        
    }

    get getDateLang(){
        if (this.$i18n.locale == 'pt-BR')
            return ptBR;
        if (this.$i18n.locale == 'es')
            return en;
        return en
    }
}
