import _Vue from 'vue';
import moment, { Moment } from 'moment';
import { DateEntity } from '@/core/models/Entities';


export class MomentPluginOptions {
  // add stuff
}
// export type PluginFunction<T> = (Vue: typeof _Vue, options?: T) => void;
export default function MomentPlugin(Vue: typeof _Vue, options?: MomentPluginOptions): void {
    // do stuff with options
    Vue.prototype.$moment = moment;
    Vue.prototype.$showDateFromObject = (data?: DateEntity) => {
      moment.locale('pt-BR');
      if (data && data.date) {
        return moment.utc(data.date + '').local().format('DD/MM/YYYY HH:mm');
      }
      return '';
    };
    Vue.prototype.$showDateFromObjectAdd = (data: DateEntity, n: number, s: any) => {
      moment.locale('pt-BR');
      if (data && data.date) {
        return moment.utc(data.date + '').add(n,s).local().format('DD/MM/YYYY HH:mm');
      }
      return '';
    };
    Vue.prototype.$diffInMinutes = (data: DateEntity, n: number) => {
      moment.locale('pt-BR');
      let a: Moment = moment.utc(data.date + '').add(n,'minutes').local();
      let now: Date = new Date();
      return a.diff((new Date), 'minutes', true);
      
    };
    Vue.prototype.$showDate = (data?: string) => {
      moment.locale('pt-BR');
      return moment.utc(data).local().format('DD/MM/YYYY HH:mm');
    };
    Vue.prototype.$showDateResponse = (data?: string) => {
      moment.locale('pt-BR');
      return moment.utc(data).local().format('YYYY-MM-DD HH:mm');
    };
    Vue.prototype.$dateUTCDB = (data: Date, hour?: string) => {
      moment.locale('pt-BR');
      let t: number = 0;
      let i: number = 0;
      if (hour && hour.indexOf(':') > 0) {
        t = parseInt(hour.split(':')[0], 10);
        i = parseInt(hour.split(':')[1], 10);
      }
      data.setHours(t);
      data.setMinutes(i);
      const y: number = data.getUTCFullYear();
      const mo: number = data.getUTCMonth();
      const d: number = data.getUTCDate();
      const h: number = data.getUTCHours();
      const m: number = data.getUTCMinutes();
      return moment(new Date(y, mo, d, h, m, 0)).format('YYYY-MM-DD HH:mm');
    };
}
