





























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { CandidatoEntity } from "@/core/models/Entities";
import FormacaoService from "@/core/Services/CandidatoFormacao";
import { FormacaoEntity } from "@/core/models/Entities";
import Section from "@/components/section/Section.vue";

@Component<CardPdfAcademicInfo>({
  components: {
    "section-card": Section
  }
})
export default class CardPdfAcademicInfo extends Vue {
  public formSRV!: FormacaoService;
  public formacoes: FormacaoEntity[] = [];
  public items: any[] = [];
  public fields: any[] = [];

  constructor() {
    super();
    this.formSRV = new FormacaoService();
  }

  // public created() {
  //   this.fields = [
  //     { key: "atividade" },
  //     { key: "nome" },
  //     { key: "dataEntrada" },
  //     { key: "dataSaida" },
  //     { key: "localidade" },
  //   ];
  // }

  public mounted(){
    this.fields = [
      { key: "nome", label: this.$t('Name') },
      { key: "atividade", label: this.$t('Activity')},
      { key: "dataEntrada", label: this.$t('Start Date') },
      { key: "dataSaida", label: this.$t('End Date') },
      { key: "localidade", label: this.$t('Location') },
    ];
  }  

  public loadData(value?: any) {
    this.formacoes = value;
    this.formacoes.forEach((el: any) => {
      this.items.push({
        nome: el.nome,
        atividade: el.atividade,
        dataEntrada: el.dataEntrada,
        dataSaida: el.dataSaida,
        localidade: el.localidade,
        opcoes: el.id,
        descricaoAtividade: el.descricaoAtividade,
        _showDetails: true
      });
    });
  }

}
