


















import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class SearchInput extends Vue {
  private search = '';
  private tagText = '';

  get currRouteName(): string {
    return this.$route.name!;
  }

  public created() {
    this.setTag();

    this.search = <string> this.$route.query.s || '';
  }

  public setTag() {
    const paths = {
      Vagas: 'Jobs',
      VagasSalvas: 'Saved Jobs',
      Candidaturas: 'Applications',
      Companies: 'Companies',
      FavoriteCompanies: 'Favorite companies',
    };

    for (let prop in paths) {
      if (!paths.hasOwnProperty(prop))
        continue;

      if (this.currRouteName == prop) {
        this.tagText = (<any> paths)[prop];
      }
    }
  }

  public doSearch() {
    if (this.search === this.$route.query.s) {
      return;
    }

    const pathsNames = [
      'Companies',
      'FavoriteCompanies',
      'VagasSalvas',
      'Candidaturas'
    ];
    let routeName: string;

    if (pathsNames.includes(this.currRouteName)) {
      routeName = this.currRouteName;
    }
    else {
      routeName = 'Vagas';
    }

    this.$router.push({
      name: routeName,
      query: {
        lang: this.$i18n.locale,
        s: this.search
      }
    });
  }
}
