




import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import videojs from 'video.js';

@Component
export default class VideoPlayer extends Vue {
  public player: any = {};

  @Prop()
  public options!: Object;

  @Watch('options')
  optionsChanged(options: any, oldValue: string) {
    if (this.player) {
      this.player.src(options.sources[0]);
      this.player.poster(options.poster);
    }
  }

  public mounted(): void {
    this.player = videojs((this.$refs["videoPlayer"] as any), this.options);
  }

  public beforeDestroy(): void {
    if (this.player) {
      this.player.dispose()
    }
  }
}
