





















// tslint:disable
// require("./static/css/layouts/page-center.css")
import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";
import NavBar from "@/views/layouts/NavBar.vue";
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import LoginForm from "@/components/login/LoginForm.vue";


@Component({
  components: {
    "nav-bar": NavBar,
    LoginForm
  }
})
export default class RootAuth extends Vue {

  public gestaoCandidatoSRV: GestaoCandidato = new GestaoCandidato();
  

  public toggleBodyClass(addRemoveClass: any, className: any) {
    const el = document.body;

    if (addRemoveClass === "addClass") {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  }

  public mounted() {
    let token = this.$route.query.acctkn;
    if (token) {
      let data: any = {
        id: 0,
        active: true,
        token: token
      };
      this.gestaoCandidatoSRV
        .update(data)
        .then((data: any) => {
          if (data) {
            try {
              this.$toast.variant = "success";
              this.$toast.addMsg(this.$t("Your account has been successfully activated!").toString());
              this.$toast.addMsg(this.$t("Access the platform with your username and password!").toString());
              this.$toast.open();
            } catch (e) {
              /* console.log(e) */
            }
          }
        })
        .catch((resp: any) => {
          try {
            this.$toast.variant = "danger";
            this.$toast.addMsg(this.$t("There was a problem").toString());

            if(resp.detail)
              this.$toast.addMsg(resp.detail);

            this.$toast.open();
          } catch (e) {
            /* console.log(e) */
          }
        });
    }

    this.toggleBodyClass("addClass", "login");
  }

  public destroyed() {
    this.toggleBodyClass("removeClass", "login");
  }
}
