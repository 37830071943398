














































// List Company evaluations
import { Vue, Component, Prop } from 'vue-property-decorator';
import FormAnswer from './FormAnswer.vue';
import { QuestionEntity, AnswerEntity, QuestionaryCandidatoEntity, QCAnswerData } from '@/core/models/Entities';
import Editor from "@tinymce/tinymce-vue";

@Component({
  components: {
    Editor
  }
})
export default class FormQuestion extends Vue {
  
  @Prop({default: () => {new QuestionEntity()}})
  public question!: QuestionEntity;

  @Prop({default: () => {new QuestionaryCandidatoEntity()}})
  public value!: QuestionaryCandidatoEntity;
    
  @Prop({ default: 0 })
  public qIndex!: number;

  @Prop({ default: false })
  public isEssay!: boolean;

  @Prop({ default: () => ([]) })
  public answers!: QCAnswerData[]

  public answerAlphaLetter: string[] = 'abcdefghijklmnopqrstuvwxyz'.split('');

  public questionTypes: any[] = [
    { text: 'Selecione', value: '' }
  ];

  public openImageModal(url: string) {
    this.$emit('openImageModal',url)
  }

  public isEditable: boolean = false;
  public descricao: string  = '';

  constructor() {
    super();
  }

  get questionDescription() {
    return `${this.qIndex+1}. ${this.question.description}`;
  }

  public cleanAnswers() {
    const keys: string[] = Object.keys(this.value.answers);
    keys.forEach(key => {
      if(this.question.id == this.value.answers[key].questionId)
        this.$delete(this.value.answers, key);
    });
    this.update()
  }
  

  public checkUniqueAnswer(answerId: number, event: any) {
    if ((event && event.target && event.target.checked) || event === true) {
      this.cleanAnswers()
      const qa: QCAnswerData = new QCAnswerData(answerId, 0);
      qa.questionId = this.question.id;
      this.$set(this.value.answers, answerId, qa)
    } 
    this.update()
  }
  
  public checkMultipleAnswer(answerId: number, event: any) {
    if ((event && event.target && event.target.checked) || event === true) {
      const qa: QCAnswerData = new QCAnswerData(answerId, 0);
      qa.questionId = this.question.id;
      this.$set(this.value.answers, answerId, qa)
    } else if(this.value.answers[answerId] && event!.target!.checked == false) {
      this.$delete(this.value.answers, answerId);
    }
    this.update()
  }
  
  public mounted() {
    if(this.question.questionTypeId > 2) {
      this.question.answers.forEach((answer: AnswerEntity, index) => {
        const qa: QCAnswerData = new QCAnswerData(answer.id, 0);
        qa.questionId = this.question.id;
        qa.description = this.answers[index]?.description

        this.$set(this.value.answers, answer.id, qa);  
      });
    }

    this.answers.forEach(answer => {
      if (this.question.questionTypeId === 1) {
        this.checkMultipleAnswer(answer.answerId, true)
      }

      if (this.question.questionTypeId === 2) {
        this.checkUniqueAnswer(answer.answerId, true);
      }
    })
  }

  public update() {
    this.$emit('input', this.value)
  }

}
