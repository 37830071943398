



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';
import Section from "@/components/section/Section.vue";

@Component<CardIdiomas>({
  components: {
    "section-card": Section
  }
})
export default class CardIdiomas extends Vue {

  @Prop({default: null})
  public value!: CandidatoEntity;
  
}
