









import { Component, Prop, Vue } from 'vue-property-decorator';
import lottie from 'lottie-web';

@Component
export default class PlayLoader extends Vue {
  
  public show: boolean = true;
  public loader: any = lottie;

  public mounted() {
    this.start();
  }

  public destroy() {
    this.show = false;
    if(this.loader)
      this.loader.destroy();
  }
  public start() {
    this.show = true;
    if(this.loader) {
      this.loader.loadAnimation({
        container: document.getElementById('play-lt-loader')!, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: '/static/loader/play-load.json' // the path to the animation json
      })
    }
  }

}
