









import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  props: {
    posicao: String,
    nivel: String
  }
})
export default class JobTitle extends Vue {}
