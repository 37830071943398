
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';

export default class Entrevista extends Repository {

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/entrevista', method: 'POST', headers: {} },
      delete: { url: '/v1/entrevista/{id}', method: 'DELETE', headers: {} },
      fetch: { url: '/v1/entrevista/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/entrevista', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      update: { url: '/v1/entrevista/{id}', method: 'PUT', headers: {} }}));
  }

  public saveInterviewFile(file: any, pid: number, transcriptionArray?: SpeechRecognitionAlternative[]): Promise<any> {
    this.hasProgress = true;
    const formData = new FormData();
    formData.append('pid', pid+"");
    formData.append('vdata', file);
    if(transcriptionArray){
      let arr: any[] = [];
      for (let index = 0; index < transcriptionArray.length; index++) {
        const element:SpeechRecognitionAlternative = transcriptionArray[index];
        arr.push({
          'transcript': element.transcript,
          'confidence': (element.confidence||0.75)
        })
      }
      formData.append('jsonStringTranscription', JSON.stringify(arr));
    }
    formData.append('supplier', ""+EVideoSupplier.Local);
    return super.create(formData, 'multipart/form-data');
  }

  public saveInterview(supplier: number, pid: number,  data?: any, transcriptionArray?: SpeechRecognitionAlternative[]): Promise<any> {
    if (supplier === EVideoSupplier.Openvidu && data !== null) {
      return super.create({
        idpergunta: pid,
        recordObject: JSON.stringify(data, null, '\t'),
        recordId: data.id,
        transcriptionArray: transcriptionArray,
        supplier: EVideoSupplier.Openvidu,
      });
    }
    if (supplier === EVideoSupplier.Twilio) {
      return super.create({
        idpergunta: pid,
        supplier: EVideoSupplier.Twilio,
        transcriptionArray: transcriptionArray,
      });
    }
    throw Error('No Supplier found');
  }

}
