
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { clientId } from '../../endpoint';
import { EmpresaEntity } from '../../models/Entities';
import ClientAuthentication from '@/core/Services/ClientAuthentication';
import Repository from '@/core/Services/Repository';

export default class Empresa extends Repository {

  public entidade: EmpresaEntity;

  constructor(i18n?: any) {
    super(new ApiRouter({
        auth: { url: '/v1/company-auth', method: 'POST', headers: {} },
        create: { url: '/v1/empresa', method: 'POST', headers: {} },
        fetch: { url: '/v1/empresa/{id}', method: 'GET', headers: {} },
        fetchAll: { url: '/v1/empresa', method: 'GET', headers: {} }, // tem parametro de Busca "s"
        info: { url: '/v1/my-info', method: 'GET', headers: {} } ,
        update: { url: '/v1/empresa/{id}', method: 'PUT', headers: {} }}));
    if (i18n) {
      this.$i18n = i18n;
    }
    this.entidade = new EmpresaEntity();
  }

  public loadEntidade(promisse: Promise<Empresa>, callback?: () => void) {
    promisse.then((data: any) => {
      this.entidade = data;
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line:no-console
      /* console.log('Couldn\'t load my info.', err) */
    });
  }

  public loadFromNomeUrl(url: string, callback?: () => void): void {
    if(url) {
      if (sessionStorage.getItem('access_token') !== null) {
          this.loadEntidade(super.call('fetch', { id: 0, urlCode: url }, 'application/json'), callback);
      } else {
        this.publicLoadFromNomeUrl(url, callback);
      }
    } else {
      console.log('URLC not defined')
    }
  }

  public publicLoadFromNomeUrl(url: string, callback?: () => void): void {
    (new ClientAuthentication()).auth().then((token: any) => {
      this.loadEntidade(super.call('fetch', { id: 0, urlCode: url, rtoken: token }, 'application/json'), callback);
    }).catch((resp) => {
      // tslint:disable-next-line:no-console
      /* console.log(resp) */
    });
  }

  public whatIsMissing(perfil: any): string {
    let msg: string = '';
    if (perfil) {
      msg += !(perfil.nomeEmpresa) ? this.$i18n.t('Company Name') + '<br />' : '';
      msg += !(perfil.cep) ? this.$i18n.t('Zip code') + '<br />' : '';
      msg += !(perfil.endereco) ? this.$i18n.t('Address') + '<br />' : '';
      msg += !(perfil.bairro) ? this.$i18n.t('District') + '<br />' : '';
    }
    return msg;
  }

  public loadMyInfo(callback?: () => void): void {
    super.myInfo().then((data: any) => {
      if (data.type === 'company') {
        this.entidade = data.entity;
      } else if (data.type === 'recruiter') {
        this.entidade = data.entity_company;
      }
      if (callback) {
        callback();
      }
    }).catch((err: any) => {
      // tslint:disable-next-line: no-console
      /* console.log('Couldn\'t load my info.', err) */
    });
  }

  public auth(data: any): Promise<any> {
    data.client_id = clientId;
    data.grant_type = 'company';

    return super.auth(data);
  }

  public authRecruiter(data: any): Promise<any> {
    data.client_id = atob(clientId);
    data.grant_type = 'recruiter';

    return super.auth(data);
  }
}
