































































































import Modal from "@/components/modal/Modal.vue";
import CircleRate from "@/components/rate/CircleRate.vue";
import CandidatoCorte from "@/core/Services/Basic/CandidatoCorte";
import { CandidatoCorteEntity, HabilidadeCandidatoEntity, VagaEntity, VagasHabilidadesEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component({
  components: {
    Modal,
    CircleRate,
  }
})
export default class ModalPerguntasCorte extends Vue {
  public respostaEliminatoria: string = '';
  public perguntaId!: number;
  public candidatoCorteService: CandidatoCorte = new CandidatoCorte;
  public habilidades: HabilidadeCandidatoEntity[] = [];
  public selected: [] = [];
  public nivel: number = 0;
  public saveInProfile: boolean = false;
  public items: VagasHabilidadesEntity[] = []
  public currentPromise: any|null = null;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: ''})
  public id!: string;

  @Prop({ default: '' })
  public vaga!: VagaEntity;

  public candidateAnswers: CandidatoCorteEntity[] = []

  @Watch('vaga.vagaHabilidades')
  public updateVagasHabilidades(newList: VagasHabilidadesEntity[]|undefined) {
    console.log(newList)
    if (!newList) {
      this.items = [];
    }
    else {
      this.items = JSON.parse(JSON.stringify(newList));
      this.items.forEach((element: VagasHabilidadesEntity,index: number) => {
        element.applyOnProfile = element.nivel > 0
      });
    }
  }

  public setNivel(value: number) {
    this.nivel = value;
  }

  // public created() {
  //   if(this.vaga){
  //     this.vaga.PerguntasCorte.forEach(perguntaCorte => {
  //       this.candidateAnswers.push(new CandidatoCorteEntity(perguntaCorte.id,null));
  //     })
  //   }
  // }

  public loadPerguntasCorte() {
    if(this.vaga && this.vaga.PerguntasCorte) {
      this.candidateAnswers = [];
      this.vaga.PerguntasCorte.forEach(perguntaCorte => {
        this.candidateAnswers.push(new CandidatoCorteEntity(perguntaCorte.id, null));
      })
    }
    this.updateVagasHabilidades(this.vaga.vagaHabilidades);
  }

  public sendAnswer() {
    let valid = true;
    console.log(this.candidateAnswers)
    this.candidateAnswers.forEach((element) => {
      if(element.resposta === null){
        valid = false;
        return;
      }
    });
    if (!valid) {
      // this.alert.title = this.$t('Attention')
      this.$alert.addMsg(this.$t("Make sure all questions are answered"));
      this.$alert.callBoxWarn()
      return
    }
    let arrHab: any[] = [];
    this.items.forEach(element => {
      arrHab.push({
        habilidade: element.habilidadeId,
        want: element.applyOnProfile,
        nivel: element.nivel,
      });
    });
    const d = {
      vaga_id: this.vaga.id,
      respostas: this.candidateAnswers,
      habilidades: arrHab,
    }
    if (this.respostaEliminatoria !== null) {
      this.currentPromise = this.candidatoCorteService.create(d)
        .then((data: any) => {
          
          if (data.detail == true) {
            this.$emit('formSubmitted',true)
          } else {
            // TODO esconder botao
            this.$alert.addTitle(this.$t("Attention").toString())
            this.$alert.addMsg(this.$t("Thank you for your interest in the vacancy, at the moment your profile did not pass the pre-filter. Don't be discouraged and stay tuned, the recruiter can still give you the chance to record the interview"));
            let obj: any = {
              b1Title: this.$t("Find more jobs").toString(),
              b2Title: this.$t("Update my profile").toString(),
              b1CallBack: () => { 
                  this.$router.push({ path: '/vagas', query: {lang: this.$i18n.locale}})
              },
              b2CallBack: () => {
                // if(this.prompt)
                  this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale}})
              }
            };
            this.$alert.callBoxCheckWithTitleAndDoubleButtons(obj);
            this.$emit('formSubmitted',false)
          }
      });
    }
  }

}
