




import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonIcon extends Vue {

  @Prop({default: ''}) public name!: string;
  @Prop({default: ''}) public size!: string;
  @Prop({default: ''}) public color!: string;

  public iconName = Boolean(this.name) ? this.name : 'info'
  public iconSize = Boolean(this.size) ? this.size : 'lg'
  public iconColor = Boolean(this.color) ? this.color : 'inherit'

  private _classes: string[] = ['icon', `icon--${this.iconSize}`, `icon--${this.iconColor}`, `jb-${this.iconName}`];
  public iconClass: string = this._classes.join(' ');
}
