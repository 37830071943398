



















import { CandidatoEntity, VagaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SidebarManualLiveRoom extends Vue {

  @Prop({default: null})
  public id!: string;

  @Prop({default: null})
  public coUID!: string;

  @Prop({default: null})
  public raToken!: string;
  
  @Prop({default: false})
  public value!: boolean;

  @Prop({ default: () => { new VagaEntity() } })
  public vaga!: VagaEntity;
  
  @Prop({ default: () => { new CandidatoEntity() } })
  public candidato!: CandidatoEntity;

  get theName() {
    return this.candidato?.primeironome||this.$t('Candidate').toString()
  }

  public saved() {
    this.$emit('done')
  }

  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }
  
}
