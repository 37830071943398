

















import Vue from "vue";
import Component from "vue-class-component";
import Section from "@/components/section/Section.vue";
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';
import { Getter, Action } from 'vuex-class';
const namespace: string = 'profile';

@Component<CardMyInfo>({
  components: {
    "section-card": Section
  }
})
export default class CardMyInfo extends Vue {
  public candidatoSRV = new CandidatoService;
  public showVideo: string = 'Y';

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  public async created() {
    if (!this.candidato) {
      this.loadCandidatoInfo();
    }
    if(this.candidato && this.candidato.perfil)
      this.showVideo = this.candidato.perfil.mostrarvideo
  }


  public saveShowVideo() {
    this.candidatoSRV.saveProfileField('mostrarvideo', this.showVideo, this.candidato!.id);
  }

}
