


































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Modal from '@/components/modal/Modal.vue';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { CandidatoEntity } from '@/core/models/Entities';
import Alert from "@/components/alerts/Alert.vue";
import VueTelInput from 'vue-tel-input';
const namespace: string = 'profile';

@Component({
  components: {
    Modal
  }
})
export default class ModalPhoneInput extends Vue {
  public candidatoSRV!: CandidatoService;
  public telefone: string = "";
  public alert!: Alert; 
  // public candidato!: CandidatoEntity;
  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Prop({default: ''})
  public id!: string;

  @Prop({default: ''})
  public title!: string;

  @Prop({default: false})
  public show!: boolean;

  @Prop({default: false})
  public size!: boolean;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.alert = new Alert();    
  }

  public async mounted() {
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    }
    this.candidatoSRV.entidade.perfil.telefone = this.candidato!.perfil.telefone
    this.telefone = this.candidato!.perfil.telefone+""
    this.candidatoSRV.entidade.id = this.candidato!.id
  }

  public savePhoneNumber() {

    if (this.telefone == '') {
      return;
    } else {
      this.candidatoSRV.saveProfileField("telefone", this.telefone, this.candidato!.id)
      this.$bvModal.hide('modalPhoneInput')
      this.$emit('savePhoneNumber')
    }
    
  }
}
