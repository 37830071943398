







































import { Vue, Component, Prop } from 'vue-property-decorator';
import IdiomaService from "@/core/Services/Basic/Idioma";
import IdiomasCandidatoService from '@/core/Services/Candidato/IdiomasCandidato';
import { CandidatoEntity, IdiomasCandidatoEntity } from '@/core/models/Entities';

@Component
export default class FormIdiomas extends Vue {

  @Prop({default: []})
  public value!: IdiomasCandidatoEntity[];

  public idiomaSRV!: IdiomaService;
  public idiomasCandidatoSRV!: IdiomasCandidatoService;
  public idiomPromisse: any| null = null;

  constructor() {
    super();
    this.idiomasCandidatoSRV = new IdiomasCandidatoService();
    this.idiomaSRV = new IdiomaService();
    this.idiomaSRV.loadOptions();
  }
  
  get arrayOfIdioms() {
    return this.value;
  }

  set arrayOfIdioms(v: any){
    const x: any[] = this.value;
    x.push(v)
    this.$emit('imput',x);
  }

  get invalidFeedback() {
    return ''
  }

  get validFeedback(){
      return '';
  }

  public addLanguage() {
    this.idiomPromisse = this.idiomasCandidatoSRV
      .create({ididioma: this.idiomaSRV.id, nivel: this.idiomaSRV.nivel})
      .then((data: any) => {
        this.arrayOfIdioms = data
      })
      .catch((err: any) => {
      });
  }

  public async delLanguage(id: any) {
    this.$confirm.addMsg(this.$t('Want to remove this language?'));
    const confirmed: boolean = await this.$confirm.callBox();

    if (confirmed) {
      this.idiomPromisse = this.idiomasCandidatoSRV
      .delete({ id })
      .then((data: any) => {
        let index = -1;       
        const x: any[] = this.value;
        index = this.value.findIndex((el,i) => { return (el.id === id); })
        if(index>-1) {
          x.splice(index,1)
        }
        this.$emit('imput',x);
      })
    }
  }


}
