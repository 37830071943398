const tzlist: { timezones: any, offsets: any, normalize: any } = {
  "timezones": {
    "Pacific/Pago_Pago": {
      "text": "(UTC-11:00) Pacific/Pago_Pago",
      "value": "Pacific/Pago_Pago",
      "timezone": "Pacific/Pago_Pago",
      "offset": -39600,
      "offsetFormatted": "-11:00",
      "prettyOffSetFormatted": "UTC-11:00"
    },
    "Pacific/Niue": {
      "text": "(UTC-11:00) Pacific/Niue",
      "value": "Pacific/Niue",
      "timezone": "Pacific/Niue",
      "offset": -39600,
      "offsetFormatted": "-11:00",
      "prettyOffSetFormatted": "UTC-11:00"
    },
    "Pacific/Midway": {
      "text": "(UTC-11:00) Pacific/Midway",
      "value": "Pacific/Midway",
      "timezone": "Pacific/Midway",
      "offset": -39600,
      "offsetFormatted": "-11:00",
      "prettyOffSetFormatted": "UTC-11:00"
    },
    "Pacific/Tahiti": {
      "text": "(UTC-10:00) Pacific/Tahiti",
      "value": "Pacific/Tahiti",
      "timezone": "Pacific/Tahiti",
      "offset": -36000,
      "offsetFormatted": "-10:00",
      "prettyOffSetFormatted": "UTC-10:00"
    },
    "Pacific/Rarotonga": {
      "text": "(UTC-10:00) Pacific/Rarotonga",
      "value": "Pacific/Rarotonga",
      "timezone": "Pacific/Rarotonga",
      "offset": -36000,
      "offsetFormatted": "-10:00",
      "prettyOffSetFormatted": "UTC-10:00"
    },
    "America/Adak": {
      "text": "(UTC-10:00) America/Adak",
      "value": "America/Adak",
      "timezone": "America/Adak",
      "offset": -36000,
      "offsetFormatted": "-10:00",
      "prettyOffSetFormatted": "UTC-10:00"
    },
    "Pacific/Honolulu": {
      "text": "(UTC-10:00) Pacific/Honolulu",
      "value": "Pacific/Honolulu",
      "timezone": "Pacific/Honolulu",
      "offset": -36000,
      "offsetFormatted": "-10:00",
      "prettyOffSetFormatted": "UTC-10:00"
    },
    "Pacific/Marquesas": {
      "text": "(UTC-09:30) Pacific/Marquesas",
      "value": "Pacific/Marquesas",
      "timezone": "Pacific/Marquesas",
      "offset": -34200,
      "offsetFormatted": "-09:30",
      "prettyOffSetFormatted": "UTC-09:30"
    },
    "America/Nome": {
      "text": "(UTC-09:00) America/Nome",
      "value": "America/Nome",
      "timezone": "America/Nome",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Metlakatla": {
      "text": "(UTC-09:00) America/Metlakatla",
      "value": "America/Metlakatla",
      "timezone": "America/Metlakatla",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Anchorage": {
      "text": "(UTC-09:00) America/Anchorage",
      "value": "America/Anchorage",
      "timezone": "America/Anchorage",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Yakutat": {
      "text": "(UTC-09:00) America/Yakutat",
      "value": "America/Yakutat",
      "timezone": "America/Yakutat",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "Pacific/Gambier": {
      "text": "(UTC-09:00) Pacific/Gambier",
      "value": "Pacific/Gambier",
      "timezone": "Pacific/Gambier",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Juneau": {
      "text": "(UTC-09:00) America/Juneau",
      "value": "America/Juneau",
      "timezone": "America/Juneau",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Sitka": {
      "text": "(UTC-09:00) America/Sitka",
      "value": "America/Sitka",
      "timezone": "America/Sitka",
      "offset": -32400,
      "offsetFormatted": "-09:00",
      "prettyOffSetFormatted": "UTC-09:00"
    },
    "America/Tijuana": {
      "text": "(UTC-08:00) America/Tijuana",
      "value": "America/Tijuana",
      "timezone": "America/Tijuana",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "Pacific/Pitcairn": {
      "text": "(UTC-08:00) Pacific/Pitcairn",
      "value": "Pacific/Pitcairn",
      "timezone": "Pacific/Pitcairn",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "America/Dawson": {
      "text": "(UTC-08:00) America/Dawson",
      "value": "America/Dawson",
      "timezone": "America/Dawson",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "America/Los_Angeles": {
      "text": "(UTC-08:00) America/Los_Angeles",
      "value": "America/Los_Angeles",
      "timezone": "America/Los_Angeles",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "America/Vancouver": {
      "text": "(UTC-08:00) America/Vancouver",
      "value": "America/Vancouver",
      "timezone": "America/Vancouver",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "America/Whitehorse": {
      "text": "(UTC-08:00) America/Whitehorse",
      "value": "America/Whitehorse",
      "timezone": "America/Whitehorse",
      "offset": -28800,
      "offsetFormatted": "-08:00",
      "prettyOffSetFormatted": "UTC-08:00"
    },
    "America/Inuvik": {
      "text": "(UTC-07:00) America/Inuvik",
      "value": "America/Inuvik",
      "timezone": "America/Inuvik",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Cambridge_Bay": {
      "text": "(UTC-07:00) America/Cambridge_Bay",
      "value": "America/Cambridge_Bay",
      "timezone": "America/Cambridge_Bay",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Hermosillo": {
      "text": "(UTC-07:00) America/Hermosillo",
      "value": "America/Hermosillo",
      "timezone": "America/Hermosillo",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Fort_Nelson": {
      "text": "(UTC-07:00) America/Fort_Nelson",
      "value": "America/Fort_Nelson",
      "timezone": "America/Fort_Nelson",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Edmonton": {
      "text": "(UTC-07:00) America/Edmonton",
      "value": "America/Edmonton",
      "timezone": "America/Edmonton",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Denver": {
      "text": "(UTC-07:00) America/Denver",
      "value": "America/Denver",
      "timezone": "America/Denver",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Dawson_Creek": {
      "text": "(UTC-07:00) America/Dawson_Creek",
      "value": "America/Dawson_Creek",
      "timezone": "America/Dawson_Creek",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Creston": {
      "text": "(UTC-07:00) America/Creston",
      "value": "America/Creston",
      "timezone": "America/Creston",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Chihuahua": {
      "text": "(UTC-07:00) America/Chihuahua",
      "value": "America/Chihuahua",
      "timezone": "America/Chihuahua",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Boise": {
      "text": "(UTC-07:00) America/Boise",
      "value": "America/Boise",
      "timezone": "America/Boise",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Mazatlan": {
      "text": "(UTC-07:00) America/Mazatlan",
      "value": "America/Mazatlan",
      "timezone": "America/Mazatlan",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Phoenix": {
      "text": "(UTC-07:00) America/Phoenix",
      "value": "America/Phoenix",
      "timezone": "America/Phoenix",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Ojinaga": {
      "text": "(UTC-07:00) America/Ojinaga",
      "value": "America/Ojinaga",
      "timezone": "America/Ojinaga",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Yellowknife": {
      "text": "(UTC-07:00) America/Yellowknife",
      "value": "America/Yellowknife",
      "timezone": "America/Yellowknife",
      "offset": -25200,
      "offsetFormatted": "-07:00",
      "prettyOffSetFormatted": "UTC-07:00"
    },
    "America/Merida": {
      "text": "(UTC-06:00) America/Merida",
      "value": "America/Merida",
      "timezone": "America/Merida",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Guatemala": {
      "text": "(UTC-06:00) America/Guatemala",
      "value": "America/Guatemala",
      "timezone": "America/Guatemala",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/El_Salvador": {
      "text": "(UTC-06:00) America/El_Salvador",
      "value": "America/El_Salvador",
      "timezone": "America/El_Salvador",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Indiana/Knox": {
      "text": "(UTC-06:00) America/Indiana/Knox",
      "value": "America/Indiana/Knox",
      "timezone": "America/Indiana/Knox",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Menominee": {
      "text": "(UTC-06:00) America/Menominee",
      "value": "America/Menominee",
      "timezone": "America/Menominee",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Indiana/Tell_City": {
      "text": "(UTC-06:00) America/Indiana/Tell_City",
      "value": "America/Indiana/Tell_City",
      "timezone": "America/Indiana/Tell_City",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/North_Dakota/Beulah": {
      "text": "(UTC-06:00) America/North_Dakota/Beulah",
      "value": "America/North_Dakota/Beulah",
      "timezone": "America/North_Dakota/Beulah",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/North_Dakota/Center": {
      "text": "(UTC-06:00) America/North_Dakota/Center",
      "value": "America/North_Dakota/Center",
      "timezone": "America/North_Dakota/Center",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "Pacific/Galapagos": {
      "text": "(UTC-06:00) Pacific/Galapagos",
      "value": "Pacific/Galapagos",
      "timezone": "Pacific/Galapagos",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Bahia_Banderas": {
      "text": "(UTC-06:00) America/Bahia_Banderas",
      "value": "America/Bahia_Banderas",
      "timezone": "America/Bahia_Banderas",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/North_Dakota/New_Salem": {
      "text": "(UTC-06:00) America/North_Dakota/New_Salem",
      "value": "America/North_Dakota/New_Salem",
      "timezone": "America/North_Dakota/New_Salem",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Belize": {
      "text": "(UTC-06:00) America/Belize",
      "value": "America/Belize",
      "timezone": "America/Belize",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Rainy_River": {
      "text": "(UTC-06:00) America/Rainy_River",
      "value": "America/Rainy_River",
      "timezone": "America/Rainy_River",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Rankin_Inlet": {
      "text": "(UTC-06:00) America/Rankin_Inlet",
      "value": "America/Rankin_Inlet",
      "timezone": "America/Rankin_Inlet",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Costa_Rica": {
      "text": "(UTC-06:00) America/Costa_Rica",
      "value": "America/Costa_Rica",
      "timezone": "America/Costa_Rica",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Regina": {
      "text": "(UTC-06:00) America/Regina",
      "value": "America/Regina",
      "timezone": "America/Regina",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Resolute": {
      "text": "(UTC-06:00) America/Resolute",
      "value": "America/Resolute",
      "timezone": "America/Resolute",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Monterrey": {
      "text": "(UTC-06:00) America/Monterrey",
      "value": "America/Monterrey",
      "timezone": "America/Monterrey",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Chicago": {
      "text": "(UTC-06:00) America/Chicago",
      "value": "America/Chicago",
      "timezone": "America/Chicago",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Tegucigalpa": {
      "text": "(UTC-06:00) America/Tegucigalpa",
      "value": "America/Tegucigalpa",
      "timezone": "America/Tegucigalpa",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Managua": {
      "text": "(UTC-06:00) America/Managua",
      "value": "America/Managua",
      "timezone": "America/Managua",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Swift_Current": {
      "text": "(UTC-06:00) America/Swift_Current",
      "value": "America/Swift_Current",
      "timezone": "America/Swift_Current",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Matamoros": {
      "text": "(UTC-06:00) America/Matamoros",
      "value": "America/Matamoros",
      "timezone": "America/Matamoros",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Mexico_City": {
      "text": "(UTC-06:00) America/Mexico_City",
      "value": "America/Mexico_City",
      "timezone": "America/Mexico_City",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Winnipeg": {
      "text": "(UTC-06:00) America/Winnipeg",
      "value": "America/Winnipeg",
      "timezone": "America/Winnipeg",
      "offset": -21600,
      "offsetFormatted": "-06:00",
      "prettyOffSetFormatted": "UTC-06:00"
    },
    "America/Panama": {
      "text": "(UTC-05:00) America/Panama",
      "value": "America/Panama",
      "timezone": "America/Panama",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Pangnirtung": {
      "text": "(UTC-05:00) America/Pangnirtung",
      "value": "America/Pangnirtung",
      "timezone": "America/Pangnirtung",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Detroit": {
      "text": "(UTC-05:00) America/Detroit",
      "value": "America/Detroit",
      "timezone": "America/Detroit",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Eirunepe": {
      "text": "(UTC-05:00) America/Eirunepe",
      "value": "America/Eirunepe",
      "timezone": "America/Eirunepe",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Petersburg": {
      "text": "(UTC-05:00) America/Indiana/Petersburg",
      "value": "America/Indiana/Petersburg",
      "timezone": "America/Indiana/Petersburg",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Grand_Turk": {
      "text": "(UTC-05:00) America/Grand_Turk",
      "value": "America/Grand_Turk",
      "timezone": "America/Grand_Turk",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Winamac": {
      "text": "(UTC-05:00) America/Indiana/Winamac",
      "value": "America/Indiana/Winamac",
      "timezone": "America/Indiana/Winamac",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Lima": {
      "text": "(UTC-05:00) America/Lima",
      "value": "America/Lima",
      "timezone": "America/Lima",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Cayman": {
      "text": "(UTC-05:00) America/Cayman",
      "value": "America/Cayman",
      "timezone": "America/Cayman",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Kentucky/Louisville": {
      "text": "(UTC-05:00) America/Kentucky/Louisville",
      "value": "America/Kentucky/Louisville",
      "timezone": "America/Kentucky/Louisville",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Jamaica": {
      "text": "(UTC-05:00) America/Jamaica",
      "value": "America/Jamaica",
      "timezone": "America/Jamaica",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Port-au-Prince": {
      "text": "(UTC-05:00) America/Port-au-Prince",
      "value": "America/Port-au-Prince",
      "timezone": "America/Port-au-Prince",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Nassau": {
      "text": "(UTC-05:00) America/Nassau",
      "value": "America/Nassau",
      "timezone": "America/Nassau",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/New_York": {
      "text": "(UTC-05:00) America/New_York",
      "value": "America/New_York",
      "timezone": "America/New_York",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Guayaquil": {
      "text": "(UTC-05:00) America/Guayaquil",
      "value": "America/Guayaquil",
      "timezone": "America/Guayaquil",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Vincennes": {
      "text": "(UTC-05:00) America/Indiana/Vincennes",
      "value": "America/Indiana/Vincennes",
      "timezone": "America/Indiana/Vincennes",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Vevay": {
      "text": "(UTC-05:00) America/Indiana/Vevay",
      "value": "America/Indiana/Vevay",
      "timezone": "America/Indiana/Vevay",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Marengo": {
      "text": "(UTC-05:00) America/Indiana/Marengo",
      "value": "America/Indiana/Marengo",
      "timezone": "America/Indiana/Marengo",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Indiana/Indianapolis": {
      "text": "(UTC-05:00) America/Indiana/Indianapolis",
      "value": "America/Indiana/Indianapolis",
      "timezone": "America/Indiana/Indianapolis",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Nipigon": {
      "text": "(UTC-05:00) America/Nipigon",
      "value": "America/Nipigon",
      "timezone": "America/Nipigon",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Havana": {
      "text": "(UTC-05:00) America/Havana",
      "value": "America/Havana",
      "timezone": "America/Havana",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Kentucky/Monticello": {
      "text": "(UTC-05:00) America/Kentucky/Monticello",
      "value": "America/Kentucky/Monticello",
      "timezone": "America/Kentucky/Monticello",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Iqaluit": {
      "text": "(UTC-05:00) America/Iqaluit",
      "value": "America/Iqaluit",
      "timezone": "America/Iqaluit",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Cancun": {
      "text": "(UTC-05:00) America/Cancun",
      "value": "America/Cancun",
      "timezone": "America/Cancun",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Bogota": {
      "text": "(UTC-05:00) America/Bogota",
      "value": "America/Bogota",
      "timezone": "America/Bogota",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Toronto": {
      "text": "(UTC-05:00) America/Toronto",
      "value": "America/Toronto",
      "timezone": "America/Toronto",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "Pacific/Easter": {
      "text": "(UTC-05:00) Pacific/Easter",
      "value": "Pacific/Easter",
      "timezone": "Pacific/Easter",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Thunder_Bay": {
      "text": "(UTC-05:00) America/Thunder_Bay",
      "value": "America/Thunder_Bay",
      "timezone": "America/Thunder_Bay",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Rio_Branco": {
      "text": "(UTC-05:00) America/Rio_Branco",
      "value": "America/Rio_Branco",
      "timezone": "America/Rio_Branco",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Atikokan": {
      "text": "(UTC-05:00) America/Atikokan",
      "value": "America/Atikokan",
      "timezone": "America/Atikokan",
      "offset": -18000,
      "offsetFormatted": "-05:00",
      "prettyOffSetFormatted": "UTC-05:00"
    },
    "America/Marigot": {
      "text": "(UTC-04:00) America/Marigot",
      "value": "America/Marigot",
      "timezone": "America/Marigot",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Anguilla": {
      "text": "(UTC-04:00) America/Anguilla",
      "value": "America/Anguilla",
      "timezone": "America/Anguilla",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Caracas": {
      "text": "(UTC-04:00) America/Caracas",
      "value": "America/Caracas",
      "timezone": "America/Caracas",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Puerto_Rico": {
      "text": "(UTC-04:00) America/Puerto_Rico",
      "value": "America/Puerto_Rico",
      "timezone": "America/Puerto_Rico",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Kralendijk": {
      "text": "(UTC-04:00) America/Kralendijk",
      "value": "America/Kralendijk",
      "timezone": "America/Kralendijk",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/La_Paz": {
      "text": "(UTC-04:00) America/La_Paz",
      "value": "America/La_Paz",
      "timezone": "America/La_Paz",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Santo_Domingo": {
      "text": "(UTC-04:00) America/Santo_Domingo",
      "value": "America/Santo_Domingo",
      "timezone": "America/Santo_Domingo",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Lower_Princes": {
      "text": "(UTC-04:00) America/Lower_Princes",
      "value": "America/Lower_Princes",
      "timezone": "America/Lower_Princes",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Manaus": {
      "text": "(UTC-04:00) America/Manaus",
      "value": "America/Manaus",
      "timezone": "America/Manaus",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Lucia": {
      "text": "(UTC-04:00) America/St_Lucia",
      "value": "America/St_Lucia",
      "timezone": "America/St_Lucia",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Martinique": {
      "text": "(UTC-04:00) America/Martinique",
      "value": "America/Martinique",
      "timezone": "America/Martinique",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Barthelemy": {
      "text": "(UTC-04:00) America/St_Barthelemy",
      "value": "America/St_Barthelemy",
      "timezone": "America/St_Barthelemy",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Kitts": {
      "text": "(UTC-04:00) America/St_Kitts",
      "value": "America/St_Kitts",
      "timezone": "America/St_Kitts",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Halifax": {
      "text": "(UTC-04:00) America/Halifax",
      "value": "America/Halifax",
      "timezone": "America/Halifax",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Thomas": {
      "text": "(UTC-04:00) America/St_Thomas",
      "value": "America/St_Thomas",
      "timezone": "America/St_Thomas",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Vincent": {
      "text": "(UTC-04:00) America/St_Vincent",
      "value": "America/St_Vincent",
      "timezone": "America/St_Vincent",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Moncton": {
      "text": "(UTC-04:00) America/Moncton",
      "value": "America/Moncton",
      "timezone": "America/Moncton",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Thule": {
      "text": "(UTC-04:00) America/Thule",
      "value": "America/Thule",
      "timezone": "America/Thule",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Montserrat": {
      "text": "(UTC-04:00) America/Montserrat",
      "value": "America/Montserrat",
      "timezone": "America/Montserrat",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Tortola": {
      "text": "(UTC-04:00) America/Tortola",
      "value": "America/Tortola",
      "timezone": "America/Tortola",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "Atlantic/Bermuda": {
      "text": "(UTC-04:00) Atlantic/Bermuda",
      "value": "Atlantic/Bermuda",
      "timezone": "Atlantic/Bermuda",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Antigua": {
      "text": "(UTC-04:00) America/Antigua",
      "value": "America/Antigua",
      "timezone": "America/Antigua",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Porto_Velho": {
      "text": "(UTC-04:00) America/Porto_Velho",
      "value": "America/Porto_Velho",
      "timezone": "America/Porto_Velho",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Guyana": {
      "text": "(UTC-04:00) America/Guyana",
      "value": "America/Guyana",
      "timezone": "America/Guyana",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Guadeloupe": {
      "text": "(UTC-04:00) America/Guadeloupe",
      "value": "America/Guadeloupe",
      "timezone": "America/Guadeloupe",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Dominica": {
      "text": "(UTC-04:00) America/Dominica",
      "value": "America/Dominica",
      "timezone": "America/Dominica",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Glace_Bay": {
      "text": "(UTC-04:00) America/Glace_Bay",
      "value": "America/Glace_Bay",
      "timezone": "America/Glace_Bay",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Barbados": {
      "text": "(UTC-04:00) America/Barbados",
      "value": "America/Barbados",
      "timezone": "America/Barbados",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Goose_Bay": {
      "text": "(UTC-04:00) America/Goose_Bay",
      "value": "America/Goose_Bay",
      "timezone": "America/Goose_Bay",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Port_of_Spain": {
      "text": "(UTC-04:00) America/Port_of_Spain",
      "value": "America/Port_of_Spain",
      "timezone": "America/Port_of_Spain",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Curacao": {
      "text": "(UTC-04:00) America/Curacao",
      "value": "America/Curacao",
      "timezone": "America/Curacao",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Grenada": {
      "text": "(UTC-04:00) America/Grenada",
      "value": "America/Grenada",
      "timezone": "America/Grenada",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Cuiaba": {
      "text": "(UTC-04:00) America/Cuiaba",
      "value": "America/Cuiaba",
      "timezone": "America/Cuiaba",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Blanc-Sablon": {
      "text": "(UTC-04:00) America/Blanc-Sablon",
      "value": "America/Blanc-Sablon",
      "timezone": "America/Blanc-Sablon",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Boa_Vista": {
      "text": "(UTC-04:00) America/Boa_Vista",
      "value": "America/Boa_Vista",
      "timezone": "America/Boa_Vista",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Campo_Grande": {
      "text": "(UTC-04:00) America/Campo_Grande",
      "value": "America/Campo_Grande",
      "timezone": "America/Campo_Grande",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/Aruba": {
      "text": "(UTC-04:00) America/Aruba",
      "value": "America/Aruba",
      "timezone": "America/Aruba",
      "offset": -14400,
      "offsetFormatted": "-04:00",
      "prettyOffSetFormatted": "UTC-04:00"
    },
    "America/St_Johns": {
      "text": "(UTC-03:30) America/St_Johns",
      "value": "America/St_Johns",
      "timezone": "America/St_Johns",
      "offset": -12600,
      "offsetFormatted": "-03:30",
      "prettyOffSetFormatted": "UTC-03:30"
    },
    "America/Belem": {
      "text": "(UTC-03:00) America/Belem",
      "value": "America/Belem",
      "timezone": "America/Belem",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Ushuaia": {
      "text": "(UTC-03:00) America/Argentina/Ushuaia",
      "value": "America/Argentina/Ushuaia",
      "timezone": "America/Argentina/Ushuaia",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Montevideo": {
      "text": "(UTC-03:00) America/Montevideo",
      "value": "America/Montevideo",
      "timezone": "America/Montevideo",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Miquelon": {
      "text": "(UTC-03:00) America/Miquelon",
      "value": "America/Miquelon",
      "timezone": "America/Miquelon",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "Antarctica/Palmer": {
      "text": "(UTC-03:00) Antarctica/Palmer",
      "value": "Antarctica/Palmer",
      "timezone": "Antarctica/Palmer",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "Antarctica/Rothera": {
      "text": "(UTC-03:00) Antarctica/Rothera",
      "value": "Antarctica/Rothera",
      "timezone": "Antarctica/Rothera",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "Atlantic/Stanley": {
      "text": "(UTC-03:00) Atlantic/Stanley",
      "value": "Atlantic/Stanley",
      "timezone": "Atlantic/Stanley",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Bahia": {
      "text": "(UTC-03:00) America/Bahia",
      "value": "America/Bahia",
      "timezone": "America/Bahia",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Asuncion": {
      "text": "(UTC-03:00) America/Asuncion",
      "value": "America/Asuncion",
      "timezone": "America/Asuncion",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Cayenne": {
      "text": "(UTC-03:00) America/Cayenne",
      "value": "America/Cayenne",
      "timezone": "America/Cayenne",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Tucuman": {
      "text": "(UTC-03:00) America/Argentina/Tucuman",
      "value": "America/Argentina/Tucuman",
      "timezone": "America/Argentina/Tucuman",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Mendoza": {
      "text": "(UTC-03:00) America/Argentina/Mendoza",
      "value": "America/Argentina/Mendoza",
      "timezone": "America/Argentina/Mendoza",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Punta_Arenas": {
      "text": "(UTC-03:00) America/Punta_Arenas",
      "value": "America/Punta_Arenas",
      "timezone": "America/Punta_Arenas",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/La_Rioja": {
      "text": "(UTC-03:00) America/Argentina/La_Rioja",
      "value": "America/Argentina/La_Rioja",
      "timezone": "America/Argentina/La_Rioja",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Jujuy": {
      "text": "(UTC-03:00) America/Argentina/Jujuy",
      "value": "America/Argentina/Jujuy",
      "timezone": "America/Argentina/Jujuy",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Cordoba": {
      "text": "(UTC-03:00) America/Argentina/Cordoba",
      "value": "America/Argentina/Cordoba",
      "timezone": "America/Argentina/Cordoba",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Catamarca": {
      "text": "(UTC-03:00) America/Argentina/Catamarca",
      "value": "America/Argentina/Catamarca",
      "timezone": "America/Argentina/Catamarca",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Buenos_Aires": {
      "text": "(UTC-03:00) America/Argentina/Buenos_Aires",
      "value": "America/Argentina/Buenos_Aires",
      "timezone": "America/Argentina/Buenos_Aires",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Araguaina": {
      "text": "(UTC-03:00) America/Araguaina",
      "value": "America/Araguaina",
      "timezone": "America/Araguaina",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Rio_Gallegos": {
      "text": "(UTC-03:00) America/Argentina/Rio_Gallegos",
      "value": "America/Argentina/Rio_Gallegos",
      "timezone": "America/Argentina/Rio_Gallegos",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/San_Luis": {
      "text": "(UTC-03:00) America/Argentina/San_Luis",
      "value": "America/Argentina/San_Luis",
      "timezone": "America/Argentina/San_Luis",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/Salta": {
      "text": "(UTC-03:00) America/Argentina/Salta",
      "value": "America/Argentina/Salta",
      "timezone": "America/Argentina/Salta",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Recife": {
      "text": "(UTC-03:00) America/Recife",
      "value": "America/Recife",
      "timezone": "America/Recife",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Argentina/San_Juan": {
      "text": "(UTC-03:00) America/Argentina/San_Juan",
      "value": "America/Argentina/San_Juan",
      "timezone": "America/Argentina/San_Juan",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Santarem": {
      "text": "(UTC-03:00) America/Santarem",
      "value": "America/Santarem",
      "timezone": "America/Santarem",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Santiago": {
      "text": "(UTC-03:00) America/Santiago",
      "value": "America/Santiago",
      "timezone": "America/Santiago",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Godthab": {
      "text": "(UTC-03:00) America/Godthab",
      "value": "America/Godthab",
      "timezone": "America/Godthab",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Sao_Paulo": {
      "text": "(UTC-03:00) America/Sao_Paulo",
      "value": "America/Sao_Paulo",
      "timezone": "America/Sao_Paulo",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Fortaleza": {
      "text": "(UTC-03:00) America/Fortaleza",
      "value": "America/Fortaleza",
      "timezone": "America/Fortaleza",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Maceio": {
      "text": "(UTC-03:00) America/Maceio",
      "value": "America/Maceio",
      "timezone": "America/Maceio",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Paramaribo": {
      "text": "(UTC-03:00) America/Paramaribo",
      "value": "America/Paramaribo",
      "timezone": "America/Paramaribo",
      "offset": -10800,
      "offsetFormatted": "-03:00",
      "prettyOffSetFormatted": "UTC-03:00"
    },
    "America/Noronha": {
      "text": "(UTC-02:00) America/Noronha",
      "value": "America/Noronha",
      "timezone": "America/Noronha",
      "offset": -7200,
      "offsetFormatted": "-02:00",
      "prettyOffSetFormatted": "UTC-02:00"
    },
    "Atlantic/South_Georgia": {
      "text": "(UTC-02:00) Atlantic/South_Georgia",
      "value": "Atlantic/South_Georgia",
      "timezone": "Atlantic/South_Georgia",
      "offset": -7200,
      "offsetFormatted": "-02:00",
      "prettyOffSetFormatted": "UTC-02:00"
    },
    "Atlantic/Cape_Verde": {
      "text": "(UTC-01:00) Atlantic/Cape_Verde",
      "value": "Atlantic/Cape_Verde",
      "timezone": "Atlantic/Cape_Verde",
      "offset": -3600,
      "offsetFormatted": "-01:00",
      "prettyOffSetFormatted": "UTC-01:00"
    },
    "America/Scoresbysund": {
      "text": "(UTC-01:00) America/Scoresbysund",
      "value": "America/Scoresbysund",
      "timezone": "America/Scoresbysund",
      "offset": -3600,
      "offsetFormatted": "-01:00",
      "prettyOffSetFormatted": "UTC-01:00"
    },
    "Atlantic/Azores": {
      "text": "(UTC-01:00) Atlantic/Azores",
      "value": "Atlantic/Azores",
      "timezone": "Atlantic/Azores",
      "offset": -3600,
      "offsetFormatted": "-01:00",
      "prettyOffSetFormatted": "UTC-01:00"
    },
    "Africa/Abidjan": {
      "text": "(UTC+00:00) Africa/Abidjan",
      "value": "Africa/Abidjan",
      "timezone": "Africa/Abidjan",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Lome": {
      "text": "(UTC+00:00) Africa/Lome",
      "value": "Africa/Lome",
      "timezone": "Africa/Lome",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "America/Danmarkshavn": {
      "text": "(UTC+00:00) America/Danmarkshavn",
      "value": "America/Danmarkshavn",
      "timezone": "America/Danmarkshavn",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Bissau": {
      "text": "(UTC+00:00) Africa/Bissau",
      "value": "Africa/Bissau",
      "timezone": "Africa/Bissau",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Accra": {
      "text": "(UTC+00:00) Africa/Accra",
      "value": "Africa/Accra",
      "timezone": "Africa/Accra",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/London": {
      "text": "(UTC+00:00) Europe/London",
      "value": "Europe/London",
      "timezone": "Europe/London",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Lisbon": {
      "text": "(UTC+00:00) Europe/Lisbon",
      "value": "Europe/Lisbon",
      "timezone": "Europe/Lisbon",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Conakry": {
      "text": "(UTC+00:00) Africa/Conakry",
      "value": "Africa/Conakry",
      "timezone": "Africa/Conakry",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Dakar": {
      "text": "(UTC+00:00) Africa/Dakar",
      "value": "Africa/Dakar",
      "timezone": "Africa/Dakar",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Jersey": {
      "text": "(UTC+00:00) Europe/Jersey",
      "value": "Europe/Jersey",
      "timezone": "Europe/Jersey",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Isle_of_Man": {
      "text": "(UTC+00:00) Europe/Isle_of_Man",
      "value": "Europe/Isle_of_Man",
      "timezone": "Europe/Isle_of_Man",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Freetown": {
      "text": "(UTC+00:00) Africa/Freetown",
      "value": "Africa/Freetown",
      "timezone": "Africa/Freetown",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Guernsey": {
      "text": "(UTC+00:00) Europe/Guernsey",
      "value": "Europe/Guernsey",
      "timezone": "Europe/Guernsey",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Dublin": {
      "text": "(UTC+00:00) Europe/Dublin",
      "value": "Europe/Dublin",
      "timezone": "Europe/Dublin",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Antarctica/Troll": {
      "text": "(UTC+00:00) Antarctica/Troll",
      "value": "Antarctica/Troll",
      "timezone": "Antarctica/Troll",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Atlantic/Madeira": {
      "text": "(UTC+00:00) Atlantic/Madeira",
      "value": "Atlantic/Madeira",
      "timezone": "Atlantic/Madeira",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Atlantic/Faroe": {
      "text": "(UTC+00:00) Atlantic/Faroe",
      "value": "Atlantic/Faroe",
      "timezone": "Atlantic/Faroe",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Nouakchott": {
      "text": "(UTC+00:00) Africa/Nouakchott",
      "value": "Africa/Nouakchott",
      "timezone": "Africa/Nouakchott",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Atlantic/Reykjavik": {
      "text": "(UTC+00:00) Atlantic/Reykjavik",
      "value": "Atlantic/Reykjavik",
      "timezone": "Atlantic/Reykjavik",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Sao_Tome": {
      "text": "(UTC+00:00) Africa/Sao_Tome",
      "value": "Africa/Sao_Tome",
      "timezone": "Africa/Sao_Tome",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Monrovia": {
      "text": "(UTC+00:00) Africa/Monrovia",
      "value": "Africa/Monrovia",
      "timezone": "Africa/Monrovia",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Atlantic/St_Helena": {
      "text": "(UTC+00:00) Atlantic/St_Helena",
      "value": "Atlantic/St_Helena",
      "timezone": "Atlantic/St_Helena",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Ouagadougou": {
      "text": "(UTC+00:00) Africa/Ouagadougou",
      "value": "Africa/Ouagadougou",
      "timezone": "Africa/Ouagadougou",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Banjul": {
      "text": "(UTC+00:00) Africa/Banjul",
      "value": "Africa/Banjul",
      "timezone": "Africa/Banjul",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Africa/Bamako": {
      "text": "(UTC+00:00) Africa/Bamako",
      "value": "Africa/Bamako",
      "timezone": "Africa/Bamako",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Atlantic/Canary": {
      "text": "(UTC+00:00) Atlantic/Canary",
      "value": "Atlantic/Canary",
      "timezone": "Atlantic/Canary",
      "offset": 0,
      "offsetFormatted": "+00:00",
      "prettyOffSetFormatted": "UTC+00:00"
    },
    "Europe/Malta": {
      "text": "(UTC+01:00) Europe/Malta",
      "value": "Europe/Malta",
      "timezone": "Europe/Malta",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Monaco": {
      "text": "(UTC+01:00) Europe/Monaco",
      "value": "Europe/Monaco",
      "timezone": "Europe/Monaco",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Luxembourg": {
      "text": "(UTC+01:00) Europe/Luxembourg",
      "value": "Europe/Luxembourg",
      "timezone": "Europe/Luxembourg",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Oslo": {
      "text": "(UTC+01:00) Europe/Oslo",
      "value": "Europe/Oslo",
      "timezone": "Europe/Oslo",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Ljubljana": {
      "text": "(UTC+01:00) Europe/Ljubljana",
      "value": "Europe/Ljubljana",
      "timezone": "Europe/Ljubljana",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Algiers": {
      "text": "(UTC+01:00) Africa/Algiers",
      "value": "Africa/Algiers",
      "timezone": "Africa/Algiers",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Madrid": {
      "text": "(UTC+01:00) Europe/Madrid",
      "value": "Europe/Madrid",
      "timezone": "Europe/Madrid",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Amsterdam": {
      "text": "(UTC+01:00) Europe/Amsterdam",
      "value": "Europe/Amsterdam",
      "timezone": "Europe/Amsterdam",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Andorra": {
      "text": "(UTC+01:00) Europe/Andorra",
      "value": "Europe/Andorra",
      "timezone": "Europe/Andorra",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Bangui": {
      "text": "(UTC+01:00) Africa/Bangui",
      "value": "Africa/Bangui",
      "timezone": "Africa/Bangui",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Gibraltar": {
      "text": "(UTC+01:00) Europe/Gibraltar",
      "value": "Europe/Gibraltar",
      "timezone": "Europe/Gibraltar",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Copenhagen": {
      "text": "(UTC+01:00) Europe/Copenhagen",
      "value": "Europe/Copenhagen",
      "timezone": "Europe/Copenhagen",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Busingen": {
      "text": "(UTC+01:00) Europe/Busingen",
      "value": "Europe/Busingen",
      "timezone": "Europe/Busingen",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Paris": {
      "text": "(UTC+01:00) Europe/Paris",
      "value": "Europe/Paris",
      "timezone": "Europe/Paris",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Brussels": {
      "text": "(UTC+01:00) Europe/Brussels",
      "value": "Europe/Brussels",
      "timezone": "Europe/Brussels",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Bratislava": {
      "text": "(UTC+01:00) Europe/Bratislava",
      "value": "Europe/Bratislava",
      "timezone": "Europe/Bratislava",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Berlin": {
      "text": "(UTC+01:00) Europe/Berlin",
      "value": "Europe/Berlin",
      "timezone": "Europe/Berlin",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Belgrade": {
      "text": "(UTC+01:00) Europe/Belgrade",
      "value": "Europe/Belgrade",
      "timezone": "Europe/Belgrade",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Budapest": {
      "text": "(UTC+01:00) Europe/Budapest",
      "value": "Europe/Budapest",
      "timezone": "Europe/Budapest",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Prague": {
      "text": "(UTC+01:00) Europe/Prague",
      "value": "Europe/Prague",
      "timezone": "Europe/Prague",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Podgorica": {
      "text": "(UTC+01:00) Europe/Podgorica",
      "value": "Europe/Podgorica",
      "timezone": "Europe/Podgorica",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Ndjamena": {
      "text": "(UTC+01:00) Africa/Ndjamena",
      "value": "Africa/Ndjamena",
      "timezone": "Africa/Ndjamena",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Malabo": {
      "text": "(UTC+01:00) Africa/Malabo",
      "value": "Africa/Malabo",
      "timezone": "Africa/Malabo",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Vaduz": {
      "text": "(UTC+01:00) Europe/Vaduz",
      "value": "Europe/Vaduz",
      "timezone": "Europe/Vaduz",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Warsaw": {
      "text": "(UTC+01:00) Europe/Warsaw",
      "value": "Europe/Warsaw",
      "timezone": "Europe/Warsaw",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Luanda": {
      "text": "(UTC+01:00) Africa/Luanda",
      "value": "Africa/Luanda",
      "timezone": "Africa/Luanda",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Libreville": {
      "text": "(UTC+01:00) Africa/Libreville",
      "value": "Africa/Libreville",
      "timezone": "Africa/Libreville",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Lagos": {
      "text": "(UTC+01:00) Africa/Lagos",
      "value": "Africa/Lagos",
      "timezone": "Africa/Lagos",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Brazzaville": {
      "text": "(UTC+01:00) Africa/Brazzaville",
      "value": "Africa/Brazzaville",
      "timezone": "Africa/Brazzaville",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Kinshasa": {
      "text": "(UTC+01:00) Africa/Kinshasa",
      "value": "Africa/Kinshasa",
      "timezone": "Africa/Kinshasa",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Zagreb": {
      "text": "(UTC+01:00) Europe/Zagreb",
      "value": "Europe/Zagreb",
      "timezone": "Europe/Zagreb",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Tirane": {
      "text": "(UTC+01:00) Europe/Tirane",
      "value": "Europe/Tirane",
      "timezone": "Europe/Tirane",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Zurich": {
      "text": "(UTC+01:00) Europe/Zurich",
      "value": "Europe/Zurich",
      "timezone": "Europe/Zurich",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/El_Aaiun": {
      "text": "(UTC+01:00) Africa/El_Aaiun",
      "value": "Africa/El_Aaiun",
      "timezone": "Africa/El_Aaiun",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Vienna": {
      "text": "(UTC+01:00) Europe/Vienna",
      "value": "Europe/Vienna",
      "timezone": "Europe/Vienna",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Douala": {
      "text": "(UTC+01:00) Africa/Douala",
      "value": "Africa/Douala",
      "timezone": "Africa/Douala",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Niamey": {
      "text": "(UTC+01:00) Africa/Niamey",
      "value": "Africa/Niamey",
      "timezone": "Africa/Niamey",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Porto-Novo": {
      "text": "(UTC+01:00) Africa/Porto-Novo",
      "value": "Africa/Porto-Novo",
      "timezone": "Africa/Porto-Novo",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Ceuta": {
      "text": "(UTC+01:00) Africa/Ceuta",
      "value": "Africa/Ceuta",
      "timezone": "Africa/Ceuta",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Casablanca": {
      "text": "(UTC+01:00) Africa/Casablanca",
      "value": "Africa/Casablanca",
      "timezone": "Africa/Casablanca",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Skopje": {
      "text": "(UTC+01:00) Europe/Skopje",
      "value": "Europe/Skopje",
      "timezone": "Europe/Skopje",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Sarajevo": {
      "text": "(UTC+01:00) Europe/Sarajevo",
      "value": "Europe/Sarajevo",
      "timezone": "Europe/Sarajevo",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/San_Marino": {
      "text": "(UTC+01:00) Europe/San_Marino",
      "value": "Europe/San_Marino",
      "timezone": "Europe/San_Marino",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Africa/Tunis": {
      "text": "(UTC+01:00) Africa/Tunis",
      "value": "Africa/Tunis",
      "timezone": "Africa/Tunis",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Rome": {
      "text": "(UTC+01:00) Europe/Rome",
      "value": "Europe/Rome",
      "timezone": "Europe/Rome",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Stockholm": {
      "text": "(UTC+01:00) Europe/Stockholm",
      "value": "Europe/Stockholm",
      "timezone": "Europe/Stockholm",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Vatican": {
      "text": "(UTC+01:00) Europe/Vatican",
      "value": "Europe/Vatican",
      "timezone": "Europe/Vatican",
      "offset": 3600,
      "offsetFormatted": "+01:00",
      "prettyOffSetFormatted": "UTC+01:00"
    },
    "Europe/Tallinn": {
      "text": "(UTC+02:00) Europe/Tallinn",
      "value": "Europe/Tallinn",
      "timezone": "Europe/Tallinn",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Uzhgorod": {
      "text": "(UTC+02:00) Europe/Uzhgorod",
      "value": "Europe/Uzhgorod",
      "timezone": "Europe/Uzhgorod",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Chisinau": {
      "text": "(UTC+02:00) Europe/Chisinau",
      "value": "Europe/Chisinau",
      "timezone": "Europe/Chisinau",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Athens": {
      "text": "(UTC+02:00) Europe/Athens",
      "value": "Europe/Athens",
      "timezone": "Europe/Athens",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Vilnius": {
      "text": "(UTC+02:00) Europe/Vilnius",
      "value": "Europe/Vilnius",
      "timezone": "Europe/Vilnius",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Bucharest": {
      "text": "(UTC+02:00) Europe/Bucharest",
      "value": "Europe/Bucharest",
      "timezone": "Europe/Bucharest",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Zaporozhye": {
      "text": "(UTC+02:00) Europe/Zaporozhye",
      "value": "Europe/Zaporozhye",
      "timezone": "Europe/Zaporozhye",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Helsinki": {
      "text": "(UTC+02:00) Europe/Helsinki",
      "value": "Europe/Helsinki",
      "timezone": "Europe/Helsinki",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Kaliningrad": {
      "text": "(UTC+02:00) Europe/Kaliningrad",
      "value": "Europe/Kaliningrad",
      "timezone": "Europe/Kaliningrad",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Kiev": {
      "text": "(UTC+02:00) Europe/Kiev",
      "value": "Europe/Kiev",
      "timezone": "Europe/Kiev",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Sofia": {
      "text": "(UTC+02:00) Europe/Sofia",
      "value": "Europe/Sofia",
      "timezone": "Europe/Sofia",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Mariehamn": {
      "text": "(UTC+02:00) Europe/Mariehamn",
      "value": "Europe/Mariehamn",
      "timezone": "Europe/Mariehamn",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Europe/Riga": {
      "text": "(UTC+02:00) Europe/Riga",
      "value": "Europe/Riga",
      "timezone": "Europe/Riga",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Nicosia": {
      "text": "(UTC+02:00) Asia/Nicosia",
      "value": "Asia/Nicosia",
      "timezone": "Asia/Nicosia",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Amman": {
      "text": "(UTC+02:00) Asia/Amman",
      "value": "Asia/Amman",
      "timezone": "Asia/Amman",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Hebron": {
      "text": "(UTC+02:00) Asia/Hebron",
      "value": "Asia/Hebron",
      "timezone": "Asia/Hebron",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Windhoek": {
      "text": "(UTC+02:00) Africa/Windhoek",
      "value": "Africa/Windhoek",
      "timezone": "Africa/Windhoek",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Kigali": {
      "text": "(UTC+02:00) Africa/Kigali",
      "value": "Africa/Kigali",
      "timezone": "Africa/Kigali",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Lubumbashi": {
      "text": "(UTC+02:00) Africa/Lubumbashi",
      "value": "Africa/Lubumbashi",
      "timezone": "Africa/Lubumbashi",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Jerusalem": {
      "text": "(UTC+02:00) Asia/Jerusalem",
      "value": "Asia/Jerusalem",
      "timezone": "Asia/Jerusalem",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Johannesburg": {
      "text": "(UTC+02:00) Africa/Johannesburg",
      "value": "Africa/Johannesburg",
      "timezone": "Africa/Johannesburg",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Harare": {
      "text": "(UTC+02:00) Africa/Harare",
      "value": "Africa/Harare",
      "timezone": "Africa/Harare",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Gaborone": {
      "text": "(UTC+02:00) Africa/Gaborone",
      "value": "Africa/Gaborone",
      "timezone": "Africa/Gaborone",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Lusaka": {
      "text": "(UTC+02:00) Africa/Lusaka",
      "value": "Africa/Lusaka",
      "timezone": "Africa/Lusaka",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Beirut": {
      "text": "(UTC+02:00) Asia/Beirut",
      "value": "Asia/Beirut",
      "timezone": "Asia/Beirut",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Maputo": {
      "text": "(UTC+02:00) Africa/Maputo",
      "value": "Africa/Maputo",
      "timezone": "Africa/Maputo",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Maseru": {
      "text": "(UTC+02:00) Africa/Maseru",
      "value": "Africa/Maseru",
      "timezone": "Africa/Maseru",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Khartoum": {
      "text": "(UTC+02:00) Africa/Khartoum",
      "value": "Africa/Khartoum",
      "timezone": "Africa/Khartoum",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Damascus": {
      "text": "(UTC+02:00) Asia/Damascus",
      "value": "Asia/Damascus",
      "timezone": "Asia/Damascus",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Tripoli": {
      "text": "(UTC+02:00) Africa/Tripoli",
      "value": "Africa/Tripoli",
      "timezone": "Africa/Tripoli",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Mbabane": {
      "text": "(UTC+02:00) Africa/Mbabane",
      "value": "Africa/Mbabane",
      "timezone": "Africa/Mbabane",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Cairo": {
      "text": "(UTC+02:00) Africa/Cairo",
      "value": "Africa/Cairo",
      "timezone": "Africa/Cairo",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Bujumbura": {
      "text": "(UTC+02:00) Africa/Bujumbura",
      "value": "Africa/Bujumbura",
      "timezone": "Africa/Bujumbura",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Africa/Blantyre": {
      "text": "(UTC+02:00) Africa/Blantyre",
      "value": "Africa/Blantyre",
      "timezone": "Africa/Blantyre",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Famagusta": {
      "text": "(UTC+02:00) Asia/Famagusta",
      "value": "Asia/Famagusta",
      "timezone": "Asia/Famagusta",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Gaza": {
      "text": "(UTC+02:00) Asia/Gaza",
      "value": "Asia/Gaza",
      "timezone": "Asia/Gaza",
      "offset": 7200,
      "offsetFormatted": "+02:00",
      "prettyOffSetFormatted": "UTC+02:00"
    },
    "Asia/Bahrain": {
      "text": "(UTC+03:00) Asia/Bahrain",
      "value": "Asia/Bahrain",
      "timezone": "Asia/Bahrain",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Kampala": {
      "text": "(UTC+03:00) Africa/Kampala",
      "value": "Africa/Kampala",
      "timezone": "Africa/Kampala",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Indian/Antananarivo": {
      "text": "(UTC+03:00) Indian/Antananarivo",
      "value": "Indian/Antananarivo",
      "timezone": "Indian/Antananarivo",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Indian/Comoro": {
      "text": "(UTC+03:00) Indian/Comoro",
      "value": "Indian/Comoro",
      "timezone": "Indian/Comoro",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Europe/Kirov": {
      "text": "(UTC+03:00) Europe/Kirov",
      "value": "Europe/Kirov",
      "timezone": "Europe/Kirov",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Nairobi": {
      "text": "(UTC+03:00) Africa/Nairobi",
      "value": "Africa/Nairobi",
      "timezone": "Africa/Nairobi",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Kuwait": {
      "text": "(UTC+03:00) Asia/Kuwait",
      "value": "Asia/Kuwait",
      "timezone": "Asia/Kuwait",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Europe/Istanbul": {
      "text": "(UTC+03:00) Europe/Istanbul",
      "value": "Europe/Istanbul",
      "timezone": "Europe/Istanbul",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Juba": {
      "text": "(UTC+03:00) Africa/Juba",
      "value": "Africa/Juba",
      "timezone": "Africa/Juba",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Indian/Mayotte": {
      "text": "(UTC+03:00) Indian/Mayotte",
      "value": "Indian/Mayotte",
      "timezone": "Indian/Mayotte",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Baghdad": {
      "text": "(UTC+03:00) Asia/Baghdad",
      "value": "Asia/Baghdad",
      "timezone": "Asia/Baghdad",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Addis_Ababa": {
      "text": "(UTC+03:00) Africa/Addis_Ababa",
      "value": "Africa/Addis_Ababa",
      "timezone": "Africa/Addis_Ababa",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Asmara": {
      "text": "(UTC+03:00) Africa/Asmara",
      "value": "Africa/Asmara",
      "timezone": "Africa/Asmara",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Mogadishu": {
      "text": "(UTC+03:00) Africa/Mogadishu",
      "value": "Africa/Mogadishu",
      "timezone": "Africa/Mogadishu",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Antarctica/Syowa": {
      "text": "(UTC+03:00) Antarctica/Syowa",
      "value": "Antarctica/Syowa",
      "timezone": "Antarctica/Syowa",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Aden": {
      "text": "(UTC+03:00) Asia/Aden",
      "value": "Asia/Aden",
      "timezone": "Asia/Aden",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Europe/Moscow": {
      "text": "(UTC+03:00) Europe/Moscow",
      "value": "Europe/Moscow",
      "timezone": "Europe/Moscow",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Europe/Simferopol": {
      "text": "(UTC+03:00) Europe/Simferopol",
      "value": "Europe/Simferopol",
      "timezone": "Europe/Simferopol",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Europe/Minsk": {
      "text": "(UTC+03:00) Europe/Minsk",
      "value": "Europe/Minsk",
      "timezone": "Europe/Minsk",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Dar_es_Salaam": {
      "text": "(UTC+03:00) Africa/Dar_es_Salaam",
      "value": "Africa/Dar_es_Salaam",
      "timezone": "Africa/Dar_es_Salaam",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Qatar": {
      "text": "(UTC+03:00) Asia/Qatar",
      "value": "Asia/Qatar",
      "timezone": "Asia/Qatar",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Riyadh": {
      "text": "(UTC+03:00) Asia/Riyadh",
      "value": "Asia/Riyadh",
      "timezone": "Asia/Riyadh",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Africa/Djibouti": {
      "text": "(UTC+03:00) Africa/Djibouti",
      "value": "Africa/Djibouti",
      "timezone": "Africa/Djibouti",
      "offset": 10800,
      "offsetFormatted": "+03:00",
      "prettyOffSetFormatted": "UTC+03:00"
    },
    "Asia/Tehran": {
      "text": "(UTC+03:30) Asia/Tehran",
      "value": "Asia/Tehran",
      "timezone": "Asia/Tehran",
      "offset": 12600,
      "offsetFormatted": "+03:30",
      "prettyOffSetFormatted": "UTC+03:30"
    },
    "Europe/Samara": {
      "text": "(UTC+04:00) Europe/Samara",
      "value": "Europe/Samara",
      "timezone": "Europe/Samara",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Europe/Volgograd": {
      "text": "(UTC+04:00) Europe/Volgograd",
      "value": "Europe/Volgograd",
      "timezone": "Europe/Volgograd",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Baku": {
      "text": "(UTC+04:00) Asia/Baku",
      "value": "Asia/Baku",
      "timezone": "Asia/Baku",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Indian/Mauritius": {
      "text": "(UTC+04:00) Indian/Mauritius",
      "value": "Indian/Mauritius",
      "timezone": "Indian/Mauritius",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Dubai": {
      "text": "(UTC+04:00) Asia/Dubai",
      "value": "Asia/Dubai",
      "timezone": "Asia/Dubai",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Yerevan": {
      "text": "(UTC+04:00) Asia/Yerevan",
      "value": "Asia/Yerevan",
      "timezone": "Asia/Yerevan",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Indian/Mahe": {
      "text": "(UTC+04:00) Indian/Mahe",
      "value": "Indian/Mahe",
      "timezone": "Indian/Mahe",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Europe/Astrakhan": {
      "text": "(UTC+04:00) Europe/Astrakhan",
      "value": "Europe/Astrakhan",
      "timezone": "Europe/Astrakhan",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Tbilisi": {
      "text": "(UTC+04:00) Asia/Tbilisi",
      "value": "Asia/Tbilisi",
      "timezone": "Asia/Tbilisi",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Indian/Reunion": {
      "text": "(UTC+04:00) Indian/Reunion",
      "value": "Indian/Reunion",
      "timezone": "Indian/Reunion",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Europe/Saratov": {
      "text": "(UTC+04:00) Europe/Saratov",
      "value": "Europe/Saratov",
      "timezone": "Europe/Saratov",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Muscat": {
      "text": "(UTC+04:00) Asia/Muscat",
      "value": "Asia/Muscat",
      "timezone": "Asia/Muscat",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Europe/Ulyanovsk": {
      "text": "(UTC+04:00) Europe/Ulyanovsk",
      "value": "Europe/Ulyanovsk",
      "timezone": "Europe/Ulyanovsk",
      "offset": 14400,
      "offsetFormatted": "+04:00",
      "prettyOffSetFormatted": "UTC+04:00"
    },
    "Asia/Kabul": {
      "text": "(UTC+04:30) Asia/Kabul",
      "value": "Asia/Kabul",
      "timezone": "Asia/Kabul",
      "offset": 16200,
      "offsetFormatted": "+04:30",
      "prettyOffSetFormatted": "UTC+04:30"
    },
    "Asia/Dushanbe": {
      "text": "(UTC+05:00) Asia/Dushanbe",
      "value": "Asia/Dushanbe",
      "timezone": "Asia/Dushanbe",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Atyrau": {
      "text": "(UTC+05:00) Asia/Atyrau",
      "value": "Asia/Atyrau",
      "timezone": "Asia/Atyrau",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Ashgabat": {
      "text": "(UTC+05:00) Asia/Ashgabat",
      "value": "Asia/Ashgabat",
      "timezone": "Asia/Ashgabat",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Aqtobe": {
      "text": "(UTC+05:00) Asia/Aqtobe",
      "value": "Asia/Aqtobe",
      "timezone": "Asia/Aqtobe",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Aqtau": {
      "text": "(UTC+05:00) Asia/Aqtau",
      "value": "Asia/Aqtau",
      "timezone": "Asia/Aqtau",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Antarctica/Mawson": {
      "text": "(UTC+05:00) Antarctica/Mawson",
      "value": "Antarctica/Mawson",
      "timezone": "Antarctica/Mawson",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Samarkand": {
      "text": "(UTC+05:00) Asia/Samarkand",
      "value": "Asia/Samarkand",
      "timezone": "Asia/Samarkand",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Indian/Kerguelen": {
      "text": "(UTC+05:00) Indian/Kerguelen",
      "value": "Indian/Kerguelen",
      "timezone": "Indian/Kerguelen",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Tashkent": {
      "text": "(UTC+05:00) Asia/Tashkent",
      "value": "Asia/Tashkent",
      "timezone": "Asia/Tashkent",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Karachi": {
      "text": "(UTC+05:00) Asia/Karachi",
      "value": "Asia/Karachi",
      "timezone": "Asia/Karachi",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Indian/Maldives": {
      "text": "(UTC+05:00) Indian/Maldives",
      "value": "Indian/Maldives",
      "timezone": "Indian/Maldives",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Yekaterinburg": {
      "text": "(UTC+05:00) Asia/Yekaterinburg",
      "value": "Asia/Yekaterinburg",
      "timezone": "Asia/Yekaterinburg",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Oral": {
      "text": "(UTC+05:00) Asia/Oral",
      "value": "Asia/Oral",
      "timezone": "Asia/Oral",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Qyzylorda": {
      "text": "(UTC+05:00) Asia/Qyzylorda",
      "value": "Asia/Qyzylorda",
      "timezone": "Asia/Qyzylorda",
      "offset": 18000,
      "offsetFormatted": "+05:00",
      "prettyOffSetFormatted": "UTC+05:00"
    },
    "Asia/Kolkata": {
      "text": "(UTC+05:30) Asia/Kolkata",
      "value": "Asia/Kolkata",
      "timezone": "Asia/Kolkata",
      "offset": 19800,
      "offsetFormatted": "+05:30",
      "prettyOffSetFormatted": "UTC+05:30"
    },
    "Asia/Colombo": {
      "text": "(UTC+05:30) Asia/Colombo",
      "value": "Asia/Colombo",
      "timezone": "Asia/Colombo",
      "offset": 19800,
      "offsetFormatted": "+05:30",
      "prettyOffSetFormatted": "UTC+05:30"
    },
    "Asia/Kathmandu": {
      "text": "(UTC+05:45) Asia/Kathmandu",
      "value": "Asia/Kathmandu",
      "timezone": "Asia/Kathmandu",
      "offset": 20700,
      "offsetFormatted": "+05:45",
      "prettyOffSetFormatted": "UTC+05:45"
    },
    "Asia/Thimphu": {
      "text": "(UTC+06:00) Asia/Thimphu",
      "value": "Asia/Thimphu",
      "timezone": "Asia/Thimphu",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Qostanay": {
      "text": "(UTC+06:00) Asia/Qostanay",
      "value": "Asia/Qostanay",
      "timezone": "Asia/Qostanay",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Urumqi": {
      "text": "(UTC+06:00) Asia/Urumqi",
      "value": "Asia/Urumqi",
      "timezone": "Asia/Urumqi",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Indian/Chagos": {
      "text": "(UTC+06:00) Indian/Chagos",
      "value": "Indian/Chagos",
      "timezone": "Indian/Chagos",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Bishkek": {
      "text": "(UTC+06:00) Asia/Bishkek",
      "value": "Asia/Bishkek",
      "timezone": "Asia/Bishkek",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Almaty": {
      "text": "(UTC+06:00) Asia/Almaty",
      "value": "Asia/Almaty",
      "timezone": "Asia/Almaty",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Dhaka": {
      "text": "(UTC+06:00) Asia/Dhaka",
      "value": "Asia/Dhaka",
      "timezone": "Asia/Dhaka",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Antarctica/Vostok": {
      "text": "(UTC+06:00) Antarctica/Vostok",
      "value": "Antarctica/Vostok",
      "timezone": "Antarctica/Vostok",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Omsk": {
      "text": "(UTC+06:00) Asia/Omsk",
      "value": "Asia/Omsk",
      "timezone": "Asia/Omsk",
      "offset": 21600,
      "offsetFormatted": "+06:00",
      "prettyOffSetFormatted": "UTC+06:00"
    },
    "Asia/Yangon": {
      "text": "(UTC+06:30) Asia/Yangon",
      "value": "Asia/Yangon",
      "timezone": "Asia/Yangon",
      "offset": 23400,
      "offsetFormatted": "+06:30",
      "prettyOffSetFormatted": "UTC+06:30"
    },
    "Indian/Cocos": {
      "text": "(UTC+06:30) Indian/Cocos",
      "value": "Indian/Cocos",
      "timezone": "Indian/Cocos",
      "offset": 23400,
      "offsetFormatted": "+06:30",
      "prettyOffSetFormatted": "UTC+06:30"
    },
    "Asia/Phnom_Penh": {
      "text": "(UTC+07:00) Asia/Phnom_Penh",
      "value": "Asia/Phnom_Penh",
      "timezone": "Asia/Phnom_Penh",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Pontianak": {
      "text": "(UTC+07:00) Asia/Pontianak",
      "value": "Asia/Pontianak",
      "timezone": "Asia/Pontianak",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Novosibirsk": {
      "text": "(UTC+07:00) Asia/Novosibirsk",
      "value": "Asia/Novosibirsk",
      "timezone": "Asia/Novosibirsk",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Novokuznetsk": {
      "text": "(UTC+07:00) Asia/Novokuznetsk",
      "value": "Asia/Novokuznetsk",
      "timezone": "Asia/Novokuznetsk",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Hovd": {
      "text": "(UTC+07:00) Asia/Hovd",
      "value": "Asia/Hovd",
      "timezone": "Asia/Hovd",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Tomsk": {
      "text": "(UTC+07:00) Asia/Tomsk",
      "value": "Asia/Tomsk",
      "timezone": "Asia/Tomsk",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Bangkok": {
      "text": "(UTC+07:00) Asia/Bangkok",
      "value": "Asia/Bangkok",
      "timezone": "Asia/Bangkok",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Jakarta": {
      "text": "(UTC+07:00) Asia/Jakarta",
      "value": "Asia/Jakarta",
      "timezone": "Asia/Jakarta",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Vientiane": {
      "text": "(UTC+07:00) Asia/Vientiane",
      "value": "Asia/Vientiane",
      "timezone": "Asia/Vientiane",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Antarctica/Davis": {
      "text": "(UTC+07:00) Antarctica/Davis",
      "value": "Antarctica/Davis",
      "timezone": "Antarctica/Davis",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Krasnoyarsk": {
      "text": "(UTC+07:00) Asia/Krasnoyarsk",
      "value": "Asia/Krasnoyarsk",
      "timezone": "Asia/Krasnoyarsk",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Indian/Christmas": {
      "text": "(UTC+07:00) Indian/Christmas",
      "value": "Indian/Christmas",
      "timezone": "Indian/Christmas",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Ho_Chi_Minh": {
      "text": "(UTC+07:00) Asia/Ho_Chi_Minh",
      "value": "Asia/Ho_Chi_Minh",
      "timezone": "Asia/Ho_Chi_Minh",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Asia/Barnaul": {
      "text": "(UTC+07:00) Asia/Barnaul",
      "value": "Asia/Barnaul",
      "timezone": "Asia/Barnaul",
      "offset": 25200,
      "offsetFormatted": "+07:00",
      "prettyOffSetFormatted": "UTC+07:00"
    },
    "Antarctica/Casey": {
      "text": "(UTC+08:00) Antarctica/Casey",
      "value": "Antarctica/Casey",
      "timezone": "Antarctica/Casey",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Kuching": {
      "text": "(UTC+08:00) Asia/Kuching",
      "value": "Asia/Kuching",
      "timezone": "Asia/Kuching",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Ulaanbaatar": {
      "text": "(UTC+08:00) Asia/Ulaanbaatar",
      "value": "Asia/Ulaanbaatar",
      "timezone": "Asia/Ulaanbaatar",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Irkutsk": {
      "text": "(UTC+08:00) Asia/Irkutsk",
      "value": "Asia/Irkutsk",
      "timezone": "Asia/Irkutsk",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Makassar": {
      "text": "(UTC+08:00) Asia/Makassar",
      "value": "Asia/Makassar",
      "timezone": "Asia/Makassar",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Macau": {
      "text": "(UTC+08:00) Asia/Macau",
      "value": "Asia/Macau",
      "timezone": "Asia/Macau",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Singapore": {
      "text": "(UTC+08:00) Asia/Singapore",
      "value": "Asia/Singapore",
      "timezone": "Asia/Singapore",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Shanghai": {
      "text": "(UTC+08:00) Asia/Shanghai",
      "value": "Asia/Shanghai",
      "timezone": "Asia/Shanghai",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Australia/Perth": {
      "text": "(UTC+08:00) Australia/Perth",
      "value": "Australia/Perth",
      "timezone": "Australia/Perth",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Hong_Kong": {
      "text": "(UTC+08:00) Asia/Hong_Kong",
      "value": "Asia/Hong_Kong",
      "timezone": "Asia/Hong_Kong",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Choibalsan": {
      "text": "(UTC+08:00) Asia/Choibalsan",
      "value": "Asia/Choibalsan",
      "timezone": "Asia/Choibalsan",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Kuala_Lumpur": {
      "text": "(UTC+08:00) Asia/Kuala_Lumpur",
      "value": "Asia/Kuala_Lumpur",
      "timezone": "Asia/Kuala_Lumpur",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Brunei": {
      "text": "(UTC+08:00) Asia/Brunei",
      "value": "Asia/Brunei",
      "timezone": "Asia/Brunei",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Taipei": {
      "text": "(UTC+08:00) Asia/Taipei",
      "value": "Asia/Taipei",
      "timezone": "Asia/Taipei",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Asia/Manila": {
      "text": "(UTC+08:00) Asia/Manila",
      "value": "Asia/Manila",
      "timezone": "Asia/Manila",
      "offset": 28800,
      "offsetFormatted": "+08:00",
      "prettyOffSetFormatted": "UTC+08:00"
    },
    "Australia/Eucla": {
      "text": "(UTC+08:45) Australia/Eucla",
      "value": "Australia/Eucla",
      "timezone": "Australia/Eucla",
      "offset": 31500,
      "offsetFormatted": "+08:45",
      "prettyOffSetFormatted": "UTC+08:45"
    },
    "Asia/Jayapura": {
      "text": "(UTC+09:00) Asia/Jayapura",
      "value": "Asia/Jayapura",
      "timezone": "Asia/Jayapura",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Yakutsk": {
      "text": "(UTC+09:00) Asia/Yakutsk",
      "value": "Asia/Yakutsk",
      "timezone": "Asia/Yakutsk",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Khandyga": {
      "text": "(UTC+09:00) Asia/Khandyga",
      "value": "Asia/Khandyga",
      "timezone": "Asia/Khandyga",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Pyongyang": {
      "text": "(UTC+09:00) Asia/Pyongyang",
      "value": "Asia/Pyongyang",
      "timezone": "Asia/Pyongyang",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Tokyo": {
      "text": "(UTC+09:00) Asia/Tokyo",
      "value": "Asia/Tokyo",
      "timezone": "Asia/Tokyo",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Dili": {
      "text": "(UTC+09:00) Asia/Dili",
      "value": "Asia/Dili",
      "timezone": "Asia/Dili",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Chita": {
      "text": "(UTC+09:00) Asia/Chita",
      "value": "Asia/Chita",
      "timezone": "Asia/Chita",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Asia/Seoul": {
      "text": "(UTC+09:00) Asia/Seoul",
      "value": "Asia/Seoul",
      "timezone": "Asia/Seoul",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Pacific/Palau": {
      "text": "(UTC+09:00) Pacific/Palau",
      "value": "Pacific/Palau",
      "timezone": "Pacific/Palau",
      "offset": 32400,
      "offsetFormatted": "+09:00",
      "prettyOffSetFormatted": "UTC+09:00"
    },
    "Australia/Darwin": {
      "text": "(UTC+09:30) Australia/Darwin",
      "value": "Australia/Darwin",
      "timezone": "Australia/Darwin",
      "offset": 34200,
      "offsetFormatted": "+09:30",
      "prettyOffSetFormatted": "UTC+09:30"
    },
    "Pacific/Port_Moresby": {
      "text": "(UTC+10:00) Pacific/Port_Moresby",
      "value": "Pacific/Port_Moresby",
      "timezone": "Pacific/Port_Moresby",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Pacific/Chuuk": {
      "text": "(UTC+10:00) Pacific/Chuuk",
      "value": "Pacific/Chuuk",
      "timezone": "Pacific/Chuuk",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Antarctica/DumontDUrville": {
      "text": "(UTC+10:00) Antarctica/DumontDUrville",
      "value": "Antarctica/DumontDUrville",
      "timezone": "Antarctica/DumontDUrville",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Pacific/Saipan": {
      "text": "(UTC+10:00) Pacific/Saipan",
      "value": "Pacific/Saipan",
      "timezone": "Pacific/Saipan",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Pacific/Guam": {
      "text": "(UTC+10:00) Pacific/Guam",
      "value": "Pacific/Guam",
      "timezone": "Pacific/Guam",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Asia/Vladivostok": {
      "text": "(UTC+10:00) Asia/Vladivostok",
      "value": "Asia/Vladivostok",
      "timezone": "Asia/Vladivostok",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Asia/Ust-Nera": {
      "text": "(UTC+10:00) Asia/Ust-Nera",
      "value": "Asia/Ust-Nera",
      "timezone": "Asia/Ust-Nera",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Australia/Brisbane": {
      "text": "(UTC+10:00) Australia/Brisbane",
      "value": "Australia/Brisbane",
      "timezone": "Australia/Brisbane",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Australia/Lindeman": {
      "text": "(UTC+10:00) Australia/Lindeman",
      "value": "Australia/Lindeman",
      "timezone": "Australia/Lindeman",
      "offset": 36000,
      "offsetFormatted": "+10:00",
      "prettyOffSetFormatted": "UTC+10:00"
    },
    "Australia/Adelaide": {
      "text": "(UTC+10:30) Australia/Adelaide",
      "value": "Australia/Adelaide",
      "timezone": "Australia/Adelaide",
      "offset": 37800,
      "offsetFormatted": "+10:30",
      "prettyOffSetFormatted": "UTC+10:30"
    },
    "Australia/Broken_Hill": {
      "text": "(UTC+10:30) Australia/Broken_Hill",
      "value": "Australia/Broken_Hill",
      "timezone": "Australia/Broken_Hill",
      "offset": 37800,
      "offsetFormatted": "+10:30",
      "prettyOffSetFormatted": "UTC+10:30"
    },
    "Pacific/Bougainville": {
      "text": "(UTC+11:00) Pacific/Bougainville",
      "value": "Pacific/Bougainville",
      "timezone": "Pacific/Bougainville",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Asia/Magadan": {
      "text": "(UTC+11:00) Asia/Magadan",
      "value": "Asia/Magadan",
      "timezone": "Asia/Magadan",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Asia/Sakhalin": {
      "text": "(UTC+11:00) Asia/Sakhalin",
      "value": "Asia/Sakhalin",
      "timezone": "Asia/Sakhalin",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Noumea": {
      "text": "(UTC+11:00) Pacific/Noumea",
      "value": "Pacific/Noumea",
      "timezone": "Pacific/Noumea",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Kosrae": {
      "text": "(UTC+11:00) Pacific/Kosrae",
      "value": "Pacific/Kosrae",
      "timezone": "Pacific/Kosrae",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Australia/Sydney": {
      "text": "(UTC+11:00) Australia/Sydney",
      "value": "Australia/Sydney",
      "timezone": "Australia/Sydney",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Guadalcanal": {
      "text": "(UTC+11:00) Pacific/Guadalcanal",
      "value": "Pacific/Guadalcanal",
      "timezone": "Pacific/Guadalcanal",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Australia/Melbourne": {
      "text": "(UTC+11:00) Australia/Melbourne",
      "value": "Australia/Melbourne",
      "timezone": "Australia/Melbourne",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Australia/Lord_Howe": {
      "text": "(UTC+11:00) Australia/Lord_Howe",
      "value": "Australia/Lord_Howe",
      "timezone": "Australia/Lord_Howe",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Australia/Hobart": {
      "text": "(UTC+11:00) Australia/Hobart",
      "value": "Australia/Hobart",
      "timezone": "Australia/Hobart",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Pohnpei": {
      "text": "(UTC+11:00) Pacific/Pohnpei",
      "value": "Pacific/Pohnpei",
      "timezone": "Pacific/Pohnpei",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Australia/Currie": {
      "text": "(UTC+11:00) Australia/Currie",
      "value": "Australia/Currie",
      "timezone": "Australia/Currie",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Efate": {
      "text": "(UTC+11:00) Pacific/Efate",
      "value": "Pacific/Efate",
      "timezone": "Pacific/Efate",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Asia/Srednekolymsk": {
      "text": "(UTC+11:00) Asia/Srednekolymsk",
      "value": "Asia/Srednekolymsk",
      "timezone": "Asia/Srednekolymsk",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Antarctica/Macquarie": {
      "text": "(UTC+11:00) Antarctica/Macquarie",
      "value": "Antarctica/Macquarie",
      "timezone": "Antarctica/Macquarie",
      "offset": 39600,
      "offsetFormatted": "+11:00",
      "prettyOffSetFormatted": "UTC+11:00"
    },
    "Pacific/Tarawa": {
      "text": "(UTC+12:00) Pacific/Tarawa",
      "value": "Pacific/Tarawa",
      "timezone": "Pacific/Tarawa",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Wake": {
      "text": "(UTC+12:00) Pacific/Wake",
      "value": "Pacific/Wake",
      "timezone": "Pacific/Wake",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Norfolk": {
      "text": "(UTC+12:00) Pacific/Norfolk",
      "value": "Pacific/Norfolk",
      "timezone": "Pacific/Norfolk",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Wallis": {
      "text": "(UTC+12:00) Pacific/Wallis",
      "value": "Pacific/Wallis",
      "timezone": "Pacific/Wallis",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Nauru": {
      "text": "(UTC+12:00) Pacific/Nauru",
      "value": "Pacific/Nauru",
      "timezone": "Pacific/Nauru",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Majuro": {
      "text": "(UTC+12:00) Pacific/Majuro",
      "value": "Pacific/Majuro",
      "timezone": "Pacific/Majuro",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Kwajalein": {
      "text": "(UTC+12:00) Pacific/Kwajalein",
      "value": "Pacific/Kwajalein",
      "timezone": "Pacific/Kwajalein",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Funafuti": {
      "text": "(UTC+12:00) Pacific/Funafuti",
      "value": "Pacific/Funafuti",
      "timezone": "Pacific/Funafuti",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Asia/Kamchatka": {
      "text": "(UTC+12:00) Asia/Kamchatka",
      "value": "Asia/Kamchatka",
      "timezone": "Asia/Kamchatka",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Asia/Anadyr": {
      "text": "(UTC+12:00) Asia/Anadyr",
      "value": "Asia/Anadyr",
      "timezone": "Asia/Anadyr",
      "offset": 43200,
      "offsetFormatted": "+12:00",
      "prettyOffSetFormatted": "UTC+12:00"
    },
    "Pacific/Fiji": {
      "text": "(UTC+13:00) Pacific/Fiji",
      "value": "Pacific/Fiji",
      "timezone": "Pacific/Fiji",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Pacific/Fakaofo": {
      "text": "(UTC+13:00) Pacific/Fakaofo",
      "value": "Pacific/Fakaofo",
      "timezone": "Pacific/Fakaofo",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Pacific/Enderbury": {
      "text": "(UTC+13:00) Pacific/Enderbury",
      "value": "Pacific/Enderbury",
      "timezone": "Pacific/Enderbury",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Pacific/Auckland": {
      "text": "(UTC+13:00) Pacific/Auckland",
      "value": "Pacific/Auckland",
      "timezone": "Pacific/Auckland",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Pacific/Tongatapu": {
      "text": "(UTC+13:00) Pacific/Tongatapu",
      "value": "Pacific/Tongatapu",
      "timezone": "Pacific/Tongatapu",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Antarctica/McMurdo": {
      "text": "(UTC+13:00) Antarctica/McMurdo",
      "value": "Antarctica/McMurdo",
      "timezone": "Antarctica/McMurdo",
      "offset": 46800,
      "offsetFormatted": "+13:00",
      "prettyOffSetFormatted": "UTC+13:00"
    },
    "Pacific/Chatham": {
      "text": "(UTC+13:45) Pacific/Chatham",
      "value": "Pacific/Chatham",
      "timezone": "Pacific/Chatham",
      "offset": 49500,
      "offsetFormatted": "+13:45",
      "prettyOffSetFormatted": "UTC+13:45"
    },
    "Pacific/Kiritimati": {
      "text": "(UTC+14:00) Pacific/Kiritimati",
      "value": "Pacific/Kiritimati",
      "timezone": "Pacific/Kiritimati",
      "offset": 50400,
      "offsetFormatted": "+14:00",
      "prettyOffSetFormatted": "UTC+14:00"
    },
    "Pacific/Apia": {
      "text": "(UTC+14:00) Pacific/Apia",
      "value": "Pacific/Apia",
      "timezone": "Pacific/Apia",
      "offset": 50400,
      "offsetFormatted": "+14:00",
      "prettyOffSetFormatted": "UTC+14:00"
    }
  },
  "offsets": {
    "0": [
      "Africa/Abidjan",
      "Africa/Accra",
      "Africa/Bamako",
      "Africa/Banjul",
      "Africa/Bissau",
      "Africa/Conakry",
      "Africa/Dakar",
      "Africa/Freetown",
      "Africa/Lome",
      "Africa/Monrovia",
      "Africa/Nouakchott",
      "Africa/Ouagadougou",
      "Africa/Sao_Tome",
      "America/Danmarkshavn",
      "Antarctica/Troll",
      "Atlantic/Canary",
      "Atlantic/Faroe",
      "Atlantic/Madeira",
      "Atlantic/Reykjavik",
      "Atlantic/St_Helena",
      "Europe/Dublin",
      "Europe/Guernsey",
      "Europe/Isle_of_Man",
      "Europe/Jersey",
      "Europe/Lisbon",
      "Europe/London"
    ],
    "3600": [
      "Africa/Algiers",
      "Africa/Bangui",
      "Africa/Brazzaville",
      "Africa/Casablanca",
      "Africa/Ceuta",
      "Africa/Douala",
      "Africa/El_Aaiun",
      "Africa/Kinshasa",
      "Africa/Lagos",
      "Africa/Libreville",
      "Africa/Luanda",
      "Africa/Malabo",
      "Africa/Ndjamena",
      "Africa/Niamey",
      "Africa/Porto-Novo",
      "Africa/Tunis",
      "Europe/Amsterdam",
      "Europe/Andorra",
      "Europe/Belgrade",
      "Europe/Berlin",
      "Europe/Bratislava",
      "Europe/Brussels",
      "Europe/Budapest",
      "Europe/Busingen",
      "Europe/Copenhagen",
      "Europe/Gibraltar",
      "Europe/Ljubljana",
      "Europe/Luxembourg",
      "Europe/Madrid",
      "Europe/Malta",
      "Europe/Monaco",
      "Europe/Oslo",
      "Europe/Paris",
      "Europe/Podgorica",
      "Europe/Prague",
      "Europe/Rome",
      "Europe/San_Marino",
      "Europe/Sarajevo",
      "Europe/Skopje",
      "Europe/Stockholm",
      "Europe/Tirane",
      "Europe/Vaduz",
      "Europe/Vatican",
      "Europe/Vienna",
      "Europe/Warsaw",
      "Europe/Zagreb",
      "Europe/Zurich"
    ],
    "7200": [
      "Africa/Blantyre",
      "Africa/Bujumbura",
      "Africa/Cairo",
      "Africa/Gaborone",
      "Africa/Harare",
      "Africa/Johannesburg",
      "Africa/Khartoum",
      "Africa/Kigali",
      "Africa/Lubumbashi",
      "Africa/Lusaka",
      "Africa/Maputo",
      "Africa/Maseru",
      "Africa/Mbabane",
      "Africa/Tripoli",
      "Africa/Windhoek",
      "Asia/Amman",
      "Asia/Beirut",
      "Asia/Damascus",
      "Asia/Famagusta",
      "Asia/Gaza",
      "Asia/Hebron",
      "Asia/Jerusalem",
      "Asia/Nicosia",
      "Europe/Athens",
      "Europe/Bucharest",
      "Europe/Chisinau",
      "Europe/Helsinki",
      "Europe/Kaliningrad",
      "Europe/Kiev",
      "Europe/Mariehamn",
      "Europe/Riga",
      "Europe/Sofia",
      "Europe/Tallinn",
      "Europe/Uzhgorod",
      "Europe/Vilnius",
      "Europe/Zaporozhye"
    ],
    "10800": [
      "Africa/Addis_Ababa",
      "Africa/Asmara",
      "Africa/Dar_es_Salaam",
      "Africa/Djibouti",
      "Africa/Juba",
      "Africa/Kampala",
      "Africa/Mogadishu",
      "Africa/Nairobi",
      "Antarctica/Syowa",
      "Asia/Aden",
      "Asia/Baghdad",
      "Asia/Bahrain",
      "Asia/Kuwait",
      "Asia/Qatar",
      "Asia/Riyadh",
      "Europe/Istanbul",
      "Europe/Kirov",
      "Europe/Minsk",
      "Europe/Moscow",
      "Europe/Simferopol",
      "Indian/Antananarivo",
      "Indian/Comoro",
      "Indian/Mayotte"
    ],
    "12600": [
      "Asia/Tehran"
    ],
    "14400": [
      "Asia/Baku",
      "Asia/Dubai",
      "Asia/Muscat",
      "Asia/Tbilisi",
      "Asia/Yerevan",
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Saratov",
      "Europe/Ulyanovsk",
      "Europe/Volgograd",
      "Indian/Mahe",
      "Indian/Mauritius",
      "Indian/Reunion"
    ],
    "16200": [
      "Asia/Kabul"
    ],
    "18000": [
      "Antarctica/Mawson",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Ashgabat",
      "Asia/Atyrau",
      "Asia/Dushanbe",
      "Asia/Karachi",
      "Asia/Oral",
      "Asia/Qyzylorda",
      "Asia/Samarkand",
      "Asia/Tashkent",
      "Asia/Yekaterinburg",
      "Indian/Kerguelen",
      "Indian/Maldives"
    ],
    "19800": [
      "Asia/Colombo",
      "Asia/Kolkata"
    ],
    "20700": [
      "Asia/Kathmandu"
    ],
    "21600": [
      "Antarctica/Vostok",
      "Asia/Almaty",
      "Asia/Bishkek",
      "Asia/Dhaka",
      "Asia/Omsk",
      "Asia/Qostanay",
      "Asia/Thimphu",
      "Asia/Urumqi",
      "Indian/Chagos"
    ],
    "23400": [
      "Asia/Yangon",
      "Indian/Cocos"
    ],
    "25200": [
      "Antarctica/Davis",
      "Asia/Bangkok",
      "Asia/Barnaul",
      "Asia/Ho_Chi_Minh",
      "Asia/Hovd",
      "Asia/Jakarta",
      "Asia/Krasnoyarsk",
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Phnom_Penh",
      "Asia/Pontianak",
      "Asia/Tomsk",
      "Asia/Vientiane",
      "Indian/Christmas"
    ],
    "28800": [
      "Antarctica/Casey",
      "Asia/Brunei",
      "Asia/Choibalsan",
      "Asia/Hong_Kong",
      "Asia/Irkutsk",
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Macau",
      "Asia/Makassar",
      "Asia/Manila",
      "Asia/Shanghai",
      "Asia/Singapore",
      "Asia/Taipei",
      "Asia/Ulaanbaatar",
      "Australia/Perth"
    ],
    "31500": [
      "Australia/Eucla"
    ],
    "32400": [
      "Asia/Chita",
      "Asia/Dili",
      "Asia/Jayapura",
      "Asia/Khandyga",
      "Asia/Pyongyang",
      "Asia/Seoul",
      "Asia/Tokyo",
      "Asia/Yakutsk",
      "Pacific/Palau"
    ],
    "34200": [
      "Australia/Darwin"
    ],
    "36000": [
      "Antarctica/DumontDUrville",
      "Asia/Ust-Nera",
      "Asia/Vladivostok",
      "Australia/Brisbane",
      "Australia/Lindeman",
      "Pacific/Chuuk",
      "Pacific/Guam",
      "Pacific/Port_Moresby",
      "Pacific/Saipan"
    ],
    "37800": [
      "Australia/Adelaide",
      "Australia/Broken_Hill"
    ],
    "39600": [
      "Antarctica/Macquarie",
      "Asia/Magadan",
      "Asia/Sakhalin",
      "Asia/Srednekolymsk",
      "Australia/Currie",
      "Australia/Hobart",
      "Australia/Lord_Howe",
      "Australia/Melbourne",
      "Australia/Sydney",
      "Pacific/Bougainville",
      "Pacific/Efate",
      "Pacific/Guadalcanal",
      "Pacific/Kosrae",
      "Pacific/Noumea",
      "Pacific/Pohnpei"
    ],
    "43200": [
      "Asia/Anadyr",
      "Asia/Kamchatka",
      "Pacific/Funafuti",
      "Pacific/Kwajalein",
      "Pacific/Majuro",
      "Pacific/Nauru",
      "Pacific/Norfolk",
      "Pacific/Tarawa",
      "Pacific/Wake",
      "Pacific/Wallis"
    ],
    "46800": [
      "Antarctica/McMurdo",
      "Pacific/Auckland",
      "Pacific/Enderbury",
      "Pacific/Fakaofo",
      "Pacific/Fiji",
      "Pacific/Tongatapu"
    ],
    "49500": [
      "Pacific/Chatham"
    ],
    "50400": [
      "Pacific/Apia",
      "Pacific/Kiritimati"
    ],
    "-36000": [
      "America/Adak",
      "Pacific/Honolulu",
      "Pacific/Rarotonga",
      "Pacific/Tahiti"
    ],
    "-32400": [
      "America/Anchorage",
      "America/Juneau",
      "America/Metlakatla",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
      "Pacific/Gambier"
    ],
    "-14400": [
      "America/Anguilla",
      "America/Antigua",
      "America/Aruba",
      "America/Barbados",
      "America/Blanc-Sablon",
      "America/Boa_Vista",
      "America/Campo_Grande",
      "America/Caracas",
      "America/Cuiaba",
      "America/Curacao",
      "America/Dominica",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Grenada",
      "America/Guadeloupe",
      "America/Guyana",
      "America/Halifax",
      "America/Kralendijk",
      "America/La_Paz",
      "America/Lower_Princes",
      "America/Manaus",
      "America/Marigot",
      "America/Martinique",
      "America/Moncton",
      "America/Montserrat",
      "America/Port_of_Spain",
      "America/Porto_Velho",
      "America/Puerto_Rico",
      "America/Santo_Domingo",
      "America/St_Barthelemy",
      "America/St_Kitts",
      "America/St_Lucia",
      "America/St_Thomas",
      "America/St_Vincent",
      "America/Thule",
      "America/Tortola",
      "Atlantic/Bermuda"
    ],
    "-10800": [
      "America/Araguaina",
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
      "America/Asuncion",
      "America/Bahia",
      "America/Belem",
      "America/Cayenne",
      "America/Fortaleza",
      "America/Godthab",
      "America/Maceio",
      "America/Miquelon",
      "America/Montevideo",
      "America/Paramaribo",
      "America/Punta_Arenas",
      "America/Recife",
      "America/Santarem",
      "America/Santiago",
      "America/Sao_Paulo",
      "Antarctica/Palmer",
      "Antarctica/Rothera",
      "Atlantic/Stanley"
    ],
    "-18000": [
      "America/Atikokan",
      "America/Bogota",
      "America/Cancun",
      "America/Cayman",
      "America/Detroit",
      "America/Eirunepe",
      "America/Grand_Turk",
      "America/Guayaquil",
      "America/Havana",
      "America/Indiana/Indianapolis",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Iqaluit",
      "America/Jamaica",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/Lima",
      "America/Nassau",
      "America/New_York",
      "America/Nipigon",
      "America/Panama",
      "America/Pangnirtung",
      "America/Port-au-Prince",
      "America/Rio_Branco",
      "America/Thunder_Bay",
      "America/Toronto",
      "Pacific/Easter"
    ],
    "-21600": [
      "America/Bahia_Banderas",
      "America/Belize",
      "America/Chicago",
      "America/Costa_Rica",
      "America/El_Salvador",
      "America/Guatemala",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Managua",
      "America/Matamoros",
      "America/Menominee",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Resolute",
      "America/Swift_Current",
      "America/Tegucigalpa",
      "America/Winnipeg",
      "Pacific/Galapagos"
    ],
    "-25200": [
      "America/Boise",
      "America/Cambridge_Bay",
      "America/Chihuahua",
      "America/Creston",
      "America/Dawson_Creek",
      "America/Denver",
      "America/Edmonton",
      "America/Fort_Nelson",
      "America/Hermosillo",
      "America/Inuvik",
      "America/Mazatlan",
      "America/Ojinaga",
      "America/Phoenix",
      "America/Yellowknife"
    ],
    "-28800": [
      "America/Dawson",
      "America/Los_Angeles",
      "America/Tijuana",
      "America/Vancouver",
      "America/Whitehorse",
      "Pacific/Pitcairn"
    ],
    "-7200": [
      "America/Noronha",
      "Atlantic/South_Georgia"
    ],
    "-3600": [
      "America/Scoresbysund",
      "Atlantic/Azores",
      "Atlantic/Cape_Verde"
    ],
    "-12600": [
      "America/St_Johns"
    ],
    "-34200": [
      "Pacific/Marquesas"
    ],
    "-39600": [
      "Pacific/Midway",
      "Pacific/Niue",
      "Pacific/Pago_Pago"
    ]
  },
  "normalize": {
    "Brasilia": "America/Sao_Paulo"
  }
};

export default tzlist;