




















import Vue from "vue";
import Component from "vue-class-component";
import CardBox from "@/components/CardBox.vue";
import CandidatoRegisterForm from "@/components/register/CandidatoRegisterForm.vue";
import FormConfirmation from "@/components/register/FormConfirmation.vue";
import NavBar from "@/views/layouts/NavBar.vue";
import { Action, Getter } from "vuex-class";
import { CandidatoEntity } from "@/core/models/Entities";
const namespace: string = "profile";

let PIDINTERVAL: any = 0;

@Component<CandidatoRegister>({
  components: {
    "card-box": CardBox,
    "register-form": CandidatoRegisterForm,
    "nav-bar": NavBar,
    FormConfirmation
  }
})
export default class CandidatoRegister extends Vue {
  public myPopUp: Window | null = null;
  public uri: string =
    window.document!.location!.protocol +
    "//" +
    window.document!.location!.host;
  public confirmationRequest: boolean = false;
  public candidato: CandidatoEntity|null = null;
  public vagaNomeUrl: string|null = null;

  @Action("userRequest", { namespace }) public  userRequest: any;
  @Action("authRequest", { namespace }) public authRequest: any;
  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfile", { namespace }) public getProfile: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  public created() {
    this.whereToSend();
  }

  public whereToSend() {
    let rurl: any = this.$route.query.redirectUrl;
  
    if (!rurl && sessionStorage.getItem("redirectTo")) {
      rurl = sessionStorage.getItem("redirectTo");
    }
  }

  public checkClosedWindow(w: Window | null, func: Function) {
    /* console.log("interval") */
    if (w !== null && w.closed) {
      /* console.log("closed_window") */
      clearInterval(PIDINTERVAL);
      func.call(0);
    }
  }

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }

  public async registerUser(at: string) {
    if(at) {
      // this.candidato =  new Candidato();
      sessionStorage.setItem("access_token", at+'');
      sessionStorage.setItem("token_type", "Bearer");
      sessionStorage.setItem("profile_type", "candidate");
      await this.userRequest().then(() => {
        /* console.log("ok") */
      }).catch(() => {
        this.removeItensFromStorage();
      })
    }else {
      this.removeItensFromStorage();
    }
  }

  public toggleBodyClass(addRemoveClass: any, className:any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  public mounted() {
    this.toggleBodyClass('addClass', 'register')
    this.vagaNomeUrl = this.$route.query.vagaURL+"";
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'register')
  }

  public registerFail() {
    console.log('fail')
  }

  public success(data: CandidatoEntity) {
    this.candidato = data;
    this.confirmationRequest = true;
    // this.$emit('success',data)
  }

  public successConfirmation(data: any) {
    
    if(this.vagaNomeUrl) {
      this.$router.push({ name: "DetalheVagaCandidato", params: { id: this.vagaNomeUrl+"" },  query: {lang: this.$i18n.locale, a: "1"} });
    } else if(sessionStorage.getItem("redirectTo")) {
      this.$router.push({ path: sessionStorage.getItem("redirectTo")+"",  query: {lang: this.$i18n.locale} });
    } else {
      this.$router.push({ name: "PerfilEdit",  query: {lang: this.$i18n.locale} });
    }
  }
}
