

















import { Component, Prop, Vue } from 'vue-property-decorator';
import MainNavigation from '@/components/main-navigation/MainNavigation.vue';

@Component({
  components: {
    MainNavigation
  }
})
export default class RootHeaderNavigation extends Vue {}
