





















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { MessageJobEntity } from '@/core/models/Entities';
import MessageJob from '@/core/Services/Basic/MessageJob';

@Component
export default class Mensagem extends Vue {
  public root: MessageJobEntity|null = null;
  public currMessages: MessageJobEntity|null = null;
  public messageJobService: MessageJob = new MessageJob;
  public messageText: string = '';
  public promise: Promise<any>|null = null;

  @Getter('getProfileType', { namespace: 'profile' })
  public getProfileType!: string;

  @Prop({ default: false }) public mobile!: boolean;
  @Prop({ default: false }) public loading!: boolean;

  public setRoot(message: MessageJobEntity) {
    this.root = message;
  }
  
  get isMobile() {
    return this.mobile
  }

  get primeironome() {
    return this.root?.candidato?.primeironome||"";
  }

  public setMessagesList(messages?: MessageJobEntity) {
    if(!messages)
      return;
    
    this.currMessages = messages;

    setTimeout(() => {
      const _this: any = this;
      _this.scroolBottom('messageContentContainer')
    },500)
  }

  public created() {
  }

  public doSearch(value: string) {
    
  }

  public backToList() {
    this.$emit('back',false)
  }

  public sendMessage() {
    if (this.messageText != '' && this.root != null) {
      const newMessage = new MessageJobEntity;
      const who: string = this.getProfileType;
      
      newMessage.mensagem = this.messageText;
      newMessage.assunto = this.root.assunto;
      newMessage.paiId = this.root!.id;
      newMessage.remetente = 'C';
      newMessage.empresaId = this.root!.empresa!.id;

      this.promise = this.messageJobService.create(newMessage)
      .then((data: any) => {
        this.messageText = '';
        this.$emit('sended',this.root);
      });
    }
  }

  public showDate(data:any) {
    return this.$showDateFromObject(data)
  }
}
