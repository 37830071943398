import { Classifications, FaceLandmarkerResult } from '@mediapipe/tasks-vision';
import * as THREE from 'three';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';
import { GLTF, GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import BasicScene from './BasicScene';

export default class Avatar {
  public scene: THREE.Scene | null = null;
  public loader: GLTFLoader = new GLTFLoader();
  public gltf: GLTF | null = null;
  public root: any = null;
  public morphTargetMeshes: THREE.Mesh[] = [];
  public object3d: THREE.Mesh | null = null;
  public url: string | null = null;
  public useKTxLoader: Boolean = false;

  constructor(url: string, basicScene: BasicScene, useKTLoader?: boolean) {
    if (!url || !basicScene || !basicScene?.renderer) {
      return;
    }

    this.useKTxLoader = !!useKTLoader;

    this.url = url;
    this.scene = basicScene.scene;
    this.loadModel(basicScene.renderer);
  }

  private loadModel(renderer: THREE.WebGLRenderer) {
    if (!this.url) {
      return;
    }
    if (this.useKTxLoader) {
      const ktx2Loader = new KTX2Loader()
        .setTranscoderPath(location.protocol + "//" + location.host + "/static/basis/")
        .detectSupport(renderer);

      this.loader
        .setKTX2Loader(ktx2Loader)
        .setMeshoptDecoder(MeshoptDecoder)
    }
    this.loader.load(
      this.url,
      (gltf: GLTF) => {
        if (this.gltf) {
          // Reset GLTF and morphTargetMeshes if a previous model was loaded.
          this.gltf.scene.remove();
          this.morphTargetMeshes = [];
          this.object3d = null;
        }

        this.gltf = gltf;
        this.init(gltf);

        console.log('loaded')
      }, (progress: ProgressEvent) => {
        console.log("Loading model...", progress.loaded, progress.total)
      }, (error) => console.error('loadModel error', error),
    );
  }

  private init(gltf: GLTF) {
    gltf.scene.traverse((object: THREE.Object3D) => {
      // Register first bone found as the root
      if (object instanceof THREE.Bone && object?.isBone && !this.root) {
        this.root = object;
      }

      if (object instanceof THREE.Mesh && !object?.isMesh) {
        return;
      }

      const mesh: THREE.Mesh = (object as THREE.Mesh);
      // Reduce clipping when model is close to camera.
      mesh.frustumCulled = false;

      const doesntIncludesMorphableTargets = !mesh.morphTargetDictionary || !mesh.morphTargetInfluences
      if (doesntIncludesMorphableTargets) return;

      this.object3d = mesh;
      this.morphTargetMeshes.push(mesh);
      this.normalizeDictionaries();
    });
  }

  public normalizeDictionaries() {

    for( const mesh of this.morphTargetMeshes) {

      if (!mesh.morphTargetDictionary || !mesh.morphTargetInfluences) {
        console.warn(`Mesh ${mesh.name} does not have morphable targets`);
        return;
      }
      const keys = Object.keys(mesh.morphTargetDictionary);
      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];
        let value = mesh.morphTargetDictionary[key];
        if (key.indexOf(' ') > -1)
          mesh.morphTargetDictionary[key.replace(' ', '')] = value;
      }
    }
  }
  
  public retarget(blendshapes: Classifications[]) {
    const categories = blendshapes[0].categories;
    let coefsMap = new Map<string, number>();
    for (let i = 0; i < categories.length; ++i) {
      const blendshape = categories[i];
      if(blendshape.categoryName.indexOf('mouth') > -1) {
        blendshape.score *= 1.2;
      }
      // Adjust certain blendshape values to be less prominent.
      // switch (blendshape.categoryName) {
      //   case "browOuterUpLeft":
      //     blendshape.score *= 1;
      //     break;
      //   case "browOuterUpRight":
      //     blendshape.score *= 1;
      //     break;
      //   case "eyeBlinkLeft":
      //     blendshape.score *= 1;
      //     break;
      //   case "eyeBlinkRight":
      //     blendshape.score *= 1;
      //     break;
      //   default:
      // }
      
      if(blendshape.score < 0 || (blendshape.score > 0 && blendshape.score < 0.0001) ) 
        blendshape.score = 0;

      if(blendshape.score > 1) 
        blendshape.score = 1;
        
      coefsMap.set(categories[i].categoryName, categories[i].score);
    }
    return coefsMap;
  }

  public retargetAndUpdateBlendshapes(blendshapes: Classifications[]){
    this.updateBlendshapes(this.retarget(blendshapes));
  }

  public updateBlendshapes(blendshapes: Map<string, number>) {

    for( const mesh of this.morphTargetMeshes) {

      if (!mesh.morphTargetDictionary || !mesh.morphTargetInfluences) {
        console.warn(`Mesh ${mesh.name} does not have morphable targets`);
        return;
      }
      for (const [name, value] of blendshapes) {
        
        if (!Object.keys(mesh.morphTargetDictionary).includes(name)) {
          // if(!window['jbprod'])
          //   console.warn(`Mesh ${name} does not have morphable targets`,mesh.morphTargetDictionary);
          continue;
        }
        const idx = mesh.morphTargetDictionary[name];
        mesh.morphTargetInfluences[idx] = value;
      }
    }
  }


  
  public applyFaceLandmarksToModel(results: FaceLandmarkerResult | undefined | null) {

    if (!(results && results.faceBlendshapes && results.facialTransformationMatrixes && this.object3d)) return;

    const transformationMatrixes = results.facialTransformationMatrixes;
    if (transformationMatrixes.length > 0) {
      let matrix = new THREE.Matrix4().fromArray(transformationMatrixes[0].data);
      this.applyMatrix(matrix, { scale: 90 });
    }

    const blendshapes = results.faceBlendshapes;
    if (blendshapes.length > 0) {
      this.retargetAndUpdateBlendshapes(blendshapes);
    }

  }

  public applyMatrix(matrix: THREE.Matrix4, matrixRetargetOptions: any) {
    const { scale = 1 } = matrixRetargetOptions || {};
    if (!this.gltf) return;

    matrix.scale(new THREE.Vector3(scale, scale, scale));
    this.gltf.scene.matrixAutoUpdate = false;
    this.gltf.scene.matrix.copy(matrix);
  }

  public offsetRoot(offset, rotation) {
    if (!this.root) return;

    this.root.position.copy(offset);

    if (!rotation) return;

    let offsetQuat = new THREE.Quaternion().setFromEuler(
      new THREE.Euler(rotation.x, rotation.y, rotation.z)
    );

    this.root.quaternion.copy(offsetQuat);
  }
}