import { MutationTree } from "vuex";
import { UiState } from "./state";

export const mutations: MutationTree<UiState> = {
  showVagasFilter(state) {
    state.showVagasFilter = true;
  },
  hideVagasFilter(state) {
    state.showVagasFilter = false;
  },
  toggleVagasFilter(state) {
    state.showVagasFilter = !state.showVagasFilter;
  }
};