






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';
import CardPersonalInfo from '@/components/cards/CardPersonalInfo.vue';

@Component({
    components: {
        CardPersonalInfo
    }
})
export default class DadosPessoaisView extends Vue {
    @Prop({default: null})
    candidato!: CandidatoEntity;

    @Prop({ default: true }) 
    public displayVideo!: boolean;
    
    @Prop({ default: false }) 
    public isPublic!: boolean;

    @Prop({ default: false }) 
    public ispdf!: boolean;
}
