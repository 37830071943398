






















import RootHeaderNavigation from '@/components/root-header-navigation/RootHeaderNavigation.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    RootHeaderNavigation
  }
})
export default class CompaniesHeaderNavigation extends Vue {
  public filterShow() {
    this.$emit('filterButton')
  }

  public isActive(routeName: string) {
    return this.$route.name == routeName;
  }

}
