
















































































































































































































import InviteFriendsEmail from '@/components/invite-dialogs/InviteFriendsEmail.vue';
import GoogleMap from '@/components/maps/GoogleMap.vue';
import { CandidaturaEntity, VagaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';

import loadFacebookSdk from '@/core/loaders/loadFacebookSdk';

@Component({
  components: {
    GoogleMap,
    InviteFriendsEmail
  }
})
export default class CardJobDetails extends Vue {
  public show: boolean = false;
  private showModalInvite = false;
  private FB!: fb.FacebookStatic;

  @Prop({ default: undefined })
  private vaga!: VagaEntity;

  @Prop({ default: undefined })
  private candidatura!: CandidaturaEntity;

  @Prop({ default: undefined })
  private applyPromise!: Promise<any>;

  @Prop({ default: false })
  private isPublic!: boolean;

  public async created() {
    this.FB = await loadFacebookSdk();
  }

  get applicable() :boolean {
    return this.vaga.status === 'ATIVO';
  }

  get isBlind(): boolean {
    return this.vaga?.blind == 1;
  }

  get cidade(): string {
    return this.vaga.cidade ? this.vaga.cidade.completoNome+'' : '';
  }

  get localidade(): string {
    return this.vaga.localidade ? this.vaga.localidade+'' : '';
  }

  get canMakeInterview(): boolean {
    return this.vaga.isCandidatado! && (this.candidatura.status == 'VIDEOSPENDENTES' || (this.candidatura.canAnswer == true && this.candidatura.status == 'EMANALISE') );
  }

  get endTimeRecord(): Date {
    let date = new Date();
    if(this.candidatura!.dataCadastro!=undefined){
      date = new Date(this.candidatura.dataCadastro.date);
      date.setDate(date.getDate() + this.vaga.daysToRecord);
    }
    return date;
  }

  public goToInterview() {
    this.$emit('goToInterview');
  }

  public applyJob() {
    console.log('apply')
    this.$emit('apply');
  }

 public examPage() {
    this.$router.push({name: 'Exams', params: { cid: this.candidatura.id+"" }, query: { lang: this.$i18n.locale }});
  }

  public convertToCurrency(value: any): string {
    // value = "6.666,43";
    value = (value+"").replace('.','').replace(',','.');
    if(parseFloat(value) > 0 && !isNaN(parseFloat(value))) {
      const number = Number(value)
      // const curr: string = this.$i18n.locale != "pt-BR" ? "USD" : "BRL"; 
      const format = { minimumFractionDigits: 2 , style: 'currency', currency:  'BRL' };
      const currencyValue = number.toLocaleString('pt-BR', format);
      return currencyValue;
    } else {
      return value;
    }
  }

  get vagaPublicUrl() {
    return `${window.location.origin}/v/${this.vaga.nomeUrl}`;
  }

  public showFacebookShareDialog() {
    this.FB.ui({
      method: 'share',
      href: this.vagaPublicUrl,
    }, (response) => {
      // response.error_message
    });
  }
}
