
























import Vue from 'vue';
import Component from 'vue-class-component';
import CardBox from '@/components/CardBox.vue';
import ProgressProfile from '@/components/progress/ProgressProfile.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';
import { Getter, Action } from 'vuex-class';

const namespace: string = 'profile';

@Component({
  components: {
    'card-box': CardBox,
    'progress-profile': ProgressProfile
  },
})
export default class CardProgressProfile extends Vue {
  public candidatoService: CandidatoService;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.candidatoService = new CandidatoService();
  }

  get percentageColor() {
    if (this.candidatoService.entidade.completionPercentage < 30) {
      return 'red';
    }
    if (this.candidatoService.entidade.completionPercentage < 80) {
      return '#e6a23c';
    } else {
      return 'green';
    }
  }

  public async created() {
    if(!this.candidato) {
      await this.loadCandidatoInfo()
    }
    this.candidatoService.entidade = this.candidato!;
  }

  public gotoProfile() {
    this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale }});
  }
}
