



















































import { Vue, Component } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import Section from '@/components/section/Section.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity, IdiomaEntity } from '@/core/models/Entities';
import Alert from '@/components/alerts/Alert.vue';

const namespace: string = 'profile';

@Component<CardRemoveAccount>({
  components: {
    'section-card': Section
  }
})
export default class CardRemoveAccount extends Vue {
  public candidatoService!: CandidatoService;
  public alert: Alert;

  public pass: string = "";
  public confirmPass: string = "";
  public magicWord: string ="";
  public currentPromise: any|null = null; 

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  constructor() {
    super();
    this.candidatoService = new CandidatoService();
    this.alert = new Alert();
  }

  public async created() {
    if(!this.candidato) {
      await this.loadCandidatoInfo();
    }
    this.candidatoService.entidade = this.candidato!;
  }

  public validateForm(): boolean {
    
    if(!(this.confirmPass === this.pass && this.pass != "")){
      this.alert.addMsg(this.$t('Password must match confirmation').toString())
      this.alert.callBoxWarn()
      return false;
    }

     if(!(this.magicWord === "DELETE")){
      this.alert.addMsg(this.$t('Incorrect confirmation word').toString())
      this.alert.callBoxWarn()
      return false;
    }

    return true;
  }

  public callRemoveAccount(evt: any) {
    evt.preventDefault();
    if(!confirm(this.$t('Are you sure you want to DELETE your jobecam account?').toString()) || !this.validateForm())
    {
        return;
    }
    let data: any =  {
      id: this.candidatoService.entidade.id,
      email: this.candidatoService.entidade.email,
      senha: this.pass,
      confirmSenha: this.confirmPass,
      magicWord: this.magicWord
    }
    this.currentPromise = this.candidatoService.delete(data).then((data: any) => {
      this.$router.push({path: '/logout', query: {lang: this.$i18n.locale}});
      return;
    })
  }

}
