



































































import Vue from "vue";
import Component from "vue-class-component";
import Section from "@/components/section/Section.vue";
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import Alert from "@/components/alerts/Alert.vue";
import { PasswordStrengh, CB } from '@/core/util'
import { Action, Getter } from "vuex-class";
import {
  CandidatoEntity,
  PerfilCandidatoEntity
} from "@/core/models/Entities";
import ClientAuthentication from "@/core/Services/ClientAuthentication";
const namespace: string = "profile";

@Component<CardChangePassword>({
  components: {
    "section-card": Section
  }
})
export default class CardChangePassword extends Vue {
  public type: String = "password"
  public passwordStrengh: PasswordStrengh|null = null;
  public myProfile: CandidatoEntity = new CandidatoEntity();
  public candidatoService: GestaoCandidato;
  public alert: Alert = new Alert();
  public currentPromise: any|null = null;

  @Action("authRequest", { namespace }) public authRequest: any;
  @Getter("isAuthenticated", { namespace }) public isAuthenticated: any;
  @Getter("getProfile", { namespace }) public getProfile: any;
  @Getter("getProfileType", { namespace }) public getProfileType: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.candidatoService = new GestaoCandidato();
    this.passwordStrengh = new PasswordStrengh();
  }

  public formresetsenha = {
    currentPass: "",
    newPass: "",
    confirmNewPass: ""
  };

  get passwordIsnotEmpty(){
    return this.passwordStrengh!.password;
  }

  public async saveChangePass() {
    this.formresetsenha.newPass = this.passwordStrengh!.password;
     if(!this.passwordStrengh!.validatePassword()){
        // this.alert.title = this.$t('Attention')
        this.alert.addMsg(this.$t('Your password has not passed the security requirements'));
        this.alert.callBoxWarn()
        return;
    }
    if (this.formresetsenha.newPass == this.formresetsenha.confirmNewPass) {
        let rtoken: string|null = null;
        try {
          rtoken = await ClientAuthentication.fetchToken('candidate_login_change_pass');
        } catch(e) {
          console.log(e)
        }
        const dta: any = {
          rtoken,
          currentPassword: this.formresetsenha.currentPass,
          newPassword: this.passwordStrengh!.password,
          confirmNewPassword: this.formresetsenha.confirmNewPass
        };
        const cdta = CB.genC(dta);
        this.candidatoService.update({
          id: this.candidato!.id,
          change: true, 
          ...cdta
        }).then(data => {
          this.passwordStrengh!.password = ''
          this.formresetsenha.currentPass = ''
          this.formresetsenha.confirmNewPass = ''
          this.alert.title = this.$t('Success');
          this.alert.addMsg(this.$t('Password successfully updated'));
          this.alert.callBoxSuccess();
        }).catch(err => { /* console.log(err); */ }) 
    } else {
      this.alert.title = this.$t('Attention')
      this.alert.addMsg(this.$t('Passwords entered in the fields (New Password and Confirm New Password) must be the same.'));
      this.alert.callBoxWarn()
    }
  }
}
