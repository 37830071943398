
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class VideoCurriculum extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/talent/video-curriculum', method: 'POST', headers: {} },
                fetch: { url: '/talent/video-curriculum/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/talent/video-curriculum', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/talent/video-curriculum/{id}', method: 'PUT', headers: {} },
                delete: { url: '/talent/video-curriculum/{id}', method: 'DELETE', headers: {} }
            }));
    }

    
  public saveFile(file: any): Promise<any> {
    this.hasProgress = true;
    const formData = new FormData();
    formData.append('id', '0');
    formData.append('vc', file);
    return super.update(formData, 'multipart/form-data');
  }
}
