


































import GoogleMap from '@/components/maps/GoogleMap.vue';
import { EmpresaEntity } from "@/core/models/Entities";
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    GoogleMap,
  }
})
export default class CardJobCompany extends Vue {
  @Prop({ default: undefined })
  private empresa!: EmpresaEntity;

  @Prop({ default: false })
  private publicArea!: boolean;

  get enderecoEmpresa(): string {
    return this.$options.filters!.formatEndereco(this.empresa.perfil);
  }

  get empresaUrl() {
    const routeName = this.publicArea ? 'CompanyPublicProfile' : 'CompanyProfile';
    return {
      name: routeName,
      params: { urlcode: this.empresa.nomeUrl! },
      query: { lang: this.$i18n.locale }
    };
  }
}
