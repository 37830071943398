




















































































// tslint:disable
import Alert from "@/components/alerts/Alert.vue";
import AssessmentDescription from "@/components/assessment/AssessmentDescription.vue";
import AssessmentInstruction from "@/components/assessment/AssessmentInstruction.vue";
import CircleRate from "@/components/rate/CircleRate.vue";
import Section from "@/components/section/Section.vue";
import MatchService from "@/core/Services/Assessment/MatchCandidato";
import QuestionaryService from "@/core/Services/Assessment/Questionary";
import Vue from "vue";
import Component from "vue-class-component";
import StepIndicator from 'vue-step-indicator';
import { Action } from "vuex-class";

import {
AnswerEntity,
QCAnswerData,
QuestionaryCandidatoEntity,
QuestionaryEntity
} from "@/core/models/Entities";

const namespace: string = 'profile';

@Component<CandidatoAssessment>({
  components:{
    'circle-rate': CircleRate,
    "section-card": Section,
    StepIndicator,
    AssessmentDescription,
    AssessmentInstruction
  }
})
export default class CandidatoAssessment extends Vue {
  public qService: QuestionaryService;
  public matchService: MatchService;
  public questionary: QuestionaryEntity;
  public qCandidato: QuestionaryCandidatoEntity;
  public sumByQuestion!: Map<any,number>;
  public active: number = 0;
  public rating: number[] = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  public textArray: string[] = [];
  public loaded: boolean = true;
  public alert: Alert;
  public showDismissibleAlert: boolean = false;
  public serverMessage: string = "";
  public fase:number = 0;

  constructor() {
    super();
    this.qService = new QuestionaryService();
    this.matchService = new MatchService();
    this.questionary = new QuestionaryEntity();
    this.qCandidato = new QuestionaryCandidatoEntity();
    this.sumByQuestion = new Map<any,number>();
    this.alert = new Alert();
  }


  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  public handleRateChange(answer: AnswerEntity, newRate: number) {
    // Atualizar rate com novo valor
    answer.rate = newRate;

    const qAnswer = this.qCandidato.answers[answer.id!];
    qAnswer.rate = answer.rate;

    this.resetSumDist();

    this.$nextTick(() => {
      const currentTotalDist: number = this.sumByQuestion.get(qAnswer.questionId)!;

      if (currentTotalDist > 10) {
        answer.rate = 0;
        qAnswer.rate = 0;
        this.resetSumDist();

        this.showDismissibleAlert = true;
        this.serverMessage = this.$t("For each question, distribute up to 10 points among the statements below. Be yourself and mark the preferences you value the most.").toString();
        setTimeout(() => {
          this.showDismissibleAlert = false;
        }, 3000);
      }
    });
  }

  public resetSumDist() {
    this.questionary.questions!.forEach((question: any) => {
      let currentRateDist: number = 0;

      this.sumByQuestion.set(question.id, currentRateDist);

      if (question.answers) {
        question.answers.forEach((answer: AnswerEntity) => {
          currentRateDist += answer.rate || 0;
          this.sumByQuestion.set(question.id, currentRateDist);
        });
      }
    });
  }

  public mounted() {
    this.loaded = true;
    this.matchService
      .fetch(2)
      .then(data => {
        if (data.questionaryAnswerCandidatos) {
          data.questionaryAnswerCandidatos.forEach((qaCandidato: any) => {
            if (this.qCandidato.answers) {
              this.qCandidato.answers[qaCandidato.answerId] = new QCAnswerData(
                qaCandidato.answerId,
                qaCandidato.rate
              );
            }
          });
        };
    this.qService
      .fetch(2)
      .then(data => {
        if (data.questions) {
          data.questions.forEach((question: any) => {
            let currentRateDist:number =0;
            this.sumByQuestion.set(question.id, currentRateDist);
            if (question.answers) {
              question.answers.forEach((answer: AnswerEntity) => {
                answer.rate = 0;

                if (this.qCandidato.answers && answer && answer.id) {
                  if (!this.qCandidato.answers[answer.id]) {
                    this.qCandidato.answers[answer.id] = new QCAnswerData(
                      answer.id,
                      0
                    );
                  } else {
                    answer.rate = this.qCandidato.answers[answer.id].rate;
                    currentRateDist+=answer.rate!
                    this.sumByQuestion.set(question.id, currentRateDist);
                  }
                  this.qCandidato.answers[answer.id].questionId =
                    question.id;
                }
              });
            }
          });
          ///* console.log(this.qCandidato.answers) */
        }
        this.questionary = data;
        this.qCandidato.questionaryId = this.questionary.id;
        this.active = 0;
        setTimeout(this.showHideContentStep, 500);

        /* this.questionary.questions!.forEach(question => {
          question.answers.forEach(answer => {
            this.$set(answer, 'rate', 0);
          });
        }); */
        // $.unblockUI();
      })
      .catch((err: any) => {
        /* console.log(err) */
        // $.unblockUI();
      });
    this.loaded = false;
  })
  .catch((err: any) => {
    // $.unblockUI();
    /* console.log(err) */
  });
  }

  public previous() {
    if (this.active-- <= 0) this.active = 0;

    this.showHideContentStep();
  }
  public next() {
    if (this.active++ >= this.questionary.questions!.length - 1) {
      this.active = 0;
    }
    this.showHideContentStep();
  }
  public save() {
    // $.blockUI();
    this.matchService
      .create(this.qCandidato)
      .then(async (data: any) => {
        this.alert.addMsg(this.$t('Your Cultural Fit was saved successfully'));
        this.alert.callBoxCheckWithTitle(this.$t('OK, I got it!').toString());
        await this.fetchCandidatoInfo();
        this.$router.push({path:'/', query: {lang: this.$i18n.locale}});
      })
      .catch((err: any) => {
        // $.unblockUI();
        this.alert.title = this.$t('Error')
        this.alert.addMsg(err.detail);
        this.alert.callBoxError()
      });
  }
  public showHideContentStep() {
    const stepContentId = "question" + this.active;
    const divsContent = document.querySelectorAll(".step-content");
    divsContent.forEach((el) => {
      el.classList.remove("active");
    });
    document.getElementById(stepContentId)!.classList.add("active");

  }
}
