















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from "vuex-class";
import VagaService from "@/core/Services/Vaga/Vaga";
import CandidaturaService from "@/core/Services/Candidato/Candidatura";
import CardJobDetails from '@/components/cards/CardJobDetails.vue';
import CardJobCompany from '@/components/cards/CardJobCompany.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import CandidatoRegisterForm from "@/components/register/CandidatoRegisterForm.vue";
import FormConfirmation from "@/components/register/FormConfirmation.vue";
import { VagaEntity, CandidaturaEntity, CandidatoEntity } from '@/core/models/Entities';
import { JobsInvites } from '@/core/util';

@Component({
  components: {
    CardJobDetails,
    CardJobCompany,
    LoginForm,
    CandidatoRegisterForm,
    FormConfirmation
  }
})
export default class Vagas extends Vue {
  private vagaService: VagaService = new VagaService();
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private vaga: VagaEntity|null = null;
  private candidatura: CandidaturaEntity|null = null;
  private invite: Boolean = false;
  private applyPromise: Promise<any>|null = null;
  public jobsInvite: JobsInvites;
  public registerRequested: boolean = false;
  public confirmationRequest: boolean = false;
  public candidato: CandidatoEntity|null = null;

  @Getter("isAuthenticated", { namespace: 'profile' }) public isAuthenticated: any;

  constructor () {
    super();
    this.jobsInvite = new JobsInvites();
  }

  async created() {
    let data: any = { id:0 };
    if(this.$route.params.urlcode){
      data["urlCode"] = this.$route.params.urlcode;
    }
    this.vaga = await this.vagaService.publicFetchWithData(data);
    if(this.$router.currentRoute.fullPath.indexOf('/invite') > -1 ) {
      this.jobsInvite.addToList(this.vaga!.nomeUrl, this.vaga!.id);
    }
    this.invite = this.jobsInvite.isOnList(this.vaga!.nomeUrl)
    document.querySelector('meta[property="og:url"]')!.setAttribute("content", ''+location);
    document.querySelector('meta[property="og:title"]')!.setAttribute("content", ''+this.vaga?.posicao);
    document.querySelector('meta[property="og:description"]')!.setAttribute("content", ''+this.vaga?.descricaoSemHtml);
    document.querySelector('meta[property="og:image"]')!.setAttribute("content", ''+this.vaga?.empresa?.logoEmpresa);
  }


  get showCompartilhar(): boolean {
    return !!this.vaga && this.vaga.restrita === 'SIM';
  }

  get showEmpresa(): boolean {
    return !!this.vaga && this.vaga.confidencial !== 'SIM';
  }

  get vagaCols(): number {
    return this.showEmpresa ? 9 : 12;
  }

  get currentLocal() {
    return location.href
  }

  public goToInterview() {
    // this.$bvModal.hide('modal-login-vaga')
    const path: string = '/vagas/'+this.vaga!.nomeUrl+"?a=1"
    this.$router.push({ path, query: {lang: this.$i18n.locale } });
  }

  public apply() {
    if(!this.isAuthenticated){
      this.registerRequested = false;
      this.$bvModal.show('modal-login-vaga')
    } else {
      this.goToInterview()
    }
    // if(this.vaga) {
    //   // const uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
    //   const path: string = '/vagas/'+this.vaga!.id+"?a=1"
    //   this.$router.push({ path: "/login", query: {
    //       vagaURL: ((this.vaga) ? this.vaga.nomeUrl||"" : ""),
    //       lang: this.$i18n.locale, redirectUrl: path,
    //       organica: ( this.invite ? "NAO" : "SIM" )  }
    //   });
    // }
  }
  public loginSuccess() {
    this.goToInterview()
  }

  public registerFail() {
    console.log('fail')
  }

  public loginFail() {
    console.log('fail')
  }

  public success(data: CandidatoEntity) {
    this.candidato = data;
    this.confirmationRequest = true;
    // this.$emit('success',data)
  }

  public successConfirmation(data: any) {
    
    this.$bvModal.hide('modal-login-vaga')
    if(this.vaga) {
        this.$router.push({ name: "DetalheVagaCandidato", params: { id: this.vaga?.nomeUrl+"" },  query: {lang: this.$i18n.locale, a: "1"}
        });
      }
  }
}
