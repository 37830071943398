



































import RootHeaderNavigation from '@/components/root-header-navigation/RootHeaderNavigation.vue';
import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

@Component({
  components: {
    RootHeaderNavigation
  }
})
export default class JobsHeaderNavigation extends Vue {
  @Action('toggleVagasFilter', { namespace: 'ui' })
  public toggleVagasFilter!: () => void;

  public getButtonClasses(name: string) {
    return {
      'active': this.$route.name == name
    };
  }
}
