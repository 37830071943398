





































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import EmpresaService from "@/core/Services/Empresa/Empresa";
import Section from '@/components/section/Section.vue';

@Component({
  components: {
    'section-card': Section
  }
})
export default class CompanyMainInfo extends Vue {
  public empresaService?: EmpresaService;
  public urlcode: string = "";
  public isPublic: boolean = false;

  constructor(){
    super();
    this.empresaService = new EmpresaService();
  }

  public mounted(){
    this.urlcode = this.$route.params.urlcode;
    this.empresaService!.loadFromNomeUrl(this.urlcode);

    if (this.$route.name == 'CompanyPublicProfile') {
      this.isPublic = true;
    }
  }

  // Get Youtube id from URL
  public youtubeId(yid:any){
    let url = yid
    let parsedStr = url.split("v=")[1];
    parsedStr = (parsedStr != undefined) ? parsedStr : url.split("youtu.be/")[1];
    let resultId = parsedStr.split("&")[0];
    return resultId
  }

  // Add id to EMBED youtube URL
  public getURL(url:string){
    if (url.indexOf("youtu") >= 0) {
      return 'https://www.youtube.com/embed/'+ this.youtubeId(url)
    }
    return url
  }

  get hasVideo(){
    return (this.empresaService!.entidade!.perfil && this.empresaService!.entidade!.perfil!.videoApresentacao);
  }
}
