




























































import { Vue, Component, Prop } from 'vue-property-decorator';
import CardJobProcessSimple from '@/components/cards/CardJobProcessSimple.vue';
import CardJobCompany from '@/components/cards/CardJobCompany.vue';
import LoginForm from '@/components/login/LoginForm.vue';
import CandidatoRegisterForm from "@/components/register/CandidatoRegisterForm.vue";
import FormConfirmation from "@/components/register/FormConfirmation.vue";
import ModalPerguntasCorte from '@/components/modal/ModalPerguntasCorte.vue';
import ModalCurriculoOculto from '@/components/modal/ModalCurriculoOculto.vue';
import VagaDetailMixin from './VagaDetailMixin';
import ModalDiversidade from '@/components/modal/ModalDiversidade.vue';

@Component({
  components: {
    CardJobProcessSimple,
    CardJobCompany,
    LoginForm,
    CandidatoRegisterForm,
    FormConfirmation,
    'pergunta-corte': ModalPerguntasCorte,
    ModalDiversidade,
    ModalCurriculoOculto
  },
  mixins: [VagaDetailMixin]
})
export default class ProcessStartSimple extends Vue {
  
  public mounted() {
    const _t: any = this;
    _t.noSidebar = true;
  }
}
