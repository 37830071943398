



import Vue, { VNode } from 'vue';
import Component from 'vue-class-component';
import { BvToastOptions } from 'bootstrap-vue';

@Component
export default class Toast extends Vue {
  public id: string = '';
  public title: string|null = null;
  public toaster: string = 'b-toaster-top-right';
  public variant: string = 'default';
  public isStatus: boolean = false;
  public bodyNodes: VNode[]|null = null;
  public titleNodes: VNode[]|null = null;
  public autoHideDelay: number = 5000;
  public appendToast: boolean = true;
  public noCloseButton: boolean = false;
  public noFade: boolean = false;
  public noHoverPause: boolean = false;
  public solid: boolean = false;
  public toastClass: string = 'custom-message';
  public headerClass: string = 'custom-message__header';
  public bodyClass: string = 'custom-message__body';
  public href?: string;
  public to?: any;
  public static: boolean = false;
  
  constructor() {
    super();
    this.id = (new Date()).getTime() + "_t";
  }

  public addMsg(msg: any){
    if(!this.bodyNodes) {
        this.bodyNodes = [];
    }
    this.bodyNodes.push(this.$createElement('div',msg+""));
  }

  public addMsgCentralized(msg: any){
    if(!this.bodyNodes) {
        this.bodyNodes = [];
    }
    this.bodyNodes.push(this.$createElement('div', {
      attrs: {
        class: 'text-center'
      }
    }, <string> msg));
  }

  public addMsgStrong(msg: string){
    if(!this.bodyNodes) {
        this.bodyNodes = [];
    }
    this.bodyNodes.push(this.$createElement('strong',msg));
  }

  public addUlMsg(typeClass: string, msgs: string[], elements?: VNode[]) {
    if(!this.bodyNodes) {
        this.bodyNodes = [];
    }
    this.bodyNodes.push(this.$createElement('ul', msgs.map(msg => {
      return this.$createElement('li', {
      class: 'text-left ' + typeClass
    }, msg);
    })));

    if(elements)
      this.bodyNodes.push(...elements);
  }

  public addElementsMsg(elements: VNode[]) {
    if(!this.bodyNodes) {
        this.bodyNodes = [];
    }
    this.bodyNodes.push(...elements);
  }

  public addContentTitle(contentTitle: string){
    if(!this.titleNodes) {
        this.titleNodes = [];
    }
    this.titleNodes.push(this.$createElement('div', contentTitle));
  }

  public addTitle(contentTitle: string){
    this.title = contentTitle;
  }

  public cleanDefaults() {
    this.id = (new Date()).getTime() + "_t";
    this.title = null;
    this.toaster = 'b-toaster-top-right';
    this.variant = 'default';
    this.isStatus = false;
    this.bodyNodes = null;
    this.titleNodes = null;
    this.autoHideDelay = 5000;
    this.appendToast = true;
    this.noCloseButton = false;
    this.noFade = false;
    this.noHoverPause = false;
    this.solid = false;
    this.toastClass = 'custom-message';
    this.headerClass = 'custom-message__header';
    this.bodyClass = 'custom-message__body';
    this.href = undefined;
    this.to = undefined;
    this.static = false;
  }

  public open() {
    if(this.bodyNodes) {
      const options: BvToastOptions = {
        title: (this.title||this.titleNodes||''),
        autoHideDelay: this.autoHideDelay,
        appendToast: true,
        variant: this.variant,
        isStatus: this.isStatus,
        noCloseButton: this.noCloseButton,
        noFade: this.noFade,
        noHoverPause: this.noHoverPause,
        solid: this.solid,
        toastClass: this.toastClass,
        headerClass: this.headerClass,
        bodyClass: this.bodyClass,
        href: this.href,
        to: this.to,
      };
      this.$bvToast.toast(this.bodyNodes, options)
      this.cleanDefaults();
    }
  }
}
