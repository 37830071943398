

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';

const namespace: string = "profile";

@Component
export default class RedirectLogin extends Vue {
  @Action("userRequest", { namespace }) public userRequest: any;
  public contentText: string = "";

  public removeItensFromStorage(): void {
    sessionStorage.removeItem("token_type");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("profile_type");
    sessionStorage.removeItem("profile");
  }
  public created() {
    if (this.$route.query.accessToken) {
      this.handleUserAccess();
    } else if(this.$route.query.p) {
      this.handleUserData();
    } else {
      this.handleError();
    }
  }

  public handleUserAccess() {
    sessionStorage.setItem(
      "access_token",
      this.$route.query.accessToken + ""
    );
    sessionStorage.setItem("token_type", "Bearer");
    sessionStorage.setItem("profile_type", "candidate");
    this.userRequest()
      .then(() => {
        this.contentText = "" + this.$t("Under Review");

        let rurl: any =
          this.$route.query.redirectTo ||
          sessionStorage.getItem("redirectTo") ||
          "/";
        setTimeout(() => {
          this.$router.push({
            path: rurl,
            query: { lang: this.$i18n.locale }
          });
        }, 500);
      })
      .catch(() => {
        this.removeItensFromStorage();
        this.contentText =
          "" +
          this.$t(
            "There was a problem. Wait a moment we'll redirect you."
          );
        this.redirectError();
      });
  }

  public handleUserData() {
    const userData: string = this.$route.query.p.toString();
    const user: any = JSON.parse(atob(decodeURIComponent(userData!)));
    sessionStorage.setItem('linkedin_user', JSON.stringify(user));
    this.$router.push({ name: 'ThirdPartyRegistry', query: { lang: this.$i18n.locale, vagaURL: ((this.$route.query.vagaURL) ? this.$route.query.vagaURL||"" : ""),
        organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : "") } });
  }
  
  public handleError() {
    this.removeItensFromStorage();
      this.contentText =
        "" +
        this.$t(
          "There was a problem. Wait a moment we'll redirect you."
        );
      this.redirectError();
  }
  redirectError() {
    setTimeout(() => {
      this.$router.push({
        path: "/login",
        query: { lang: this.$i18n.locale }
      });
    }, 500);
  }
}
