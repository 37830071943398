



















































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TextInput extends Vue {
  public scopeID: string;
  
  @Prop()
  public value!: any;
  
  @Prop({default: false})
  public required!: boolean;
  
  @Prop({default: false})
  public disabled!: boolean;
  
  @Prop({default: ''})
  public placeholder!: string;
  
  @Prop({default: ''})
  public label!: string;
  
  @Prop({default: false})
  public grouped!: string;

  @Prop({default: 'text'})
  public type!: string;
  
  @Prop({default: 255})
  public maxlength!: number;
  
  @Prop({default: ''})
  public name!: string;

  @Prop({default: null})
  public state!: boolean;

  @Prop({default: ''})
  public invalidFeedback!: string;

  constructor() {
      super();
        this.scopeID = (new Date()).getTime() + '_' + Math.ceil((Math.random()*100));
  }

  public handleInput(v: any) {
    this.$emit('input', v);
  }

  public handleBlur(v: any) {
    this.$emit('blur', v);
  }
}
