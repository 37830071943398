










import Vue from 'vue';
import Component from 'vue-class-component';

@Component<CardSeparator>({
    props: {
        text: String,
        size: String,
        icon: String,
        cclass: String,
    },
})
export default class CardSeparator extends Vue {
    public text!: any;
    public size!: string;
    public icon!: string;

    constructor() {
        super();
    }
}
