











































































































































import ExperienciaService from '@/core/Services/CandidatoExperiencia';
import { CandidatoEntity, ExperienciaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DadosProfissionaisSimple extends Vue {
  @Prop({default: []})
  public experiencias!: ExperienciaEntity[];
  
  @Prop({default: () => {new CandidatoEntity()}})
  public value!: CandidatoEntity;
  
  public handleChangeTipo(v: any){
    this.loadData()
  }

  public expSRV!: ExperienciaService;
  public showLocalidadeText: boolean = true;
  public items: any[] = [];
  public fields: any[] = [];
  public segmentoArr: any[] = [];
  public periodoArr: any[] = [];
  public disabled: boolean = false;
  public buttonLabel: string = "";
  public dataEntrada: Date|null = null;
  public dataSaida: Date|null = null;
  public editEnable: boolean = false;
  public showForm: boolean = false;
  public currentPromise: any|null = null;
  public currentDeletePromise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public options!: any;

  constructor() {
    super();
    this.expSRV = new ExperienciaService();
  }

  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }
  
  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

  public mounted(){
    this.buttonLabel = ""+this.$t('Add');
    this.fields = [
      { key: "atividade", label: this.$t('Position'), class: 'text-center' },
      { key: "periodo", label: this.$t('Period'), class: 'text-center' },
      { key: "segmento", label: this.$t('Segment'), class: 'text-center' },
      { key: "opcoes", label: this.$t('Options'), class: 'text-center' }
    ];
    let i = 1;
    this.segmentoArr.push("");
    for(i;i<=149; i++) {
      if (i!=2)
        this.segmentoArr.push(this.$t("setorIndustrial." + i).toString())
    }
    for (let index = 1; index <= 10; index++) {
      let text = this.$t('{n} year(s)', {n: index}).toString();
      if(index == 10)
       text = '+'+text;

      this.periodoArr.push({text, value: index})
    }
    this.handleChangeTipo(null);
    this.loadData()
    this.showForm = (this.value.perfil.temExperiencia === true || this.value.perfil.temExperiencia === null) && this.items.length == 0
    
  }

  public reset() {
    this.$set(this.expSRV, 'entidade', Object.assign({},new ExperienciaEntity()));
    this.buttonLabel = this.$t('Add').toString();
    this.dataEntrada = null;
    this.dataSaida = null;
    this.editEnable = false;
    this.showForm = false;
  }

  public editEXP(id: any) {
    this.buttonLabel = ""+this.$t('Save');
    this.editEnable = true;
    this.showForm = true;
    this.getEntidadeFromList(id);
  }

  public getEntidadeFromList(id?: any) {
    if (!id) {
      return null;
    }
    this.experiencias.forEach(el => {
      if( el.id === id ){
        this.expSRV.entidade = el;
        if(el.dataEntrada) {
          const arrE: any[] = el.dataEntrada!.split('-');
          if(!isNaN(parseInt(arrE[0])) && !isNaN(parseInt(arrE[1])) ) {
            this.dataEntrada = new Date(parseInt(arrE[1]),parseInt(arrE[0])-1,1)
          }
          if (el.dataSaida) {
            const arrS: any[] = el.dataSaida!.split('-');
            if(!isNaN(parseInt(arrS[0])) && !isNaN(parseInt(arrS[1])) ) {
              this.dataSaida = new Date(parseInt(arrS[1]),parseInt(arrS[0])-1,1)
            }
          }
        }
        return true;
      }
    })
  }
  
  public save(evt: any) {
    if (this.expSRV.entidade.atual_ == 1) {
      this.expSRV.entidade.dataSaida = ''
    }
    evt.preventDefault();
    if(!this.disabled)
      this.currentPromise = this.expSRV.save().then((ret: boolean) => {
        if(ret) {
          let hasObject: boolean = false;
          let index: number|null = null;
          const obj = {
                    atividade: this.expSRV.entidade.atividade,
                    descricaoAtividade: this.expSRV.entidade.descricaoAtividade,
                    periodo: this.periodText(this.expSRV.entidade.total),
                    segmento: this.expSRV.entidade.segmento,
                    opcoes: this.expSRV.entidade.id,
                    promise: null,
                  };
          this.items.forEach((el: any,index: number) => {
            if(el.opcoes==this.expSRV.entidade.id){
              hasObject = true
              el = obj
            }
          })
          if (!hasObject) {
            this.showForm = false;
            this.items.push(obj);
          }
          
          if((this.$refs.tProfissoes as any))
            (this.$refs.tProfissoes as any).refresh();

          this.value.perfil.temExperiencia = true;
          this.showAlert();
          this.reset();
          this.$emit('saved',true);
        }
      })
  }

  public saveField() {
    this.$emit('saveProfile',true);
    
    this.$emit('saved',(!this.value.perfil.temExperiencia || this.items.length > 0));
  }

  public removeEXP(id: any) {
    let tIndex: number = -1;
    let tElement: any = null;
    this.items.forEach((element: any,index: number) => {
      if(element.opcoes === id){
        tIndex = index;
        tElement = element;
        return true;
      }
    });
    
    if (tIndex > -1 && tElement != null) {
      this.$confirm.addTitle(this.$t('Attention').toString())
      this.$confirm.addMsg(this.$t('Do you want to remove this work experience?') + tElement.atividade)
      this.$confirm.callBox().then((dec: boolean) => {    
        if(dec) {
          this.items[tIndex].promise = this.expSRV.delete({id}).then(() => {
            this.items.splice(tIndex,1);
            
            if((this.$refs.tProfissoes as any))
              (this.$refs.tProfissoes as any).refresh();

            this.$emit("saved",this.items.length == 0)
          });
        }
      });
    }
  }

  public handleDateEntradaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.expSRV.entidade.dataEntrada = str.substring(str.length-7,str.length);
  }

  public loadData(value?: any) {
    if (value) {
      this.experiencias = value;
    }
    this.items = [];
    this.experiencias.forEach(el => {
      this.items.push({
        atividade: el.atividade,
        descricaoAtividade: el.descricaoAtividade,
        periodo: this.periodText(el.total),
        segmento: el.segmento,
        opcoes: el.id,
        promise: null,
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
      text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }
}
