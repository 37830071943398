
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class PerfilCandidato extends Repository {
    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/perfilcandidato', method: 'POST', headers: {} },
                fetch: { url: '/v1/perfilcandidato/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/perfilcandidato', method: 'GET', headers: {} }, // tem parametro de Busca 's'
                update: { url: '/v1/perfilcandidato/{id}', method: 'PUT', headers: {} },
                delete: { url: '/v1/perfilcandidato/{id}', method: 'DELETE', headers: {} }
            }));
    }
}
