






































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {pcdObject} from '@/core/models/pcd';


@Component
export default class PcdDetails extends Vue {
  @Prop({default: () => { return {} }})
  public pcdDetail!: any;
  //ex:. { "tipo": null, "nivel": null, "key": "fala" }

  @Prop({default: ''})
  public kei!: string;

  public pcdOBJ: any = {};
  public tipoModel: string = "";
  public nivelModel: string = "";
  public scopeID: string;

  @Watch('tipoModel')
  public handleChangeTipo(v: string){
    this.$emit('selectedType',this.kei, v)
  }

  @Watch('nivelModel')
  public handleChangeNivel(v: string){
    this.$emit('selectedNivel',this.kei, v)
  }

  @Watch('pcdDetail')
  public updateObject(){
    this.pcdOBJ = pcdObject[this.kei]
  }

  constructor() {
    super()
    this.scopeID = (new Date()).getTime() + '';
  }


  public mounted(){
    this.pcdOBJ = pcdObject[this.kei];
    this.nivelModel = this.pcdDetail.nivel;
    this.tipoModel = this.pcdDetail.tipo;
  }

}
