

























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import Recorder from '@/components/video/BetaStartRecord.vue';
import PerfilCandidatoService from '@/core/Services/Candidato/PerfilCandidato';
import VideoCurriculumService from '@/core/Services/Candidato/VideoCurriculum';
import { LocalRecorder, LocalRecorderState, OpenViduError } from 'openvidu-browser';
import { CandidatoEntity } from '@/core/models/Entities';

const namespace = 'profile';

@Component({
  components: {
    Recorder,
  }
})
export default class Entrevista extends Vue {
  public perfil: PerfilCandidatoService;
  public service: VideoCurriculumService;
  public currentPromisse: any|null = null;
  public showTips: boolean = true;
  public videoData: any = null;

  public introAnswered: boolean = false;
  public testStarted: boolean = false;
  

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.perfil = new PerfilCandidatoService();
    this.service = new VideoCurriculumService();
  }
  public async mounted() {
    if (!this.candidato) {
      await this.loadCandidatoInfo();
    }
    this.openFullscreen(document.documentElement);
  
    this.callWarningAlert();
  }

  private callWarningAlert() {
    
    this.$alert.title = this.$t("Get ready for the video resume");
    this.$alert.addUlMsg("mobile-vc-alert",[
      this.$t('Let`s test your camera and microphone') as string
    ]);

    this.$alert.addElementsMsg([
      this.$createElement('div', {
        class: 'jb-phone-handheld'
      }),
    ]);
    this.$alert.callBoxWarn()
  }

  public initRecording() {
    (<Recorder> this.$refs.rec).prepareToRecord();
  }

  public recordEnded(data: any) {
    this.videoData = data;
  }

  public doChangeVideoCV(recordData: any) {
    if (recordData && recordData.url && this.candidato!.perfil!.id) {
      this.currentPromisse = this.perfil.update({
        id: this.candidato!.perfil!.id,
        recordObject: JSON.stringify(recordData, null, '\t'),
        recordId: recordData.id,
      }).then(() => {
        this.$router.go(-1)
      }).catch((err: any) => {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
      });
    } else if(recordData instanceof LocalRecorder) {
      //is local Recorder
      if(recordData.state == LocalRecorderState.FINISHED) {
        console.log('==init.saveFile')
        this.service.saveFile(recordData.getBlob()).then(() => {
          this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale }});
        }).catch(() => {
          this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
        })
      } else {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
      }
    } else {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
    }
  }

  public saveThisOne() {
    (<Recorder> this.$refs.rec).changeVideo();
  }

  public openFullscreen(elem: HTMLElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
  
  public handleOpenviduError(error: OpenViduError) {
    this.$alert.title = this.$t('Error');
    this.$alert.addMsg(this.$t(error.name));
    this.$alert.callBoxError();
  }

  public backToProfile() {
    this.$router.push({path:'/perfil', query: {lang: this.$i18n.locale}})
  }
}
