


















































































































































































import FacebookRegister from "@/components/social/FacebookRegister.vue";
import LinkedinRegister from "@/components/social/LinkedinRegister.vue";
import { CandidatoEntity, FacebookUser, LinkedInUser } from '@/core/models/Entities';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { PasswordStrengh } from '@/core/util';
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueTelInput from 'vue-tel-input';
import { Action } from "vuex-class";

Vue.use(VueTelInput);
const namespace: string = "profile";

let PIDINTERVAL: any = 0;

declare global {
    interface Window { recaptchaVerifier: any; confirmationResult: any; recaptchaWidgetId: any;}
}
window.recaptchaVerifier = window.recaptchaVerifier || {};
window.confirmationResult = window.confirmationResult || {};
window.recaptchaWidgetId = window.confirmationResult || {};

@Component({
  components: {
    "facebook-button": FacebookRegister,
    "linkedin-button": LinkedinRegister,
  }
})
export default class CandidatoRegisterForm extends Vue {
  public candidatoService: CandidatoService = new CandidatoService();
  public candidato: CandidatoEntity|null = null;
  public username: string = ""
  public password: string = ""
  public passConfirm: string = "";
  public emailConfirm: string = "";
  public load: boolean = false;
  public accept: number = 0;
  public accept_news: string = 'N';
  public passwordStrengh: PasswordStrengh = new PasswordStrengh();
  public box: any;
  public typePassword: string = "password";
  public showPassword: boolean = false;
  public currentPromise: any|null = null;
  public myPopUp: Window|null = null;
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
  public telefone: string = "";
  // public firebaseConfig: any = {};
  public authCode: string = '';
  private linkedinToken = '';

  @Prop({ default: false })
  private emitLogin!: boolean;

  @Prop({ default: '' })
  private nomeUrl!: string;

  @Action("userRequest", { namespace })
  public userRequest: any;

  public mounted() {
    this.candidato = new CandidatoEntity();
  }

   public loggedInFacebookRegistered(accessToken: string) {
    this.registerUser(accessToken);
    this.whereToSend();
  }

  public loggedInFacebookNotRegistered(user: FacebookUser) {
    sessionStorage.setItem('facebook_user', JSON.stringify(user));
    this.$router.push({ name: 'ThirdPartyRegistry', query: { lang: this.$i18n.locale, vagaURL: this.vagaNomeURL,
          organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : "") } });
  }


  public haveaccount() {
    const path = '/login'
        this.$router.push({path: path ,query: {
          vagaURL: this.vagaNomeURL,
          organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : ""),
          lang: this.$i18n.locale} })
  }

    public whereToSend(){
      let rurl: any = this.$route.query.redirectUrl;
      if(rurl){
        sessionStorage.setItem("redirectTo", rurl);
      }
      if(sessionStorage.getItem("access_token")) {
        const path = rurl ? rurl : '/';
        this.$router.push({path: path,query: {lang: this.$i18n.locale} })
      }
      if (sessionStorage.getItem('linkedin_user')) {
        this.$router.push({ 
          name: 'ThirdPartyRegistry', 
          query: { 
            lang: this.$i18n.locale, 
            vagaURL: this.vagaNomeURL,
            organica: ((this.$route.query.organica) ? this.$route.query.organica||"" : "") 
          } 
        });
      }
    }

    public checkClosedWindow(w: Window|null, func: Function) {
        if (w !== null && w.closed) {
        clearInterval(PIDINTERVAL);
        func.call(0);
        }
    }

    public removeItensFromStorage(): void {
        sessionStorage.removeItem("token_type");
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("profile_type");
        sessionStorage.removeItem("profile");
    }

    public async registerUser(at: string) {
        if(at) {
          // this.candidato =  new Candidato();
          sessionStorage.setItem("access_token", at+'');
          sessionStorage.setItem("token_type", "Bearer");
          sessionStorage.setItem("profile_type", "candidate");
          await this.userRequest().then(() => {
              /* console.log("ok") */
          }).catch(() => {
              this.removeItensFromStorage();
          })
        }else {
          this.removeItensFromStorage();
        }
    }

    public verifySignUpCodeWithCallBack(call: any) {
        let code = this.authCode.replace(/\s|-/g, '');
        let me = this
        window.confirmationResult.confirm(code).then((result:any) => {
            call();
        }).catch((error:any) => {
            /* console.log(error) */
            this.$alert.addMsg(me.$t('Invalid code. Please check your code and enter again, or click in resend code.'));
            this.$alert.title = me.$t('Attention')
            this.$alert.callBoxError()
        });
    }

    public async loginWithLinkedIn(value: string|LinkedInUser) {
      if (this.linkedinToken) {
        return;
      }

      if (typeof(value) === 'string') {
        this.linkedinToken = value;
        await this.registerUser(this.linkedinToken);
      } else {
        const user = value;
        sessionStorage.setItem('linkedin_user', JSON.stringify(user));
      }
    }

    public linkedInPopupClosed() {
      this.whereToSend();
    }


    get passwordIsnotEmpty(){
      return this.passwordStrengh!.password;
    }

    public isValidForm(midia: boolean) {
        let valid: boolean = true;

        if(this.candidato == null) {
          this.candidato = new CandidatoEntity();
          return false;
        }

        if (!midia) {
            if( (this.candidato?.primeironome == "")||(this.candidato?.primeironome == null) ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Name'));

            }
            if( (this.candidato?.sobrenome == "")||(this.candidato?.sobrenome == null) ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Last name'));
            }

            if( (this.candidato?.email == "")||(this.candidato?.email == null) ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('E-ail'));
            } else if ( this.candidato?.email.match(/[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/) == null ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Invalid email format'));
            }
            this.candidato.email = this.candidato?.email.trim();

            if( (this.candidato.perfil!.telefone == "")||(this.candidato.perfil!.telefone == null) ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Telephone'));
            }

            this.password = this.passwordStrengh!.password;
            if( (this.password == "")||(this.password == null) ){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Password'));
            }

            if(this.password != this.passConfirm){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Passwords entered in the fields (New Password and Confirm New Password) must be the same.'));
            }

            if(!this.passwordStrengh!.validatePassword()){
                valid = false;
                this.$alert.addMsg('- '+this.$t('Your password has not passed the security requirements'));
            }
        }
        if(this.accept==0){
            valid = false;
            this.$alert.addMsg('- '+this.$t('Before proceeding you must accept the Terms of Use and Privacy Policy'));
        }

        if(!valid){
            this.box = ''
            this.$alert.title = this.$t('Please inform')
            this.$alert.callBoxError()
            .then(value => {
                this.box = value
            })
            .catch(err => {
                /* console.log(err) */
            })
        }
        return valid;
    }

    get vagaNomeURL() {
      return this.nomeUrl||this.$route.query.vagaURL||""
    }

    public submitFormCandidato() {
        let valid: boolean = this.isValidForm(false);

        if (!valid && this.authCode === "" || this.authCode === null) {
            return
        }

        if(this.candidato == null) {
          this.candidato = new CandidatoEntity();
          return false;
        }

        let data = {
            primeironome: this.candidato.primeironome,
            sobrenome: this.candidato.sobrenome,
            email: this.candidato.email,
            senha: this.password,
            lingua: this.$i18n.locale ? this.$i18n.locale : 'en',
            idioma: this.$i18n.locale ? this.$i18n.locale : 'en',
            notification: 1,
            newsletter: this.accept_news,
            vagaURL: this.vagaNomeURL,
            organica: this.$route.query.organica,
            code: this.$route.query.code,
            perfilcandidato: {
                telefone: this.candidato.perfil!.telefone
            }
        }
        this.load = true;
        /* console.log("ok") */
        
        this.currentPromise = this.candidatoService.create(data).then((dataR: any) => {
            if(this.emitLogin) {
              this.$emit('success', dataR);
              return;
            }

            // $.unblockUI();
            /* console.log(data) */
            if(dataR){
                /* console.log(data) */
                this.load = false;
                this.box = '';
              let rurl: string|null = sessionStorage.getItem("redirectTo");
              if (rurl) {
                  this.$router.push({ name: 'completed', params: {email: this.candidato?.email||''} , query: {lang: this.$i18n.locale, redirectUrl: rurl} });
              } else {
                  this.$router.push({ name: 'completed', params: {email: this.candidato?.email||''} , query: {lang: this.$i18n.locale } });
              }                
            }
        }).catch((err: any) => {
            // $.unblockUI();
            this.load = false;
            /* console.log(err) */
            if(this.emitLogin) {
              this.$emit('fail', err);
              return;
            }
        });





    }

    public sendVerificationCode(){
        let valid = true;
        valid = this.isValidForm(false);

        if(!valid)
            return;


    }

    public verifySignUpCode() {
        let code = this.authCode.replace(/\s|-/g, '');
        window.confirmationResult.confirm(code).then((result:any) => {
            this.submitFormCandidato();
        }).catch((error:any) => {
            /* console.log(error) */
            this.$alert.addMsg(this.$t('Invalid code. Please check your code and enter again, or click in resend code.'));
            this.$alert.title = this.$t('Attention')
            this.$alert.callBoxError()
        });
    }

    
  public toggleShow() {
    this.showPassword = !this.showPassword;
    this.typePassword = this.showPassword ? "text" : "password";
  }


}
