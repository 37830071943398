






































import Vue from "vue";
import Component from "vue-class-component";
import { Action, Getter } from 'vuex-class';
import CardTip from "@/components/cards/CardTip.vue";
import CardWarning from "@/components/cards/CardWarning.vue";
import CardProfileViewedByCompany from "@/components/cards/CardProfileViewedByCompany.vue";
import CardJobEngagedList from "@/components/cards/CardJobEngagedList.vue";
import CardJobEngaged from "@/components/cards/CardJobEngaged.vue";
import CardProgressProfile from "@/components/cards/CardProgressProfile.vue";
import CardLatestJobs from "@/components/cards/CardLatestJobs.vue";
import { CandidatoEntity, VagaEntity } from "@/core/models/Entities";
import VagaService from "@/core/Services/Vaga/Vaga";
import { baseEndPoint, clientId } from "@/core/endpoint";
import CardJob from "@/components/cards/CardJob.vue";
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import ModalDiversidade from '@/components/modal/ModalDiversidade.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import Alert from "@/components/alerts/Alert.vue";

const namespace: string = 'profile';

@Component<PaginaInicial>({
  components: {
    CardTip,
    CardProfileViewedByCompany,
    CardJobEngagedList,
    CardJobEngaged,
    CardProgressProfile,
    CardJob,
    CardLatestJobs,
    CardWarning,
    'modal-diversidade' : ModalDiversidade,
  }
})
export default class PaginaInicial extends Vue {
  // name: 'PaginaInicial',
  public candidatoSRV!: CandidatoService;
  public vagaService: VagaService;
  public vagasList: VagaEntity[];
  public limit!: number;
  public companyId!: number;
  public companyUrl!: string;
  public mini!: boolean;
  public search!: string;
  public loaded: boolean = false;
  public page: number = 0;
  public currentPage: number = 1;
  public loading: boolean = true;
  public totalCount: number = 0;
  public keywords: string = "";
  public candidaturaSRV: CandidaturaService;
  public showCardWarning: boolean = false;
  public alert: Alert = new Alert();
  public currentPromise: any|null = null;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.candidaturaSRV = new CandidaturaService();
    this.vagaService = new VagaService();
    this.vagasList = [];
  }

  public vagaPage(vaga: any) {
    this.$router.push({
      name: "DetalheVagaCandidato",
      params: { urlcode: vaga.nomeUrl },
      query: { lang: this.$i18n.locale }
    });
  }

  public infiniteHandler($state: any) {
    this.filterResults(this.currentPage++, $state);
  }

  public filterResults(page?: number, $state?: any) {
    this.loading = true;
    this.page = page ? page : this.page;
    let u = this.companyUrl||"";
    let i = this.companyId||"";
    let l = this.limit||8;
    let s = this.search ? this.search : this.keywords;
    this.vagaService
      .fetchAll({
        page: this.page,
        limit: l,
        s: s,
        eid: i,
        curl: u
      })
      .then((data: any) => {
        if (data) {
          data["vagas"].forEach((vaga: any) => {
            this.vagasList.push(vaga);
          });
          this.totalCount = parseInt(data["size"]) - 10;
          if ($state) {
            $state.loaded();
          }
        }
        this.loading = false;
      })
      .catch((err: any) => {
        /* console.log(err) */
        this.loading = false;
      });
  }
  public saveModelDiversidade(){
    this.currentPromise = this.candidatoSRV.save().then(async(data: any) => {
      if(!this.$route.query.vaid) {
        this.alert.addMsg(this.$t('Profile successfully saved'));
        this.alert.callBoxCheckWithTitle(this.$t('Success').toString());            
        this.$bvModal.hide('modalDiversidade');
      }
    })
  }

  public async mounted(){
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    } 
    this.$set(this.candidatoSRV,'entidade', JSON.parse(JSON.stringify(this.candidato)));

    if(this.candidato?.perfil.grupoMovBlack == null){
      this.$bvModal.show('modalDiversidade');
    }

    this.filterResults()
    this.candidaturaSRV.fetchAll({limit: 1, status: 'VIDEOSPENDENTES'}).then((data) => {
      if(data["candidaturas"].length >= 1) {
        this.showCardWarning = true;
      }
    })
    this.candidaturaSRV.fetchAll({limit: 1, status: 'EMREGISTRO'}).then((data) => {
      if(data["candidaturas"].length >= 1) {
        this.showCardWarning = true;
      }
    })

  }
};
