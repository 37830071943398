




























































































import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import Component from 'vue-class-component';
import ProfileHeaderNavigation from '@/components/perfil/header-navigation/ProfileHeaderNavigation.vue';
import DadosPessoaisEdit from '@/components/perfil/DadosPessoaisEdit.vue';
import DadosProfissionaisEdit from '@/components/perfil/DadosProfissionaisEdit.vue';
import DadosAcademicosEdit from '@/components/perfil/DadosAcademicosEdit.vue';
import HabilidadesEdit from '@/components/perfil/HabilidadesEdit.vue';
import CardVideoCurriculum from '@/components/perfil/cards-group/CardVideoCurriculum.vue';
import CardIdiomas from '@/components/perfil/cards-group/CardIdiomas.vue';
import Confirm from '@/components/alerts/Confirm.vue';
import Alert from '@/components/alerts/Alert.vue';

import CandidatoService from '@/core/Services/Candidato/Candidato';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import {CandidatoEntity, CandidaturaEntity} from '@/core/models/Entities';

const namespace: string = 'profile';

@Component<PerfilEdit>({
  components: {
    'dados-pessoais': DadosPessoaisEdit,
    'dados-profissionais': DadosProfissionaisEdit,
    'dados-academicos': DadosAcademicosEdit,
    'habilidades': HabilidadesEdit,
    CardVideoCurriculum,
    CardIdiomas,
    ProfileHeaderNavigation
  },
})
export default class PerfilEdit extends Vue {
  public candidaturaSRV!: CandidaturaService;
  public candidatoSRV!: CandidatoService;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public alert: Confirm;
  public alert1: Alert;
  public prompt: boolean = false;
  public disableBtn: boolean = false;
  public loaded: boolean = false;
  public currentPromise: any|null = null;
  public steps: any[] = [];

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;
  
  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.candidaturaSRV = new CandidaturaService();
    this.alert = new Confirm();
    this.alert1 = new Alert();
  }

  public async mounted() {
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    } 

    console.log('start', (new Date()).getTime())
    this.$set(this.candidatoSRV,'entidade', JSON.parse(JSON.stringify(this.candidato)));

    this.addTutorialSteps();
    this.goToP();
    this.$set(this.candidatoSRV.entidade, 'perfil', this.candidatoSRV.entidade.perfil);
      
    this.loaded = true;
    this.$nextTick(() => {
      (this.$refs.dpe as DadosPessoaisEdit).loadData();
      window['tourObject'] = this.$tours['candidateEditTourPage'];
      setTimeout(() => {
        if(!this.candidatoSRV.entidade.doneTutorial) {
          this.$tours['candidateEditTourPage'].start();
        }
      },1000)
    });
    console.log('end', (new Date()).getTime())

  }

  public onFinish() {
    this.goToP();
    this.candidatoSRV.entidade.doneTutorial = true;
    this.handleSave(true)
  }

  public handleCurriculumChanged(curr: string) {
    this.candidatoSRV.entidade.perfil.curriculum = curr;
    this.fetchCandidatoInfo()
  }

  
  public handleAvatarChanged(curr: string) {
    this.candidatoSRV.entidade.fotoAvatar = curr;
    this.fetchCandidatoInfo()
  }

  public goToP() {
    (this.$refs.hnav as ProfileHeaderNavigation).goToTop();
  }

  public addTutorialSteps() {
    const steps: any[] = [];
    const d: boolean = (this.candidatoSRV.entidade.doneTutorial == false);
    if(d || !!this.candidatoSRV.entidade.fotoAvatar) {
      steps.push({
        target: '#avatar-upload-label',
        content: this.$t('Here you can change your profile picture').toString(),
        params: {
          placement: 'left' 
        }
      })
    }
    if(d || !!this.candidatoSRV.entidade.perfil.linkLinkedin){
      steps.push({
        target: '#sidebar-candidate-edit-files',
        content: this.$t('Here you can add your social networks').toString(),
        params: {
          placement: 'left' 
        }
      })
    }
    if(d || !this.candidatoSRV.isProfileCompletedToJob()){
      steps.push({
        target: '#dpe',
        content: this.$t('Complete your personal details here, you can also decide to allow if the recruiter can see and search for you outside of a selection process').toString(),
        params: {
          placement: 'bottom' 
        }
      })
      steps.push({
        target: '#habe',
        content: this.$t('Add your skills to your profile').toString(),
        params: {
          placement: 'top' 
        }
      })
      steps.push({
        target: '#dppe',
        content: this.$t('Complete your profile with your professional experiences, or let us know if you are looking for your first job').toString(),
        params: {
          placement: 'top' 
        }
      })
      steps.push({
        target: '#dped',
        content: this.$t('Complete your profile including your academic courses, technical training, certifications, or check the box if you have nothing to declare').toString(),
        params: {
          placement: 'top' 
        }
      })
      steps.push({
        target: '.box-video',
        content: this.$t('Record your video curriculum to stand out in your interviews').toString(),
        params: {
          placement: 'top' 
        }
      })
    }

    this.steps = steps
    
  }

  get profile() {
    if(this.candidatoSRV && this.candidatoSRV.entidade && this.candidatoSRV.entidade.perfil)
      return this.candidatoSRV.entidade.perfil;
    return null;
  }

  get formacoes() {
    if(this.profile) 
      return this.profile.formacoes
    
    return [];
  }
  

  get experiencias() {
    if(this.profile) 
      return this.profile.experiencias
    
    return [];
  }

  get habilidades() {
    if(this.profile) 
      return this.profile.habilidades
    
    return [];
  }

  public handleSave(hideAlert?: boolean) {
    const dde =  (this.$refs.dpe as DadosPessoaisEdit);
    dde.handleSubmit();
    if(dde.allGood) {
      this.disableBtn = true
      this.currentPromise = this.candidatoSRV.save(false).then(async(data: any) => {
        this.fetchCandidatoInfo();
        if(!!hideAlert) {
          this.disableBtn = false;
          return;
        }
          
        if(!data) {
          this.disableBtn = false;
        } else { 
          this.showAlert();
          dde.loadData();
          

          if(!this.$route.query.vaid) {
            this.alert1.addMsg(this.$t("Profile successfully saved").toString());
            const dataCandidaturas = await this.candidaturaSRV.fetchAll({limit: 1, status: 'VIDEOSPENDENTES'})
            const dataCandidaturasR = await this.candidaturaSRV.fetchAll({limit: 1, status: 'EMREGISTRO'})
            if(dataCandidaturas["candidaturas"].length >= 1 || dataCandidaturasR["candidaturas"].length >= 1) {
              let x: string = this.$t('You have pending interviews').toString()
              let vNode: any = {
                attrs: {href: "/vagas/candidaturas"},
                on: { click: (event) => {
                    event.preventDefault();
                    this.$router.push({path: '/vagas/candidaturas', query: { lang: this.$i18n.locale }});
                    this.alert1.hide();
                  }
                }
              };
              this.alert1.msg.push(this.$createElement('a', vNode, x));
            }
          }

          let b2Title: string = "";

          if(!!this.$route.query.vaid) {
            if(!this.candidatoSRV.isProfileCompletedToJob()){
              this.alert1.title = this.$t("Incomplete Profile");
              this.alert1.msg.push(this.$createElement('div', { class: 'font-weight-bold text-left'}, this.$t('Pending fields').toString()));
              this.alert1.addUlMsg('',this.candidatoSRV.missingArray);
              this.alert1.callBoxCheckWithTitle('OK');
              this.disableBtn = false;
              return;
            } else {
              this.alert1.title = this.$t("1st stage completed!");
              b2Title = this.$t("Go to Interview").toString();
            }
          } else {
            b2Title = this.$t("Go to jobs").toString();
            this.alert1.title = this.$t("Congratulations");
          } 

          let obj: any = {
            b1Title: this.$t("Continue editing").toString(),
            b2Title: b2Title,
            b1CallBack: () => { /* console.log('b1') */ },
            b2CallBack: () => {
              // if(this.prompt)
              if (this.$route.query.vaid && this.candidatoSRV.isProfileCompletedToJob()) {
                this.$router.push({ path: '/vagas/'+this.$route.query.vaid, query: {lang: this.$i18n.locale, a: "1"}})
              } else {
                this.$router.push({ path: '/vagas', query: {lang: this.$i18n.locale}})
              }
            }
          };
          this.alert1.callBoxCheckWithTitleAndDoubleButtons(obj)
          this.disableBtn = false;
        }
      })
    } else {
      this.$toast.variant = 'danger';
      this.$toast.addMsg(this.$t("There are unfilled required fields").toString())
      this.$toast.addTitle(this.$t("Incomplete Registration").toString());
      this.$toast.open();
      this.currentPromise = this.candidatoSRV.save(false).then(async(data: any) => {
        this.fetchCandidatoInfo();
      });
      setTimeout(() => {
        this.disableBtn = false;
        },1000)
    }
  }

  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }

  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

}
