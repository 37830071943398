import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class Faq extends Repository {

  constructor() {
    super(new ApiRouter({
        fetchAll: { url: '/v1/faq', method: 'GET', headers: {} }, // tem parametro de Busca 's'
      })
    )
  } 

}
