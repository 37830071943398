










































































































import Alert from '@/components/alerts/Alert.vue';
import Confirm from '@/components/alerts/Confirm.vue';
import PlayLoader from '@/components/loader/PlayLoader.vue';
import RecLoader from '@/components/loader/RecLoader.vue';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import VideoRecordService from '@/core/Services/ThirdParty/VideoRecord';
import RecordService from '@/core/Services/Video/Record';
import RoomService from '@/core/Services/Video/Room';
import hark from 'hark';
import { connect, createLocalTracks } from 'twilio-video';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueScrollTo from 'vue-scrollto';

Vue.use(vueAwesomeCountdown, 'countdown');

@Component<TwilioStartRecord>({
  components: {
    PlayLoader,
    RecLoader,
  }
})
export default class TwilioStartRecord extends Vue {
  public room: RoomService;
  public record: RecordService;
  // public roomManager!: OpenViduApp;
  public startedProcess: boolean = false;
  public startingProcess: boolean = false;
  public startedRecProcess: boolean = false;
  public alteringRecProcess: boolean = false;
  public displayingCounterOnStart: boolean = true;
  public recording: boolean = false;
  public preview: boolean = false;
  public alert!: Alert;
  public confirm!: Confirm;
  public options!: any;
  public hasVideo: boolean = false;
  public currentPromise: any|null = null;
  public currentVideoDeviceId: string|null = null;
  public currentAudioDeviceId: string|null = null;
  public testingPhase: boolean = true;
  public showPopover: boolean = true;
  public deviceError: boolean = false;

  public activeRoom!: any;
  public previewTracks: any[] = [];
  public identity!: any;
  public roomName: string = "";
  public roomId!: any;
  public token!: any;

  private startCounterFinished: boolean = false;
  private vrService!: VideoRecordService;

  @Prop({default: 30000})
  public maxTime!: number; // default 30 seconds
  public time: number = 0;
  public PID: any = 0;

  public startCounterTime: number = 60000; // 60 seconds

  @Prop({default: ''})
  public startMessage!: string;

  @Prop({default: EVideoSupplier.Openvidu})
  public supplier!: number;

  @Prop({default: 0})
  public pid!: number;

  @Prop({default: false})
  private logVolume!: boolean;

  @Prop({default: EVideoPurpose.Curriculum})
  private purpose!: number;

  @Prop({default: false})
  private showCounterOnStart!: boolean;

  private harkSpeech?: hark.Harker;

  constructor() {
      super();
      this.room = new RoomService();
      this.record = new RecordService();
      this.options = {
        container: '#page-content',
        easing: 'ease-in',
        offset: -60,
        force: true,
        cancelable: true,
        x: false,
        y: true
      }
  }

  public mounted() {
    this.alert = new Alert();
  }

  get canMakeActions(): boolean {
    return this.purpose != EVideoPurpose.TestOnly
  }

  public stopStartCounter() {
    this.displayingCounterOnStart = true;
    (<any> this.$refs.startCounter).finishCountdown();
  }

  public onStartCounterFinished() {
    this.startCounterFinished = true;
    this.doStartRecording();
  }

  public closeRoom() {
    if(this.room.entidade && this.room.entidade.id)
      this.room.close(this.room.entidade.id);
  }

  public cancelVideoCV() {
    this.$emit('cancel');
  }

  /* public getDecurredTime(time: number): string {
    return this.formatTime(time);
  } */

  public formatTime(time: number): string {
    time = (time < 0) ? 0 : time;
    const seconds = time/1000; // divided by int return int
    const valor_time_mod = (seconds < -1) ? seconds * -1 : seconds;
    const m = (Math.round(seconds - (seconds % 60)) / 60).toString();
    const s = (Math.round(valor_time_mod % 60)).toString();
    return (m.length == 1 ? "0" + m : m) + ":" + (s.length == 1 ? "0" + s : s);
  }

  public countdownTime(time: number): string {
    return Math.round(time/1000).toString();
  }

  public changeVideo() {
    //video-preview-file
    if(this.purpose != EVideoPurpose.Curriculum) {
      this.$emit('changeApproved',this.record.entidade)
      return true;
    }
    let me: any = this;
    this.confirm = new Confirm();
    this.confirm.addMsg(''+this.$t('Want to apply this video to your profile?'))
    this.confirm.title = ''+this.$t('Attention')
    this.confirm.callBox().then((value: boolean) => {
      if(value) {
        this.$emit('changeApproved',this.record.entidade)
        this.closeRoom();
      } else {
        this.cancelVideoCV()
      }
    });
  }

  public async startProcess() {
    this.startingProcess = true;

    if(this.purpose == EVideoPurpose.TestOnly) {
      setTimeout(() =>{
        VueScrollTo.scrollTo("#comecarButton", 500, this.options);
      },500)
    }
    else {
      this.stopVolumeLog();
    }

    if(this.previewTracks.length == 0)
      await this.openlocalMedia();
  }


  public async openlocalMedia() {
    let width: number = 1280;
    let height: number = 720;
    if (window.innerWidth < window.innerHeight) {
    let width: number = 720;
    let height: number = 1280;
    }
    let videoConstraint: any = { width, height };
    this.previewTracks = await createLocalTracks({ audio: true, video: videoConstraint });
    const localMediaContainer = document.getElementById('video-container');
    this.previewTracks.forEach((track: any) => {
      localMediaContainer!.appendChild(track.attach());
    })
    this.getVideoElement()!.classList.add('recorder-video');
    /* console.log(this.previewTracks) */
    this.hasVideo = true;
    this.startingProcess = false;
    this.startedProcess = true;
    this.$emit('startedProcess');

    this.initVolumeLog();
  }

  public closelocalMedia() {
    this.previewTracks.forEach((track: any) => {
      track.stop()
      track.detach().forEach((detachedElement: any) => {
        detachedElement.remove();
      });
    });
    this.hasVideo = false;
    this.previewTracks = [];
  }



  public initVolumeLog(): void {
    // const localAudio = (this.previewTracks[0] as LocalAudioTrack);

    // const attachments = localAudio.mediaStreamTrack
    // const audioElement = document.getElementById('video-container') as HTMLAudioElement;

    // const mediaStream = audioElement.srcObject as MediaStream;

    // this.harkSpeech = hark(mediaStream, {});

    // this.harkSpeech.on('volume_change', value => {
    //   this.$emit('volumeChange', value);
    // });
    // this.harkSpeech.suspend
  }

  public stopVolumeLog(): void {
    if (this.harkSpeech) {
      // Will stop the polling and events will not be emitted.
      this.harkSpeech.stop();
    }
  }

  public loadLastVideo() {
      this.preview = true;
      (<HTMLVideoElement>document.getElementById('video-preview-file')).src =this.record.entidade.url;
      (<HTMLVideoElement>document.getElementById('video-preview-file')).load();
  }

  public startProgress() {
    this.time = 0;
    this.PID = setInterval(() => {
      this.time = (this.time)+100;
      if(this.time >= this.maxTime) {
        clearInterval(this.PID)
      }
    }, 100)
  }

  public getVideoElement() {
    return document.querySelectorAll('#video-container video:not(#video-preview-file)')[0];
  }

  public blurVideo() {
    this.getVideoElement()!.classList.add('blur-video');
  }
  public unBlurVideo() {
    this.getVideoElement()!.classList.remove('blur-video');
  }

  private async prepareToRecord() {
    if (this.previewTracks.length == 0) {
      this.startProcess();
    }

    this.preview = false;
    this.alteringRecProcess = true;

    let roomData: any;

    if(this.purpose == EVideoPurpose.Interview) {
      VueScrollTo.scrollTo("#questionTabs", 500, this.options);
    }
    // let roomData = await this.room.createCurriculum(EVideoSupplier.Openvidu);
    this.startRecording();

  }

  public async startRecording() {
    if (this.showCounterOnStart) {
      this.blurVideo();
      this.displayingCounterOnStart = false;
      (<any> this.$refs.startCounter).startCountdown(true);
      this.$emit('startCountDownStarted');
    }
    else {
      this.doStartRecording();
    }
  }

  private async doStartRecording() {
    this.preview = false;
    await this.openRoom();
    if(this.roomId && this.roomName) {
      await this.joinRoom();
      this.startProgress();
      this.alteringRecProcess = false;
      this.startedRecProcess = true;
      setTimeout(() => {
        this.$nextTick(() => {
          (<any>this.$refs.counter).startCountdown(true);
          this.unBlurVideo();
        });
      },1000)       
      this.$emit('recordStarted',this.room.entidade);
    } else {
      this.alert.addMsg(''+this.$t('Please try again later'))
      this.alert.title = this.$t('Your Video cannot be uploaded.')
      this.alert.callBoxDanger();
      this.unBlurVideo();
    }
    // await this.record.startRecording({session: this.room.entidade.key });
  }

  public async stopRecording() {
    this.time = 0;
    (<any> this.$refs.counter).stopCountdown();
    this.startedRecProcess = false;
    this.alteringRecProcess = true;
    clearInterval(this.PID);
    await this.leaveRoom();
    // this.roomManager.removePublisher();
    /* console.log('recordEnded') */
    if(this.room.entidade)
      this.$emit("recordEnded");

    this.room.entidade = null;
    this.alteringRecProcess = false;
  }
  public async joinRoom(){
    // Bind button to join Room.
    /* console.log(this.previewTracks) */
    if(this.previewTracks.length == 0){
      await this.openlocalMedia();
    }
    if (!this.roomName) {
      alert('Please enter a room name.');
      return;
    }
    var connectOptions = {
      name: this.roomName,
      tracks: this.previewTracks
    };

    // Join the Room with the token from the server and the
    // LocalParticipant's Tracks.
    try{
      let room = await connect(this.token, connectOptions)
      this.roomJoined(room);
      /* console.log(`Connected to Room: ${room.name}`) */
    } catch (e) {
      /* console.log(`Connected to Room`) */
    }
  }

  public async leaveRoom() {
    try {
      if (this.vrService) {
        let dc: any = {
          id: this.roomId,
          rName: this.roomName,
          pid: this.pid,
          supplier: EVideoSupplier.Twilio,
          purpose: EVideoPurpose.Interview
        };
        let d = await this.vrService.update(dc);
        await this.room.find(this.roomId);
        if (this.activeRoom) {
          this.activeRoom.disconnect();
        }
        /* console.log(d) */
      }
      return true;
    } catch (e) {
      /* console.log(e) */
      return false;
    }
  }

  public async openRoom() {
    // Obtain a token from the server in order to connect to the Room.
    try {
      this.vrService = new VideoRecordService();
      var dc: any = {
        pid: this.pid,
        supplier: EVideoSupplier.Twilio,
        purpose: EVideoPurpose.Interview
      };
      let data: any = await this.vrService.create(dc)
      this.identity = data.identity;
      this.roomName = data.roomName;
      this.token = data.token;
      this.roomId = data.roomId;
      await this.room.find(this.roomId);
    } catch(e) {
      this.leaveRoom();
    }
  }


  // Attach the Participant's Tracks to the DOM.
  public attachParticipantTracks(participant: any, container: any) {
    participant.tracks.forEach((publication: any) => {
      const track = publication.track;
      container.appendChild(track.attach());
    });
  }

  // Detach the Participant's Tracks from the DOM.
  public detachParticipantTracks(participant: any) {
    const tracks = Array.from(participant.tracks.values());
    participant.tracks.forEach(function (publication: any) {
      const track = publication.track;
      track.detach().forEach((detachedElement: any) => {
        detachedElement.remove();
      });
     });
  }

  // Successfully connected!
  public roomJoined(room: any) {
   this.activeRoom = room;

    // Once the LocalParticipant leaves the room, detach the Tracks
    // of all Participants, including that of the LocalParticipant.
    room.on('disconnected',() => {
      // this.log('Left');
      if (this.previewTracks) {
        this.previewTracks.forEach((track: any) => {
          track.stop();
        });
        this.previewTracks = [];
      }
      this.detachParticipantTracks(room.localParticipant);
      room.participants.forEach(this.detachParticipantTracks);
      this.activeRoom = null;
    });
  }
}
