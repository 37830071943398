





















import { Vue, Component, Prop } from 'vue-property-decorator';
// import InterviewQuestionContent from '@/components/interview/InterviewQuestionContent.vue';
import InterviewBoxContent from '@/components/interview-mobile/InterviewBoxContent.vue';
import { VagaPerguntaEntity } from '@/core/models/Entities';

// import vueAwesomeCountdown from 'vue-awesome-countdown';
// Vue.use(vueAwesomeCountdown, 'countdown');

@Component({
  components: {
    // InterviewQuestionContent
    InterviewBoxContent
  }
})
export default class InterviewBoxTab extends Vue {
  @Prop()
  private pergunta!: VagaPerguntaEntity;

  @Prop()
  private active!: boolean;
}
