









































import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import Logo from '@/components/logo/Logo.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { Component, Prop, Vue } from "vue-property-decorator";

const namespace: string = 'profile';

@Component({
  components: {
    ButtonIcon,
    'logo-jobecam': Logo,
  },
})
export default class PublicHeader extends Vue {
  
  @Prop({default: false})
  public noSidebar!: boolean;

  public candidatoSRV = new CandidatoService;
  private toggleClickChat: boolean = false;
  public title!: string;

  private langs = [
    {
      id: '10',
      locale: 'pt-BR',
      name: 'Português'
    },
    {
      id: '3',
      locale: 'es',
      name: 'Español'
    },
    {
      id: '2',
      locale: 'en-US',
      name: 'English'
    }
  ];

  public goHelp() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/1`;
    window.open(fc, '_blank');
    //this.$router.replace({ path: '/ajuda', query: {lang: this.$i18n.locale }});
  }

  public toggleChat() {
    this.toggleClickChat = true;    
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/cpp/create-ticket/`;
    window.open(fc, '_blank');

  }

  public created() {
    this.title = this?.$router?.currentRoute?.meta?.title
  }

  public setLocale(lang: any) {
    if (this.$i18n.locale === lang.locale) {
      return;
    }
    this.$i18n.locale = lang.locale;
    this.$router.replace({path:  this.$router.currentRoute.path, query: { lang: lang.locale } })
  }

  public toggleSidebar() {
    this.$emit('on-sidebar');
  }
}
