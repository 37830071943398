



















import Vue from "vue";
import Component from "vue-class-component";
import NavBar from "@/views/layouts/NavBar.vue";
import TermsResponsability from '@/views/terms/TermsResponsability.vue';

const namespace: string = "profile";

let PIDINTERVAL: any = 0;

@Component<CandidatoRegister>({
  components: {
    "nav-bar": NavBar,
    "terms": TermsResponsability,
  }
})
export default class CandidatoRegister extends Vue {

   public toggleBodyClass(addRemoveClass: any, className: any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  public mounted() {
   this.toggleBodyClass('addClass', 'login');
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'login')
  }
}
