


















import Vue from "vue";
import Component from "vue-class-component";
import Section from "@/components/section/Section.vue";


@Component<TermsPolicy>({
  components: {
    "section-card": Section,
  }
})
export default class TermsPolicy extends Vue {

}
