

















































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import HabilidadeService from "@/core/Services/Habilidade";
import HabilidadeCandidatoService from "@/core/Services/Candidato/HabilidadeCandidato";
import { HabilidadeEntity,HabilidadeCandidatoEntity } from "@/core/models/Entities";
import Section from '@/components/section/Section.vue';
import DateInput from '@/components/form/DateInput.vue';
import Autocomplete from '@trevoreyre/autocomplete-vue'
import CircleRate from "@/components/rate/CircleRate.vue";
import Alert from '@/components/alerts/Alert.vue';
import Confirm from '../alerts/Confirm.vue';


@Component<HabilidadesEdit>({
  components: {
    "section-card": Section,
    'auto-complete': Autocomplete,
    DateInput,
    CircleRate,
  }
})
export default class HabilidadesEdit extends Vue {
  @Prop({default: []})
  public minhasHabilidades!: HabilidadeEntity[];

  public habSRV: HabilidadeService;
  public habCandSRV!: HabilidadeCandidatoService;
  public showLocalidadeText: boolean = true;
  public habilidades: HabilidadeEntity[] = [];
  public hashHabilidades: Map<number,number>|null = null;
  public items: any[] = [];
  public fields: any[] = [];
  public disabled: boolean = false;
  public buttonLabel: any;
  public nivel: number = 0;
  public search: string|null = null;
  public currentDeletePromise: any|null = null;
  public habilidadeSelecionada!: HabilidadeEntity|null;
  public buscaHabilidades: string = '';
  public timeout: any;
  public currentPromise: any|null = null;
  public alert: Alert = new Alert();
  public alertConfirm!: Confirm;

  constructor() {
    super();
    this.habSRV = new HabilidadeService();
    this.habCandSRV = new HabilidadeCandidatoService();
    this.habilidades = [];
  }

  public handleChangeTipo(v: any){
    this.loadData(this.minhasHabilidades)
  }

  public searchHabilities(v1: string) {
     return new Promise((resolve: (v: any[]) => void) => {
       clearTimeout(this.timeout);

       if(!v1)
        return;
        
        this.timeout = setTimeout(async (currentValue: string) => {
          this.currentPromise = this.habSRV.fetchAll({s: currentValue}).then((data) => {
            this.updateHabilidadesList(data.habilidades)
            const arrFilter: any[] = this.habilidades.filter((hb: HabilidadeEntity) => {  
                if(hb && hb.name)
                  return (hb.name.toLowerCase().indexOf(currentValue.toLowerCase()) > -1) 

                return false;
              })
            resolve(arrFilter)
          })
        }, 500, v1);
      })
  }

  public getResultValue(result: HabilidadeEntity) {
    return result.name;
  }

  public setNivel(value: number) {
    this.nivel = value;
  }

  get disableAdd() {
    return !this.habilidadeSelecionada;
  }

  public updateHabilidadesList(habs: any[]) {
    if (this.hashHabilidades == null) {
        this.hashHabilidades = new Map<number,number>();
    }
    habs.forEach((value: any,index: number) => {
      if(!this.hashHabilidades!.has(value.id!)) {
        this.habilidades.push(value);
        this.hashHabilidades!.set(value.id!,index);
      }
    })
  }

  public created() {
    this.buttonLabel = ""+this.$t('Save');
    this.fields = [
      { key: "nome" },
      { key: "nivel" },
      { key: "id" }
    ];
    this.alertConfirm = new Confirm();
    this.alertConfirm.addTitle(this.$t('Attention') as string);
  }

  public reset() {
    this.habSRV.entidade = new HabilidadeEntity()
    this.buttonLabel = ""+this.$t('Save');
    // this.editEnable = false;
  }

  public save() {
    if(!this.disabled)
      this.habSRV.save()
  }

  public editEXP(id: any) {
    this.buttonLabel = ""+this.$t('Edit');
    // this.editEnable = true;
    this.getEntidadeFromList(id);
  }

  public getEntidadeFromList(id?: any) {
    if (!id) {
      return null;
    }
    this.habilidades.forEach(el => {
      if( el.id === id ){
        this.habSRV.entidade = el;
        return true;
      }
    })
  }

  public add() {
    if (!this.habilidadeSelecionada) {
      this.alert.addMsg(this.$t("Select an Ability to proceed"));
      this.alert.callBoxWarn();
      return;
    }

    const habilidade = this.items.find(habilidade => habilidade.habilidadeId === this.habilidadeSelecionada!.id);

    if (habilidade) {
      this.alert.addMsg(this.$t("This ability has already been added."));
      this.alert.callBoxWarn();
      return;
    }

    this.habCandSRV.entidade = new HabilidadeCandidatoEntity()
    this.habCandSRV.entidade.habilidadeId = this.habilidadeSelecionada.id;
    this.habCandSRV.entidade.nivel = this.nivel;

    this.currentPromise = this.habCandSRV.save()
    .then((success) => {
            
      if (!success) {
        return;
      }
      this.items.push({
        nome: this.habilidadeSelecionada!.name,
        nivel: this.nivel,
        id: this.habCandSRV.entidade.id,
        habilidadeId: this.habCandSRV.entidade.habilidade!.id,
      });
      this.habilidadeSelecionada = null;
      // Limpar busca
      this.buscaHabilidades = '';
      (this.$refs.autoComplete as any).value = '';//Só limpar a model não funciona :/
      this.$emit('saved',true);
    });
    (this.$refs.tHabilidades as any).refresh();
  }

  public async removeHabilidade(id: any) {
    this.alertConfirm.addMsg(this.$t('Want to remove this skill?'));
    const confirmed: boolean = await this.alertConfirm.callBox();

    if (!confirmed) {
      return;
    }

    const tIndex = this.items.findIndex((habilidade: any) => {return habilidade.id == id});

    if (-1 === tIndex) {
      // Not found
      return;
    }

    await this.habCandSRV.delete({id});

    this.items.splice(tIndex, 1);
    (this.$refs.tHabilidades as any).refresh();
  }

  public loadData(habilidades?: any[]) {
    if (!habilidades)
      return;

    this.items = [];
    habilidades.forEach((habilidade: any) => {
      this.items.push({
        nome: habilidade.habilidade.name,
        nivel:habilidade.nivel,
        id: habilidade.id,
        habilidadeId: (habilidade.habilidade as HabilidadeEntity).id,
      });
    });
  }

  public mounted() {
    this.handleChangeTipo(null)
  }
}
