
import tzlists from './timezone';

export default class TimezoneHelper {
  
  // List made using PHP(DateTimeZone::getOffset) and DateTimeZone::listIdentifiers
  public static tzlist: { timezones: any, offsets: any, normalize: any } = tzlists;
  
  public static GuessTimezone(): string {
    // Get current date  
    const date = new Date();
    // get UTC offset of the current date in minutes
    const tzOffset: number = date.getTimezoneOffset();
    let dString: string = date.toString();
    const tzOffsetSeconds: string = (tzOffset*60*(-1))+'';

    let index = dString.indexOf('(');
    let indexE = dString.indexOf(')');

    if(index > -1) {
      dString = dString.substring(index+1,indexE);
      dString = dString.replace('Standard Time','');
      dString = dString.replace('Horário Padrão de','');
      dString = dString.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().replace(' ', '_');
      
    }

    /* 
    * The tzlist has a list of timezones by offset in seconds 
    * to guess we can get the 'tzOffset' and the string from date.toString() and 
    * check if there is a Timezone related to the Timefuse in the string, thats why is a guess because 
    * if we cant find any, the first will be displayed
    */

    if(this.tzlist.offsets[tzOffsetSeconds]) {
      const currentOffset: string[] = this.tzlist.offsets[tzOffsetSeconds];
      
      if(process.env.NODE_ENV != 'production') {
        console.log("O Fuso horário local deve ser de %s",dString)
      }
      if(this.tzlist.normalize[dString]) {
        return (this.tzlist.normalize[dString] as string);
      }
      
      let guessedTz: string = currentOffset[0];
      for(let tz of currentOffset) {
        
        if(process.env.NODE_ENV != 'production') {
          console.log("Procurando .... ",tz)
        }
        if(tz.indexOf(dString) > -1) {
          guessedTz = tz;
          break;
        }
      }
      
      return guessedTz;
    } else {
      return "Europe/Dublin";
    } 
  }

}