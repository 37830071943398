













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import UserModel from '@/core/recording/UserModel';

@Component
export default class SpeakingBox extends Vue {
  
  @Prop({default: null})
  public user!: UserModel;

}
