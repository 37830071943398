


















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SelectInput extends Vue {
    
    @Prop({default: ''})
    public value!: any;

    @Prop({default: false})
    public required!: boolean;
    
    @Prop({default: () => {return null}})
    public placeholder!: object;
    
    @Prop({default: () => {return null}})
    public label!: string;
    
    @Prop({default: () => {return {}; }})
    public optionList!: any[];

    public scopeID: string;
    public currentValue: any|null = null;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput() {
        this.$emit('input', this.currentValue);
    }

    public handleChange() {
        this.$emit('change', this.currentValue);
    }

    @Watch('value')
    public setCurrent() {
        this.currentValue = this.value;
    }

    public mounted() {
        setTimeout(() => {
            this.currentValue = this.value;
        },1500)
    }

}
