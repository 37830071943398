





















import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class EtniaSelect extends Vue {

  public etnias: any[] = [];
  
  @Prop({default: ''})
  public value!: string;

  get etniaValue() {
    return this.value
  }

  set etniaValue(v: string) {
    this.$emit('input',v)
    this.$emit('change', v)
  }


  // @Watch('etniaValue')
  // public handleModelChange(v){
  //   this.value = v;
  // }

  public created(){
    this.etnias = [
      { text: this.$t("Select").toString() , value: null },
      { text: this.$t("BRANCO").toString(), value: "BRANCO" },
      { text: this.$t("PRETO").toString(), value: "PRETO" },
      { text: this.$t("PARDO").toString(), value: "PARDO" },
      { text: this.$t("AMARELA").toString(), value: "AMARELA" },
      { text: this.$t("INDÍGENA").toString(), value: "INDÍGENA" },
      { text: this.$t("ASIÁTICO").toString(), value: "ASIÁTICO" },
      { text: this.$t("HISPÂNICO").toString(), value: "HISPÂNICO" },
      { text: this.$t("OUTROS").toString(), value: "OUTROS" },
    ];
  }

}
