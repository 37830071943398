








































import CheckboxInput from '@/components/form/CheckboxInput.vue';
import TextInput from '@/components/form/TextInput.vue';
import SearchInput from '@/components/search/Search.vue';
import MessageJob from '@/core/Services/Basic/MessageJob';
import { MessageJobEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import ListaMensagens from './ListaMensagens.vue';
import Mensagem from './Mensagem.vue';

@Component({
  components: {
    'search-input': SearchInput,
    CheckboxInput,
    TextInput,
    ListaMensagens,
    Mensagem
  }
})
export default class Mensagens extends Vue {
  
  public messageJobService: MessageJob = new MessageJob;
  public tabActive = 1;
  public mobileIsShowMessages = false;
  public empty: boolean = false;
  public loading: boolean = false;
  
  public created() {
    const _this: any = this;
    _this.containerCustomClass = 'messagePageContainer';
    clearInterval(_this.messagePID)
  }
  public mounted() {
    const _this: any = this;
    _this.containerCustomClass = 'messagePageContainer';
    clearInterval(_this.messagePID)
  }
  public doSearch(value: string) {
    /* console.log(value) */
  }
  public closeModal() {
    this.$bvModal.hide('modalNewMessage');
  }

  get isMobile() {
    /* console.log('isMobile') */
    return this.mobileIsShowMessages;
  }
  public beforeDestroy() {
    const _this: any = this;
    console.log('clearPID message,',_this.messagePID)
    clearInterval(_this.messagePID)

  }

  public sended(message?: MessageJobEntity) {    
    // TODO something
    this.loadOnlyMessages(message);
    this.mobileIsShowMessages = true;
  }

  public handleBack(v: boolean){
    this.mobileIsShowMessages = v;
  }
  
  public loadOnlyMessages(message?: MessageJobEntity) {     
    if(message) {
      this.messageJobService.fetch(message!.id||0)
      .then((data) => {
        this.mobileIsShowMessages = true;
        if(data?.mensagens?.length > 0)
          (<Mensagem>this.$refs.msgs).setMessagesList(data.mensagens);
        // (<Mensagem>this.$refs.msgs).setMobile(this.mobileIsShowMessages);
      });
    }
  }
  public loadMessage(message?: MessageJobEntity) {    
    if(message) {
      this.loading = true;
      this.messageJobService.fetch(message!.id||0)
      .then((data) => {
        this.loading = false;
        this.mobileIsShowMessages = true;
        if(!message)
          this.empty = data.mensagens.length == 0;
        (<Mensagem>this.$refs.msgs).setRoot(message);
        (<Mensagem>this.$refs.msgs).setMessagesList(data.mensagens);
        // (<Mensagem>this.$refs.msgs).setMobile(this.mobileIsShowMessages);
      });
      const _this: any = this;
      clearInterval(_this.messagePID)
      _this.messagePID = setInterval(() => {
        console.log('load message,')
        this.loadOnlyMessages(message);
      },10000);
    }
  }
}
