




























































































































































































































































































































import Alert from "@/components/alerts/Alert.vue";
import ModalCurriculoOculto from '@/components/modal/ModalCurriculoOculto.vue';
import ModalDiversidade from '@/components/modal/ModalDiversidade.vue';
import SidebarBackgroundImage from '@/components/sidebar/SidebarBackgroundImage.vue';
import SidebarDiversidade from '@/components/sidebar/SidebarDiversidade.vue';
import SidebarManualLiveRoom from '@/components/sidebar/SidebarManualLiveRoom.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import { CandidatoEntity } from '@/core/models/Entities';
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VideoRoomMixin from './mixins/VideoRoomMixin';
import AboutBlindRoomContent from './partials-video/AboutBlindRoomContent.vue';
import OptimizeExperienceContent from './partials-video/OptimizeExperienceContent.vue';
import Chat from './partials-video/Chat.vue';
import Stream from './partials-video/Stream.vue';
import Toolbar from './partials-video/Toolbar.vue';
import TopNavBar from './partials-video/TopNavBar.vue';
import cities from '@/core/helper/cities';
import { LocalDataManagement } from "@/core/util";

const sleep = async (ms: any) => {
  return new Promise((resolve: any) => setTimeout(resolve, ms));
};

// let localUser = new UserModel();

@Component({
  components: {
    Stream,
    Chat,
    'modal-diversidade' : ModalDiversidade,
    SidebarDiversidade,
    Toolbar,
    TopNavBar,
    SidebarManualLiveRoom,
    SidebarBackgroundImage,
    ModalCurriculoOculto,
    AboutBlindRoomContent,
    OptimizeExperienceContent
  },
  mixins: [VideoRoomMixin]
})
export default class RoomManager extends Vue {
  public URL: string = location.protocol + "//" + location.host;
  public currentVideoDeviceId: string = "";
  public currentAudioDeviceId: string = "";
  public currentPromise: any|null = null;
  public candidatoSRV!: CandidatoService;
  public alert: Alert = new Alert();
  public introDone: boolean =false;
  public diversidadeDone: boolean =false;
  public sidebarDiversidade: boolean =false;

  @Getter("candidatoInfo", { namespace: 'profile' })
  public candidato?: CandidatoEntity;

  @Action("fetchCandidatoInfo", { namespace: 'profile' })
  public fetchCandidatoInfo: any;

  get primeiroNome() {
    return (Boolean(this.candidato!.primeironome) ? this.candidato!.primeironome : null);
  }

  get imageBackground() {
    const _: any = this;
    return location.protocol + "//" + location.host + "/static/img/small/poster"+_.ovManager?.localUser?.random+".png";
  }

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    window['vrm'] = this;
  }

  public city(index){
    return cities[index];
  }

  public saveModelDiversidade(){
    this.currentPromise = this.candidatoSRV.save().then(async(data: any) => {
      if(!this.$route.query.vaid) {
        this.alert.addMsg(this.$t('Profile successfully saved'));
        this.alert.callBoxCheckWithTitle(this.$t('Success').toString());            
        this.$bvModal.hide('modalDiversidade');
        this.sidebarDiversidade = false;
      }
    })
  }

  public modalListener() {
    this.$root.$on('bv::modal::hide', async (bvEvent, modalId) => {
      if(modalId == "modalRoomAlert") {
          console.log('modalId not clicked on button must initialize Room')
          await sleep(1);
          if (!this.introDone) {
            this.initializeRoom()
          }
      } 
      if(modalId == "modalOptimizeExp" ) {
        console.log("modalOptimizeExp - hide")
        LocalDataManagement.saveLocalData('JB_M_OPTIMAL', {opened:true},null)
        this.openFirstModal()
      }
    })
  }

  // private redirectTalentIfItsUsingIphone() {
  //   const deviceIsAnIphone = /iPhone|iPad/i.test(navigator.userAgent);

  //   if (deviceIsAnIphone) {
  //     this.$router.push(`/iphone-error?lang=${this.$i18n.locale}`);
  //   }
  // }

  public openFirstModal() {
    console.log("openFirstModal")
    const _this: any = this;
    if(!_this.platform.canUseMediaPipe() && !LocalDataManagement.getLocalData('JB_M_OPTIMAL')?.opened) {
      this.$bvModal.show('modalOptimizeExp')
    } else {
      this.$bvModal.show('modalRoomAlert')
    }
  }

  public async mounted() {
    // this.redirectTalentIfItsUsingIphone();
    
    
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    } 

    this.$set(this.candidatoSRV,'entidade', JSON.parse(JSON.stringify(this.candidato)));

    document.body.style.overflow = 'auto';
    const _this: any = this;

    this.modalListener();   

    if (sessionStorage.getItem('access_token')) {
      await this.loggedInUserStartUP()
    } else {
     this.publicUserStartUp()
    }
  }

  public async publicUserStartUp() {
    const _this: any = this;
    // check if room is blind by the parameter passed on url
    try {
      _this.room.entidade = await _this.publicRoom.fetchPublicRoomInfo(this.$route.params.sid);
      if (_this.room.entidade && _this.room.entidade.key) {
        _this.ovManager.enableFaceMesh = _this.room.entidade.enableFaceMesh && _this.platform.canUseMediaPipe();
        _this.ovManager.enableCustomBack = _this.room.entidade.enableCustomBack && _this.platform.canUseMediaPipe();
        if (_this.room.entidade.blind == true) {
          _this.ovManager.enableFilter = true;
          this.openFirstModal()
          return;
        } else {
          this.initializeRoomParams();
          return;
        }
      } else {
        _this.roomError = true;
        _this.loadingPage = false;
      }
    } catch (error) {
        _this.roomError = true;
        _this.loadingPage = false;
    }
    
  }

  public async loggedInUserStartUP() {
    const _this: any = this;
    try {
      await _this.room.findByKey(this.$route.params.sid);
      if (_this.room.entidade && _this.room.entidade.id) {
        _this.ovManager.enableFaceMesh = _this.room.entidade.enableFaceMesh;
        _this.ovManager.enableCustomBack = _this.room.entidade.enableCustomBack;
        if (_this.room.entidade.blind == true) {
          _this.ovManager.enableFilter = true;
          console.log(this.candidatoSRV.needToCreateHiddenCV(),'<< needToCreateHiddenCV')
          if(_this.room.entidade.requestBlindCVInfo == true && this.candidatoSRV.needToCreateHiddenCV()) {
            this.$bvModal.show('modalCurriculoOculto')
          } else {
            this.openFirstModal()
          }
          return;
        } else {
          this.initializeRoomParams();
          return;
        }
      } else {
        _this.roomError = true;
        _this.loadingPage = false;
      }
    } catch (error) {
        _this.roomError = true;
        _this.loadingPage = false;
    }
  }

  public async initializeRoomParams() {
    const _this: any = this;
    _this.userName = this.primeiroNome;
    _this.userEmail = this.candidato?.email;

    _this.openRoom = true;
    if(!this.candidato)
      await this.fetchCandidatoInfo();
      
    _this.UID = this.candidato?.id;
    _this.GUID = this.candidato?.guid;
    if(!_this.testDone) {
      await _this.openTestVideo();
      return;
    }
    // _this.loadVariables(this.candidato!.id, sessionStorage.getItem("profile_type"),this.candidato?.guid);
    // _this.createOpenviduCall();
    //await _this.openTestVideo();
    //this.currentVideoDeviceId = _this.ovManager.currentVideoDeviceId;
    // _this.isAuthenticatedUser = true;
  }

  public async initializeRoom() {
    if(this.candidato?.perfil.grupoMovBlack == null){
      //this.$bvModal.show('modalDiversidade');
      this.sidebarDiversidade =  true;
    }

    console.log('initializeRoom')
    this.introDone = true;
    const _this: any = this;
    if (sessionStorage.getItem('access_token')) {
      this.initializeRoomParams();
    } else {
      await _this.openTestVideo();
      // this.currentVideoDeviceId = _this.ovManager.currentVideoDeviceId;
    }
    this.$bvModal.hide('modalRoomAlert')
    setTimeout(() => {
      document.body.style.overflow = 'auto';    
    },7000)
  }

  /**
   * diversitySaved
   */
  public diversitySaved() {
    console.log('dados de diversidade salvos com sucesso')
  }
  
  /**
   * diversityError
   */
  public diversityError() {
    console.log('dados de diversidade não coletados')
  }

}

