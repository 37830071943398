


































import { CandidaturaEntity, VagaEntity } from '@/core/models/Entities';
import CandidaturaService from "@/core/Services/Candidato/Candidatura";
import VagaService from "@/core/Services/Vaga/Vaga";
import { Component, Vue } from 'vue-property-decorator';
//TROQUEI PARA CANDIDATURAS AQUI
@Component
export default class CardLatestJobs extends Vue {
  public candidaturaService: CandidaturaService = new CandidaturaService();
  public vagaService: VagaService;
  public vagas: VagaEntity[] = [];
  public candidaturas: CandidaturaEntity[] = [];
  public items: any[] = [];
  public fields: any[] = [];
  public limit: number = 6;

  // @Prop({default: () => { return new VagaEntity()}})
  // public vaga!: VagaEntity;

  constructor() {
    super();
    this.vagaService = new VagaService();
    // this.vagasList = []
  }

  public mounted() {
    this.applications()

    this.fields = [
      { key: "vaga",label: this.$t('Job')  },
      { key: "nivel", label: this.$t('Level') },
      { key: "empresa", label: this.$t('Company') },
      { key: "dataPublicacao", label: this.$t('Post date') },
      { key: "detalhes", label: this.$t('Details') }
    ];
  }

  public loadData() {
    this.items = [];
    this.candidaturas.forEach((ca: CandidaturaEntity) => {
      const el: VagaEntity = ca.vaga;
      console.log(el.dataPublicacao)
      this.items.push({
        nivel: el.nivel,
        vaga: el.posicao,
        empresa: el.empresa?.nomeEmpresa,
        dataPublicacao: this.$options.filters!.dateOnly(el.dataPublicacao),
        mensagem: '',
        status: ca.status,
        detalhes: el.nomeUrl
      });
    });
    
  }

  public applications() {
    this.candidaturaService.fetchAll({limit: this.limit}).then(data => {
       this.candidaturas = []
       data.candidaturas.forEach((candidatura: any,index: number) => {
         this.candidaturas.push(candidatura);
         this.vagas.push(candidatura.vaga)
       });
       this.loadData()
    });
  }

  get vagasPage(){
    return { name: 'Candidaturas', query: {lang: this.$i18n.locale}};
  }

}
