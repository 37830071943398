export const pcdObject = {
  "auditiva":{
    "label": "Auditiva",
     "nivel":[
       "Select",
        "de 41 a 55 dB – Surdez Moderada",
        "de 56 a 70 dB – Surdez Acentuada",
        "de 71 a 90 dB – Surdez Severa",
        "Acima de 91 dB – Surdez Profunda",
        "Anacusia"
     ],
     "tipo":[
      "Select",
        "Oralizado",
        "Usuário de libras",
        "Precisa de intérprete"
     ]
  },
  "fala":{
    "label": "Fala",
     "nivel":null,
     "tipo":[
      "Select",
        "Grandes alterações na fala",
        "Mudez"
     ]
  },
  "fisica":{
    "label": "Física",
     "tipo":[
      "Selecione",
        "Monoparesia",
        "Monoplegia",
        "Hemiparesia",
        "Paraparesia",
        "Paraplegia",
        "Triparesia",
        "Triplegia",
        "Tetraparesia",
        "Tetraplegia",
        "Amputação ou ausência de membro",
        "Membros com deformidade congênita ou adquirida",
        "Nanismo",
        "Ostomia",
        "Paralisia cerebral",
        "Deficiência Crônica Renal"
     ],
     "nivel":null
  },
  "mental":{
    "label": "Mental",
     "nivel":null,
     "tipo":[
        "Select",
        "Comunicação",
        "Cuidado pessoal",
        "Habilidade social",
        "Utilização de recursos da comunidade",
        "Saúde e segurança",
        "Habilidade acadêmica",
        "Lazer",
        "Trabalho",
        "Visual"
     ]
  },
  "visual":{
    "label": "Visual",
     "nivel":[
      "Select",
        "unilateral",
        "bilateral"
     ],
     "tipo":[
      "Select",
        "Visão Subnormal ou Baixa Visão",
        "Cegueira",
        "Visão monocular",
        "Somatória da medida do campo visual em ambos os olhos for igual ou menor que 60º"
     ]
  }
}