








import { Vue, Component, Prop } from 'vue-property-decorator';
import { VagaPerguntaEntity } from '@/core/models/Entities';

@Component
export default class InterviewQuestionContent extends Vue {}
