import { render, staticRenderFns } from "./CardProgressProfile.vue?vue&type=template&id=fb8b6d52&scoped=true"
import script from "./CardProgressProfile.vue?vue&type=script&lang=ts"
export * from "./CardProgressProfile.vue?vue&type=script&lang=ts"
import style0 from "./CardProgressProfile.vue?vue&type=style&index=0&id=fb8b6d52&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb8b6d52",
  null
  
)

export default component.exports