import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';

export default class Candidatura extends Repository {

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/candidatura', method: 'POST', headers: {} },
      delete: { url: '/v1/candidatura/{id}', method: 'DELETE', headers: {} },
      fetch: { url: '/v1/candidatura/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/candidatura', method: 'GET', headers: {} }, // tem parametro de Busca "s"
      update: { url: '/v1/candidatura/{id}', method: 'PUT', headers: {} }}));
  }
}
