import { render, staticRenderFns } from "./RootLoggedArea.vue?vue&type=template&id=6b8b692d&scoped=true"
import script from "./RootLoggedArea.vue?vue&type=script&lang=ts"
export * from "./RootLoggedArea.vue?vue&type=script&lang=ts"
import style0 from "./RootLoggedArea.vue?vue&type=style&index=0&id=6b8b692d&prod&lang=scss&scoped=true"
import style1 from "./RootLoggedArea.vue?vue&type=style&index=1&id=6b8b692d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b8b692d",
  null
  
)

export default component.exports