








import Vue from 'vue';
import Component from 'vue-class-component';

@Component<CardBox>({})
export default class CardBox extends Vue {
}
