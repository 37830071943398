


















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { HabilidadeCandidatoEntity } from "@/core/models/Entities";

@Component
export default class CardPdfSkills extends Vue {

  @Prop()
  public habilidades!: HabilidadeCandidatoEntity[];
  public items: any[] = [];
  public fields: any[] = [];
  public num: number = 4

  constructor() {
    super();
  }

  public created() {
    this.fields = [
      { key: "tecnicas" },
      { key: "nivel", label: '' },
    ];
    this.loadData();
  }

  public loadData() {
    this.items = [];
    this.habilidades.forEach((el: any) => {
      this.items.push({
        tecnicas: el.habilidade.name,
        nivel: el.nivel,
      });
    });
  }
}
