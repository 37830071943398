



































































































import InterviewBoxContent from '@/components/interview-mobile/InterviewBoxContent.vue';
import InterviewBoxQuestion from '@/components/interview-mobile/InterviewBoxQuestion.vue';
import InterviewBoxTab from '@/components/interview-mobile/InterviewBoxTab.vue';
import Recorder from '@/components/video/BetaStartRecord.vue';
import TwilioRecorder from '@/components/video/TwilioStartRecord.vue';
import EntrevistaService from '@/core/Services/Candidato/Entrevista';
import RoomService from '@/core/Services/Video/Room';
import { CandidaturaEntity, VagaPerguntaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import { LocalRecorder, LocalRecorderState } from 'openvidu-browser';

import vueAwesomeCountdown from 'vue-awesome-countdown';
Vue.use(vueAwesomeCountdown, "countdown");

@Component({
  components: {
    InterviewBoxTab,
    InterviewBoxContent,
    Recorder,
    InterviewBoxQuestion,
    TwilioRecorder
  }
})
export default class InterviewBox extends Vue {
  private showPergunta: boolean = false;
  private entrevistaService: EntrevistaService = new EntrevistaService();
  public room: RoomService = new RoomService();
  private videoProgressTime: number = 0;

  @Prop()
  private candidatura!: CandidaturaEntity;

  @Prop()
  private introAnswered!: boolean;

  @Prop({ default: '' })
  public question!: string;

  get purpose(): number {
    return this.introAnswered
      ? EVideoPurpose.Interview
      : EVideoPurpose.TestOnly;
  }

  get currPergunta(): VagaPerguntaEntity {
    return this.perguntas[this.currIndex];
  }

  // Indice atual é sempre o indice da primeira pergunta não respondida.
  get currIndex(): number {
    return this.perguntas.findIndex((pergunta: VagaPerguntaEntity) => {
      return !pergunta.answered;
    });
  }

  get hasMorePerguntas(): boolean {
    return (
      -1 !==
      this.perguntas.findIndex(
        (pergunta: VagaPerguntaEntity, index: number) => {
          return !pergunta.answered && index > this.currIndex;
        }
      )
    );
  }

  get videoSupplier(): EVideoSupplier  {
    const vagaVideoSupplier = this.isSafari ? EVideoSupplier.Twilio : this.candidatura!.vaga.videoSupplier;

    return vagaVideoSupplier ? vagaVideoSupplier : EVideoSupplier.Openvidu;
  }

  get questionMaxTime() {
    return this.introAnswered ? this.currPergunta.tempo * 60 * 1000 : 0;
  }

  get startMessage(): string {
    return !this.introAnswered ? this.$t('Start test').toString() : this.$t('Start Recording').toString();
  }

  get perguntas(): Array<VagaPerguntaEntity> {
    return this.candidatura!.vaga.perguntas;
  }

  get currTabIndex() {
    return !this.introAnswered ? this.currIndex : this.currIndex + 1;
  }

  get tabs() {
    let arr: any[] = [];
    const totalLength: number = this.perguntas.length;
    const currentI = this.currIndex||0;

    if( totalLength <= 4 ) {
      arr = this.perguntas;
    } else {
      let i: number = 0;
      for(i = currentI;i<(currentI+4);i++){
        if(totalLength<=i)
          break;

        arr.push(this.perguntas[i]);
      }
    }
    /* console.log(arr) */
    const tabs: {pergunta: VagaPerguntaEntity|null}[] = arr.map(pergunta => {
      return {
        pergunta: pergunta
      };
    });

    tabs.unshift({pergunta: null});

    /* console.log(tabs) */
    return tabs;
  }

  get vaga() {
    return this.candidatura?.vaga;
  }

  get twilioRecorder() {
    return <TwilioRecorder> this.$refs.twilioRecorder;
  }
  get openviduRecorder() {
    return <Recorder> this.$refs.recorder;
  }

  get recorder() {
    // const index = this.currTabIndex;
    if (this.videoSupplier == EVideoSupplier.Twilio) {
      return this.twilioRecorder;
    }
    else {
      return this.openviduRecorder;
    }
  }

  public goToFirstQuestion(): void {
    // this.introAnswered = true;
    this.$emit('introAnswered');
    // this.logVolume = false;
    // this.resetVolume();
    this.changeQuestion();

    this.$nextTick(() => {
      this.recorder.testingPhase = false;
      this.recorder.startProcess();
    });
  }

  public nextQuestion(): void {
    // Atualizar esse valor automaticamente atualiza a computed prop currIndex
    this.currPergunta.answered = true;
    this.changeQuestion();

    /* if (this.videoSupplier == EVideoSupplier.Twilio) {
      (<TwilioStartRecord> this.$refs.twilioRecorder).startProcess();
    }
    else {
      if (!(<Recorder> this.$refs.recorder).roomManager.publisher) {
        (<Recorder> this.$refs.recorder).startProcess();
      }
    } */
    this.recorder.startProcess();
  }

  public changeQuestion(): void {
    this.showPergunta = false;
    if (this.videoSupplier == EVideoSupplier.Twilio) {
      this.twilioRecorder.leaveRoom();
    }
    else {
      this.openviduRecorder.roomManager.removePublisher();
    }
  }

  /* public startedProcess(): void {
    this.videoStarted = true;
  } */

  public videoCounterProcess(time: number): void {
    this.videoProgressTime = time;
  }

  public startCountDownStarted() {
    this.showPergunta = true;
  }

  public async recordEnded(data: object, roomData: any): Promise<void> {
    this.videoProgressTime = 0;
    /* console.log("save interview") */
    if(data instanceof LocalRecorder) {
      //is local Recorder
      if(data.state == LocalRecorderState.FINISHED) {
        console.log('==init.saveFile')
        this.entrevistaService
        .saveInterviewFile(data.getBlob(), this.currPergunta.id)
        .then(async () => {
          await this.successSavedInterview(roomData, true);
        }).catch(async (err) => {
          console.error(err)
          await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');  
          location.href = location.protocol + "//" + location.host + location.pathname + '?lang='+this.$i18n.locale+"&fs=1";
        })
      } else {
        await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
        location.reload()
      }
    } else {
      const supplier: any = (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio || this.isSafari) ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
      this.entrevistaService
      .saveInterview(supplier, this.currPergunta.id, data)
      .then(async () => {
        await this.successSavedInterview(roomData, false);
      }).catch(async (err) => {
        console.error(err)
        await this.$alert.pleaseTryLater('Your Video cannot be uploaded.');  
        location.reload()
      });
    }
  }

  public async successSavedInterview(roomData: any, localRecorder: boolean) {
    console.log('/==successSavedInterview==/')
    const supplier: any = (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio || this.isSafari) ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
    if (this.hasMorePerguntas) {
      this.nextQuestion();
      
      if(supplier === EVideoSupplier.Openvidu && !localRecorder) {
        try {
          await this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
        } catch(Exception) {
          console.log('Falha ao Fechar a Sala OV')
        }
      }

        
    } else {
      
      if((<Recorder>this.$refs.recorder))
        (<Recorder>this.$refs.recorder).removeListeners();

      if(supplier === EVideoSupplier.Openvidu && !localRecorder) {
        try {
          await this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
        } catch(Exception) {
          console.log('Falha ao Fechar a Sala OV')
        }
      }

      if (this.candidatura?.urlCallback && this.candidatura.urlCallback?.indexOf('http') > -1) {         
        this.showCountDownUrlCallback()
      } else if(this.$route.name == "EntrevistaNoSidebar") {
        this.candidatura!.urlCallback = (this.candidatura?.urlCallback||"https://jobecam.com/candidato");
        this.showCountDownUrlCallback()
      } else {
        //TODO call vaga end 
        this.$alert.addMsg(this.$t("Please note that it is important to keep your profile up to date. Good luck!."));
        let obj: any = {
          b1Title: this.$t("Find more jobs").toString(),
          b2Title: this.$t("Update my profile").toString(),
          b1CallBack: () => { 
            this.$router.push({ path: '/vagas', query: { lang: this.$i18n.locale }})
          },
          b2CallBack: () => {
            this.$router.push({ path: '/edit', query: { lang: this.$i18n.locale }})
          }
        };
        this.$alert.callBoxCheckWithTitleAndDoubleButtons(obj);
      }  
    }
  }

  public showCountDownUrlCallback() {
    this.$bvModal.show('modalurlCallbackAlert');
    setTimeout(()=> {
      (<any>this.$refs.urlCallbackCounter).startCountdown(true);
    },500)
  }

  public forwardToUrlCallback() {
    this.$bvModal.hide('modalurlCallbackAlert');
    if(this.candidatura?.urlCallback)
      location.href = this.candidatura?.urlCallback;
  }
  public stopCounterPropagation() {
    (<any>this.$refs.urlCallbackCounter).finishCountdown();
    //this.forwardToUrlCallback();
  }

  public forceEnded(roomData: any): void {
    if (this.videoSupplier === EVideoSupplier.Openvidu)
      this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
  }

  private isActive(tab, i) {
    return (!this.introAnswered && !tab.pergunta) || (this.introAnswered && tab.pergunta && tab.pergunta.id == this.currPergunta.id);
  }
  get isSafari() {
    return false; // (!!window['safari'] || /^((?!chrome|android).)*safari/i.test(navigator.userAgent));
  }
}
