
// ---------------------------------------
// import * as data from '../../static/config/endpoint.json';
// export const baseEndPoint: string = data.api;
// export const siteEndPoint: string = data.assets;

// local
// export const baseEndPoint: string = 'https://jobecamapi.lc.com';
// export const siteEndPoint: string = 'https://jobecam.pix';

// production
// export const baseEndPoint: string = 'https://api.jobecam.com';
// export const siteEndPoint: string = 'https://app.jobecam.com';
//staging
// export const baseEndPoint: string = 'https://st-api.jobecam.com';


// homologation
// export const baseEndPoint: string = 'https://jobecamapi.tk';
// export const siteEndPoint: string = 'https://front.jobecam.tk';

export const baseEndPoint: string = process.env.VUE_APP_BASE_END_POINT!;
export const siteEndPoint: string = process.env.VUE_APP_SITE_END_POINT!;



// second platform url
// export const siteCandidateEndPoint: string =
//   'https://jobecam-candidate-hom.firebaseapp.com/';
export const siteLandingEndPoint: string = process.env.VUE_APP_SITE_LANDING_END_POINT!;


// ---------------------------------------
export const clientId: string = process.env.VUE_APP_CID!;

// dados /ações do Candidato
// envio de video