










































import Alert from '@/components/alerts/Alert.vue';
import AvatarUpload from '@/components/avatar-upload/AvatarUpload.vue';
import TextInputAppend from '@/components/form/TextInputAppend.vue';
import Sidebar from '@/components/layouts/Sidebar.vue';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { CandidatoEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

const namespace: string = 'profile';

@Component<SidebarEdit>({
  components: {
    'avatar-upload': AvatarUpload,
    'sidebar': Sidebar,
    'text-input-append': TextInputAppend
  },
})
export default class SidebarEdit extends Vue {
  public selected: any = {};
  public idiomasAPPList: Array<any> = [];
  public candidatoService: CandidatoService;
  public alert!: Alert;
  public currentFoto: string|null = null;
  public currentbackgroundAvatar: string|null = null;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.candidatoService = new CandidatoService();
  }
  get fotoAvatar(){
    return this.selected.fotoAvatar ? this.selected.fotoAvatar : undefined;
  }

  public async created() {
    await this.loadCandidatoInfo();
    if (this.candidato) {
      this.selected = this.candidato
      this.candidatoService.entidade = this.candidato;
      this.currentFoto = this.selected.fotoAvatar;
      this.currentbackgroundAvatar = this.selected.backgroundAvatar;
    }
    this.alert = new Alert();
  }

  public handleAvatarChange(file: any) {
    if(file) {
      this.candidatoService.saveAvatar(file)
      .then((data: any) => {
        this.alert.addMsg(this.$t('Saved successfully'))
        this.alert.callBoxCheck('OK');
        setTimeout(async () => { 
          if(this.currentFoto) {
            this.currentFoto = this.candidatoService.entidade.fotoAvatar + '&v='+ (new Date()).getTime()
          } else {
            await this.fetchCandidatoInfo();
            if (this.candidato) {
              this.selected = this.candidato
              this.candidatoService.entidade = this.candidato;
              this.currentbackgroundAvatar = this.selected.backgroundAvatar;
            }
          }
        }, 2000);
      })
    }
  }

  public handleBackgroundImageChange(event: any) {
    // /* console.log(event.target.files) */
    const file:any|null = event.target.files[0];
    if(file) {
      this.candidatoService.saveBackgroundAvatar(file)
      .then((data: any) => {
        this.alert.addMsg(this.$t('Saved successfully').toString())
        this.alert.callBoxCheck('OK');
        setTimeout(async () => { 
          if(this.currentbackgroundAvatar) {
            this.currentbackgroundAvatar = this.candidatoService.entidade.backgroundAvatar + '&v='+ (new Date()).getTime()
          } else {
            await this.fetchCandidatoInfo();
            if (this.candidato) {
              this.selected = this.candidato
              this.candidatoService.entidade = this.candidato;
              this.currentFoto = this.selected.fotoAvatar;
            }
          }
        }, 2000);
      })
    }
  }

  public handleFBChange(e: any){
    const facebookUrlRegex = /^(https:\/\/)?(www\.)?facebook\.com/;

    const facebookLink = this.candidatoService!.entidade!.perfil!.linkFacebook;
    const urlIsValid = facebookLink === '' ?  true : facebookUrlRegex.test(facebookLink ?? '');

    if (!urlIsValid && e.type === 'blur') {
      this.$toast.variant = 'danger';
      this.$toast.addTitle(this.$t('Attention').toString());
      this.$toast.addMsg(this.$t('Invalid Facebook link'));
      this.$toast.open();
    }

    if (!urlIsValid) return;

    this.candidatoService.saveProfileField('linkFacebook',this.candidatoService!.entidade!.perfil!.linkFacebook)
  }
  
  public handleLNChange(e: any){
    const linkedinUrlRegex = /^(https:\/\/)?(www\.)?linkedin\.com/;

    const linkedinLink = this.candidatoService!.entidade!.perfil!.linkLinkedin;
    const urlIsValid = linkedinLink === '' ?  true : linkedinUrlRegex.test(linkedinLink ?? '');

    if (!urlIsValid && e.type === 'blur') {
      this.$toast.variant = 'danger';
      this.$toast.addTitle(this.$t('Attention').toString());
      this.$toast.addMsg(this.$t('Invalid Linkedin link'));
      this.$toast.open();
    }

    if (!urlIsValid) return;

    this.candidatoService.saveProfileField('linkLinkedin',this.candidatoService!.entidade!.perfil!.linkLinkedin)
  }
  
  public handleINChange(e: any) {
    const instagramUrlRegex = /^(https:\/\/)?(www\.)?instagram\.com/;

    const instagramLink = this.candidatoService!.entidade!.perfil!.linkInstagram;
    const urlIsValid = instagramLink === '' ? true : instagramUrlRegex.test(instagramLink ?? '');

    if (!urlIsValid && e.type === 'blur') {
      this.$toast.variant = 'danger';
      this.$toast.addTitle(this.$t('Attention').toString());
      this.$toast.addMsg(this.$t('Invalid Instagram link'));
      this.$toast.open();
    }

    if (!urlIsValid) return;

    this.candidatoService.saveProfileField('linkInstagram',this.candidatoService!.entidade!.perfil!.linkInstagram)
  }
}
