






import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CandidatoEntity } from '@/core/models/Entities';
import CardProfessionalInfo from '@/components/cards/CardProfessionalInfo.vue';

@Component({
    components: {
        CardProfessionalInfo
    }
})
export default class DadosProfissionaisView extends Vue {
    @Prop({default: null})
    candidato!: CandidatoEntity;
}
