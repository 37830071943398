






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class SidebarBackgroundImage extends Vue {
  
  @Prop({default: null})
  public id!: string;

  @Prop({default: false})
  public value!: boolean;

  public listOfBackgrounds: string[] = [];
  public listOfCustomBackgrounds: string[] = [];
  
  public hidden(){
    this.$emit('input',false)
    this.$emit('hidden')
  }

  public shown(){
    this.$emit('shown')
  }

  public mounted() { 
    let bcks: string[] = []
    for(let i = 1; i < 29; i++ ) {
      bcks.push(`https://jobecam-assets.s3.us-west-1.amazonaws.com/v2/blind/poster${i}.png`)
    }
    let custom: string|null = localStorage.getItem('JB_ROOM_CUSTOM_BCKS')
    if(custom) {
      try {
        this.listOfCustomBackgrounds = JSON.parse(atob(custom));
      }catch(e) {
        // some error
        this.listOfCustomBackgrounds = [];
      }
    }
    this.listOfBackgrounds = this.listOfCustomBackgrounds.concat(bcks);
  }

  public select(url) {
    this.$emit('selected',url)
  }
  public blur(n: number|null) {
    this.$emit('blur',n)
  }
  public clear() {
    this.$emit('blur',3)
    this.$emit('selected',null)
  }

  getImageData() {
    console.log('aqui')
    const chooseFile: HTMLInputElement|null = document.querySelector("#choose-file");
    if(chooseFile && chooseFile.files !== null) {
      const files = chooseFile.files[0];
      if (files) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files);
        fileReader.addEventListener("load", (event: ProgressEvent<FileReader>) => {
          if(event.target?.result) {
            this.listOfCustomBackgrounds.push(""+event.target?.result)
            this.listOfBackgrounds.unshift(""+event.target?.result)
            this.$emit('selected', event.target?.result)
            try {
              localStorage.setItem('JB_ROOM_CUSTOM_BCKS',btoa(JSON.stringify(this.listOfCustomBackgrounds)))
            } catch(e) {
              console.error('Cannot save the selected image')
            }
          }
        });    
      }
      }
    }
}
