














import { Vue, Component, Prop } from 'vue-property-decorator';
import FBService from '@/core/Services/ThirdParty/Facebook';
import loadFacebookSdk from '@/core/loaders/loadFacebookSdk';
import { clientId } from "@/core/endpoint";

@Component
export default class FacebookRegister extends Vue {
  private FB: fb.FacebookStatic|null = null;
  private fbService: FBService = new FBService();
  private facebookLoginStatus?: fb.StatusResponse;
  private btnPromise: Promise<any>|null = null;

  public async created() {
    
    this.FB = await loadFacebookSdk();

    this.FB.getLoginStatus(async response => {
      this.facebookLoginStatus = response;
    });
  }

  public async loginWithFacebook() {
    if (!this.facebookLoginStatus) {
      return;
    }

    this.btnPromise = new Promise(async resolve => {
      try {
        this.doLoginWithFacebook(resolve);
      }
      catch (e) {
        console.error(e);
        resolve(true);
      }
    });
  }

  // Esse método só existe para ficar mais fácil de envolver no try catch
  private doLoginWithFacebook(resolve: (value?: any) => void) {
    if(this.FB) {
      if (!this.facebookLoginStatus) {
        resolve();
        return;
      }

      if (this.facebookLoginStatus.status === 'connected') {
        // Logged into your app and Facebook.
        this.handleFacebookStatusConnected(resolve);
      }
      else {
        // not_authorized ou unkown
        // The person is logged into Facebook, but has not logged into your webpage.
        // OU
        // The person is not logged into Facebook, so you don't know if they have logged into your webpage. Or FB.logout() was called before, and therefore, it cannot connect to Facebook.
        this.FB.login(response => {
          if (response.status  === 'connected') {
            this.facebookLoginStatus = response;
            this.handleFacebookStatusConnected(resolve);
          }
          else {
            // Não deu permissão
            resolve();
          }
        }, {scope: 'public_profile,email'});
      }
    }
  }

  public async handleFacebookStatusConnected(resolve: (value?: any) => void) {
    let registered = true;
    let error = false;
    if(this.FB) {
      this.FB.api('/me', "post", {fields: 'first_name,last_name,email' }, async (user: any) => {
        
        if(user.error) {
          resolve();
          return;
        }
        const response: {
          accessToken: string,
          clientId: string,
          expires: {
            date: string,
            timezone_type: number,
            timezone: string
          },
          userId: number,
        } = await this.fbService.create({
          accessToken: this.facebookLoginStatus!.authResponse.accessToken,/* 'invalid-token',*/
          tp: 'fb',
          expiresAt: this.facebookLoginStatus!.authResponse.expiresIn + (new Date()).getTime(),
          clientId: clientId,
        })
        .catch(reason => {
          if (400 === reason.response.status) {
            registered = false;
          }
          else {
            error = true;
            /* console.log(reason) */
          }
        });

        if (error) {
          resolve();
          return;
        }

        if (!registered) {
          // Not registered, ir para tela de cadastro já preenchida com os dados do FB
          
            this.$emit('loggedInNotRegistered', user);
            resolve();
        }
        else {
          // Alredy registered
          this.$emit('loggedInRegistered', response.accessToken);
          resolve();
        }
        
      });
    }
  }
}
