
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import PublicRepository from '@/core/Services/PublicRepository';


export default class GestaoCandidato extends PublicRepository {
    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/gestao-candidato', method: 'POST', headers: {} },
            fetch: { url: '/v1/gestao-candidato/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/gestao-candidato', method: 'GET', headers: {} },
            update: { url: '/v1/gestao-candidato/{id}', method: 'PUT', headers: {} }}));
    }
}
