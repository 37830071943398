




























import InterviewQuestion from '@/components/interview/InterviewQuestion.vue';
import InterviewQuestionContent from '@/components/interview/InterviewQuestionContent.vue';
import { VagaPerguntaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    InterviewQuestion,
    InterviewQuestionContent
  }
})
export default class InterviewQuestionsList extends Vue {
  @Prop()
  public perguntas!: Array<VagaPerguntaEntity>;

  @Prop()
  public introAnswered!: boolean;

  @Prop()
  public currIndex!: number;

  @Prop()
  public showPergunta!: boolean;

  @Prop({ default: 0 })
  public videoProgressTime!: number;

  get perguntasToTabs() {
    let arr: any[] = []
    const totalLength: number = this.perguntas.length;
    const currentI = this.currIndex||0;

    if( totalLength <= 4 ) {
      arr = this.perguntas;
    } else {
      let i: number = 0;
      for(i = currentI;i<(currentI+4);i++){
        if(totalLength<=i)
          break;

        arr.push(this.perguntas[i]);
      }
    }

    return arr;
  }

}
