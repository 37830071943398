

































import Vue from "vue";
import Component from "vue-class-component";
import VagasFilter from "@/components/filter/VagasFilter.vue";
import CardJob from "@/components/cards/CardJob.vue";
import { VagaEntity } from "@/core/models/Entities";
import VagaService from "@/core/Services/Vaga/Vaga";
import VagasSearchMixin from './VagasSearchMixin';

@Component<Vagas>({
  components: {
    "vagas-filter": VagasFilter,
    "vagas-job": CardJob
  },
  mixins: [VagasSearchMixin]
})
export default class Vagas extends Vue {
  public service: VagaService =  new VagaService();
  public list: VagaEntity[] = [];
  public responseKey = 'vagas';
}
