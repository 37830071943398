



















import Vue from 'vue';
import Component from 'vue-class-component';
import CardBox from '@/components/CardBox.vue';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import { CandidaturaEntity } from '@/core/models/Entities';


@Component({
  components: {
    'card-box': CardBox,
  },
})
export default class CardJobEngaged extends Vue {
  public candidaturas: CandidaturaEntity[];
  public candidaturaService: CandidaturaService;

  constructor() {
      super();
      this.candidaturas = [];
      this.candidaturaService = new CandidaturaService();

  }

  public created() {
      this.candidaturaService.fetchAll()
      .then((data: any) => {
          this.candidaturas = data.candidaturas;
      })
      // tslint:disable-next-line:no-console
      .catch(console.error);
  }

  public gotoCandidaturas() {
    this.$router.push({path:'/vagas/candidaturas', query: {lang: this.$i18n.locale}})
  }

}
