
import { ActionTree } from 'vuex';
import Candidato from '@/core/Services/Candidato/Candidato';
import Empresa from '@/core/Services/Empresa/Empresa';
import { i18n } from '@/plugins/i18n';
import { RootState } from '@/store/types';
import { ProfileState } from '@/store/profile/types';
import { CandidatoEntity } from '@/core/models/Entities';

function removeItensFromStorage(): void {
  sessionStorage.removeItem('token_type');
  sessionStorage.removeItem('access_token');
  sessionStorage.removeItem('profile_type');
  sessionStorage.removeItem('profile');
}

function addItensOnStorage(t: string, tt: string, pt: string): void {
  sessionStorage.setItem('access_token', t);
  sessionStorage.setItem('token_type', tt);
  sessionStorage.setItem('profile_type', pt);
}

export const actions: ActionTree<ProfileState, RootState> = {
  authRequest({ commit, dispatch }, user: any): Promise<any> {
     return new Promise((resolve: any, reject: any) => {
      const cand: Candidato = new Candidato();
      cand.auth(user).then(async (data: any) => {
        if (data.access_token != null) {
          addItensOnStorage(data.access_token, data.token_type, 'candidate');
          commit('authSuccess', data); // to mutator
          await dispatch('userRequest'); // to action
        } else {
          commit('authError');
          removeItensFromStorage();
        }
        // here set the header of your ajax library to the token value.
        // example with axios
        resolve(data);
      })
      .catch((err) => {
        commit('authError', err);
        removeItensFromStorage();
        reject(err);
      });
    });
  },
  userRequest({ commit, dispatch }: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      new Candidato().me().then((response: any) => {
        sessionStorage.setItem('profile', btoa(JSON.stringify(response)));
        i18n.locale = response.lang;
        commit('userSucess', response);
        resolve(response);
      }).catch((reason) => {
        commit('authError');
        reject(reason);
      });
    });
  },
  authLogout({commit, dispatch}: any): Promise<any> {
    return new Promise((resolve: any, reject: any) => {
      try {
        removeItensFromStorage();
        resolve('deslogado');
      } catch (e) {
        reject(e);
      }
    });
  },
  setCandidatoData({commit}: any, data: object) {
    commit('setCandidatoData', data);
  },
  loadCandidatoInfo({state, dispatch}): Promise<CandidatoEntity> {
    if (state.candidato_info) {
      // Se o dado já existir retornar promise resolvida com valor
      return new Promise((resolve) => {
        resolve((state.candidato_info as CandidatoEntity));
      });
    }
    return dispatch('fetchCandidatoInfo');
  },
  fetchCandidatoInfo({commit}): Promise<CandidatoEntity> {
    return new Promise(async (resolve, reject) => {
      try {
        const candidatoService: Candidato = new Candidato();
        await candidatoService.loadMyInfo()        
        commit('setCandidatoInfo', candidatoService.entidade);
        resolve(candidatoService.entidade);
      } catch (e) {
        reject(e);
      }
    });
  },
};
