



























import Vue from 'vue';
import Component from 'vue-class-component';
import Logo from '@/components/logo/Logo.vue';
import { siteLandingEndPoint } from '@/core/endpoint';

@Component<NavBar>({
  components: {
    'logo-jobcam': Logo,
  },
})
export default class NavBar extends Vue {
  
  public landingURL: string = "";

  public mounted() {
    this.landingURL = siteLandingEndPoint;
  }
  
  get getSupportPage() {
    
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/cpp/create-ticket/`;
    return fc;

  }
  
  get goHelp() {
    const locale: string = this.$i18n.locale.indexOf('pt')>-1 ? 'pt_BR' : this.$i18n.locale;
    const fc: string = `https://ajuda.jobecam.com/${locale}/category/1`;
    return fc
    //this.$router.replace({ path: '/ajuda', query: {lang: this.$i18n.locale }});
  }
}
