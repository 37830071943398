








import { Action, Getter, State } from 'vuex-class'; 
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ImageModal extends Vue {
  @Prop({default: ''})
  public imgSrc!:string;

  @Prop({default: 'image-modal'})
  public mid!:string;
}
