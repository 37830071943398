














































































































import FormacaoService from "@/core/Services/CandidatoFormacao";
import { CandidatoEntity, FormacaoEntity } from "@/core/models/Entities";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';


@Component
export default class DadosAcademicosSimple extends Vue {
  @Prop({default: []})
  public formacoes!: FormacaoEntity[];

  @Prop({default: () => {new CandidatoEntity()}})
  public value!: CandidatoEntity;
  
  @Watch('formacoes')
  public handleChangeTipo(v: any){
    this.loadData()
  }
  public formSRV!: FormacaoService;
  public showLocalidadeText: boolean = true;
  public items: any[] = [];
  public fields: any[] = [];
  public disabled: boolean = false;
  public buttonLabel: any;
  public dataEntrada: Date|null = null;
  public dataSaida: Date|null = null;
  public editEnable: boolean = false;
  public showForm: boolean = false;
  public statusArr: any[] = [];
  public currentPromise: any|null = null;
  public currentDeletePromise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public options!: any;
  public typeList: any[] =[];

  constructor() {
    super();
    this.formSRV = new FormacaoService();
  }
  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }
  
  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

  public created() {
    this.buttonLabel = ""+this.$t('Add');
    
  }

  public mounted(){
    this.fields = [
      { key: "atividade", label: this.$t('Activity'), class: 'text-center'},
      { key: "status", label: this.$t('Status'), class: 'text-center' },
      { key: "tipo", label: this.$t('Type'), class: 'text-center' },
      { key: "opcoes", label: this.$t('Options'), class: 'text-center' }
    ];
    this.typeList = [
      {text: this.$t('Select'), value: ''},
      {text: this.$t('Technical'), value: this.$t('Technical')},
      {text: this.$t('Graduate'), value: this.$t('Graduate')},
      {text: this.$t('Postgraduate'), value: this.$t('Postgraduate')},
      {text: this.$t('Master degree'), value: this.$t('Master degree')},
      {text: this.$t('Doctorate degree'), value: this.$t('Doctorate degree')},
      {text: this.$t('Post Doctor'), value: this.$t('Post Doctor')},
    ]
    this.statusArr.push("")
    this.statusArr.push({text: this.$t("Studying").toString(), value: this.$t("Studying").toString()})
    this.statusArr.push({text: this.$t("Completed").toString(), value: this.$t("Completed").toString()})
    this.statusArr.push({text: this.$t("Incompleted").toString(), value: this.$t("Incompleted").toString()})
    this.loadData()
    this.showForm = (this.value.perfil.temFormacao === true || this.value.perfil.temFormacao === null) && this.items.length == 0
  }

  public reset() {
    this.$set(this.formSRV, 'entidade', Object.assign({},new FormacaoEntity()));
    this.buttonLabel = this.$t('Add');
    this.dataEntrada = null;
    this.dataSaida = null;
    this.editEnable = false;
    this.showForm = false;
  }

  public save(evt: any) {
    if (this.formSRV.entidade.atual_ == 1) {
      this.formSRV.entidade.dataSaida = ''
    }

    this.formSRV.entidade.atividade = this.formSRV.entidade.atividade?.trim()
    if(!this.formSRV.entidade.atividade) {
      this.$toast.addTitle(this.$t("Incomplete Registration").toString())
      this.$toast.addMsg(this.$t("Fill in this field"))
      this.$toast.addMsg(this.$t("Field of Study"))
      return;
    }
    
    evt.preventDefault();
    if(!this.disabled)
      this.currentPromise = this.formSRV.save().then((ret: boolean) => {
        if(ret) {
          let hasObject: boolean = false;
          const obj = {
                  nome: this.formSRV.entidade.nome,
                  atividade: this.formSRV.entidade.atividade,
                  dataEntrada: this.formSRV.entidade.dataEntrada,
                  dataSaida: this.formSRV.entidade.dataSaida,
                  localidade: this.formSRV.entidade.localidade,
                  opcoes: this.formSRV.entidade.id,
                  promise: null,
                };
          this.items.forEach((el: any,index: number) => {
            if(el.opcoes==this.formSRV.entidade.id){
              hasObject = true
              el = obj
            }
          })
          if (!hasObject) {
            this.items.push(obj);
            this.showForm = false;
            this.formacoes.push(this.formSRV.entidade)
          }

          if((this.$refs.tAcademic as any))
            (this.$refs.tAcademic as any).refresh();

          this.value.perfil.temFormacao = true;
          this.showAlert();
          this.reset();
          this.$emit('saved',true);
        }
      })
  }

  public saveField() {
    this.$emit('saveProfile',true);
    
    this.$emit('saved',(!this.value.perfil.temFormacao || this.items.length > 0));
  }
 
  public editEXP(id: any) {
    this.buttonLabel = this.$t('Save').toString();
    this.editEnable = true;
    this.showForm = true;
    this.getEntidadeFromList(id);
    
  }

  public getEntidadeFromList(id?: any) {
    if (!id) {
      return null;
    }
    this.formacoes.forEach(el => {
      if( el.id === id ){
        this.formSRV.entidade = el;
        if(el.dataEntrada) {
          const arrE: any[] = el.dataEntrada!.split('-');
          if(!isNaN(parseInt(arrE[0])) && !isNaN(parseInt(arrE[1])) ) {
            this.dataEntrada = new Date(parseInt(arrE[1]),parseInt(arrE[0])-1,1)
          }
          if (el.dataSaida) {
            const arrS: any[] = el.dataSaida!.split('-');
            if(!isNaN(parseInt(arrS[0])) && !isNaN(parseInt(arrS[1])) ) {
              this.dataSaida = new Date(parseInt(arrS[1]),parseInt(arrS[0])-1,1)
            }
          }
        }
        if(!el.status) {
          this.formSRV.entidade.status = el.atual_ == 1 ? this.$t("Studying").toString() : this.$t("Completed").toString();
        }
        return true;
      }
    })
  }
  
  public handleDateEntradaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.formSRV.entidade.dataEntrada = str.substring(str.length-7,str.length);
  }

  public handleDateSaidaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.formSRV.entidade.dataSaida = str.substring(str.length-7,str.length);
  }

  public removeEXP(id: any) {
     let tIndex: number = -1;
    let tElement: any = null;
     
    this.items.forEach((element: any,index: number) => {
      if(element.opcoes === id){
        tIndex = index;
        tElement = element;
        return true;
      }
    })

    if (tIndex > -1 && tElement != null) {
      this.$confirm.addTitle(this.$t('Attention').toString())
      this.$confirm.addMsg(this.$t('Do you want to remove this academic experience?') + ' ' + tElement.atividade)
      this.$confirm.callBox().then((dec: boolean) => {    
        if(dec) {
          this.items[tIndex].promise = this.formSRV.delete({id}).then(() => {
            this.items.splice(tIndex,1);
            
            if((this.$refs.tAcademic as any))
              (this.$refs.tAcademic as any).refresh();

            this.$emit("saved",this.items.length == 0)
          });
        }
      });
    }
  }

  public loadData(value?: any) {
    if (value) {
      this.formacoes = value;
    }

    this.items = [];
    this.formacoes.forEach((el: any) => {
      this.items.push({
        atividade: el.atividade,
        status: el.status||(el.atual_ == 1 ? this.$t("Studying") : this.$t("Completed")),
        tipo: el.tipo,
        opcoes: el.id
      });
    });
  }

  public periodText(total: any) {
    if(!total)
      return "";
      
    let text = ""
    if (total.y > 0) {
        text += this.$t("{n} year(s)", {n: total.y})
    }
    if (total.m > 0) {
      if(text) {
        text += ` ${this.$t('and')} `
      }
      text += this.$t("{n} month(s)", {n: total.m})
    }
    return text;
  }
}
