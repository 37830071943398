











import Vue from 'vue';
import Component from 'vue-class-component';
import DadosPessoaisView from '@/components/perfil/DadosPessoaisView.vue';
import DadosProfissionaisView from '@/components/perfil/DadosProfissionaisView.vue';
import DadosAcademicosView from '@/components/perfil/DadosAcademicosView.vue';
import CandidatoService from '@/core/Services/Candidato/Candidato';
import CardPdfSkills from '@/components/cards/CardPdfSkills.vue';
import { CandidatoEntity } from '@/core/models/Entities';
import Alert from '@/components/alerts/Alert.vue';


@Component<PerfilPublico>({
  components: {
    DadosPessoaisView,
    DadosProfissionaisView,
    DadosAcademicosView,
    CardPdfSkills
  }
})
export default class PerfilPublico extends Vue {
  
  public candidatoSRV!: CandidatoService;
  public alert!: Alert;
  public candidato?: CandidatoEntity = new CandidatoEntity();
  public error: boolean = false;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
  }

  public created() {
    this.loadProfile();  
  }

  public alertCheck() {
    this.error = true;
    this.alert = new Alert();
      this.alert.addTitle(this.$t('Oops').toString())
      this.alert.addMsg(this.$t('Jobecam Profile Not Found').toString())
      this.alert.callBoxError().finally(() => {
        this.$router.push({ path: '/login', query: {lang: this.$i18n.locale }});
      })
  }

  public async loadProfile() {
    let urlcode = this.$route.params.urlcode;
    this.candidatoSRV.loadFromNomeUrl(urlcode, async () => {
      if(this.candidatoSRV.entidade) {
        this.candidato = this.candidatoSRV.entidade;
        (this.$refs.habe as CardPdfSkills).loadData(this.candidatoSRV.entidade!.perfil!.habilidades);
      }
    }, async () => {
      /* console.log('exception on request') */
      if(urlcode && !this.error) { 
        this.error = true;
        await this.loadProfile()
      } else {
        this.alertCheck(); 
      }
    });
  }
}
