// tslint:disable: no-string-literal

interface IEndPointStructureHeaders {
  [key: string]: string;
  Authorization: string;
  'Content-Type': string;
  'Accept': string;
  'Accept-Language': string;
}


interface IEndPointStructureObject {
  url: string;
  method: string;
  headers: IEndPointStructureHeaders;
  grantType: string;
}

export default class ApiRouter {
  public create: IEndPointStructureObject;
  public fetch: IEndPointStructureObject ;
  public fetchAll: IEndPointStructureObject;
  public fetchByToken: IEndPointStructureObject ;
  public update: IEndPointStructureObject;
  public delete: IEndPointStructureObject;
  public me: IEndPointStructureObject;
  public meup: IEndPointStructureObject;
  public auth: IEndPointStructureObject;
  public logout: IEndPointStructureObject;
  public info: IEndPointStructureObject;
  // public endpoints = {};

  constructor(mounted: any) {
    
    const keys: string[] = Object.keys(mounted);
    const nonCustom: string[] = ['create','fetch','fetchAll','fetchByToken','update','delete','me','logout','auth','info', 'meup']

    for (let index = 0; index < keys.length; index++) {
      const element = keys[index];
      if(nonCustom.indexOf(element) == -1)
        this[element] = mounted[element]
    }
    this.create = mounted['create'];
    this.fetch = mounted['fetch'];
    this.fetchAll = mounted['fetchAll'];
    this.fetchByToken = mounted['fetchByToken'];
    this.update = mounted['update'];
    this.delete = mounted['delete'];
    this.me = mounted['me'];
    this.logout = mounted['logout'];
    this.auth = mounted['auth'];
    this.info = mounted['info'];
    this.meup = mounted['meup'];

  }

}
