





























































import Vue from 'vue';
import Component from 'vue-class-component';

import Alert from '@/components/alerts/Alert.vue';
import InterviewBox from '@/components/interview-mobile/InterviewBox.vue';
import JobTitle from '@/components/job/JobTitle.vue';
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
// import StartRecord from '@/components/video/StartRecord.vue';
// import VolumeTracker from '@/components/audio/VolumeTracker.vue';
// import InterviewQuestionsList from '@/components/interview/InterviewQuestionsList.vue';

import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import EntrevistaService from '@/core/Services/Candidato/Entrevista';
import { CandidaturaEntity } from '@/core/models/Entities';
import { OpenViduError } from 'openvidu-browser';

@Component({
  components: {
    JobTitle,
    // InterviewQuestionsList,
    InterviewBox,
    // StartRecord,
    // VolumeTracker,
    JobsHeaderNavigation
  }
})
export default class Entrevista extends Vue {
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private entrevistaService: EntrevistaService = new EntrevistaService();
  private candidatura: CandidaturaEntity | null = null;
  private beforeStartWarningAlert!: Alert;
  private finishedEntrevistaAlert!: Alert;
  private errorAlert!: Alert;
  private cameraTestAlert!: Alert;

  // A faixa de volume vária de -100 a 0s
  private volume: number = -100;

  private introAnswered = false;
  private videoStarted = false;
  private logVolume: boolean = true;


  public resetVolume(): void {
    this.volume = -100;
  }

  public async created() {
    
    const g: boolean = await this.checkIfCanMakeInterview();
    
    if(!g)
      return;

    this.cameraTestAlert = new Alert();
    this.cameraTestAlert.title = this.$t("Get ready for the interview!");
    this.cameraTestAlert.addUlMsg('mobile-interview-alert',[
      this.$t("Before we get started let's test your camera and microphone to make sure your recording goes well.") as string
    ]);

    this.cameraTestAlert.addElementsMsg([
      this.$createElement('div', {
        class: 'jb-phone-handheld'
      }),
    ]);

    await this.candidaturaService
      .fetch(+this.$route.params.id)
      .then((candidatura: CandidaturaEntity) => {
        this.candidatura = candidatura;

        if (!this.candidatura.canAnswer || this.candidatura.status == "REVISAOCURRICULAR") {
          this.$router.push({
            name: "Candidaturas",
            query: { lang: this.$i18n.locale }
          });
          return;
        }
        this.cameraTestAlert.callBoxWarn().then(() => {
          this.openFullscreen(document.documentElement);
        });
      })
      .catch(() => {
        // Erro
      });

    this.beforeStartWarningAlert = new Alert();
    this.beforeStartWarningAlert.title = this.$t("Atenção!");
    this.beforeStartWarningAlert.addMsg(
      this.$t(
        "Once the interview starts, you will not be able to pause or record again."
      )
    );
    this.beforeStartWarningAlert.addMsg(this.$t("Pay attention to the time you have to answer each question."));
    this.beforeStartWarningAlert.on("hide", () => {
      (this.$refs.interviewBox as InterviewBox).goToFirstQuestion();
      this.beforeStartWarningAlert.$destroy();
    });

    this.finishedEntrevistaAlert = new Alert();
    this.finishedEntrevistaAlert.title = this.$t("Congratulations");
    this.finishedEntrevistaAlert.addMsg(
      this.$t(
        "Your interview has been sent successfully. We wish you the best of luck."
      ).toString()
    );
    this.finishedEntrevistaAlert.on("hide", () => {
      this.$router.push({
        name: "Candidaturas",
        query: { lang: this.$i18n.locale }
      });
    });

    this.errorAlert = new Alert();
    this.errorAlert.title = this.$t("Error");
    
  }

  public checkIfCanMakeInterview(): Promise<boolean> {
    return new Promise((resolve: any,reject: any) => {
      this.entrevistaService
      .update({id: this.$route.params.id}).then((d) => {
        resolve(true)
      })
      .catch((err) => {
        this.beforeStartWarningAlert = new Alert();
        this.beforeStartWarningAlert.title = this.$t("Attention");
        this.beforeStartWarningAlert.addMsg(
          this.$t(
            "This position no longer accepts applications"
          )
        );
        this.beforeStartWarningAlert.callBoxWarn().finally(() => {
          this.$router.push({
            name: "Candidaturas",
            query: { lang: this.$i18n.locale }
          });
        })
        resolve(false);
      })
    });
     
  }

  public onVolumeChange(value: number) {
    this.volume = value;
  }

  public showWarningModal() {
    this.beforeStartWarningAlert.callBoxTime();
  }

  public startedProcess(): void {
    this.videoStarted = true;
  }

  public onIntroAnswered() {
    this.introAnswered = true;
    this.logVolume = false;
  }

  public recordEnded() {
    this.finishedEntrevistaAlert.callBoxCheck(this.$t('Ok') as string);
  }

  public handleOpenviduError(error: OpenViduError) {
    this.errorAlert.addMsg(this.$t(error.name));
    this.errorAlert.callBoxDanger();
  }

  public openFullscreen(elem: HTMLElement) {
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }
}
