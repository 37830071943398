



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckboxInput extends Vue {
    public scopeID: string;

    @Prop()
    public value!: any;

    @Prop({default: false})
    public required!: boolean;

    @Prop({default: ''})
    public label!: string;

    @Prop({default: ''})
    public input_size!: string;

    @Prop({default: () => { return false } })
    public accepted!: any;

    @Prop({default: () => { return false } })
    public switch_type!: any;

    @Prop({default: () => { return false } })
    public not_accepted!: any;

    @Prop({default: () => { return false } })
    public disabled!: any;

    constructor() {
        super();
        this.scopeID = (new Date()).getTime() + '';
    }

    public handleInput (v: any) {
        this.$emit('input', v);
    }
}
