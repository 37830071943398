





















import Vue from 'vue';
import Component from 'vue-class-component';
import { State, Action, Getter } from 'vuex-class';
import { ScriptAsyncLoad } from "@/core/util";
import PortugueseCookiesPrivacyPolicy from '@/views/privacy-policy/PortugueseCookiesPrivacyPolicy.vue';
import SpanishCookiesPrivacyPolicy from '@/views/privacy-policy/SpanishCookiesPrivacyPolicy.vue';
import EnglishCookiesPrivacyPolicy from '@/views/privacy-policy/EnglishCookiesPrivacyPolicy.vue';
import NavBar from "@/views/layouts/NavBar.vue";

@Component<CookiesPrivacyPolicy>({
	components: {
    'portuguese': PortugueseCookiesPrivacyPolicy,
    'spanish': SpanishCookiesPrivacyPolicy,
    'english': EnglishCookiesPrivacyPolicy,
    'nav-bar': NavBar,
	}
})
export default class CookiesPrivacyPolicy extends Vue {

}
