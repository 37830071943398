
const isMyScriptLoaded = (url: string) : boolean => 
{
  if (!url) url = "https://connect.facebook.net/en_US/sdk.js";
  var scripts = document.getElementsByTagName('script');
  for (var i = scripts.length; i--;) {
      if (scripts[i].src.indexOf(url) > -1) return true;
  }
  return false;
}

// Load Facebook SDK asynchronously
const loadFacebookSdk = (lang: string = 'en_US'): Promise<fb.FacebookStatic> => {
  const id = 'facebook-jssdk';
  const src = `https://connect.facebook.net/${lang}/sdk.js`;

  if (isMyScriptLoaded(src) && 'FB' in window ) {
    // Already loaded
    return Promise.resolve(FB);
  }
  
  return new Promise((resolve, reject) => {
      try {
        if( !isMyScriptLoaded(src)) {
          const script = document.createElement('script');
          script.id = id;
          script.src = src;

          script.addEventListener('error', e => {
            const src = ((e as Event).target as HTMLScriptElement).src;
            throw new URIError("The script " + src + " didn't load correctly.");
          });

          document.body.prepend(script);
        }
        window.fbAsyncInit = () => {
          FB.init({
            appId      : '718230435217694',
            cookie     : true,                     // Enable cookies to allow the server to access the session.
            status     : true,
            xfbml      : true,                     // Parse social plugins on this webpage.
            version    : 'v5.0'           // Use this Graph API version for this call.
          });

          resolve(FB);
        };
    } catch (e) {
      reject(e);
    }
  });
};

export default loadFacebookSdk;
