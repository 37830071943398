






import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class VolumeTracker extends Vue {
  @Prop({default: 100})
  private width!: number;

  @Prop({default: '#fff'})
  private color!: string;

  private get style(): string {
    return `width: ${this.width}%; background-color: ${this.color}`;
  }
}
