
















import Vue from 'vue';
import Component from 'vue-class-component';
import Header from '@/components/layouts/Header.vue';
import SidebarEdit from '@/views/layouts/SidebarEdit.vue';
import SidebarMain from '@/views/layouts/SidebarMain.vue';
import Content from '@/components/layouts/Content.vue';

@Component<RootLoggedArea>({
  components: {
    'sidebar-edit': SidebarEdit,
    'sidebar-main': SidebarMain,
    'app-header': Header,
    'app-content': Content,
  },
})
export default class RootLoggedArea extends Vue {
  public sidebarIsOpen: Boolean = false;

  updated() {
    // /* console.log('update root') */
  }

  mounted() {
    // console.info('App this router:', this.$router)
    // console.info('App currentRoute:', this.$router.currentRoute)
  }

  public toggleSidebar() {
    this.sidebarIsOpen = !this.sidebarIsOpen
  }
}
