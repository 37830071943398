

































import Vue from "vue";
import Component from "vue-class-component";
import CardJob from "@/components/cards/CardJob.vue";
import { VagaEntity } from "@/core/models/Entities";
import VagasFavoritasService from "@/core/Services/Basic/VagasFavoritas";
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
import VagasSearchMixin from './VagasSearchMixin';

@Component({
  components: {
    CardJob,
    JobsHeaderNavigation
  },
  mixins: [VagasSearchMixin]
})
export default class VagasSalvas extends Vue {
  public service: VagasFavoritasService = new VagasFavoritasService();
  public list: Array<{vaga: VagaEntity}> = [];
  public showFilter: boolean = false;
  public notCandidated: boolean = true;
  public responseKey = 'vagasFavoritos';
}
