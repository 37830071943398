








import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
    props: {
        label: String,
        value: String,
        align: String,
    }
})
export default class TextLabelValue extends Vue {}
