









































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import ConvidarAmigosService from '@/core/Services/ConvidarAmigos';
import { InvitedFriend, VagaEntity } from '@/core/models/Entities';

import { Validate } from 'vuelidate-property-decorators';
import { required, email } from 'vuelidate/lib/validators';
import ClientAuthentication from '@/core/Services/ClientAuthentication';

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

@Component({
  components: {
    ButtonIcon,
  }
})
export default class InviteFriendsEmail extends Vue {
  private convidarAmigosService = new ConvidarAmigosService();

  @Prop({ default: false })
  private show!: boolean;

  @Prop()
  private vaga?: VagaEntity;

  @Validate({
    $each: {
      nome: { required },
      email: { required, email }
    }
  })
  private friends: InvitedFriend[] = [];

  private result: 'unsent'|'error'|'success' = 'unsent';
  private sendingPromise: null|Promise<void> = null;

  @Watch('show')
  onVisibilityChange(show: boolean) {
    if (!show) {
      // Modal fechando, resetar estado de envio
      this.result = 'unsent';
    }
  }

  public created() {
    this.addFriend();
  }

  private onChange(visibility: boolean) {
    if (!visibility) {
      this.$emit('close');
    }
  }

  public addFriend() {
    const friend = new InvitedFriend;
    friend.id = generateUniqueId();
    this.friends.push(friend);
  }

  public removeFriend(index: number) {
    this.friends.splice(index, 1);
  }

  public sendInvitations() {
    this.$v.$touch();

    if (this.$v.$invalid) {
      return;
    }

    this.sendingPromise = new Promise(async (resolve, reject) => {
      try {
        let token: string|null = null;
        try {
          token = await ClientAuthentication.fetchToken('candidate_login_invite_friend');
        } catch(e) {
          console.log(e)
        }

        await this.convidarAmigosService.save((token||""), this.friends, this.vaga ? this.vaga.id : undefined);
        this.result = 'success';

        this.friends = [];
        this.addFriend();

        this.$v.$reset();
        resolve();
      }
      catch(e) {
        this.result = 'error';
        reject();
      }
    });
  }
}
