import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { RoomEntity, RoomParticipantEntity } from '@/core/models/Entities';
import Repository from '@/core/Services/Repository';


export default class RoomParticipant extends Repository {
    public optionList: any[];
    public entidade!: RoomParticipantEntity;

    constructor() {
        super(new ApiRouter({
            create: { url: '/v1/room-participant', method: 'POST', headers: {} },
            delete: { url: '/v1/room-participant/{id}', method: 'DELETE', headers: {} },
            fetch: { url: '/v1/room-participant/{id}', method: 'GET', headers: {} },
            fetchAll: { url: '/v1/room-participant', method: 'GET', headers: {} },
            update: { url: '/v1/room-participant/{id}', method: 'PUT', headers: {} }}));
        this.optionList = [];
    }

    public async loadOptions() {
        const data: any = await this.fetchAll();
        if (data && data.rooms) {
            this.optionList = [];
            data.rooms.forEach((item: any) => {
                this.optionList!.push({
                    createdAt: item.createdAt,
                    key: item.key,
                    status: item.status,
                    text: item.key,
                    value: item.id,
                });
            });
        }
    }

    public createLiveInterview(supplier: number, sid?: string): Promise<any> {
        return super.create({
            purpose: EVideoPurpose.LiveInterview,
            sid,
            supplier,
        });
    }

    public createCurriculum(supplier: number, sid?: string, recordingMode?: string): Promise<any> {
        return super.create({
            purpose: EVideoPurpose.Curriculum,
            sid,
            supplier,
            recordingMode,
        });
    }

    public createInterview(supplier: number, sid?: string,recordingMode?: string): Promise<any> {
        return super.create({
            purpose: EVideoPurpose.Interview,
            sid,
            supplier,
            recordingMode,
        });
    }

    public findByKey(sid: string) {
        return super.fetchWithData({id: 0, key: sid}).then((data) => {
            if (data) {
                this.entidade = data;
            }
        });
    }

    public find(id: number) {
        return super.fetch(id).then((data) => {
            if (data) {
                this.entidade = data;
            }
            return data;
        });
    }

    public close(id: number) {
        return super.delete({id});
    }
    public closeInterViewRoom(id: number, pid: number) {
        return super.delete({id,pid});
    }
}


