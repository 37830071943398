
















import Logo from '@/components/logo/Logo.vue';
import { Component, Prop, Vue } from "vue-property-decorator";

@Component<Sidebar>({
  components: {
    'logo-jobecam': Logo,
  },
})
export default class Sidebar extends Vue {
  @Prop({ default: null }) public backgroundAvatar!: string;
  private imageSrc = Boolean(this.backgroundAvatar) && this.backgroundAvatar || null

  public gotoHome() {
    this.$router.replace({name:'PaginaInicial', query: {lang: this.$i18n.locale }});
  }
}
