import { render, staticRenderFns } from "./VagasSalvas.vue?vue&type=template&id=b3c37326&scoped=true"
import script from "./VagasSalvas.vue?vue&type=script&lang=ts"
export * from "./VagasSalvas.vue?vue&type=script&lang=ts"
import style0 from "./VagasSalvas.vue?vue&type=style&index=0&id=b3c37326&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3c37326",
  null
  
)

export default component.exports