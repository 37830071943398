




































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';
import CardVideoInstructions from '@/components/cards/CardVideoInstructions.vue';

import BetaStartRecord from '@/components/video/BetaStartRecord.vue';
import PerfilCandidatoService from '@/core/Services/Candidato/PerfilCandidato';
import VideoCurriculumService from '@/core/Services/Candidato/VideoCurriculum';
import Alert from '@/components/alerts/Alert.vue';
import { CandidatoEntity } from '@/core/models/Entities';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { LocalRecorder, LocalRecorderState } from 'openvidu-browser';

const namespace = 'profile';

@Component<VideoCurriculum>({
  components: {
    'card-instruction': CardVideoInstructions,
    'start-record': BetaStartRecord
  },
})
export default class VideoCurriculum extends Vue {
  public perfil: PerfilCandidatoService;
  public service: VideoCurriculumService;
  public alert: Alert = new Alert();
  public currentPromisse: any|null = null;
  public showTips: boolean = true;
  public introAnswered: boolean = false;
  public testStarted: boolean = false;
  public videoData: any = null;
  public ableToDeleteVideo = false;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.showTips = true;
    this.perfil = new PerfilCandidatoService();
    this.service = new VideoCurriculumService();
  }

  public initRecording() {
    (<BetaStartRecord> this.$refs.rec).prepareToRecord();
  }

  public instructionList: any[] = [] 

  public mounted() {
    this.ableToDeleteVideo = !!this.candidato?.perfil.videoapresentacao;

    this.instructionList = [
      {
        text: this.$t('You will have 30 seconds and can re-record as many times as you wish'),
        iconName: 'tempo',
        iconSize: '3x',
      },{
        text: this.$t('Prefer bright places and organized environments'),
        iconName: 'luz',
        iconSize: '3x',
        iconColor: 'primary'
      },{
        text: this.$t('And preferably no outside noises'),
        iconName: 'som',
        iconSize: '3x',
        iconColor: 'primary'
      },{
        text: this.$t('Keep calm and speak calmly'),
        iconName: 'calma',
        iconSize: '3x',
      },{
        text: this.$t('Say your name, field of expertise, skills and differentials'),
        iconName: 'fala',
        iconSize: '3x',
        iconColor: 'primary'
      },{
        text: this.$t('As soon as you`re ready, just get started'),
        iconName: 'claquete',
        iconSize: '3x',
        iconColor: 'primary'
      }
    ];
  }

  public recordEnded(data: any) {
    this.videoData = data;
  }

  public doChangeVideoCV(recordData: any) {
    if (recordData && recordData.url && this.candidato!.perfil!.id) {
      this.currentPromisse = this.perfil.update({
        id: this.candidato!.perfil!.id,
        recordObject: JSON.stringify(recordData, null, '\t'),
        recordId: recordData.id,
      }).then(() => {
        this.$router.go(-1)
      }).catch((err: any) => {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
      });
    } else if(recordData instanceof LocalRecorder) {
      //is local Recorder
      if(recordData.state == LocalRecorderState.FINISHED) {
        console.log('==init.saveFile')
        this.service.saveFile(recordData.getBlob()).then(() => {
          this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale }});
        }).catch(() => {
          this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
        })
      } else {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
      }
    } else {
        this.$alert.pleaseTryLater('Your Video cannot be uploaded.');
    }
  }
  public deleteCurrent() {
    
      this.$confirm.addTitle(this.$t('Attention').toString());
      this.$confirm.addMsg(this.$t('Are you sure about that?').toString());
      this.$confirm.callBoxSuccess().then((c) =>{
        if(c) {
          if(this.candidato) {
              this.currentPromisse = this.perfil.delete({
                id: this.candidato.id,
                vc: true
              }).then(() => {
                this.alert.addMsg(this.$t('Video curriculum deleted').toString());
                this.alert.callBoxCheckWithTitle(this.$t('Success').toString());

                this.ableToDeleteVideo = false;
              }).catch((err: any) => {
                this.alert.pleaseTryLater('Your Video cannot be uploaded.');
              });
            }
          }
      })

      
  }

  public saveThisOne() {
    (<BetaStartRecord> this.$refs.rec).changeVideo();
  }

}

