










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Tag extends Vue {
    @Prop({default: ''})
    public text!: any;

    @Prop({default: ''})
    public size!: string;

    @Prop({default: ''})
    public icon!: string;

    @Prop({default: 'info'})
    public variant!: string;

    constructor() {
        super();
    }
    public handleClick() {
        this.$emit('click')
    }
}
