
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from 'vue-property-decorator';
import Circle from 'vue-rate-it/glyphs/circle';

@Component
export default class CircleRate extends Vue {
  public circle: any = Circle;

  @Prop({default: 0})
  public rating!: number;

  @Prop({default: ""})
  public texts!: string;

  @Prop({default: 1})
  public incremental!: any;

  @Prop({default: 10})
  public maxRating!: any;

  @Prop({default: 20})
  public itemSize!: any;

  @Prop({default: 5})
  public spacing!: any;

  @Prop({default: '#00AF69'})
  public activeColor!: any;

  @Prop({default: false})
  public readOnly!: boolean;

  public emitRate(rate: number) {
    this.$emit("rateSelected", rate);
    this.$emit("input", rate);
  }
}
