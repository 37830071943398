
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class Estado extends Repository {
    public optionList?: any[];

    constructor() {
        super(new ApiRouter({
                create: { url: '/v1/estado', method: 'POST', headers: {} },
                fetch: { url: '/v1/estado/{id}', method: 'GET', headers: {} },
                fetchAll: { url: '/v1/estado', method: 'GET', headers: {} }, // tem parametro de Busca "s"
                update: { url: '/v1/estado/{id}', method: 'PUT', headers: {} } }));
        this.optionList = [];
    }

    public async loadOptions(pid?: any, SelectText?: any) {
        let data: any = null;
        if(!pid)
            pid = 0;
        const key: string = 'uf_'+pid;
        data = this.getLocalData(key);
        if(!data) {
            data = await this.fetchAll({pid});
            this.saveLocalData(key,data,null)
        }
        if (data && data.estados) {
            this.optionList = [
                {
                    text: SelectText?SelectText:'Selecione',
                    value: ''
                }
            ];
            data.estados.forEach((item: any) => {
                this.optionList!.push({
                    text: item.ufNome,
                    value: item.id,
                    abbrev: item.ufAbrev,
                });
            });
        }
    }

    public estadoByName(uf?: string) {
        let id = null;
        if (this.optionList !== undefined && this.optionList.length > 0) {
            this.optionList.forEach((item: any) => {
                if (item && item.abbrev && item.abbrev.indexOf(uf) > -1) {
                    id = item.value;
                }
            });
        }
        return id;
    }

}
