













































import Vue from 'vue';
import { Action, Getter } from 'vuex-class';
import Component from 'vue-class-component';
import Avatar from '@/components/avatar/Avatar.vue';

import Sidebar from '@/components/layouts/Sidebar.vue';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import { CandidatoEntity, VagaEntity, PaginaInstitucionalEntity } from '@/core/models/Entities';
import EmpresaService from '@/core/Services/Empresa/Empresa';
import VagaService from '@/core/Services/Vaga/Vaga';
import { EProfile } from '@/core/Enums/EProfile';
import PaginaEmpresa from '../../core/Services/Empresa/PaginaEmpresa';
import VueScrollTo from 'vue-scrollto';

const namespace: string = 'profile';

@Component<SidebarEmpty>({
  components: {
    'avatar': Avatar,
    'button-icon': ButtonIcon,  
    'sidebar': Sidebar
  },
})
export default class SidebarEmpty extends Vue {
  public selected: any|null = null;
  public currentbackgroundAvatar: string|null = null;
  public candidatoSRV: CandidatoService;
  public empresaService: EmpresaService;
  public vagaService: VagaService;
  public pageService: PaginaEmpresa;
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
  public pageType: EProfile = EProfile.Candidato;
  public pageList: PaginaInstitucionalEntity[] = [];
  public options!: any;
  public confidential: boolean = false;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  constructor() {
    super();
    this.candidatoSRV = new CandidatoService();
    this.empresaService = new EmpresaService();
    this.vagaService = new VagaService();
    this.pageService = new PaginaEmpresa();
    this.confidential = false;
    this.options = {
      container: "#page-content",
      easing: "ease-in",
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  get primeiroNome() {
    return  this.selected
      ? this.selected!.primeironome||this.selected!.nomeEmpresa
      : null;
  }

  get profissao() {
    return  this.selected && Boolean(this.selected.perfil)
      ? this.selected.perfil.profissao2||""
      : null;
  }

  get fotoAvatar() {
    return  this.selected
      ? this.selected!.fotoAvatar||this.selected!.logoEmpresa
      : undefined;
  }

  get nomeUrl() {
    return  this.selected && Boolean(this.selected!.nomeUrl)
      ? this.uri+'/c/'+this.selected!.nomeUrl
      : null;
  }

  get backgroundAvatar() {
    return  this.selected
      ? this.selected!.backgroundAvatar||this.selected!.background
      : undefined;
  }

  public async created() {
    /* console.log(this.$route.fullPath) */
    let urlcode = this.$route.params.urlcode;
    if (this.$route.fullPath.indexOf("/c/")>-1) {
      this.pageType = EProfile.Candidato;
      this.candidatoSRV.loadFromNomeUrl(urlcode, () => {
        this.selected = this.candidatoSRV.entidade
      }, () => {
        /* console.log('Error: Profile not found') */
      });
    } else if (this.$route.fullPath.indexOf("/e/")>-1) {
      this.pageType = EProfile.Empresa;
      this.empresaService.loadFromNomeUrl(urlcode, () => {
        this.selected = this.empresaService.entidade;
        this.fetchCompanyPages();
      })
    } else if (this.$route.fullPath.indexOf("/v/")>-1 || this.$route.fullPath.indexOf("/invite/")>-1) {
      this.pageType = EProfile.Vaga;
      let data: any = { id:0 };
      if(urlcode){
        data["urlCode"] = urlcode;
      }
      let vaga: VagaEntity = await this.vagaService.publicFetchWithData(data);
      if(vaga && vaga.empresa) {
        this.selected = vaga.empresa;
      } 
      if(vaga.confidencial == "SIM") {
        this.confidential = true;
      }
      this.fetchCompanyPages();
    }


    console.log(this.pageList.length);
  }

  public fetchCompanyPages() {
      if(this.pageType != EProfile.Candidato && this.selected && this.selected.nomeUrl) {
        this.pageService = new PaginaEmpresa();
        this.pageService.fetchAll({urlcode: this.selected.nomeUrl, compact: true}).then((data: any)=> {
            this.pageList = data;
        })
      }
  }

  public handleAvatarChange(value: any) {
    /* console.log(value) */
  }

  public handleBackgroundImageChange(event: any) {
    /* console.log(event) */
  }

  public openFacebook() {
    let facebook = this.selected!.perfil!.linkFacebook
    if(facebook && facebook.includes("https://")){
      window.open(facebook, "_blank");
    } else {
      window.open("https://" + facebook, "_blank")
    }
  }

  get haveFacebook() {
    return (this.selected && this.selected.perfil && this.selected.perfil.linkFacebook);
  }

  public openLinkedin() {
    let linkedin = this.selected!.perfil!.linkLinkedin
    if(linkedin && linkedin.includes("https://")) {
      window.open(linkedin, "_blank");
    } else {
      window.open("https://" + linkedin, "_blank")
    }
  }

  get haveLinkedIn() {
    return (this.selected && this.selected.perfil && this.selected.perfil.linkLinkedin);
  }

  public openInstagram() {
    let instagram = this.selected!.perfil!.linkInstagram
    if(instagram && instagram.includes("https://")) {
      window.open(instagram, "_blank");
    } else {
      window.open("https://" + instagram, "_blank")
    }
  }

  get haveInstagram() {
    return (this.selected && this.selected.perfil && this.selected.perfil.linkInstagram);
  }

  public openWindowCurriculo() {
    const uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
    window.open(uri+'/p-profile-pdf/'+this.selected.nomeUrl,"_blank");
  }

  
  public emitSidebar() {
    if(window.innerWidth <= 992){
      this.$emit('on-sidebar')
    }
  }

  public scrollToSection(page: PaginaInstitucionalEntity) {
    if (this.$route.name == 'CompanyPublicProfile') {
      VueScrollTo.scrollTo("#page_"+page.id, 500, this.options);
    } else {
      this.$router.push({name: 'CompanyPublicProfile', params: { urlcode: this.selected.nomeUrl+"" }, hash: "#page_"+page.id, query: { lang: this.$i18n.locale }});
      setTimeout(() => { VueScrollTo.scrollTo("#page_"+page.id, 500, this.options)},1500);
    }
  }

}
