import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';
import { InvitedFriend } from '@/core/models/Entities';

export default class ConvidarAmigos extends Repository {
  // public entidade: InvitedFriend;

  constructor() {
    super(new ApiRouter({
      create: { url: '/v1/convite-amigo', method: 'POST', headers: {} },
    }));
  }

  public async save(recaptchaToken: string, friends: InvitedFriend[], vagaId?: number): Promise<any> {
    return super.create({
        token: recaptchaToken,
        conviteAmigo: friends,
        vagaId: vagaId,
      });
  }
}
