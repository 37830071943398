







































































































import Vue from 'vue';
import Component from 'vue-class-component';

import InterviewQuestionsList from '@/components/interview/InterviewQuestionsList.vue';
import JobTitle from '@/components/job/JobTitle.vue';

import VolumeTracker from '@/components/audio/VolumeTracker.vue';
import StartRecord from '@/components/video/BetaStartRecord.vue';

import Alert from '@/components/alerts/Alert.vue';
import JobsHeaderNavigation from '@/components/perfil/header-navigation/JobsHeaderNavigation.vue';
import TwilioStartRecord from '@/components/video/TwilioStartRecord.vue';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import CandidaturaService from '@/core/Services/Candidato/Candidatura';
import EntrevistaService from '@/core/Services/Candidato/Entrevista';
import RoomService from '@/core/Services/Video/Room';
import { CandidaturaEntity, VagaPerguntaEntity } from '@/core/models/Entities';
import { OpenViduError } from 'openvidu-browser';

@Component({
  components: {
    JobTitle,
    InterviewQuestionsList,
    StartRecord,
    VolumeTracker,
    TwilioStartRecord,
    JobsHeaderNavigation
  }
})
export default class PublicEntrevista extends Vue {
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private entrevistaService: EntrevistaService = new EntrevistaService();
  private candidatura: CandidaturaEntity | null = null;
  private warningAlert!: Alert;
  private finishedEntrevistaAlert!: Alert;
  private errorAlert!: Alert;
  public room: RoomService;

  // A faixa de volume vária de -100 a 0s
  private volume: number = -100;

  private introAnswered = false;
  private videoStarted = false;
  private showPergunta: boolean = false;
  private logVolume: boolean = true;
  private videoProgressTime: number = 0;

  constructor() {
    super();
    this.room = new RoomService();
  }

  private get purpose(): number {
    return this.introAnswered
      ? EVideoPurpose.Interview
      : EVideoPurpose.TestOnly;
  }

  private get startMessage(): string {
    return !this.introAnswered ? this.$t('Start test').toString() : this.$t('Start recording').toString();
  }

  private get perguntas(): Array<VagaPerguntaEntity> {
    return this.candidatura!.vaga.perguntas;
  }

  private get currPergunta(): VagaPerguntaEntity {
    return this.perguntas[this.currIndex];
  }

  // Indice atual é sempre o indice da primeira pergunta não respondida.
  private get currIndex(): number {
    return this.perguntas.findIndex((pergunta: VagaPerguntaEntity) => {
      return !pergunta.answered;
    });
  }

  private get hasMorePerguntas(): boolean {
    return (
      -1 !==
      this.perguntas.findIndex(
        (pergunta: VagaPerguntaEntity, index: number) => {
          return !pergunta.answered && index > this.currIndex;
        }
      )
    );
  }

  private get questionMaxTime() {
    return this.introAnswered ? this.currPergunta.tempo * 60 * 1000 : 0;
  }

  public resetVolume(): void {
    this.volume = -100;
  }

  public created(): void {
    this.warningAlert = new Alert();
    this.warningAlert.title = this.$t("Attention");
    this.warningAlert.addMsg(
      this.$t(
        "Once the interview starts, you will not be able to pause or record again."
      )
    );
    this.warningAlert.addMsg(this.$t("Pay attention to the time you have to answer each question."));
    this.warningAlert.on("hide", () => {
      this.goToFirstQuestion();
      this.warningAlert.$destroy();
    });

    this.finishedEntrevistaAlert = new Alert();
    this.finishedEntrevistaAlert.title = this.$t("Congratulations");
    this.finishedEntrevistaAlert.addMsg(
      this.$t(
        "Your interview has been sent successfully. We wish you the best of luck.."
      ).toString()
    );

    this.errorAlert = new Alert();
    this.errorAlert.title = this.$t("Error");

    // this.checkIfCanMakeInterview();

    this.candidaturaService
      .fetch(+this.$route.params.cid)
      .then((candidatura: CandidaturaEntity) => {
        this.candidatura = candidatura;

        if (!this.candidatura.canAnswer) {
          this.$router.push({
            name: "Candidaturas",
            query: { lang: this.$i18n.locale }
          });
          return;
        } 

       
      })
      .catch(() => {
        // Erro
      });
  }

  public checkIfCanMakeInterview() {
    this.entrevistaService
    .update({id: this.$route.params.cid})
    .catch((err) => {
      this.warningAlert = new Alert();
      this.warningAlert.title = this.$t("Attention");
      this.warningAlert.addMsg(
        this.$t(
          "This position no longer accepts applications"
        )
      );
      this.warningAlert.callBoxWarn().finally(() => {
        this.$router.push({
          name: "Candidaturas",
          query: { lang: this.$i18n.locale }
        });
      })
    })
  }

  public onVolumeChange(value: number) {
    this.volume = value;
  }

  public showWarningModal() {
    this.warningAlert.callBoxTime();
  }

  public goToFirstQuestion(): void {
    this.introAnswered = true;
    this.logVolume = false;
    this.resetVolume();
    this.changeQuestion();

    this.$nextTick(() => {
      if (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio) {
        (<TwilioStartRecord>this.$refs.twilioRecorder).startProcess();
      } else {
        (<StartRecord>this.$refs.recorder).startProcess();
      }
    });
  }

  public nextQuestion(): void {
    // Atualizar esse valor automaticamente atualiza a computed prop currIndex
    this.currPergunta.answered = true;
    this.changeQuestion();
     if (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio) {
        (<TwilioStartRecord>this.$refs.twilioRecorder).startProcess();
      } else {
         if (!(<StartRecord>this.$refs.recorder).roomManager.publisher) {
            (<StartRecord>this.$refs.recorder).startProcess();
          }
      }


  }

  public changeQuestion(): void {
    this.showPergunta = false;
    if (this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio) {
      (<TwilioStartRecord>this.$refs.twilioRecorder).leaveRoom();
    } else {
      (<StartRecord>this.$refs.recorder).roomManager.removePublisher();
    }
  }

  public startedProcess(): void {
    this.videoStarted = true;
  }

  public videoCounterProcess(time: number): void {
    this.videoProgressTime = time;
  }

  public startCountDownStarted() {
    this.showPergunta = true;
  }

  public recordEnded(data: object, roomData: any): void {
    this.videoProgressTime = 0;
    /* console.log("save interview") */
    const supplier: any = this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
    this.entrevistaService
      .saveInterview(supplier, this.currPergunta.id, data)
      .then(() => {
        if (this.hasMorePerguntas) {
          this.nextQuestion();
        } else {
          //TODO call vaga end 
          this.finishedEntrevistaAlert.addMsg(this.$t("Please note that it is important to keep your profile up to date. Good luck!"));
          let obj: any = {
            b1Title: this.$t("Find more jobs").toString(),
            b2Title: this.$t("Update my profile").toString(),
            b1CallBack: () => { 
                this.$router.push({ path: '/vagas', query: {lang: this.$i18n.locale}})
            },
            b2CallBack: () => {
              // if(this.prompt)
                this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale}})
            }
          };
          this.finishedEntrevistaAlert.callBoxCheckWithTitleAndDoubleButtons(obj);
        }
        if(supplier === EVideoSupplier.Openvidu)
         this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
      });
  }
  public forceEnded(roomData: any): void {
    const supplier: any = this.candidatura!.vaga.videoSupplier == EVideoSupplier.Twilio ? EVideoSupplier.Twilio : EVideoSupplier.Openvidu;
    if(supplier === EVideoSupplier.Openvidu)
      this.room.closeInterViewRoom(roomData.id, this.currPergunta.id);
  }

  public handleOpenviduError(error: OpenViduError) {
    this.errorAlert.addMsg(this.$t(error.name));
    this.errorAlert.callBoxError();
  }
}
