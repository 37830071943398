


















































import Avatar from '@/components/avatar/Avatar.vue';
import ButtonIcon from '@/components/button-icon/ButtonIcon.vue';
import TextInputAppend from '@/components/form/TextInputAppend.vue';
import Sidebar from '@/components/layouts/Sidebar.vue';
import CandidatoService from "@/core/Services/Candidato/Candidato";
import { CandidatoEntity } from '@/core/models/Entities';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Action, Getter } from 'vuex-class';

const namespace: string = 'profile';

@Component<SidebarMain>({
  components: {
    'avatar': Avatar,
    'button-icon': ButtonIcon,
    'sidebar': Sidebar,
    'text-input-append': TextInputAppend
  },
})
export default class SidebarMain extends Vue {
  public idiomasAPPList: Array<any> = [];
  // public perfil: PerfilCandidatoEntity = new PerfilCandidatoEntity();
  // public myProfile: CandidatoEntity = new CandidatoEntity();
  public candidatoService: CandidatoService;
  public uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;

  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  constructor() {
    super();
    this.candidatoService = new CandidatoService();
  }

  public created() {
    if (!this.candidato) {
      this.loadCandidatoInfo();
    }
  }

  get primeiroNome() {
    return Boolean(this.candidato!.primeironome)
      ? this.candidato!.primeironome
      : null;
  }

  get nomeUrl() {
    return Boolean(this.candidato!.nomeUrl)
      ? this.uri+'/c/'+this.candidato!.nomeUrl
      : null;
  }

  get profissao() {
    return Boolean(this.candidato!.perfil)
      ? this.candidato!.perfil!.profissao2
      : null;
  }

  get fotoAvatar() {
    return Boolean(this.candidato!.fotoAvatar)
      ? this.candidato!.fotoAvatar
      : null;
  }


  get backgroundAvatar() {
    return Boolean(this.candidato!.backgroundAvatar)
      ? this.candidato!.backgroundAvatar
      : null;
  }

  public handleAvatarChange(value: any) {
    /* console.log(value) */
  }

  public handleBackgroundImageChange(event: any) {
    /* console.log(event) */
  }

  public openFacebook() {
    let facebook = this.candidato!.perfil!.linkFacebook
    if(facebook && facebook.includes("https://")){
      window.open(facebook, "_blank");
    } else {
      window.open("https://" + facebook, "_blank")
    }
  }

  get haveFacebook() {
    return (this.candidato!.perfil!.linkFacebook);
  }

  public openLinkedin() {
    let linkedin = this.candidato!.perfil!.linkLinkedin
    if(linkedin && linkedin.includes("https://")) {
      window.open(linkedin, "_blank");
    } else {
      window.open("https://" + linkedin, "_blank")
    }
  }
  
  get haveLinkedIn() {
    return (this.candidato!.perfil!.linkLinkedin);
  }

  public openInstagram() {
    let instagram = this.candidato!.perfil!.linkInstagram
    if(instagram && instagram.includes("https://")) {
      window.open(instagram, "_blank");
    } else {
      window.open("https://" + instagram, "_blank")
    }
  }
  
  get haveInstagram() {
    return (this.candidato!.perfil!.linkInstagram);
  }

  public openWindowCurriculo() {
    const uri: string = window.document!.location!.protocol + "//" + window.document!.location!.host;
    window.open(uri+'/profile-pdf',"_blank");
  }

  public openWindowCurriculoOculto() {
    let routeData = this.$router.resolve({name: 'HiddenTalentInfoPage', query: {lang: this.$i18n.locale}});
    window.open(routeData.href, '_blank');
  }

  public emitSidebar() {
    if(window.innerWidth <= 992){
      this.$emit('on-sidebar')
    }
  }
}
