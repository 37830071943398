import { EApplication } from '@/core/Enums/EApplication';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import PublicRoomService from '@/core/Services/Video/PublicRoom';
import RecordService from '@/core/Services/Video/Record';
import RoomService from "@/core/Services/Video/Room";
import RoomParticipantService from "@/core/Services/Video/RoomParticipant";
import VisionManager from '@/core/Services/WasmTasks/VisionManager';
import cities from '@/core/helper/cities';
import OpenViduApp from '@/core/recording/OpenViduApp';
import PlatformUtil from '@/core/recording/PlatformUtil';
import UserModel from '@/core/recording/UserModel';
import { Results as SGResults, SelfieSegmentation } from '@mediapipe/selfie_segmentation';
import { OpenViduErrorName } from 'openvidu-browser/lib/OpenViduInternal/Enums/OpenViduError';
import { Component, Vue } from 'vue-property-decorator';
import { OpenViduLayout } from './openvidu-layout';

@Component
export default class VideoRoomMixin extends Vue {

  /* #region  Attributes */
  public hasBeenUpdated: boolean = false;
  public room: RoomService;
  public publicRoom: PublicRoomService;
  public participant: RoomParticipantService;
  public record: RecordService;
  public layout = new OpenViduLayout();
  public sessionName: string | null = null;
  public userName: string = '';
  public userEmail: string = '';
  public fullscreen: boolean = false;
  public state: any = {
    mySessionId: '',
    myUserName: '',
    session: undefined,
    localUser: undefined,
    subscribers: [],
    chatDisplay: 'none',
    messageReceived: false
  };
  public ovManager: OpenViduApp;
  public openRoom: boolean = false;
  public isAuthenticatedUser: boolean = false;
  public isSessionClosed: boolean = false;
  public showNotification: boolean = false;
  public loadingPage: boolean = true;
  public loadingTest: boolean = true;
  public roomError: boolean = false;
  public messageList: any[] = [];
  public approved: boolean = false;
  public errorMessage: string = 'This room is closed!';
  public UID: number | null = null;
  public GUID: string | null = null;

  public sidebarManual1Visible: boolean = false;
  public testDone: boolean = false;

  public videoElement: HTMLVideoElement | null = null;
  public outputVideoElement: HTMLVideoElement | null = null;
  public canvasElement: HTMLCanvasElement | null = null;
  public canvasCtx: WebGL2RenderingContext | WebGLRenderingContext | CanvasRenderingContext2D | undefined | null = null;
  public startTime: number | undefined | null = 0;

  public visionManager: VisionManager | null = null;
  public selfieSegmentation: SelfieSegmentation | null = null;
  public fmEnabled: boolean = false;
  public sgEnabled: boolean = false;
  public backgrounImage: any = null;
  public useBackground: boolean = false;
  public useStrongBlur: boolean = false;
  public useLightBlur: boolean = false;
  public frameResultPID: any = null;
  public platform: PlatformUtil = PlatformUtil.getInstance();

  public sidebarBackground: boolean = false;
  public enableCC: boolean = false;
  public closedCaptionBuffer: string[] = [];
  public closedCaptionList: string[] = [];
  /* #endregion */

  constructor() {
    super();
    this.room = new RoomService();
    this.publicRoom = new PublicRoomService();
    this.participant = new RoomParticipantService();
    this.ovManager = new OpenViduApp(null);
    this.ovManager.purpose = EVideoPurpose.LiveInterview;
    this.record = new RecordService();
    

    // Add the Random Number!
    const n: string = '' + PlatformUtil.getRandomIntInclusive(0,11);
    this.ovManager.localUser.random = n;
  }

  /* #region  Watch */
  get user() {
    return this.ovManager.localUser;
  }

  get videoDevices() {
    return this.ovManager.videoDevices;
  }

  get audioDevices() {
    return this.ovManager.audioDevices;
  }

  get screenShareUser() {
    return this.ovManager.screenShareUser;
  }

  get screenSharePublisher() {
    return !!this.ovManager.screenSharePublisher;
  }

  get showShare() {
    return this.ovManager.screenShareUser && this.ovManager.screenShareUser.getStreamManager() != null && this.ovManager.screenShareUser.isScreenShareActive();
  }

  get someOneSharing() {
    return this.subscribers.some((user) => user.isScreenShareActive()) || this.user.isScreenShareActive();
  }

  get chatDisplay() {
    return this.state.chatDisplay;
  }

  get messageReceived() {
    return this.state.messageReceived;
  }

  get blindEnabled() {
    return this.ovManager.enableFilter;
  }

  get showModeratorOnly() {
    return this.blindEnabled && this.ovManager.appType == EApplication.Candidate
  }

  get recordingEnabled() {
    return this.ovManager.recordingEnabled;
  }

  get subscribers() {
    return this.ovManager.subscribersList;
  }

  get enableCanvasMediaStream() {
    return (this.ovManager.enableCustomBack || this.ovManager.enableFaceMesh)
  }
  /* #endregion */

  public camStatusChanged() {
    this.ovManager.camStatusChanged(!!this.blindEnabled);
  }

  public micStatusChanged() {
    this.ovManager.micStatusChanged();
  }

  public screenShare() {
    this.ovManager.screenShare();
  }

  public nicknameChanged(n: any) {
    this.ovManager.nicknameChanged(n);
  }

  public stopScreenShare() {
    /* console.log('Stopping Share') */
    this.ovManager.stopScreenShare();
    // this.ovManager.connectWebCam();
  }

  public unloadWarnings() {
    const _this: VideoRoomMixin = this;
    const unloadListener = (e: any) => {
      if (_this.ovManager) _this.ovManager.leaveRoom();
    };
    const beforeUnloadListener = (e: any) => {
      e.preventDefault();
      e.returnValue = this.$t(
        'Do you want to exit the recording page? you can lose your data'
      ).toString();
      return e.returnValue;
    };
    window.addEventListener('unload', unloadListener);
    window.onpopstate = (e: any) => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
      window.removeEventListener('unload', unloadListener);
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
  }

  public beforeDestroy() {
    if (!this.isSessionClosed) {
      this.ovManager.leaveRoom();
    }

    document.body.style.backgroundColor = "";
  }

  public createPublisherFromTest() {
    if (this.canvasElement && this.platform.canUseMediaPipe() && this.enableCanvasMediaStream) {
      this.ovManager.canvaMediaStream = this.canvasElement.captureStream(25).getVideoTracks()[0];
    }
  }

  public createOpenviduCall() {
    console.log('createOpenviduCall')
    this.loadingPage = true;
    this.ovManager.joinRoom().then(() => {
      console.log('reached to callback')
      this.joinCallback();

      setTimeout(() => {
        console.log('update Layout')
        this.layout.updateLayout();
      }, 5000);
      // (this.$refs.chatComponent as Chat).setLoadWatcher();
    }).catch(async (e: any) => {
      this.leaveSession();
      this.roomError = true;
      if (e.response?.data?.detail?.indexOf("Limite no uso da Sala de entrevista para seu plano") > -1) {
        console.log('Não foi possivel conectar por falta de limite de minutos no mês')
        return;
      } else if (e.response?.data?.type?.indexOf("custom_msg") > -1) {
        // alterar essa msg
        this.errorMessage = "It was not possible to connect the room";
        return;
      }
      this.errorMessage = "It was not possible to connect the room";
      /* console.log(e) */
      this.throwDeviceError(e)
      await this.openTestVideo()
    }).finally(() => {
      this.loadingPage = false;
    })
  }

  public applyGStreamerFilterAudio(c: any) {
    this.ovManager.applyAudioFilter(c);
  }

  public removeFilter(c: any) {
    this.ovManager.removeFilter();
  }

  public audioCustomFilter(pitch: string, rate: string, tempo: string) {
    this.ovManager.applyDynamicAudioFilter(pitch, rate, tempo);
  }

  public async startRecording() {
    await this.record.startRecording({ session: this.ovManager.sessionId, outputMode: "COMPOSED" })
      .then((_: any) => {
        this.ovManager.recordingEnabled = true;
        this.ovManager.sendRecordingChanced({ status: 'started' })
      })
  }

  public async stopRecording() {
    await this.record.stopRecording(
      EVideoSupplier.Openvidu,
      this.ovManager.sessionId + '',
      this.record.entidade.id
    )
    this.ovManager.sendRecordingChanced({ status: 'stopped' })
  }

  public applyFaceOverlayFilter(c: any) {
    this.ovManager.applyVideoFilter(c);
  }

  public leaveSession() {
    this.ovManager.leaveRoom();
    this.isSessionClosed = true;
    this.openRoom = false;

    // if (this.isAuthenticatedUser)
    //   this.closeRoom();
  }

  public async closeRoom() {
    if (!this.room) {
      this.room = new RoomService();
    }
    if (this.sessionName) {
      this.room = new RoomService();
      await this.room.findByKey(this.sessionName);
      this.room.close(this.room.entidade!.id);
      this.$router.push({ path: "/", query: { lang: this.$i18n.locale } });
    }
  }

  public returnNavigation() {
    this.$router.push({ path: "/", query: { lang: this.$i18n.locale } });
  }
  
  public addMediaToVideoElement() {
    if(this.ovManager.mediaStream != null && this.videoElement != null) {
      this.videoElement.srcObject = null;
      this.videoElement.muted = true;
      if ('srcObject' in this.videoElement) {
        this.videoElement.srcObject = this.ovManager.mediaStream;
      } else {
        (this.videoElement as any).src = URL.createObjectURL(this.ovManager.mediaStream as any);
      }
      this.videoElement.play();
    }
  }

  private isContextRestored: boolean = false;
  private handleCanvasContextLoss() {
    this.canvasElement?.addEventListener('webglcontextlost', (event) => {
      event.preventDefault();
      this.ovManager.localUser.setVideoActive(true);
      this.ovManager.camStatusChanged(false);
      this.isContextRestored = false;
      setTimeout(() => {
        if(!this.isContextRestored)
          this.errorSendingVideoCallback(); 
      },5000)
    });

    this.canvasElement?.addEventListener('webglcontextrestored', async () => {
      this.isContextRestored = true;
      this.errorSendingVideoCallback();
    });
  }

  public async openTestVideo() {
    const _this: any = this;
    this.openRoom = false;
    this.loadingTest = true;
    this.loadingPage = false;
    this.ovManager.sessionId = this.$route.params.sid;
    this.isSessionClosed = false;
    this.ovManager.mediaStream = null;
    this.ovManager.canvaMediaStream = null;
    this.videoElement = document.querySelector("#input_video");
    this.ovManager.localUser.nickname = this.userName;
    this.ovManager.userEmail = this.userEmail;
    this.ovManager.nickname = this.ovManager.localUser.nickname;
    

    if ((this.room.entidade && this.room.entidade.blind) == true || this.$route.name == "PublicSalaEntrevista") {
      this.ovManager.enableFilter = true;
      this.ovManager.localUser.nickname = cities[this.ovManager.localUser.random];
    }

    await this.ovManager.getToken(this.ovManager.sessionId);

    this.outputVideoElement = document.querySelector("#output_video");

    // TODO: Worker
    this.canvasElement = document.querySelector("#output_canvas");

    this.handleCanvasContextLoss();

    try {
      await this.ovManager.openCamera();
      if (this.ovManager.mediaStream != null && this.videoElement != null) {
        this.addMediaToVideoElement();
        
        if (this.enableCanvasMediaStream) {
          if (_this.isCandidateApp && this.blindEnabled && this.platform.canUseMediaPipe() && this.ovManager.enableFaceMesh) {
            try {
              this.backgrounImage = new Image();
              this.backgrounImage.crossOrigin = "anonymous";
              this.backgrounImage.alt = "Anonymous All Poster";
              this.backgrounImage.src = location.protocol + "//" + location.host + "/static/img/small/poster"+this.ovManager.localUser.random+".png";
              
              const model: string = location.protocol + "//" + location.host + "/static/models/avatar" + this.ovManager.localUser.random + ".glb";
              if (this.visionManager != null) {
                console.log("Start Dispose")
                this.visionManager.closeAll();
                this.canvasElement = document.querySelector("#output_canvas");
                await _this.sleepFor(1000);
              }
              
              this.visionManager = new VisionManager(<HTMLDivElement>this.canvasElement?.parentElement, this.videoElement, model)
              await this.visionManager.start()
              this.canvasCtx = this.visionManager?.getContext();
              this.fmEnabled = true;
              this.ovManager.localUser.fmEnabled = this.fmEnabled;
              this.ovManager.approveCandidateCallback = () => {
                console.log('approveCandidateCallback')
                  try {
                    this.visionManager?.closeAll();
                    this.canvasElement = document.querySelector("#output_canvas");
                    this.ovManager.enableFilter = false;
                    this.fmEnabled = false;
                    const { currentAudioDeviceId, currentVideoDeviceId } = this.ovManager;
                    this.ovManager.toggleCamera(currentVideoDeviceId || undefined, currentAudioDeviceId);
                    this.startCanvasAnimationFrame();
                  } catch (e) {
                    console.log(e)
                  }
                
              }
            } catch (e) {
              this.fmEnabled = false;
              this.canvasCtx = null;
              this.canvasElement = null;
              console.log(e)
            }
          } else {
            this.startCanvasAnimationFrame();
          }
        }

        // should end here
        return;
      }
      this.fmEnabled = false;
      throw Error("No Media Stream")
    } catch (e) {
      console.log('err ff')
      console.error(e)
      //TODO: create modal to reload page
    } finally {
      this.loadingTest = false;
    }
  }

  public async closeTestVideo() {
    this.ovManager.removePublisher();
    this.openRoom = true;
    this.testDone = true;

    if (sessionStorage.getItem('access_token')) {
      this.isAuthenticatedUser = true;
      this.loadVariables(this.UID, sessionStorage.getItem("profile_type") || undefined, this.GUID || undefined);
    } else {
      this.isAuthenticatedUser = false;
      if(this.$route.name == "PublicSalaEntrevista"){
        this.ovManager.connectionToken = null;
        await this.ovManager.getToken(this.ovManager.sessionId);
      }
      this.loadVariables(null, "candidate");
    }
    this.createPublisherFromTest();
    this.createOpenviduCall();
  }

  // chegando no load variables qqer pessoa pode acessar a sala
  public async loadVariables(userId?: any, userType?: string, userGuid?: string) {
    const _this: any = this;
    this.ovManager.appType = (_this.isCompanyApp) ? EApplication.Company : EApplication.Candidate;
    this.ovManager.sessionId = this.$route.params.sid;
    this.sessionName = this.ovManager.sessionId;
    this.ovManager.localUser.fmEnabled = this.fmEnabled;

    this.ovManager.localUser.userId = userId;
    this.ovManager.localUser.userGuid = userGuid||null;
    this.ovManager.localUser.userType = userType||null;

    this.initCallbacks()

    let x: any = document.getElementById('layout');

    this.layout.initLayoutContainer(x, this.ovManager.checkSomeoneShareScreen());

    const cont: HTMLDivElement | null = document.querySelector('#container');
    if (cont)
      cont.style.backgroundColor = ' rgb(33, 37, 41)';
  }

  public initCallbacks() {
    
    window.addEventListener("resize", (ev: UIEvent) => {
      this.updateLayout();
    })
    
    this.ovManager.leaveRoomCallback = () => {

      this.isSessionClosed = true;
      this.openRoom = false;

      document.body.style.backgroundColor = 'rgb(33,37,41)';
    };
    this.ovManager.streamPlayingCallback = () => {
      this.layout.setLayoutOptions(this.ovManager.checkSomeoneShareScreen());
      this.updateLayout();
    };
    this.ovManager.sessionDestroyedCallback = () => {
      /* console.log('Session Destroyed') */
      this.layout.setLayoutOptions(this.ovManager.checkSomeoneShareScreen());
      this.updateLayout();
    };
    this.ovManager.signalUserChangedCallback = () => {
      /* console.log('session signal UserChanged layout msut change') */
      this.layout.setLayoutOptions(this.ovManager.checkSomeoneShareScreen());
      this.updateLayout();
    };

    this.ovManager.receivedCCCallback = (a: any) => {
      console.log('receivedCCCallback', a)
      this.closedCaptionBuffer.push(a)
      this.closedCaptionList.push(a)

      const timelength: number = (a.length < 40) ? 2000 : ((a.length < 70) ? 3000 : 4000);
      setTimeout(() => {
        this.closedCaptionBuffer.shift()
      }, timelength)
    }

    this.ovManager.joinCallback = () => {
      /* console.log('session signal UserChanged layout msut change') */
      this.joinCallback()
    };

    this.ovManager.beforeReconnectCallback = this.beforeReconnectCallback

    this.ovManager.toggleCameraCallback = () => {
      this.addMediaToVideoElement();
      if(this.useBackground) {
        const backgrounImageSrc = this.backgrounImage.src;
        this.disableSelfieSegmentation();
        this.imageSelected(backgrounImageSrc);
      }
      this.createPublisherFromTest();
    }

    this.ovManager.errorSendingVideoCallback = this.errorSendingVideoCallback;
  }

  public async errorSendingVideoCallback() {
    const _this = this as any;

    const isBlind = _this.isCandidateApp && this.blindEnabled && !this.ovManager.blindApproved;

    const usingAvatar = (
      isBlind &&
      this.platform.canUseMediaPipe() &&
      this.ovManager.enableFaceMesh
    );

    if (usingAvatar) {
      await this.restartAvatarVideo();
      return;
    }
    
    if (!isBlind) {
      const { currentAudioDeviceId, currentVideoDeviceId } = this.ovManager;
      await this.ovManager.toggleCamera(currentVideoDeviceId || undefined, currentAudioDeviceId);
    }
  }

  private async restartAvatarCanvas() {
    this.visionManager?.closeAll();
    this.canvasElement = document.querySelector("#output_canvas");
    this.videoElement = document.querySelector("#input_video");
    this.outputVideoElement = document.querySelector("#output_video");

    if (!this.canvasElement || !this.videoElement || !this.visionManager) {
      return;
    }

    const canvasContainer = this.canvasElement.parentElement as HTMLDivElement;
    const avatar = this.visionManager?.avatar ?? null;

    this.visionManager?.closeAll();

    this.visionManager = new VisionManager(
      canvasContainer,
      this.videoElement,
    );

    this.visionManager.avatar = avatar;
    await this.visionManager?.start();

    this.canvasElement = document.querySelector("#output_canvas");
    this.canvasCtx = this.visionManager?.getContext();

    this.handleCanvasContextLoss();
  }

  private async restartAvatarVideo() {
    await this.restartAvatarCanvas();

    const { currentAudioDeviceId, currentVideoDeviceId } = this.ovManager;

    await this.ovManager.toggleCamera(currentVideoDeviceId || undefined, currentAudioDeviceId);
    await this.ovManager.applyAudioFilter(4);
  }

  public async beforeReconnectCallback() {
    const shouldBeBlind = this.ovManager.blindFilterEnabled && !this.ovManager.blindApproved;
    
    if (shouldBeBlind && this.canvasElement) {
      this.ovManager.canvaMediaStream = this.canvasElement.captureStream(25).getVideoTracks()[0];
    }
  }

public joinCallback() {
  this.ovManager.localUser.getStreamManager().stream.session.on('signal:chat', (event) => {
      const data = JSON.parse(event.data);
      /* console.log('chego aqui') */
      const lastMessage: any = { connectionId: event.from.connectionId, nickname: data.nickname, message: data.message };
      this.messageList.push(lastMessage);
      const document = window.document;
      setTimeout(() => {
        this.state.messageReceived = true;
        // todo: add toast -- somente sobe o toast se for a mensagem de outra pessoa talquei
        if (this.ovManager.localUser.connectionId !== lastMessage.connectionId) {
          this.$toast.variant = 'light';
          this.$toast.addMsg(lastMessage.message)
          this.$toast.addTitle(lastMessage.nickname);
          this.$toast.open();
        }
      }, 50);
      setTimeout(() => {
        this.state.messageReceived = false;
      }, 2000);
    });
    if (this.isAuthenticatedUser && this.room && this.room.entidade)
      this.participant.create({
        rid: this.room.entidade.id,
        key: this.user.getStreamManager().stream.streamId,
        connectionId: this.user.connectionId
      }).then((r: any) => {
        this.ovManager.sendSignalUserChanged({ pid: r.id })
      })

    if (this.ovManager.speech && !this.ovManager.speech.recognizing) {
      this.ovManager.speech.start(null);
    }
  }

  public isFullScreen() {
    return this.fullscreen;
  }

  public toggleSpeechRecognition() {
    this.enableCC = !this.enableCC;
    if (this.enableCC) {
      this.ovManager.sendSignalEnableCC();
    } else {
      this.ovManager.sendSignalDisableCC();
    }
  }

  public toggleFullscreen() {
    const fs = window.document.body;
    if (!this.isFullScreen()) {
      this.fullscreen = true;
      if (fs)
        this.openFullscreen(fs);
    } else {
      this.fullscreen = false;
      this.exitFullScreen();
    }
  }

  public exitFullScreen() {
    try {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      } else if (window.document.msExitFullscreen) {
        window.document.msExitFullscreen();
      } else if (window.document.mozCancelFullScreen) {
        window.document.mozCancelFullScreen();
      } else if (window.document.webkitExitFullscreen) {
        window.document.webkitExitFullscreen();
      }
    } catch (e) {
      /* console.log('cannot exit full screen') */
    }
    this.updateLayout();
  }

  public openFullscreen(elem: HTMLElement) {
    try {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) { /* Firefox */
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) { /* IE/Edge */
        elem.msRequestFullscreen();
      }
    } catch (e) {
      /* console.log('cannot go to full screen') */
    }
    this.updateLayout();
  }

  public updateLayout() {
    setTimeout(() => {
      this.layout.updateLayout();
    }, 1000);
  }

  public checkSize() {
    if (document.getElementById('layout')!.offsetWidth <= 700 && !this.hasBeenUpdated) {
      this.toggleChat('none');
      this.hasBeenUpdated = true;
    }
    if (document.getElementById('layout')!.offsetWidth > 700 && this.hasBeenUpdated) {
      this.hasBeenUpdated = false;
    }
  }
  public toggleChat(property: any) {
    let display = property == 'none' ? 'block' : 'none';
    this.showNotification = !this.showNotification;

    if (display == 'block') {
      this.setState({ chatDisplay: display, messageReceived: false });
    } else {
      console.log('chat', display);
      this.setState({ chatDisplay: display });
    }
    ///this.updateLayout();
  }

  public setState(obj: any) {
    const keys: string[] = Object.keys(obj);
    keys.forEach((key: string) => {
      this.$set(this.state, key, obj[key]);
    });
  }

  public checkNotification(event: any) {
    this.setState({
      messageReceived: this.state.chatDisplay === 'none',
    });
  }

  public showManual1Visible() {
    this.sidebarManual1Visible = true;
  }

  public showSubscriber(u: UserModel) {
    if (u.userType == 'candidate' && this.room && this.room.entidade && this.room.entidade.id) {
      this.participant.update({
        cid: u.userId,
        rid: this.room.entidade.id,
        connid: u.connectionId,
        show: true
      })
    }
    this.ovManager.sendSignalUserChanged({
      connectionToShowId: u.connectionId,
      a: 1
    })
  }

  public muteSubscriber(u: UserModel) {
    this.ovManager.sendSignalUserChanged({
      connectionToShowId: u.connectionId,
      a: 2
    })
  }

  public changeSubscriberAudoFilter(u: UserModel, n: number) {
    this.ovManager.sendSignalUserChanged({
      connectionToShowId: u.connectionId,
      a: 3,
      filter: n
    })
  }


  public throwDeviceError(e: any) {
    console.log('/==throwDeviceError==/');
    if (e && e.name == OpenViduErrorName.DEVICE_ACCESS_DENIED) {
      this.$alert.addMsg(this.$t('Jobecam needs to have access to the camera and microphone. Click the blocked camera icon in your browsers address bar and refresh the page').toString());
    } else if (e && e.name) {
      this.$alert.addMsg(this.$t('There was a problem trying to free your camera and microphone, check that they are connected correctly').toString());
    }
    this.$alert.addMsg(this.$t('Another program may be using your camera, please close it and refresh the page').toString());
    this.$alert.addTitle(this.$t('Your camera and microphone are blocked!').toString());
    this.$alert.callBoxError();
  }

  /* #region  Device Management */
  public toggleCamera(deviceId: string) {
    this.ovManager.toggleCamera(deviceId);
  }
  
  public toggleRandomNumber(random: number) {
    this.ovManager.localUser.random = random;
    this.openTestVideo()
  }

  public toggleTestCamera(deviceId: string) {
    this.ovManager.currentVideoDeviceId = deviceId;
    this.openTestVideo()
  }

  public toggleTestAudio(deviceId: string) {
    this.ovManager.currentAudioDeviceId = deviceId;
    this.openTestVideo()
  }

  public toggleDevices(videoDeviceId: any, audioDeviceId: any) {
    this.ovManager.toggleCamera(videoDeviceId, audioDeviceId);
  }
  /* #endregion */

  /* #region Background Management */

  public toggleBackgroundSidebar() {
    this.sidebarBackground = !this.sidebarBackground;
  }

  public imageSelected(imgURL: string | null) {
    if (imgURL != null) {
      this.useBackground = true;
      this.backgrounImage = new Image();
      this.backgrounImage.crossOrigin = "anonymous";
      this.backgrounImage.alt = "Anonymous All Poster";
      this.backgrounImage.src = imgURL;
      this.loadSelfieObject();
    } else {
      this.useBackground = false;
      this.backgrounImage = null;
      if (this.selfieSegmentation != null && !this.useStrongBlur && !this.useLightBlur) {
        this.disableSelfieSegmentation()
      }
    }
  }

  public blurSelected(inp: number) {
    console.log('blurSelected', inp)
    switch (inp) {
      case 1:
        this.useBackground = true;
        this.useStrongBlur = true;
        this.useLightBlur = false;
        this.loadSelfieObject();
        break;
      case 2:
        this.useBackground = true;
        this.useStrongBlur = false;
        this.useLightBlur = true;
        this.loadSelfieObject();
        break;
      default:
        this.useStrongBlur = false;
        this.useLightBlur = false;
        if (this.selfieSegmentation != null && this.backgrounImage == null) {
          this.disableSelfieSegmentation()
        }
    }

  }

  /* #endregion */

  /* #region MediaPipe functions */

  public disableSelfieSegmentation() {
    if (this.selfieSegmentation != null) {
      this.useBackground = false;
      this.selfieSegmentation.close().catch((e) => { console.log(e) });
      this.selfieSegmentation = null;
      this.sgEnabled = false;
      this.startCanvasAnimationFrame();
    }
  }

  public async loadSelfieSegmentation() {
    try {
      if (this.selfieSegmentation != null) {
        try {
          await this.selfieSegmentation.close()
        } catch (e) {
          //do nothing
        } finally {
          this.selfieSegmentation = null;
        }
      }
      this.selfieSegmentation = new SelfieSegmentation({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation/${file}`;
        }
      });
      this.selfieSegmentation.setOptions({
        modelSelection: 1,
      });
      this.selfieSegmentation.onResults(this.onSelfieSegResults);
      this.sgEnabled = true;
      setTimeout(this.startCanvasAnimationFrame, 1000);
    } catch (e) {
      this.sgEnabled = false;
      console.log(e)
    }
  }

  public onSelfieSegResults(results: SGResults): void | Promise<void> {
    if (!(this.canvasCtx instanceof CanvasRenderingContext2D))
      return;

    this.canvasCtx.clearRect(0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);

    // Only overwrite existing pixels.

    this.canvasCtx.globalCompositeOperation = 'source-out';
    this.canvasCtx.filter = 'none';
    if (this.useStrongBlur) {
      this.canvasCtx.filter = 'blur(5px)';
    } else if (this.useLightBlur) {
      this.canvasCtx.filter = 'blur(3px)';
    }
    if (this.useBackground && this.backgrounImage != null) {
      this.canvasCtx.drawImage(this.backgrounImage, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    } else {
      this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    }


    // Only overwrite missing pixels.
    this.canvasCtx.globalCompositeOperation = 'destination-atop';
    this.canvasCtx.filter = 'none';
    this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);

    this.canvasCtx.restore();

  }


  public onFrameResult() {
    if (!(this.canvasCtx instanceof CanvasRenderingContext2D) || !this.videoElement)
      return;

    this.canvasCtx.save();
    this.canvasCtx.clearRect(0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    if (this.blindEnabled && !this.ovManager.blindApproved) {
      this.canvasCtx.drawImage(this.backgrounImage, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    } else {
      this.canvasCtx.drawImage(this.videoElement, 0, 0, this.canvasElement?.width || 320, this.canvasElement?.height || 240);
    }

    this.canvasCtx.restore();
  }

  public startAnimationFrameErrorCounter: number = 0;
  public startAnimationFrame() {
    try {
      if (this.startAnimationFrameErrorCounter > 200) {
        if (this.videoElement && this.selfieSegmentation) {
          // this might help
          this.selfieSegmentation.close().catch((e: any) => { console.log(e) })
          this.selfieSegmentation = null;
          return;
        } 
      }

      if (this.platform.isMobileDevice()) {
        window.requestAnimationFrame(() => { this.stepFrame() })
      } else {
        setTimeout(() => { this.stepFrame() }, 25)
      }
    } catch (e) {
      this.startAnimationFrameErrorCounter++;
    }
  }
  public stepFrame() {
    if (this.videoElement?.paused || this.videoElement?.currentTime === this.startTime || (this.startTime = this.videoElement?.currentTime)) {
      if (this.videoElement && this.selfieSegmentation) {
        const b: Promise<void> | undefined = this.selfieSegmentation.send({ image: this.videoElement }).catch(() => {
          this.sgEnabled = false;
          // this.ovManager.canvaMediaStream = null;
          this.selfieSegmentation?.close().catch((e) => { console.log(e) });;
        });
        b ? b.then(() => { this.startAnimationFrame() }) : this.startAnimationFrame()
      }
    }
  }

  public async loadSelfieObject() {
    if (this.useBackground && this.platform.canUseMediaPipe() && this.selfieSegmentation == null) {
      console.log('loadSelfieSegmentation')
      await this.loadSelfieSegmentation()
      this.startAnimationFrame();
    }
  }
  public loopAnimation() {
    if (this.fmEnabled || this.sgEnabled) {
      if (this.frameResultPID != null) {
        clearTimeout(this.frameResultPID);
        this.frameResultPID = null;
      }
      return;
    }
    if (!this.videoElement?.paused && !this.videoElement?.ended) {
      if (this.platform.isMobileDevice()) {
        window.requestAnimationFrame(() => { this.onFrameResult() })
      } else {
        setTimeout(() => { this.onFrameResult() }, 25)
      }

      this.frameResultPID = setTimeout(this.loopAnimation, 30);
    }
  }
  public startCanvasAnimationFrame() {
    this.canvasCtx = this.canvasElement?.getContext('2d');
    
    if (this.blindEnabled) {
      this.backgrounImage = new Image();
      this.backgrounImage.crossOrigin = "anonymous";
      this.backgrounImage.alt = "Anonymous All Poster";
      this.backgrounImage.src = location.protocol + "//" + location.host + "/static/img/small/poster"+this.ovManager.localUser.random+".png";
    }
    if (this.videoElement) {
      this.videoElement.addEventListener('play', () => {
        console.log('play')
        this.loopAnimation();
      });
      this.videoElement.play();
      this.loopAnimation();
    }
  }

  /* #endregion */
}
