


































































































































































































import DateInput from '@/components/form/DateInput.vue';
import Section from '@/components/section/Section.vue';
import ExperienciaService from '@/core/Services/CandidatoExperiencia';
import { CandidatoEntity, ExperienciaEntity } from '@/core/models/Entities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import VueScrollTo from 'vue-scrollto';
import Confirm from '../alerts/Confirm.vue';

@Component<DadosProfissionaisEdit>({
  components: {
    "section-card": Section,
    DateInput,
  }
})
export default class DadosProfissionaisEdit extends Vue {
  @Prop({default: []})
  public experiencias!: ExperienciaEntity[];
  
  @Prop({default: () => {new CandidatoEntity()}})
  public value!: CandidatoEntity;
  
  public handleChangeTipo(v: any){
    this.loadData()
  }

  public expSRV!: ExperienciaService;
  public showLocalidadeText: boolean = true;
  public items: any[] = [];
  public fields: any[] = [];
  public segmentoArr: any[] = [];
  public disabled: boolean = false;
  public buttonLabel: string = "";
  public dataEntrada: Date|null = null;
  public dataSaida: Date|null = null;
  public editEnable: boolean = false;
  public currentPromise: any|null = null;
  public currentDeletePromise: any|null = null;
  public dismissCountDown: number|null = 0;
  public dismissSecs: number|null = 5;
  public alertConfirm: Confirm;
  public options!: any;

  constructor() {
    super();
    this.expSRV = new ExperienciaService();
    this.alertConfirm = new Confirm();
    this.options = {
      container: "#page-content",
      easing: "ease-in",
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  public countDownChanged(dismissCountDown: any) {
    this.dismissCountDown = dismissCountDown
  }
  
  public showAlert() {
    this.dismissCountDown = this.dismissSecs
  }

  public created() {
    // this.fields = [
    //   { key: "nome" },
    //   { key: "atividade" },
    //   { key: "dataEntrada" },
    //   { key: "dataSaida" },
    //   { key: "localidade" },
    //   { key: "opcoes" }
    // ];
  }

  public mounted(){
    this.buttonLabel = ""+this.$t('Save');
    this.fields = [
      { key: "nome", label: this.$t('Name') },
      { key: "atividade", label: this.$t('Position')},
      { key: "dataEntrada", label: this.$t('Start Date') },
      { key: "dataSaida", label: this.$t('End Date') },
      { key: "localidade", label: this.$t('Location') },
      { key: "opcoes", label: this.$t('Options') }
    ];
    let i = 1;
    this.segmentoArr.push("");
    for(i;i<=149; i++) {
      if (i!=2)
        this.segmentoArr.push(this.$t("setorIndustrial." + i).toString())
    }
    this.handleChangeTipo(null);
    this.loadData()
  }

  public reset() {
    this.expSRV.entidade = new ExperienciaEntity();
    this.expSRV.entidade.segmento = "";
    this.buttonLabel = ""+this.$t('Save');
    this.dataEntrada = null;
    this.dataSaida = null;
    this.editEnable = false;
  }

  public editEXP(id: any) {
    this.buttonLabel = ""+this.$t('Edit');
    this.editEnable = true;
    this.getEntidadeFromList(id);
    VueScrollTo.scrollTo("#dppe", 500, this.options);
  }

  public getEntidadeFromList(id?: any) {
    if (!id) {
      return null;
    }
    this.experiencias.forEach(el => {
      if( el.id === id ){
        this.expSRV.entidade = el;
        const arrE: any[] = el.dataEntrada!.split('-');
        if(!isNaN(parseInt(arrE[0])) && !isNaN(parseInt(arrE[1])) ) {
          this.dataEntrada = new Date(parseInt(arrE[1]),parseInt(arrE[0])-1,1)
        }
        if (el.dataSaida) {
          const arrS: any[] = el.dataSaida!.split('-');
          if(!isNaN(parseInt(arrS[0])) && !isNaN(parseInt(arrS[1])) ) {
            this.dataSaida = new Date(parseInt(arrS[1]),parseInt(arrS[0])-1,1)
          }
        }
        return true;
      }
    })
  }
  
  public save(evt: any) {
    if (this.expSRV.entidade.atual_ == 1) {
      this.expSRV.entidade.dataSaida = ''
    }
    evt.preventDefault();
    if(!this.disabled)
      this.currentPromise = this.expSRV.save().then((ret: boolean) => {
        if(ret) {
          let hasObject: boolean = false;
          let index: number|null = null;
          const obj = {
                    nome: this.expSRV.entidade.nome,
                    atividade: this.expSRV.entidade.atividade,
                    dataEntrada: this.expSRV.entidade.dataEntrada,
                    dataSaida: this.expSRV.entidade.dataSaida,
                    localidade: this.expSRV.entidade.localidade,
                    opcoes: this.expSRV.entidade.id,
                    promise: null,
                  };
          this.items.forEach((el: any,index: number) => {
            if(el.opcoes==this.expSRV.entidade.id){
              hasObject = true
              el = obj
            }
          })
          if (!hasObject) {
            this.items.push(obj);
            this.experiencias.push(this.expSRV.entidade)
          }
          
          if((this.$refs.tProfissoes as any))
            (this.$refs.tProfissoes as any).refresh();

          this.value.perfil.temExperiencia = true;
          this.showAlert();
          this.reset();
          this.$emit('saved',true);
        }
      })
  }

  public saveField() {
    this.$emit('saved',true);
  }

  public removeEXP(id: any) {
    let tIndex: number = -1;
    let tElement: any = null;
    this.items.forEach((element: any,index: number) => {
      if(element.opcoes === id){
        tIndex = index;
        tElement = element;
        return true;
      }
    });
    
    if (tIndex > -1 && tElement != null) {
      this.alertConfirm.addTitle(this.$t('Attention').toString())
      this.alertConfirm.addMsg(this.$t('Do you want to remove this work experience?') + ' ' + tElement.atividade)
      this.alertConfirm.callBox().then((dec: boolean) => {    
        if(dec) {
          this.items[tIndex].promise = this.expSRV.delete({id}).then(() => {
            this.items.splice(tIndex,1);
            if((this.$refs.tProfissoes as any))
              (this.$refs.tProfissoes as any).refresh();
          });
        }
      });
    }
  }

  public handleDateEntradaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.expSRV.entidade.dataEntrada = str.substring(str.length-7,str.length);
  }

  public handleDateSaidaChange(value: any) {
    let str = '';
    if (!value)
      return null;

    /* console.log(value) */
    str = ('0' + (value.getMonth()+1) + '-' + value.getFullYear());
    this.expSRV.entidade.dataSaida = str.substring(str.length-7,str.length);
  }

  public loadData(value?: any) {
    if (value) {
      this.experiencias = value;
    }
    this.items = [];
    this.experiencias.forEach(el => {
      this.items.push({
        nome: el.nome,
        atividade: el.atividade,
        dataEntrada: el.dataEntrada,
        dataSaida: el.dataSaida,
        localidade: el.localidade,
        opcoes: el.id,
        promise: null,
      });
    });
  }
}
