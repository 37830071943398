






































































































































import Section from '@/components/section/Section.vue';
import VideoPlayer from '@/components/video/VideoPlayer.vue';
import { CandidatoEntity } from '@/core/models/Entities';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    VideoPlayer,
    "section-card": Section
  }
})
export default class CardPersonalInfo extends Vue {
  @Prop({ default: null })
  candidato!: CandidatoEntity;

  // showButtons is for to show or to hide video on the card
  @Prop({ default: true })
  public displayVideo!: boolean;

  @Prop({ default: false })
  public isPublic!: boolean;

  @Prop({ default: false })
  public ispdf!: boolean;

  public pcdArrayDescritivo: any[] = [];
  public pcdDetails: any = {};
  public fieldOfWork = '';
  public studyLevel = '';

  get videoApresentacaoOptions(): object | null {
    const url = this!.candidato!.perfil!.videoapresentacaoMP4;

    if (!url) {
      return null;
    }

    return {
      autoplay: false,
      controls: true,
      poster: this!.candidato!.perfil!.thumbVideo+'#'+(new Date()).getTime(),
      sources: [
        {
          src: url,
          type: "video/mp4"
        }
      ]
    };
  }
  get localidade() {
    let loc = `${this.candidato?.perfil?.localidade}`;
    if(this.candidato?.perfil?.nomeCidade)
      loc = `${this.candidato?.perfil?.nomeCidade}/${this.candidato?.perfil?.nomeEstado}`;

    return loc;
  }

  get endereco(): string {
    return this.$options.filters!.formatEndereco(this.candidato.perfil);
  }

  @Watch('candidato',{deep:true})
  public loadPCDObject() {
    try{ 
      const data:any = JSON.parse(this.candidato.perfil.pcdDescricao)
      if(data && Array.isArray(data.array))
        this.pcdArrayDescritivo = data.array;
      if(data && data.details)
        this.pcdDetails = data.details; 
    } catch(e) {
      /* console.log('error on parse pcd') */
    }
  }

  public mounted() {
    this.loadPCDObject()

    const fieldOfWorkId = this.candidato.perfil.areaatuacaoId;
    const studyLevelId = this.candidato.perfil.escolaridadeId;

    if (fieldOfWorkId) {
      this.fieldOfWork = this.$i18n.t('areaAtuacoes.' + fieldOfWorkId).toString();
    }

    if (studyLevelId) {
      this.studyLevel = this.$i18n.t('niveisEscolaridade.' + studyLevelId).toString();
    }
  }
}
