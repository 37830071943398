



























import Vue from 'vue';
import Component from 'vue-class-component';
import Section from '@/components/section/Section.vue';
import { VagaEntity } from '@/core/models/Entities';
import VagaService from "@/core/Services/Vaga/Vaga";
import CardJob from "@/components/cards/CardJob.vue";
import VagasSearchMixin from '../../vagas/VagasSearchMixin';

@Component<CompanyJobsList>({
  components: {
    CardJob
  },
  mixins: [VagasSearchMixin]
})
export default class CompanyJobsList extends Vue {
  public service: VagaService =  new VagaService();
  public list: VagaEntity[] = [];
  public responseKey = 'vagas';
  public companyId!: number;
  public companyUrl!: string;
  
  public created() {
    this.companyUrl = this.$route.params.urlcode;
    this.companyId = 0;
  }
}
