
export default class UserModel {
    public userId: number|null;
    // candidate company or recruiter
    public userType: string|null;
    public userGuid: string|null;
    public connectionId: string;
    public audioActive: boolean;
    public videoActive: boolean;
    public screenShareActive: boolean;
    public nickname: string;
    public random: any;
    public streamManager: any;
    public role: string;
    public appFrom: number | null;
    public type: string; // 'remote' || 'local'
    public blinded: boolean;
    public speaking: boolean;
    public audioVolume: any;
    public webRtcCommomStats: any;
    public statCounter: number;
    public errCounter: number;    
    public fmEnabled: boolean;
    public startSpeakTime: number = 0;
    public endSpeakTime: number = 0;
    public speakTimeHistory: number[] = [];
    public speakerSpace: number = 0;
  
    constructor() {
        this.userId = null;
        this.userType = null;
        this.connectionId = '';
        this.audioActive = true;
        this.videoActive = true;
        this.screenShareActive = false;
        this.nickname = '';
        this.streamManager = null;
        this.type = 'local';
        this.role = '';
        this.appFrom = null;
        this.blinded = false;
        this.speaking = false;
        this.userGuid = null;
        this.webRtcCommomStats = null;
        this.statCounter = 0;
        this.errCounter = 0;
        this.fmEnabled = false;
    }
  
    setSpeaking(v:boolean) {
      if(!this.isAudioActive()){
          this.speaking = false;
          return;
      }
      if(v) {
          if(!this.speaking)
              this.startSpeakTime = (new Date()).getTime();
  
          this.speaking = v;
          this.endSpeakTime = (new Date()).getTime();
      } else {
          const differenceInMillis = Math.abs((new Date()).getTime() - this.endSpeakTime);
          if (differenceInMillis >= 3000) {
              this.speaking = v;
              this.speakTimeHistory.push(this.endSpeakTime - this.startSpeakTime)
          }
      }
    }
  
    isAudioActive() {
        return this.audioActive;
    }
  
    isVideoActive() {
        return this.videoActive;
    }
  
    isScreenShareActive() {
        return this.screenShareActive;
    }
  
    showBigScreen() {
        return (!this.screenShareActive && this.appFrom == 2);
    }
  
    getConnectionId() {
        return this.connectionId;
    }
  
    getNickname() {
        return this.nickname;
    }
  
    getRole() {
        return this.role;
    }
  
    getFrom() {
        return this.appFrom;
    }
  
    getStreamManager() {
        return this.streamManager;
    }
  
    isLocal() {
        return this.type === 'local';
    }
    isRemote() {
        return !this.isLocal();
    }
    setAudioActive(isAudioActive: any) {
        this.audioActive = isAudioActive;
    }
    setVideoActive(isVideoActive: any) {
        this.videoActive = isVideoActive;
    }
    setScreenShareActive(isScreenShareActive: any) {
        this.screenShareActive = isScreenShareActive;
    }
    setStreamManager(streamManager: any) {
        this.streamManager = streamManager;
    }
  
    setConnectionId(conecctionId: any) {
        this.connectionId = conecctionId;
    }
    setRole(role: string) {
        this.role = role;
    }
    setFrom(f: number) {
        this.appFrom = f;
    }
    setNickname(nickname: string) {
        this.nickname = nickname;
    }
    setType(type: string) {
        if (type === 'local' ||  type === 'remote') {
            this.type = type;
        }
    }
  }
  