









import { Component, Prop, Vue } from 'vue-property-decorator';
import CompaniesHeaderNavigation from '@/components/perfil/header-navigation/CompaniesHeaderNavigation.vue';
import CompanyMainInfo from '@/views/company/company-profile/CompanyMainInfo.vue';
import CompanyJobsList from '@/views/company/company-profile/CompanyJobsList.vue';
import CompanyPage from '@/views/company/company-profile/CompanyPage.vue';
// import CompanyLocationMap from '@/views/company/company-profile/CompanyLocationMap.vue';
import { EmpresaEntity, PaginaInstitucionalEntity } from "@/core/models/Entities";
import PaginaEmpresa from "@/core/Services/Empresa/PaginaEmpresa";

@Component<CompanyProfile>({
  components: {
    CompaniesHeaderNavigation,
    CompanyMainInfo,
    CompanyJobsList,
    // CompanyLocationMap,
    CompanyPage
  },

})
export default class CompanyProfile extends Vue {
  public service?: PaginaEmpresa;
  public loaded: boolean = true;
  public urlcode: string = "";
  public isPublic: boolean = false;
  public pageList: PaginaInstitucionalEntity[] = []
  // TODO insert clean Session on every public call from routes
  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }
  public created() {
    this.urlcode = this.$route.params.urlcode;
    if (this.$route.name == 'CompanyPublicProfile') {
      this.isPublic = true;
      this.cleanSession();
    }
    if(this.urlcode) {
      this.service = new PaginaEmpresa();
      this.service.fetchAll({ urlcode: this.urlcode, compact: false }).then((data: any)=> {
          this.pageList = data;
      })
    }
  }
}
