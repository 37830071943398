





























import { Component, Vue, Prop } from 'vue-property-decorator';
import AccountCofirmation from '@/core/Services/Candidato/AccountCofirmation';
import { Action } from 'vuex-class';

const namespace: string = "profile";

@Component
export default class FormConfirmation extends Vue {
  @Prop({default: null})
  public email!: string|null;

  @Prop({default: null})
  public buttonText!: string|null;

  @Action("userRequest", { namespace }) 
  public userRequest: any;

  public promise: Promise<any> |null = null;
  private service = new AccountCofirmation();

  public code: string|null = null;

  public submitForm() {
    if(this.code && this.email) {
      this.promise = this.service.create({
        email: this.email,
        code: this.code
      }).then(async (data: any) => {
        if(data?.access_token) {
          sessionStorage.setItem(
            "access_token",
            data.access_token + ""
          );
          sessionStorage.setItem("token_type", "Bearer");
          sessionStorage.setItem("profile_type", "candidate");
          await this.userRequest()
          this.$emit('success',data)
        } else {
          this.$alert.addMsg(this.$t('Informed code is invalid').toString())
          this.$alert.callBoxWarn()
          this.$emit('error',"Código inválido")
        }
        
      }).catch((ee) => {
        this.$alert.addMsg(this.$t('Informed code is invalid').toString())
        this.$alert.callBoxWarn()
        this.$emit('error',ee)
      })
    } 
  }

  get state() {
    return this.code && this.code.length == 6
  }

  get invalidFeedback() {
      if (this.code && this.code.length != 6) {
        return this.$t('Code will have 6 characters')
      }
      return ''
    }

}
