import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { UiState } from './state';

export const actions: ActionTree<UiState, RootState> = {
  showVagasFilter({commit}) {
    commit('showVagasFilter');
  },
  hideVagasFilter({commit}) {
    commit('showVagasFilter');
  },
  toggleVagasFilter({commit}) {
    commit('toggleVagasFilter');
  },
};
