










import { Vue, Component } from 'vue-property-decorator';
import BrowserSupport from "@/plugins/browser-support";


@Component
export default class App extends Vue {
  public created(){
    const browser = new BrowserSupport();
    browser.browserDetection(this.$i18n)
  }
  public mounted() {
      setTimeout(() => {
        const recaptchaBadges = document.querySelectorAll('.grecaptcha-badge');
        if(recaptchaBadges)
        for (let index = 0; index < recaptchaBadges.length; index++) {
          const element: any = recaptchaBadges[index];
          element.style.visibility = 'hidden'
        }
      },1000)
  }
}
