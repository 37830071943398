
















































// tslint:disable
// require('./static/css/layouts/page-center.css')
import Vue from "vue";
import { State, Action, Getter } from 'vuex-class';
import Component from 'vue-class-component';
import GestaoCandidato from "@/core/Services/Candidato/GestaoCandidato";
import NavBar from "@/views/layouts/NavBar.vue";
import Alert from '@/components/alerts/Alert.vue';
import ClientAuthentication from "@/core/Services/ClientAuthentication";
const namespace: string = 'profile';


@Component<ForgotPassword>({
  components: {
    "nav-bar": NavBar,
  }
})
export default class ForgotPassword extends Vue {
  public username = '';
  public gestaoCandidatoService!: GestaoCandidato;

  public showDismissibleAlert: boolean = false;
  public serverMessage?: string|null = null;
  public currentPromise: any|null = null;

  public alert!: Alert;

  constructor() {
    super();
    this.gestaoCandidatoService = new GestaoCandidato();
    this.alert = new Alert();
  }

  public cleanSession() {
    sessionStorage.removeItem('token_type');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('profile_type');
    sessionStorage.removeItem('profile');
  }
  public async forgot() {
    this.cleanSession();
    let rtoken: string|null = null;
    try {
      rtoken = await ClientAuthentication.fetchToken('candidate_login_forgot_pass');
    } catch(e) {
      console.log(e)
    }

    if (!this.username) {
      this.alert.addMsg(this.$t('Please enter a valid email!').toString());
      this.alert.addTitle(this.$t('Attention').toString());
      this.alert.callBoxDanger();
      return
    }
    let data: any = {
      forgot: true,
      email: this.username,
      rtoken
    };
    this.currentPromise = this.gestaoCandidatoService.create(data).then((data: any) => {
      this.alert.addMsg(this.$t('Your request is being processed, you will shortly receive an email with a password renewal link.').toString());
      this.alert.addTitle(this.$t('Attention').toString());
      this.alert.callBoxSuccess();
    }).catch((resp: any) => {
      this.showDismissibleAlert = true;
      try {
        this.serverMessage = this.$t('There was a problem').toString()
        this.serverMessage = resp.detail;
      } catch(e) {
        /* console.log(e) */
      }
    })
  }

  public toggleBodyClass(addRemoveClass: any, className:any) {
    const el = document.body;

    if (addRemoveClass === 'addClass') {
      el.classList.add(className)
    } else {
      el.classList.remove(className)
    }
  }

  public mounted() {
    this.toggleBodyClass('addClass', 'register')
  }

  public destroyed() {
    this.toggleBodyClass('removeClass', 'register')
  }
}
