import { render, staticRenderFns } from "./InterviewBoxTab.vue?vue&type=template&id=0a9b8492&scoped=true"
import script from "./InterviewBoxTab.vue?vue&type=script&lang=ts"
export * from "./InterviewBoxTab.vue?vue&type=script&lang=ts"
import style0 from "./InterviewBoxTab.vue?vue&type=style&index=0&id=0a9b8492&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a9b8492",
  null
  
)

export default component.exports