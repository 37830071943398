
import ApiRouter from '@/core/apirouting/EndpointRouterInterface';
import Repository from '@/core/Services/Repository';


export default class SetorIndustria extends Repository {
  public optionList?: any[];

  constructor() {
    super(new ApiRouter({
      fetch: { url: '/v1/setor-industria/{id}', method: 'GET', headers: {} },
      fetchAll: { url: '/v1/setor-industria', method: 'GET', headers: {} },
    }));
    this.optionList = [];
  }

  public async loadOptions() {
    const data: any = await this.fetchAll();
    if (data && data.setorIndustrias) {
      this.optionList = [
        {
          text: 'Selecione',
          value: ''
        }
      ];
      data.setorIndustrias.forEach((item: any) => {
        this.optionList!.push({
          text: item.descricao,
          value: item.descricao,
        });
      });
    }
  }

  public processArray(data: any, query: string): any {
    const arr: any = [];
    if (data.setorIndustrias.length > 0) {
      data.setorIndustrias.forEach((element: any) => {
        arr.push({ value: element.descricao, item: element.id });
      });
    } else {
      arr.push({ value: query, item: 0 });
    }
    return arr;
  }

}
